/*
    ./client/components/App.jsx
*/
import React from 'react';
import KeyBuildTypeSelector from './keyBuildTypeSelector.jsx';
import KeyBuildManualEditor from './keyBuildManualEditor.jsx';
import KeyBuildEditor from './keyBuildEditor.jsx';
import './styles.scss';

export default class KeyBuildCreation extends React.Component {

    getContent = () => {
        // If the object has an ID - this object goes into editing
        if(this.props.object.id && this.props.object.id > 0)
        {
            return <KeyBuildManualEditor />;
        }

        // Objecct creation based on user selected building source
        if(this.props.object.keyBuildType) {
            if(this.props.object.keyBuildType === "FROM_DATA") {
                return <KeyBuildEditor />;
            }
            if(this.props.object.keyBuildType === "MANUAL") {
                return <KeyBuildManualEditor />;
            }
        }

        // This is a new object - let the user select how to create it
        return <KeyBuildTypeSelector selectType={this.selectKeyBuildType} />
    }

    render() {
        return this.getContent();
    }
}