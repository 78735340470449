/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Dimmer, Loader, Message } from "semantic-ui-react";
import "whatwg-fetch";
import { ContourActions } from "../../../services/contours/actions.jsx";
import { Actions as IncidentActions } from "../../../services/incident/actions.jsx";
import { Actions as KeyBuildActions } from "../../../services/keyBuild/actions.jsx";
import { Actions as CommonActions } from "../../../services/common/actions.jsx";
import { CREATE_SIMULATION_FAIL, CREATE_SIMULATION_SUCCESS, SimulationActions } from "../../../services/simulation/actions.jsx";

class SimulationCreateEditor extends React.Component {
  state = {
    error: null,
    selectedSimulationType: null,
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    var { t } = this.props;
    // If duplicating, wait for the update to the selected simulation (If user clicked on clone simulation)
    if (
      !this.props.simulationState.duplicating &&
      prevProps.simulationState.duplicating
    ) {
      // Check if duplication was successful, by checking whether a new scenario was created
      if (this.props.simulationState.actionType === CREATE_SIMULATION_SUCCESS) {
        // Start fetching the incidents to update the store
        this.props.fetchIncidents(this.props.scenarioState.scenario.id);
      }
      else if (this.props.simulationState.actionType === CREATE_SIMULATION_FAIL) {
        this.setState({ loading: false, error: t("simulation.messages.failedToDuplicateSimulation") });
      }
    }

    // Check the incidents have been updated in the store
    if (
      prevProps.incidentState.loading &&
      !this.props.incidentState.loading
    ) {
      // Check if the incidents weere successfully updated
      if (!this.props.incidentState.error) {
        // If true, start updating the met profiles in the store
        this.props.fetchMets(this.props.scenarioState.scenario.id);
      } else {
        // An error occured - stop component loading and notify the user
        this.setState({ loading: false, error: t("simulation.messages.cloningProcessWasInterruptedReload") });
      }
    }

    // Check the mets have been updated in the store to start fetching key buildings
    if (
      prevProps.metState.loading &&
      !this.props.metState.loading
    ) {
      // Check if the met profiles were successfully updated
      if (!this.props.metState.error) {
        // If true, start updating the key buildings in the store
        this.props.fetchKeyBuildings(this.props.scenarioState.scenario.id);
      } else {
        // An error occured - stop component loading and notify the user
        this.setState({ loading: false, error: t("simulation.messages.cloningProcessWasInterruptedReload") });
      }
    }

    // Check the key buildings have been updated in the store
    if (
      prevProps.keyBuildState.loading &&
      !this.props.keyBuildState.loading
    ) {
      // Check if the key buildings were successfully updated
      if (!this.props.keyBuildState.error) {
        // If true, start updating the contour params in the store
        this.props.fetchContourConfigs(this.props.scenarioState.scenario.id);
      } else {
        // An error occured - stop component loading and notify the user
        this.setState({ loading: false, error: t("simulation.messages.cloningProcessWasInterruptedReload") });
      }
    }


    // Check the contour params have been updated in the store
    if (
      prevProps.contourConfigState.loading &&
      !this.props.contourConfigState.loading
    ) {
      // Check if the contour params were successfully updated
      if (!this.props.contourConfigState.error) {
        // Reset the loading state
        this.setState({ loading: false });
        this.props.next();
      } else {
        // An error occured - stop component loading and notify the user
        this.setState({ loading: false, error: t("simulation.messages.cloningProcessWasInterruptedReload") });
      }
    }

    // Check the status of creation of a simulation (If user clicked on new simulation)
    if (!this.props.simulationState.creatingSimulation && prevProps.simulationState.creatingSimulation) {
      // Check whether simulation was successfully created, and handle accordingly
      if (this.props.simulationState.actionType === CREATE_SIMULATION_SUCCESS) {
        // Reset the loading state
        this.setState({ loading: false });
        // Move on to the next panel
        this.props.next();
      }
      else if (this.props.simulationState.actionType === CREATE_SIMULATION_FAIL) {
        this.setState({ loading: false, error: t("simulation.messages.failedToCreateSimulation") });
      }
    }
  }

  createSimulation = () => {
    // Deselect a simulation first
    this.props.deselectSimulation();

    // Start loading process, and clear prevoius error if existed
    this.setState({ loading: true, error: null });

    // Create a new simulation
    this.props.createSimulation(this.props.scenarioState.scenario.id);
  };

  cloneSimulation = () => {
    if (this.props.simulationState.selectedSimulation) {
      let id = this.props.simulationState.selectedSimulation.id;
      this.props.deselectSimulation();

      // Notify the state that the component is loading data and clear any previous error
      this.setState({ loading: true, error: null });

      // Initiate the copying of a simulation
      this.props.copySimulation(id, this.props.scenarioState.scenario.id);
    }
  };

  changeSelectedSimulationType = (e, { name }) => {
    this.setState({ selectedSimulationType: name });
  };

  render() {
    var { t } = this.props;

    return (
      <div className="simulation-types">

        <Dimmer active={this.state.loading} inverted>
          <Loader>{t("simulation.messages.creating")}</Loader>
        </Dimmer>

        {this.state.error ?
          <Message negative>
            {this.state.error}
          </Message>
          : null
        }

        <Button
          onClick={this.cloneSimulation}
          disabled={this.props.simulationState.selectedSimulation === null}
        >
          {t("sidebar.copySimulation")}
        </Button>

        <Button name="UDM" onClick={this.createSimulation} color="pink">
          {t("simulation.createNewSimulation")}
        </Button>
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    simulationState: store.simulationState,
    incidentState: store.incidentState,
    keyBuildState: store.keyBuildState,
    metState: store.metState,
    contourConfigState: store.contourConfigState,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSimulation: (scenarioId) => {
      dispatch(SimulationActions.createSimulation(scenarioId));
    },
    deselectSimulation: () => {
      dispatch(SimulationActions.selectSimulation(null));
    },
    fetchIncidents: (scenarioId) => {
      dispatch(IncidentActions.fetchIncidents(scenarioId));
    },
    fetchMets: (scenarioId) => {
      dispatch(CommonActions.fetchMets(scenarioId));
    },
    fetchKeyBuildings: (scenarioId) => {
      dispatch(KeyBuildActions.fetchKeyBuildings(scenarioId));
    },
    fetchContourConfigs: (scenarioId) => {
      dispatch(ContourActions.fetchContourConfigs(scenarioId));
    },
    copySimulation: (simulation, scenarioId) => {
      dispatch(SimulationActions.copySimulation(simulation, scenarioId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SimulationCreateEditor);
