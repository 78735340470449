import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Menu, Popup } from "semantic-ui-react";
import IncidentsPanel from "./incidentsPanel";
import {ReactComponent as IncidentIcon} from '../../../images/Warning.svg';

/**
 * Button to control whether the incident displaying/editing views are visible
 */
class IncidentsMenu extends React.Component {
  getContent() {
    if (this.props.activeItem === this.props.name) {
      return (
        <IncidentsPanel cancel={this.props.cancel} next={this.props.next} />
      );
    }
  }

  render() {
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name={this.props.name}
              active={this.props.activeItem === this.props.name}
              onClick={this.props.onClick}
            >
             
             <IncidentIcon className="sidebar-icon"/>
             
            </Menu.Item> 
          }
          position={"right center"}
        >
          {this.props.title}
        </Popup>

        <div className="menu-option">{this.getContent()}</div>
      </>
    );
  }
}

export default compose(withTranslation())(IncidentsMenu);
