

import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { compose } from "redux";
import { Dimmer, Loader, Popup } from "semantic-ui-react";
import { ReactComponent as SimulationIcon } from '../../../images/Simulation.svg';
import { ContourActions } from "../../../services/contours/actions.jsx";
import { SimulationActions } from "../../../services/simulation/actions.jsx";

class SimulationLoader extends React.Component {

    state = {
        loading: true,
        loadingConfigs: true,
        loadingSimulations: true,
        error: false
    };

    componentDidMount = () => {
        // Start fetching data
        this.props.fetchSimulations(this.props.scenarioState.scenario.id);
        // NB In future it may be appropriate to have a separate icon for contour config
        this.props.fetchContourConfigs(this.props.scenarioState.scenario.id);
    };

    componentDidUpdate(prevProps) {
        // Check fetching finished
        if (!this.props.simulationState.loading && prevProps.simulationState.loading) {
            if (this.props.simulationState.error) {
                // Error occurred - notify parent:
                this.props.fetchError("Simulation");
                this.setState({ loading: false, error: true });
            } else {
                this.setState({ loadingSimulations: false, error: false });
            }
        } else if (!this.props.contourConfigState.loading && prevProps.contourConfigState.loading) {
            if (this.props.contourConfigState.error) {
                // Error occurred - notify parent:
                this.props.fetchError("Simulation");
                this.setState({ loading: false, error: true });
            } else {
                this.setState({ loadingConfigs: false, error: false });
            }
        }
        // If the component is still loading the data - check whether both simulations and configurations have been loaded
        // and then finish the loading process
        if (this.state.loading) {
            if (!this.state.loadingConfigs && !this.state.loadingSimulations && !this.state.error) {
                this.props.fetchFinished("Simulation");
                this.setState({ loading: false });
            }
        }
    }

    render() {
        const { t } = this.props;
        var additionalClass = "";
        if (!this.state.loading) {
            if (!this.state.error) {
                additionalClass = " success";
            } else {
                additionalClass = " error";
            }
        }

        return (
            <div className="data-loader">
                <Popup
                    content={t("sidebar.simulations")}
                    position={"right center"}
                    size='mini'
                    trigger={
                        <div style={{ position: "relative" }}>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader size='small' />
                            </Dimmer>
                            <SimulationIcon className={"data-loader-icon" + additionalClass} />
                        </div>
                    }
                />
            </div>
        );
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        simulationState: store.simulationState,
        contourConfigState: store.contourConfigState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSimulations: (scenarioId) => {
            dispatch(SimulationActions.fetchSimulations(scenarioId));
        },
        fetchContourConfigs: (scenarioId) => {
            dispatch(ContourActions.fetchContourConfigs(scenarioId));
        },
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SimulationLoader);