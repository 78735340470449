export const CoordinateUnits = Object.freeze({
    LAT_LON_DD: 'LAT_LON_DD',
    MGRS: 'MGRS'
});

export const WindDirectionUnits = Object.freeze({
    DEGREES: 'DEGREES',
    MILS: 'MILS'
});

export const DateFormats = Object.freeze({
    ISO: 'ISO',
    MIL_DATE_TIME_GROUP: 'MIL_DATE_TIME_GROUP'
});
