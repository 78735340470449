import React from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withTranslation } from "react-i18next";
import { Button, Message, Modal } from 'semantic-ui-react';
import { SimulationActions, UPDATE_SIMULATION_SUCCESS, UPDATE_SIMULATION_FAILURE } from "../../../services/simulation/actions.jsx";
import '../sidebar.scss';

class CancelSimulationModal extends React.Component {

    state = {
        loading: false,
        error: ""
    }

    componentDidUpdate(prevProps) {
        // Only handle updates if the modal is actually rendered
        if (this.props.showCancelModal) {
            // Handle the simulation cancellation here to decide
            // whether to hide the modal, or show an error message
            // Detect simulation update failure if that's the current action in simulation store
            if (prevProps.simulationState.actionType !== this.props.simulationState.actionType &&
                this.props.simulationState.actionType === UPDATE_SIMULATION_FAILURE) {

                // Simulation failed to get updated, show the error
                this.setState({ loading: false, error: t("simulation.messages.cancelFail") });
            } else if (prevProps.simulationState.actionType !== this.props.simulationState.actionType &&
                this.props.simulationState.actionType === UPDATE_SIMULATION_SUCCESS) {

                // The update was successful, hide the modal
                this.props.changeCancelState();
            }
        }
    }

    cancelSimulation = () => {

        // Update the simulation status to CANCELLED
        const simulationDto = {
            type: 'SimulationStatus',
            id: this.props.simulationId,
            status: 'CANCELLED'
        };
        this.props.updateSimulation(simulationDto, this.props.scenarioId);
    }

    getError = () => {
        if (this.state.error) {
            return (
                <Message negative>
                    {this.state.error}
                </Message>
            )
        }
    }


    render() {
        var { t } = this.props;

        if (this.props.showCancelModal) {
            return (
                <div className="scenario-title">
                    <Modal
                        className="ua-modal title-modal"
                        open={this.props.showCancelModal}
                    >
                        <Modal.Header>{t("simulation.messages.confirmSimulationCancel")}</Modal.Header>
                        <Modal.Content>
                            {t("simulation.messages.confirmCancel")}
                            {this.getError()}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                color='black'
                                onClick={() => this.props.changeCancelState()}
                                disabled={this.state.loading}
                            >
                                {t("app.no")}
                            </Button>
                            <Button
                                className="accept-button"
                                onClick={this.cancelSimulation}
                                disabled={this.state.loading}
                            >
                                {t("app.yes")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (store, props) => {
    return {
        simulationState: store.simulationState,
        ...props
    };
};

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        updateSimulation: (simulationDto, scenarioId) => {
            dispatch(SimulationActions.updateSimulation(simulationDto, scenarioId));
        }
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CancelSimulationModal);
