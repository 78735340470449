import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, List, Message } from "semantic-ui-react";
import { Actions } from "../../services/user/actions";
import StatusMessage from "../common/StatusMessage";
import "./appHeader.scss";
import Logo from './ua-logo.png';
import Requests from '../../services/requests';

class Login extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      email: "",
      password: "",
      actionSelected: false,
      error: props.error,
      loading: false
    };
  }

  login = () => {
    const { t } = this.props;

    var user = {
      email: this.state.email.trim(),
      password: this.state.password,
    };

    this.setState({ error: null, loading: true });

    Requests.post('/accounts/login', user).then(response => {
      if (response && response.user) {
        this.props.setUser(response.user);
        if (this.props.onSuccessLogin) {
          this.props.onSuccessLogin();
        }
      } else {
        this.setState({ error: t('messages.errorCheckLogs'), loading: false });
      }
    })
      .catch(err => {
        console.log("Caught error, ", err);
        if (err.message) {
          if (err.message.includes("User has been archived")) {
            this.setState({ error: t('login.messages.archivedUser'), loading: false });
            return;
          }
          this.setState({ error: err.message, loading: false });
          return;
        }
        this.setState({ error: t('messages.errorCheckLogs'), loading: false });
      });
  }

  validate = () => {
    /**
     * Validate the input username and password.
     */

    if (!this.state.email || this.state.email === "") {
      return false;
    }
    if (!this.state.password || this.state.password === "") {
      return false;
    }
    return true;
  };

  getLoginForm = () => {
    const { t } = this.props;

    return (
      <List className="point-properties-card">
        <List.Item>
          <Form className="ua-login-form">
            <Form.Field>
              <Form.Input
                required={true}
                id="register-username"
                label={t('title.email')}
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value })
                } />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required={true}
                id="register-password"
                type="password"
                label={t('title.password')}
                value={this.state.password}
                onChange={(e) =>
                  this.setState({ password: e.target.value })
                } />
            </Form.Field>
            {this.state.error &&
              <StatusMessage
              isError={true}
              messageHeader={t('app.error')}
              messageContent={this.state.error}/>
            }
            <div>
              <Button
                className="login-button"
                onClick={this.login}
                loading={this.state.loading}
                disabled={!this.validate()}
              >
                {t('title.login')}
              </Button>
              {
                // If onlyLogin - do not render register/forgottenPassword buttons
                this.props.onlyLogin ? null :
                  <>
                    <button
                      className="register" onClick={this.props.registerClick}>{t('title.register')}
                    </button>
                    <div className="forgotten-password" onClick={this.props.resetPasswordClick}>{t('title.forgottenPassword')}</div>
                  </>
              }
            </div>
          </Form>
        </List.Item>
      </List>
    );
  }

  render() {
    /**
     * Render the Login Form
     */

    const { t } = this.props;
    return (
      <div className={"login-panel"}>
        <div className="login-title-panel">
          {t("title.loginTitle")}
          <img src={Logo} alt="UrbanAware logo" />
        </div>
        <div className="login-content">
          {this.getLoginForm()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(Actions.setUser(user));
    },
  };
};

export default compose(withTranslation(), connect(null, mapDispatchToProps))(Login);
