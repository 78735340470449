import React from 'react';
import { LayerGroup, WMSTileLayer, LayersControl } from 'react-leaflet';
import RasterDataLegend from '../legends/RasterDataLegend.jsx';

const { Overlay, } = LayersControl
const GEOSERVER_POPULATION_LAYER_NAME = 'total_population'

export class PopulationLayers {

    static getMapLayers(t) {
        // UA Geoserver URL
        const url = window.env.REACT_APP_GEOSERVER_URL + '/geoserver/geo_data/wms';

        return (
            <Overlay name={t("map.totalPopulation")}>
                <LayerGroup id="populationLayer" >
                    <WMSTileLayer
                        url={url}
                        layers={GEOSERVER_POPULATION_LAYER_NAME}
                        format='image/png'
                        transparent='true'
                        opacity="0.7"
                    />
                </LayerGroup>
            </Overlay>
        )
    }

    static getLegend(t) {
        return <RasterDataLegend
            layerName="populationLayer"
            layerTitle={t("map.totalPopulation")}
            noDataLabel={t("map.noPopulationData")}
            url="/legends/population/"
            unitsLabel=""
        />;
    }
}