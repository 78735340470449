/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Loader } from "semantic-ui-react";
import { Actions } from "../../../../services/incident/actions.jsx";
import "../sources.scss";

class IncidentTemplateDropdown extends React.Component {

    state = {
        incidentDirectory: "",
        incidentList: [],
        selectedTemplate: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            incidentDirectory: props.incidentDirectory,
            incidentList: [],
            selectedTemplate: null
        };
    }

    componentDidMount() {
        Actions.getAvailableIncidentTemplates(this.state.incidentDirectory).then(
            (incidentList) => {
                // If there is only one choice, just move to the next page
                if (incidentList != undefined && incidentList.length === 1) {
                    this.setState({
                        incidentList: incidentList,
                        selectedTemplate: incidentList[0].fileName
                    });
                    this.createIncidentFromFile()
                }
                // Default select first element
                else if (incidentList.length > 0) {
                    this.setState({
                        incidentList: incidentList,
                        selectedTemplate: incidentList[0].fileName
                    });
                }
            }
        );
    }

    validate = () => {
        if (!this.state.selectedTemplate) {
            return false;
        }

        return true;
    };

    setSelectedTemplate = (data) => {
        this.setState({
            selectedTemplate: data.value
        });
    };

    createIncidentFromFile = () => {
        this.props.createComplexIncident(this.state.selectedTemplate, this.state.incidentDirectory);
    };

    render() {
        const { t } = this.props;
        if (this.state.incidentList === undefined || this.state.incidentList.length <= 1) {
            return (<Loader active inline='centered' size='medium'></Loader>)
        }
        return (
            <Form className="ua-form">
                <Form.Group>
                    <Form.Field>
                        <Form.Select
                            inline
                            required={true}
                            label={t("incident.chooseTemplate")}
                            options={this.state.incidentList.map((f) => {
                                return { key: f.fileName, text: f.displayName, value: f.fileName };
                            })}
                            value={this.state.selectedTemplate}
                            onChange={(e, data) => this.setSelectedTemplate(data)}
                        />
                    </Form.Field>
                </Form.Group>

                <div className="create-source">
                    <button
                        disabled={!this.validate()}
                        onClick={(e) => this.createIncidentFromFile()}>
                        {t("incident.createTemplatedIncident")}
                    </button>
                </div>
            </Form>
        )
    };
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        incidentState: store.incidentState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getIncidentTemplates: (dirName) => {
            dispatch(Actions.getAvailableIncidentTemplates(dirName));
        },
        createComplexIncident: (filename, directory) => {
            dispatch(Actions.createComplexIncident(filename, directory));
        }
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(IncidentTemplateDropdown);
