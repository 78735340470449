/*
    ./client/components/App.jsx
*/
import React from "react";
import { Dropdown, Form, Input } from "semantic-ui-react";
import "./styles.scss";
import UnitConverter from "./UnitConverter";
import units from "./units.json";

const MAX_DECIMAL_PLACES_ROUND = 10;

export default class InputFieldWithUnits extends React.Component {

  constructor(props) {
    super(props);

    // Get unit converter method.
    let isTemperatureValue = "TEMPERATURE" === props.type;
    if (isTemperatureValue) {
      this.convertUnits = (value, currentUnit, newUnit) =>
        UnitConverter.convertTemperature(value, currentUnit, newUnit);
    } else {
      this.convertUnits = (value, currentUnit, newUnit) =>
        UnitConverter.convertMultiplicative(value, currentUnit, newUnit);
    }

    // Get the default/SI units that the DB uses.
    let unitList = units[props.type];
    if (isTemperatureValue) {
      // Special case for temperature
      this.defaultUnit = unitList.find(unit => "kelvin" === unit.key);
    } else {
      this.defaultUnit = unitList.find(unit => 1 === unit.convert);
    }

    var displayValue = this.getDisplayValue(
      props.value,
      props.unit,
      props.type
    );

    this.state = {
      selectedUnit: props.unit,
      unitType: props.type,
      value: displayValue
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value) {
      this.setValue(this.props.value, false);
    }
  };

  setValue = (value, propagateToProps) => {

    // Special case for empty fields and '-'
    let nonwhitespace = /[^\s]/;
    if (!value.toString().match(nonwhitespace) || value === '-') {
      this.setState({ value: value });
      if (propagateToProps) {
        this.props.setValue(value);
      }

      return;
    }

    // Validate that the input is a number
    if (isNaN(value) && value !== '-') {
      console.warn("Cannot parse input to a number", value);
      return;
    }

    // Convert value to the default unit and pass it to parent
    const unitList = units[this.state.unitType];
    const currentUnit = unitList.find(unit => unit.value === this.state.selectedUnit);
    const isTemperatureValue = "TEMPERATURE" === this.state.unitType;

    if (!currentUnit) {
      throw "Not found unit information for " + this.state.selectedUnit;
    }

    if (propagateToProps) {
      // Convert to default units; call method to setValue to inform parent of changes
      const valueInDefUnit = this.convertUnits(value, currentUnit, this.defaultUnit);
      if (this.isAcceptableNumber(valueInDefUnit)) {
        this.setState({ value: value });
        this.props.setValue(valueInDefUnit);
      }
    } else {
      // Convert from default to current units
      const valueInDefUnit = this.roundToX(this.convertUnits(value, this.defaultUnit, currentUnit), MAX_DECIMAL_PLACES_ROUND);
      if (this.isAcceptableNumber(valueInDefUnit)) {
        this.setState({ value: valueInDefUnit });
      }
    }
  };

  getDisplayValue = (valueInSI, selectedUnit, unitType) => {
    if (!this.isAcceptableNumber(valueInSI)) {
      return "";
    }

    const unitList = units[unitType]; // eslint-disable-line security/detect-object-injection
    const currentUnit = unitList.find(unit => unit.value === selectedUnit);

    if (!currentUnit) {
      throw "Not found unit information for " + selectedUnit;
    }

    let displayValue = this.roundToX(this.convertUnits(valueInSI, this.defaultUnit, currentUnit), MAX_DECIMAL_PLACES_ROUND);
    // Input value cannot be null, but can be empty string
    if (displayValue === null) {
      displayValue = "";
    }
    return displayValue;

  };

  roundToX = (num, X) => {
    // If the provided number already in 'e' notation - just return it, as it's too small for further rounding
    if (num && num.toString().toUpperCase().includes("E")) {
      return num;
    }
    return +(Math.round(num + "e+" + X) + "e-" + X);
  }

  isAcceptableNumber = (value) => {
    return !isNaN(value) && Number.parseFloat(value) == value && value !== Infinity && value !== -Infinity;
  }

  unitsChange = (e, data) => {
    const unitList = units[this.state.unitType];
    var numSigFigs = 6;
    var currentUnit;
    var nextUnit;
    for (var i = 0; i < unitList.length; i++) {
      var unitFromList = unitList[i]; // eslint-disable-line security/detect-object-injection
      if (unitFromList.value === data.value) {
        nextUnit = unitFromList;
      } else if (unitFromList.value === this.state.selectedUnit) {
        currentUnit = unitFromList;
      }
    }

    let newValue = this.state.value;
    if (this.isAcceptableNumber(newValue)) {
      newValue = Number.parseFloat(
      this.roundToX(this.convertUnits(this.state.value, currentUnit, nextUnit).toPrecision(numSigFigs)
        , MAX_DECIMAL_PLACES_ROUND));
    }

    this.setState({
      selectedUnit: data.value,
      value: newValue,
    });
  };

  render() {
    return (
      <Form.Field inline={this.props.inline} required={this.props.required}
        error={!!this.props.error}>
        <label>{this.props.label}</label>
        <Input
          label={
            <Dropdown
              className="units-dropdown"
              defaultValue={this.state.selectedUnit}
              options={units[this.state.unitType]}
              onChange={this.unitsChange}
              disabled={this.props.disabled}
              upward={this.props.upward}
            />
          }
          className={"input-bar"}
          labelPosition="right"
          value={this.props.displayBlank ? "" : this.state.value}
          onChange={(e) => this.setValue(e.target.value, true)}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
        />
        {this.props.error ? <div className="ui pointing above prompt label">
          {this.props.error}
        </div> : null}
      </Form.Field>
    );
  }
}
