import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Checkbox, Button } from 'semantic-ui-react';
import AuditAccordion from "./auditAccordion.jsx";
import "../styles.scss";

class AuditPanel extends React.Component {

  state = {
    simulationOnly: false
  }

  getCheckBox = () => {
    let { t } = this.props;
    let disabled = false;
    let onCheckboxClick = this.selectSimulationOnlyAudits;
    // If no simulation is selected - disable the option
    // Also override the onClick, as the checkbox seems to only apply styling for disable
    if (!this.props.simulationState.selectedSimulation) {
      disabled = true;
      onCheckboxClick = null;
    }

    return (
      <div className="options">
        <Checkbox label={t('auditTool.currentSimulationOnly')}
          checked={this.state.simulationOnly}
          onClick={onCheckboxClick}
          disabled={disabled} />
        <Button
          type="button"
          className="download-report"
          onClick={this.downloadReport} >
          {this.getDownloadBtnTitle()}
        </Button>
      </div>
    );
  }

  getDownloadBtnTitle = () => {
    let { t } = this.props;
    if (this.isSimulationOnly()) {
      return t("auditTool.downloadSimulationReport");
    }
    return t("auditTool.downloadScenarioReport");
  }

  selectSimulationOnlyAudits = (e, data) => {
    this.setState({ simulationOnly: data.checked });
  }

  downloadReport = () => {
    // Determine whether downloading full scenario report
    // or just simulation report
    if (this.isSimulationOnly()) {
      this.props.downloadReport(null, this.props.simulationState.selectedSimulation.id);
    } else {
      this.props.downloadReport(this.props.scenarioState.scenario.id);
    }
  }

  isSimulationOnly = () => {
    return this.props.simulationState.selectedSimulation && this.state.simulationOnly;
  }

  getAudits = () => {
    let audits = this.props.auditState.audits;
    // If only simulation audits are selected - filter out the audits by simulation ID
    if (this.isSimulationOnly()) {
      audits = audits.filter(audit => audit.simulationId === this.props.simulationState.selectedSimulation.id);
    }

    return audits;
  }

  getLastUpdatedFooter = () => {
    let { t } = this.props;
    if (this.props.auditState.lastFetchTime) {
      return (
        <div className="audits-footer">
          {t('auditTool.lastUpdated')}<span>&nbsp;{new Date(this.props.auditState.lastFetchTime).toLocaleString()}</span>
        </div>
      )
    }
    return null;
  }

  getAccordionKey = () => {
    let key = "-" + this.props.scenarioState.scenario.id;
    if (this.isSimulationOnly()) {
      key = key + "-" + this.props.simulationState.selectedSimulation.id;
    }
    return key;
  }

  render() {
    let audits = this.getAudits();
    return (
      <div className="content-body audits-panel">
        {this.getCheckBox()}
        <AuditAccordion key={audits.length + this.getAccordionKey()} audits={audits} />
        {this.getLastUpdatedFooter()}
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    simulationState: store.simulationState,
    auditState: store.auditState,
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(AuditPanel);
