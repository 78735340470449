import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    FeatureGroup
} from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DetectorMarker from './DetectorMarker';
import Requests from '../../../services/requests';
import { w3cwebsocket as W3CWebSocket } from "websocket";

const MINUTE = 60000;

class DetectorsLayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sensors: [],
            sensorErrors: null
        };
    }

    componentDidMount() {
        this.props.mapState.map.on("dragend", this.getSensors);
        this.props.mapState.map.on("zoomend", this.getSensors);
        // Fetch sensors on the initial render
        this.getSensors();
        this.connectWS();
    }

    connectWS = () => {
        this.client = new W3CWebSocket(`wss://${window.location.host}/ws`);
        this.client.onmessage = (message) => {
            if(message && message.data) {
                this.handleSensorMessage(JSON.parse(message.data));
            }
        };
        this.client.onerror = (error) => {
            console.error("websocket connection error", error);
        };
        this.client.onclose = (event) => {
            console.error("websocket connection closed", event);
        };
    }

    disconnectWS = () => {
        if(this.client) {
            console.log("Disconnect from websocket server");
            this.client.close();
        }
    }

    handleSensorMessage = (sensor) => {
        const currentSensors = JSON.parse(JSON.stringify(this.state.sensors));
        // Either add the sensor to the list, add the matching sensors reading
        const foundSensor = currentSensors.find(s => s.id === sensor.id && s.deviceId === sensor.deviceId);
        if(foundSensor) {
            foundSensor.readings.push(...sensor.readings);
        } else {
            currentSensors.push(sensor);
        }
        this.setState({sensors: currentSensors});
    }

    componentDidUpdate(prevProps) {
        const selectedSimulation = this.props.simulationState.selectedSimulation;
        const prevSimulation = prevProps.simulationState.selectedSimulation;
        const fromNullToSelection = prevSimulation === null && selectedSimulation !== null;
        const fromSelectionToNull = prevSimulation !== null && selectedSimulation === null;

        if (fromNullToSelection || fromSelectionToNull || (selectedSimulation !== null && prevSimulation != null && selectedSimulation.id !== prevSimulation.id)) {
            this.getSensors();
        }

        if (this.props.userState.verified !== prevProps.userState.verified) {
           if(!this.props.userState.verified) {
                this.disconnectWS();
           } else {
                this.connectWS();
           }
        }
    }

    componentWillUnmount() {
        // Remove the fetch polling timer
        clearInterval(this.sensorsFetchTimer);
        this.sensorsFetchTimer = null;
    }

    getSensors = () => {

        // If the user is not verified - do not fetch until the user gets verified again
        if (!this.props.userState.verified) {
            return;
        }

        const mapBounds = this.props.mapState.map.getBounds();
        const boundsGeometry = {
            type: "Polygon",
            coordinates: [[
                [mapBounds.getNorthWest().lng, mapBounds.getNorthWest().lat],
                [mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat],
                [mapBounds.getSouthEast().lng, mapBounds.getSouthEast().lat],
                [mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat],
                [mapBounds.getNorthWest().lng, mapBounds.getNorthWest().lat]
            ]]
        };
        const timeDomain = this.getTimeBounds();
        this.fetchSensorData(JSON.stringify(boundsGeometry), timeDomain);
    }

    getTimeBounds = () => {
        const selectedSimulation = this.props.simulationState.selectedSimulation;
        if (selectedSimulation && (selectedSimulation.status === "FINISHED")) {
            var times = this.props.resultsState.simulationTimes[selectedSimulation.id];

            return [new Date(times[0]), new Date(times[times.length - 1])];
        } else {
            let currTime = new Date();
            let pastTime = new Date();
            pastTime.setHours(currTime.getHours() - 1);
            return [pastTime, currTime];
        }
    }

    fetchSensorData = (geometry, timeDomain) => {
        const { t } = this.props;
        let errorMessage = t("sensor.error");

        Requests.post("/sensors/region", { geometry: geometry, startTime: timeDomain[0], endTime: timeDomain[1] })
            .then(res => {
                if (res) {
                    this.setState({ sensorErrors: null, sensors: res });
                }
                else {
                    console.error("Error fetching sensor information");
                    this.setState({ sensorErrors: errorMessage });
                }
            })
            .catch(err => {
                console.error("Error fetching sensor information: " + err);
                this.setState({ sensorErrors: errorMessage });
            });
    }

    parseSensors = () => {
        let markers = [];
        let currTime = this.props.currentTime;
        for (let i in this.state.sensors) {
            const sensor = this.state.sensors[i];
            markers.push(
                <DetectorMarker
                    key={`${sensor.id}-${currTime}`}
                    sensor={sensor}
                    sensorErrors={this.state.sensorErrors}
                />
            );
        }
        return markers;
    }

    render() {
        return (
            <FeatureGroup>
                {this.parseSensors()}
            </FeatureGroup>
        )
    }

}

const mapStateToProps = (store) => {
    return {
        currentTime: store.mapState.currentTime,
        simulationState: store.simulationState,
        resultsState: store.resultsState,
        mapState: store.mapState,
        userState: store.userState
    }
}

export default compose(withTranslation(), connect(mapStateToProps, null))(DetectorsLayer);