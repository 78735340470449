import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import "./appHeader.scss";
import CreateOrLoadScenario from "./createOrLoadScenario";

class StartupWindow extends React.Component {
  /**
   * Panel which renders the CreateOrLoadScenario 
   * after a small time interval.
   */
  state = {
    mounted: false
  };

  componentDidMount = () => {
    setTimeout(() => this.setState({ mounted: true }), 10);
  };

  closeScenarioLoader = () => {
    this.props.closeScenarioLoader();
  };

  render() {
    /**
     * render method for the StartupWindow
     */

    return (
      <div className={"startup-window"}>
        <div className={"actions-box"}>
          <CreateOrLoadScenario onClick={this.closeScenarioLoader} />
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(StartupWindow);
