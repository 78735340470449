import { SELECT_LOCATION, SET_MAP, HIDE_LAYER, SHOW_LAYER, SELECT_CURRENT_TIME } from './actions.jsx';

export class MapReducer {

	static mapInfo(state, action) {

		const initialState = {
			location: null,
			position: null,
			currentTime: null,
			map: null
		}

		switch (action.type) {
			case SELECT_LOCATION:
				return Object.assign({}, state, {
					location: action.payload.location,
					position: action.payload.position,
				})
			case SELECT_CURRENT_TIME:
				return Object.assign({}, state, {
					currentTime: action.payload
				})
			case SET_MAP:
				return Object.assign({}, state, {
					map: action.payload
				})
			default:
				return state || initialState;
		}
	}

	static mapFilterInfo(state, action) {

		const initialState = {
			hiddenLayerIds: []
		}

		switch (action.type) {
			case HIDE_LAYER:
				var newHiddenLayerIds = state.hiddenLayerIds;
				newHiddenLayerIds.push(action.payload);
				return Object.assign({}, state, {
					hiddenLayerIds: newHiddenLayerIds
				})
			case SHOW_LAYER:
				var newHiddenLayerIds = state.hiddenLayerIds.filter(item => item !== action.payload);
				return Object.assign({}, state, {
					hiddenLayerIds: newHiddenLayerIds
				})
			default:
				return state || initialState;
		}
	}

}