import 'leaflet';
import L from 'leaflet';
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { compose } from "redux";
import { Button, Message } from "semantic-ui-react";
import { Actions } from "../../../services/scenario/actions.jsx";
import AOILoader from './AOILoader';
import KeyBuildLoader from './KeyBuildLoader';
import MetLoader from './MetLoader';
import OutputLoader from './OutputLoader';
import SimulationLoader from './SimulationLoader';
import SourceLoader from './SourceLoader';

const INCLUDE_SIMULATIONS = window.env.REACT_APP_INCLUDE_SIMULATIONS === "true";

class ScenarioLoader extends React.Component {

    state = {
        loading: true,
        errors: [],
        progress: [],
        hash: "load-now"
    };

    componentDidUpdate() {

        let progressLength = 6;
        if (!INCLUDE_SIMULATIONS) {
            // Only check AOI
            progressLength = 1;
        }

        // If progress contains 6 entries, all data has been finished loading
        if (this.state.progress.length === progressLength && this.state.loading) {
            this.setState({ loading: false });

            // If no errors occurred - display selected scenario panels
            if (this.state.errors.length === 0) {
                this.recentreMap();
            }
        }
    }

    recentreMap = () => {
        let latitudes = [];
        let longitudes = [];

        // Get all locations of incident sources and incidents without sources
        for (var i in this.props.incidentState.incidents) {
            let incident = this.props.incidentState.incidents[i] // eslint-disable-line security/detect-object-injection
            if (incident.sources.length > 0) {
                for (var j in incident.sources) {
                    this.getLatLonsFromGeometry(incident.sources[j].geometry, latitudes, longitudes); // eslint-disable-line security/detect-object-injection
                }
            } else {
                this.getLatLonsFromGeometry(incident.geometry, latitudes, longitudes);
            }
        }

        // Get all locations of aois
        for (var key in this.props.aoiState.aois) {
            let aoi = this.props.aoiState.aois[key] // eslint-disable-line security/detect-object-injection
            this.getLatLonsFromGeometry(aoi.geometry, latitudes, longitudes);
        }

        // Set the map window based on all the points we have found
        // Only fly if the scenario has been set up with something to fly to
        if (latitudes.length > 0) {
            let latMax = Math.max(...latitudes);
            let lonMax = Math.max(...longitudes);
            let latMin = Math.min(...latitudes);
            let lonMin = Math.min(...longitudes);
            let corner1 = L.latLng(latMax, lonMax);
            let corner2 = L.latLng(latMin, lonMin);
            let bounds = L.latLngBounds(corner1, corner2);
            this.props.mapState.map.flyToBounds(bounds, {
                padding: [50, 50],
                maxZoom: 15
            });
        }
        
        this.props.closeScenarioLoader();
    }

    getLatLonsFromGeometry(geometry, latitudes, longitudes) {
        if (geometry.type === "Point") {
            // Coordinates is just a lat lon                    
            latitudes.push(geometry.coordinates[1]);
            longitudes.push(geometry.coordinates[0]);
        }
        else if (geometry.type === "Polygon") {
            // Coordinates is an array of lat lons
            geometry.coordinates.forEach(coords => {
                coords.forEach(coord => {
                    latitudes.push(coord[1]);
                    longitudes.push(coord[0]);
                });
            });
        }
        else {
            // Coordinates is an array of lat lons
            geometry.coordinates.forEach(coord => {
                latitudes.push(coord[1]);
                longitudes.push(coord[0]);
            });
        }
    }

    fetchError = (dataType) => {
        // Increment progress and save error
        this.setState(prevState => ({
            ...prevState,
            progress: [...prevState.progress, dataType],
            errors: [...prevState.errors, dataType],
        }));
    }

    fetchFinished = (dataType) => {
        // Increment progress
        this.setState(prevState => ({
            ...prevState,
            progress: [...prevState.progress, dataType],
        }));
    }

    back = () => {
        // Set no scenario to be selected
        this.props.selectScenario(null);
    }

    retry = () => {
        // Reset the state and assign new hash to be used as a key to re-render loaders
        this.setState({
            hash: "load-" + new Date(),
            loading: true,
            errors: [],
            progress: []
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="scenario-loader-div">
                <div key={this.state.hash} className="scenario-loader">
                    { INCLUDE_SIMULATIONS ? <SimulationLoader fetchError={this.fetchError} fetchFinished={this.fetchFinished} /> : null }
                    <AOILoader fetchError={this.fetchError} fetchFinished={this.fetchFinished} />
                    { INCLUDE_SIMULATIONS ? <SourceLoader fetchError={this.fetchError} fetchFinished={this.fetchFinished} /> : null }
                    { INCLUDE_SIMULATIONS ? <KeyBuildLoader fetchError={this.fetchError} fetchFinished={this.fetchFinished} /> : null }
                    { INCLUDE_SIMULATIONS ? <MetLoader fetchError={this.fetchError} fetchFinished={this.fetchFinished} /> : null }
                    { INCLUDE_SIMULATIONS ? <OutputLoader fetchError={this.fetchError} fetchFinished={this.fetchFinished} /> : null }
                </div>
                {this.state.loading ?
                    <div className='scenario-loader-loading'>
                        {t('scenario.messages.loading')}
                    </div>
                    :
                    this.state.errors.length > 0 ?
                        <div className='scenario-loader-loading'>
                            <Message negative className="ua-error">
                                <Message.Header>{t('app.error')}</Message.Header>
                                <p>{t('scenario.messages.failedToLoadScenario')}</p>
                            </Message>
                            <Button className="scenario-button retry" onClick={this.retry}>{t("scenario.retry")}</Button>
                            <Button className="scenario-button retry" onClick={this.back}>{t("scenario.back")}</Button>
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        incidentState: store.incidentState,
        aoiState: store.aoiState,
        mapState: store.mapState,
        userState: store.userState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        selectScenario: (scenario) => {
            dispatch(Actions.selectScenario(scenario));
        },
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ScenarioLoader);