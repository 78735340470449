
class FetchRequests {
    get = async (url, isBlob) => {
        return fetch(url)
            .then(response => {
                if (response.ok) {
                    if (isBlob) {
                        return response.blob();
                    }
                    return response.json().catch(err => {
                        // If the response was success, but json failed, means the returned object was null
                        return null;
                    });
                } else {
                    return response.json()
                        .then(json => {
                            throw new Error(json.message);
                        })
                        .catch(err => {
                            throw err;
                        });
                }
            })
            .catch(err => {
                throw err;
            })
    };

    post = async (url, body) => {
        let headersAndBody = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }

        if (body) {
            headersAndBody.body = JSON.stringify(body)
        }

        return fetch(url, headersAndBody)
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .catch(err => {
                            // If the response was success, but json failed, means the returned object was null
                            return null;
                        });
                } else {
                    return response.json()
                        .then(json => {
                            throw new Error(json.message);
                        })
                        .catch(err => {
                            throw err;
                        });
                }
            })
            .catch(err => {
                throw err;
            })
    };

    patch = async (url, body) => {
        let headersAndBody = {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }

        if (body) {
            headersAndBody.body = JSON.stringify(body)
        }

        return fetch(url, headersAndBody)
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .catch(err => {
                            // If the response was success, but json failed, means the returned object was null
                            return null;
                        });
                } else {
                    return response.json()
                        .then(json => {
                            throw new Error(json.message);
                        })
                        .catch(err => {
                            throw err;
                        });
                }
            })
            .catch(err => {
                throw err;
            })
    };

    put = async (url, body) => {
        let headersAndBody = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }

        if (body) {
            headersAndBody.body = JSON.stringify(body);
        }

        return fetch(url, headersAndBody)
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .catch(err => {
                            // If the response was success, but json failed, means the returned object was null
                            return null;
                        });
                } else {
                    return response.json()
                        .then(json => {
                            throw new Error(json.message);
                        })
                        .catch(err => {
                            throw err;
                        });
                }
            })
            .catch(err => {
                throw err;
            })
    };

    deleteRequest = async (url) => {
        let headersAndBody = {
            method: 'DELETE'
        }

        return fetch(url, headersAndBody)
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .catch(err => {
                            // If the response was success, but json failed, means the returned object was null
                            return null;
                        });
                } else {
                    return response.json()
                        .then(json => {
                            throw new Error(json.message);
                        })
                        .catch(err => {
                            throw err;
                        });
                }
            })
            .catch(err => {
                throw err;
            })
    };

}

export default FetchRequests;