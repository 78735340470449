/*
    ./client/components/App.jsx
*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown, Menu, Table } from 'semantic-ui-react';
import 'whatwg-fetch';
import { ReactComponent as FilterIcon } from '../../../images/Filter.svg';
import { Actions } from "../../../services/map/actions.jsx";
import { DataUtil } from '../../common/DataUtil.jsx';
import PanelWrapper from '../../common/panelWrapper.jsx';
import './styles.scss';

class DataFilterMenu extends React.Component {

    state = {
        dataType: 'SOURCES'
    }

    constructor(props) {

        super(props);
        const { t } = this.props;

        this.dataTypes = [
            { key: 'sources', text: t('Sources'), value: 'SOURCES' },
            { key: 'pois', text: t('Points Of Interest'), value: 'POIS' },
            { key: 'met', text: t('Met Observations'), value: 'MET' },
            { key: 'keyb', text: t('Key Buildings'), value: 'KEY_B' }
        ]
    }

    getTable = () => {
        var { t } = this.props;

        return (
            <Table celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("filteringTool.name")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("filteringTool.display")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.getRows()}
                </Table.Body>
            </Table>
        )
    }

    getRows = () => {
        var simulationId = this.props.simulationState.selectedSimulation.id;
        if (this.state.dataType === 'SOURCES') {
            var sources = [];
            Object.values(this.props.incidentState.incidents).forEach(s => {
                if (s.simulationId === simulationId) {
                    sources = sources.concat(s.sources);
                }
            });

            return this.getDataRows(sources);
        }
        if (this.state.dataType === 'POIS') {
            return this.getDataRows(this.props.aoiState.aois);
        }
        if (this.state.dataType === 'KEY_B') {
            return this.getDataRows(this.props.keyBuildState.keyBuilds);
        }
        if (this.state.dataType === 'MET') {
            var metProfiles = [];
            Object.values(this.props.metState.metProfiles).forEach(m => {
                if (m.simulationId === simulationId) {
                    metProfiles.push(m);
                }
            });
            return this.getDataRows(metProfiles);
        }

        return null;
    }

    getDataRows = (dataMap) => {
        var { t } = this.props;
        let data = [];

        for (var m in dataMap) {
            let ind = m;
            let source = dataMap[m]; // eslint-disable-line security/detect-object-injection
            let checked = this.isDisplayed(source.id);
            var firstCol = "";
            if (this.state.dataType === 'SOURCES') {
                firstCol = DataUtil.capitaliseFirstLetter(source.agentType) + ': ' + source.materialName;
            }
            if (this.state.dataType === 'POIS') {
                firstCol = source.aoiType + ': ' + source.name;
            }
            else if (this.state.dataType === 'KEY_B') {
                firstCol = source.name;
            }
            else if (this.state.dataType === 'MET') {
                firstCol = t("sidebar.metObservation") + (m + 1);
            }
            data.push(
                <Table.Row>
                    <Table.Cell>{firstCol}</Table.Cell>
                    <Table.Cell className="actions">
                        {checked ?
                            <i className="fas fa-map-marker-alt" onClick={() => this.flyTo(source.id)}></i> : null}
                        <i class={checked ? "far fa-check-square" : "far fa-square"} onClick={() => this.showHideLayer(source, !checked)}></i>
                    </Table.Cell>
                </Table.Row>
            );
        }

        if (data.length === 0) {
            data.push(
                <Table.Row key="default">
                    <Table.Cell>{t("sidebar.noData")}</Table.Cell>
                </Table.Row>
            );
        }

        return data;
    }

    showHideLayer = (data, show) => {
        if (show) {
            this.props.showLayer(data.id);
        }
        else {
            this.props.hideLayer(data.id);
        }
    }

    isDisplayed = (id) => {
        return !this.props.mapFilterState.hiddenLayerIds.includes(id);
    }

    flyTo = (id) => {
        this.props.mapState.map.eachLayer((layer) => {
            if (layer.options && layer.options.id === id) {
                if (layer.options.data && layer.options.data.type === 'Point') {
                    this.props.mapState.map.flyTo(layer.getBounds().getCenter());
                } else {
                    this.props.mapState.map.flyToBounds(layer.getBounds());
                }
                return;
            }
        });
    }

    selectDataType = (dataType) => {
        this.setState({ dataType: dataType });
    }

    getContent = () => {
        var { t } = this.props;

        if (this.props.activeItem === this.props.name) {
            return (
                <PanelWrapper key="p1" title={t("filteringTool.filteringTool")} cancel={this.props.cancel}>
                    <div className="filter-display keyBuilds-display">
                        <div>
                            <label>{t("filteringTool.dataType")}:</label>
                            <Dropdown
                                placeholder='Select Data Type'
                                fluid
                                selection
                                value={this.state.dataType}
                                options={this.dataTypes}
                                onChange={(e, data) => this.selectDataType(data.value)}
                            />
                        </div>
                        <div className="sources-table">
                            {this.getTable()}
                        </div>
                    </div>
                </PanelWrapper>
            )
        }
        return null;
    }

    render() {

        // If no simulation is selected - do not render
        if (this.props.simulationState.selectedSimulation === null) {
            return null;
        }

        return (
            <div className="content-body">
                <Menu.Item
                    className="icon-container"
                    title={this.props.title}
                    name={this.props.name}
                    active={this.props.activeItem === this.props.name}
                    onClick={this.props.onClick}
                >
                    <FilterIcon className="sidebar-icon" />
                </Menu.Item>
                {this.getContent()}
            </div>
        )
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapFilterState: store.mapFilterState,
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        incidentState: store.incidentState,
        aoiState: store.aoiState,
        metState: store.metState,
        keyBuildState: store.keyBuildState,
        mapState: store.mapState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        showLayer: (id) => {
            dispatch(Actions.showLayer(id));
        },
        hideLayer: (id) => {
            dispatch(Actions.hideLayer(id));
        }
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DataFilterMenu);