import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Actions } from "../../../services/incident/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";
import "../sidebar.scss";
import IncidentTemplateDropdown from "../sources/complexSources/incidentTemplateDropdown.jsx";

class IncidentTypeSelection extends React.Component {
  /**
   * Choose the type of source created.
   */
  state = {
    selectAgentType: false,
    dirName: null,
    incidentType: null,
    agentType: null,
  };

  next = (e, { incidentType, agentType }) => {
    if (incidentType) {
      this.setState({
        selectAgentType: true,
        incidentType: incidentType,
      });
    }

    if (agentType) {
      this.setState(
        {
          agentType: agentType,
        },
        this.createSource
      );
    }
  };

  createSource = () => {
    this.props.createSource({
      agentType: this.state.agentType,
      incidentType: this.state.incidentType,
    });

    this.props.populateIncidentType(this.state.incidentType);

    this.setState({
      selectAgentType: false,
    });
  };

  getIncidentTemplates = (dirName) => {
    this.setState({
      selectAgentType: false,
      dirName: dirName
    });
  };

  selectAgentType = () => {
    var { t } = this.props;

    return (
      <>
        <div className="content-body">
          <div className="source-title">{t("sidebar.agentType")}:</div>
          <div className="agentTypes">
            <button onClick={(e) => this.next(e, { agentType: "CHEMICAL" })}>
              {t("sidebar.CHEMICAL")}
            </button>
            <button onClick={(e) => this.next(e, { agentType: "BIOLOGICAL" })}>
              {t("sidebar.BIOLOGICAL")}
            </button>
            <button disabled={true} onClick={(e) => this.next(e, { agentType: "RADIOLOGICAL" })}>
              {t("sidebar.RADIOLOGICAL")}
            </button>
          </div>
        </div>

        <div className="bottom-bar">
          <CommonButtonsBar />
        </div>
      </>
    );
  };

  getContent = () => {
    const { t } = this.props;
    const basicSourcesEnabled = this.props.userState.user.organisation.models.includes("UDM");
    const complexSourcesEnabled = (window.env.REACT_APP_INCLUDE_COMPLEX_INCIDENTS === "true") &&
      this.props.userState.user.organisation.models.includes("BLAST");
    const templatesEnabled = (window.env.REACT_APP_INCLUDE_TEMPLATES === "true") && 
      this.props.userState.user.organisation.models.includes("ATP45");

    if (this.state.selectAgentType) {
      return this.selectAgentType();
    }
    if (this.state.dirName) {
      return (
        <div className="incident-template-content-body">
          <IncidentTemplateDropdown incidentDirectory={this.state.dirName} />
        </div>
      );
    }
    return (
      <>
        <div className="content-body">
        {basicSourcesEnabled ? <>
            <div className="source-title">{t("sidebar.basic")}:</div>
            <div className="releaseTypes">
              <button onClick={(e) => this.next(e, { incidentType: "POINT" })}>
                {t("sidebar.PointSource")}
              </button>
              <button onClick={(e) => this.next(e, { incidentType: "LINE" })}>
                {t("sidebar.LineSource")}
              </button>
              <button onClick={(e) => this.next(e, { incidentType: "MOVING" })}>
                {t("sidebar.MovingSource")}
              </button>
              <button
                onClick={(e) => this.next(e, { incidentType: "LIQUID_POOL" })}
              >
                {t("sidebar.PoolSource")}
              </button>
            </div>
          </> : null}
          {complexSourcesEnabled ? (
            <>
              <div className="source-title">{t("sidebar.complex")}:</div>
              <div className="releaseTypes">
                <button
                  onClick={(e) => this.getIncidentTemplates("teargascanister")}
                >
                  {t("sidebar.teargas")}
                </button>
                <button
                  onClick={(e) => this.getIncidentTemplates("explosiveDispersion")}
                >
                  {t("sidebar.explosiveDispersion")}
                </button>
              </div>
              <div className="source-title">{t("sidebar.nonDispersion")}:</div>
              <div className="releaseTypes">
                <button onClick={(e) => this.getIncidentTemplates("explosive")}>
                  {t("sidebar.explosive")}
                </button>
              </div>
            </>
          ) : null}
          {templatesEnabled ? (
            <>
              <div className="source-title">{t("sidebar.template")}:</div>
              <div className="releaseTypes">
                <button onClick={(e) => this.getIncidentTemplates("atp45")}>
                  {t("sidebar.atp45")}
                </button>
              </div>
            </>
          ) : null}
        </div>
        <div className="bottom-bar">
          <CommonButtonsBar />
        </div>
      </>
    );
  };

  render() {
    return this.getContent();
  }
}

const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    simulationState: store.simulationState,
    incidentState: store.incidentState,
    userState: store.userState,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSource: (basicSource) => {
      dispatch(Actions.createSource(basicSource));
    },
    populateIncidentType: (incidentType) => {
      dispatch(Actions.populateIncidentType(incidentType));
    }
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(IncidentTypeSelection);
