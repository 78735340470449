import 'leaflet';
import L from 'leaflet';
import 'leaflet-timedimension';
import { MapControl, withLeaflet } from "react-leaflet";
import "react-leaflet-draw";
import { connect } from 'react-redux';
import { Actions } from "../../services/map/actions.jsx";
import { Actions as PopupActions } from "../../services/popups/actions.jsx";
import BetterWMS from './util/betterWMS';

class TimeSlider extends MapControl {

    createLeafletElement() {
        // This method is expected to be present when using withLeaflet() hook
    }

    componentDidMount() {
        // Construct a time dimension object
        this.timeControl = new L.control.timeDimension({ loopButton: true, timeZones: ['Local'] });
    }

    componentWillUnmount() {
        // Needed to explicitly override MapControl componentWillUnmount method
    }

    componentDidUpdate(prevProps) {

        // Get the actual selected simulation from the list
        // Which is kept up to date constantly
        let selectedSimulation = null;
        let isRunningOrFinished = false;
        if (this.props.simulationState.selectedSimulation !== null) {
            for (var index in this.props.simulationState.simulations) {
                let simulation = this.props.simulationState.simulations[index]; // eslint-disable-line security/detect-object-injection
                if (simulation.id === this.props.simulationState.selectedSimulation.id) {
                    selectedSimulation = simulation;
                    isRunningOrFinished = selectedSimulation.status === "FINISHED" || selectedSimulation.status === "RUNNING";
                    break;
                }
            }
        }

        var prevSimulation = prevProps.simulationState.selectedSimulation;
        var fromNullToSelection = prevSimulation === null && selectedSimulation !== null;
        var fromSelectionToNull = prevSimulation !== null && selectedSimulation === null;
        var selectionIDsDiffer = false;
        if (prevSimulation && selectedSimulation) {
            // Neither simulations are null - check if their IDs match
            selectionIDsDiffer = prevSimulation.id !== selectedSimulation.id;
        }

        // Check the data selection has changed (only if current selected simulation is not null)
        var dataSelectionChanged = false;
        if (selectedSimulation !== null &&
            (this.props.resultsState.selectedDataTypes.length !== prevProps.resultsState.selectedDataTypes.length ||
                this.props.resultsState.selectedMaterials.length !== prevProps.resultsState.selectedMaterials.length ||
                this.props.resultsState.selectedDataTypes[0] != prevProps.resultsState.selectedDataTypes[0])) {
            dataSelectionChanged = true;
        }

        // Check the status has changed into RUNNING
        var statusChangedToRunning = false;
        if (selectedSimulation !== null && prevSimulation !== null &&
            selectedSimulation.id === prevSimulation.id &&
            selectedSimulation.status !== prevSimulation.status &&
            selectedSimulation.status === "RUNNING") {
            statusChangedToRunning = true;
        }

        // If changed from no selection to selection - display time slider
        // Or if simulation got unselected - remove time slider
        if (((fromNullToSelection || selectionIDsDiffer) && isRunningOrFinished) || statusChangedToRunning) {
            this.timeControl.addTo(this.props.leaflet.map);
            this.props.leaflet.map.timeDimension.on('timeload', (event) => {
                this.props.selectCurrentTime(event.time);
            });
        } else if (fromSelectionToNull || selectionIDsDiffer) {
            this.props.leaflet.map.removeControl(this.timeControl);
            if (this.simulationLayer) {
                this.props.leaflet.map.removeLayer(this.simulationLayer);
                this.simulationLayer = null;
            }
            // Since the selection is null - there's nothing more to setup
            return;
        }

        // If the simulation selection changes and is now running or finished
        // Or simulation stayed the same but changes to Running status, or output data selection changes - add the wms layer
        if (((fromNullToSelection || selectionIDsDiffer) && isRunningOrFinished) || statusChangedToRunning || dataSelectionChanged) {
            // Remove previous layer if existed
            if (this.simulationLayer) {
                this.props.leaflet.map.removeLayer(this.simulationLayer);
                this.simulationLayer = null;
            }

            // Create a filter for the contour layer
            let filter = 'simulation_id = ' + selectedSimulation.id;
            // If there is data type selected - update filter query
            if (this.props.resultsState.selectedDataTypes && this.props.resultsState.selectedDataTypes.length > 0) {
                filter += ' AND data_type_id IN (' + this.props.resultsState.selectedDataTypes.toString() + ')'
            }
            // If there is material selected - update filter query
            if (this.props.resultsState.selectedMaterials && this.props.resultsState.selectedMaterials.length > 0) {
                filter += ' AND material_id IN (' + this.props.resultsState.selectedMaterials.toString() + ')'
            }

            const viewparams = [
                'key:' + this.props.userState.user.organisation.key
            ];

            const url = window.env.REACT_APP_GEOSERVER_URL + '/geoserver/urban_aware/wms?viewparams=' + viewparams.join(';');
            const displayOutputPopup = this.props.displayOutputPopup;
            const contourWMSLayer = BetterWMS.extend({
                'showOutputPopup': function (data) {
                    displayOutputPopup(data);
                },
                'id': "simulation_output_layer"
            })

            // Ensure the commonly used timeDimension is initialised before adding the simulation layer to the map
            // if possible
            const times = this.props.resultsState.simulationTimes[selectedSimulation.id];
            if(times) {
                this.props.leaflet.map.timeDimension.setAvailableTimes(times, 'replace');
                if (times && times.length > 0) {
                    const latestTime = times[times.length - 1];
                    this.props.leaflet.map.timeDimension.setCurrentTime(latestTime);
                }
            }

            const betterLayer = new contourWMSLayer(url,
                {
                    layers: 'contours',
                    format: 'image/png',
                    transparent: 'true',
                    opacity: 0.5,
                    cql_filter: filter,
                    tileSize: 768,
                    zIndex: 100,
                    transparent: true,
                    maxZoom: 24
                });

            this.simulationLayer = L.timeDimension.layer.wms(betterLayer, {
                updateTimeDimension: false,
                cache: 15
            });
            this.simulationLayer.addTo(this.props.leaflet.map);
            return;
        }

        // If the simulation stayed selected with no changes going from null to selection
        // or going from selection to null, or changing between simulation IDs - check the
        // available output times have changed in length - if they have, then update the 
        // available times on the slider (thi is to keep time slider up to date with output times while simulation
        // is still Running, or has finished running, but the results have not been fetched in time of status change)
        // Also check the timeDimension exists, just to be safe on possible race conditions
        if (selectedSimulation !== null && isRunningOrFinished && !fromNullToSelection && !fromSelectionToNull && !selectionIDsDiffer) {
            // check output times have changed
            let prevTimes = prevProps.resultsState.simulationTimes[selectedSimulation.id];
            let currTimes = this.props.resultsState.simulationTimes[selectedSimulation.id];
            if (prevTimes && currTimes) {
                // If both previous and current output times are defined - compare their lengths
                if (prevTimes.length !== currTimes.length) {
                    this.props.leaflet.map.timeDimension.setAvailableTimes(currTimes, 'replace');
                    // Also update the currently selected time to automatically update the map output
                    // as the new results keep coming in for a RUNNING simulation.
                    // TODO: Do not update the selected time, if the user has manually changed the slider during the RUNNING simulation
                    let latestTime = currTimes[currTimes.length - 1];
                    this.props.leaflet.map.timeDimension.setCurrentTime(latestTime);

                }
            }
            // If previous output times are not defined but current are - set current times as available times
            // And set the selected time to 
            else if (!prevTimes && currTimes && currTimes.length > 0) {
                this.props.leaflet.map.timeDimension.setAvailableTimes(currTimes, 'replace');
                let latestTime = currTimes[currTimes.length - 1];
                if (latestTime !== this.props.leaflet.map.timeDimension.getCurrentTime()) {
                    // Set the latest time available (if different than currently selected)
                    this.props.leaflet.map.timeDimension.setCurrentTime(latestTime);
                }
            }
        }
    }
}

const mapStateToProps = (store) => {
    return {
        popupState: store.popupState,
        simulationState: store.simulationState,
        resultsState: store.resultsState,
        currentTime: store.mapState.currentTime,
        userState: store.userState
    }
}

/*
    * Maps properties to dispatch methods to send actions to the store reducers
    */
const mapDispatchToProps = (dispatch) => {
    return {
        selectCurrentTime: (currentTimeInMs) => {
            dispatch(Actions.selectCurrentTime(currentTimeInMs));
        },
        displayOutputPopup: (data) => {
            dispatch(PopupActions.showOutputPopup(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLeaflet(TimeSlider));
