/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Table } from "semantic-ui-react";
import { Actions } from "../../../services/keyBuild/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";

class KeyBuildsDisplay extends React.Component {
  state = {
    selectedIndex: null,
  };

  getTable = (t) => {
    return (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("sidebar.name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("sidebar.type")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.getRows(t)}</Table.Body>
      </Table>
    );
  };

  selectRow = (index) => {
    var newSelectedIndex = index;
    if (this.state.selectedIndex === index) {
      newSelectedIndex = null;
    }
    this.setState({
      selectedIndex: newSelectedIndex,
    });
  };

  getRows = (t) => {
    let keyBuilds = [];
    var simulationId = this.props.simulationState.selectedSimulation.id;

    for (var m in this.props.keyBuildState.keyBuilds) {
      let ind = m;
      var keyBuild = this.props.keyBuildState.keyBuilds[m]; // eslint-disable-line security/detect-object-injection

      if (keyBuild.simulationId !== simulationId) {
        continue;
      }

      keyBuilds.push(
        <Table.Row
          key={ind}
          onClick={(e) => this.selectRow(ind)}
          className={
            this.state.selectedIndex && this.state.selectedIndex === ind
              ? "active"
              : "incident-row"
          }
        >
          <Table.Cell className={"incident-cell"}>{keyBuild.name}</Table.Cell>
          <Table.Cell className={"incident-cell"}>{keyBuild.bvimConfig.buildingType.name}</Table.Cell>
        </Table.Row>
      );
    }

    if (keyBuilds.length === 0) {
      keyBuilds.push(
        <Table.Row key="default">
          <Table.Cell>{t("sidebar.addNewKeyBuildingTxt")}</Table.Cell>
        </Table.Row>
      );
    }
    return keyBuilds;
  };

  removeKeyBuild = () => {
    if (this.state.selectedIndex != null) {
      this.props.removeKeyBuild(this.state.selectedIndex, this.props.simulationState.selectedSimulation.id, this.props.scenarioState.scenario.id);
      this.setState({ selectedIndex: null });
    }
  };

  editKeyBuild = () => {
    if (this.state.selectedIndex != null) {
      this.props.editKeyBuild(this.state.selectedIndex);
    }
  };

  flyTo = () => {
    if (this.state.selectedIndex != null) {
      var source = this.props.keyBuildState.keyBuilds[this.state.selectedIndex];
      this.props.mapState.map.eachLayer((layer) => {
        if (layer.options && layer.options.id === ("key-build-layer-" + source.id)) {
          this.props.mapState.map.flyToBounds(layer.getBounds());
        }
      });
    }
  };

  createKeyBuild = () => {
    this.props.createKeyBuild();
  };

  render() {
    var { t } = this.props;

    return (
      <div className="keyBuilds-display">
        <div className="sources-table">{this.getTable(t)}</div>
        <div className="bottom-bar">
          <CommonButtonsBar
            flyTo={this.flyTo}
            flyToItemDescription={t("keyBuild.flyToKeyBuild")}
            removeItem={this.removeKeyBuild}
            removeItemDescription={t("keyBuild.removeKeyBuild")}
            createItem={this.createKeyBuild}
            editItem={this.editKeyBuild}
            editItemDescription={t("keyBuild.editKeyBuild")}
            createItemDescription={t("keyBuild.addKeyBuild")}
            selectedIndex={this.state.selectedIndex}
          />
        </div>

      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    keyBuildState: store.keyBuildState,
    simulationState: store.simulationState,
    scenarioState: store.scenarioState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    createKeyBuild: () => {
      dispatch(Actions.createKeyBuild());
    },
    removeKeyBuild: (id, simulationId, scenarioId) => {
      dispatch(Actions.deleteKeyBuilding(id, simulationId, scenarioId));
    },
    editKeyBuild: (id) => {
      dispatch(Actions.editKeyBuild(id));
    },
    addKeyBuild: (keyBuild) => {
      dispatch(Actions.addKeyBuild(keyBuild));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(KeyBuildsDisplay);
