import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { withLeaflet, GridLayer } from "react-leaflet";
import * as WMS from "leaflet.wms";

/**
 * A React component for Lealfet to provide a WMS layer that can be clicked
 * to receive GetFeatureInfo and display it in a popup
 */
class WMSFeatureLayer extends GridLayer {

    createLeafletElement(props) {
        const { url, options, layer, leaflet } = props;

        // Extend the provided WMS class, in order to override how the popup gets created
        var MySource = WMS.Source.extend({

            'showFeatureInfo': function (latlng, info) {
                // This attempts to parse the getFeatureInfo response into a json
                try {
                    let featureInfo = JSON.parse(info);
                    // Clicking on empty spaces on the layer triggers a GetFeatureIfno request returning
                    // an empty feature for given latlng location, so check the feature is not empty before creating a popup
                    if (featureInfo.features && featureInfo.features.length > 0) {
                        // If there is no provided popup string function callback - generate a generic popup showing all existing properties
                        let popupString = "";
                        if (props.getPopupString) {
                            popupString = props.getPopupString(props.t, featureInfo.features[0].properties);
                        } else {
                            popupString = '<div style="padding: 10px;">' +
                                '<h2 style="color: #f7af09;">Feature Information</h2>' +
                                '<ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">';
                            for (let x in featureInfo.features[0].properties) {
                                popupString += `<li style="clear: both;"${x}: <em>${featureInfo.features[0].properties[x]}</em></li>`;
                            }
                        }

                        if (!leaflet && !leaflet.map) {
                            return;
                        }
                        leaflet.map.openPopup(popupString, latlng);
                    }
                } catch (err) {
                    // There was most likely an exception parsing the GetFeature response into JSON
                    // As it may have been an error message rather than JSON formatted string
                    console.error("Failed to parse clicked feature response", info, err);
                }
            }
        });

        // Add WMS source/layers
        const source = new MySource(
            url,
            options
        );

        // Return a layer from the WMW.Source, to be used as a react-leaflet component
        return source.getLayer(layer);
    }
}

export default withTranslation()(withLeaflet(WMSFeatureLayer));