import Requests from '../requests';

/*
* action types
*/
export const UPDATE_SOURCE = 'UPDATE_SOURCE';
export const CREATE_OR_UPDATE_EDITING_INCIDENT = 'CREATE_OR_UPDATE_EDITING_INCIDENT';
export const SET_EDITING_FETCHED_INCIDENT = 'SET_EDITING_FETCHED_INCIDENT';
export const CREATE_SOURCE = 'CREATE_SOURCE';
export const CANCEL_EDIT = 'CANCEL_EDIT';
export const EDIT_INCIDENT = 'EDIT_INCIDENT';

export const REMOVE_INCIDENT = 'REMOVE_INCIDENT';
export const SAVE_INCIDENT_SUCCESS = 'SAVE_INCIDENT_SUCCESS';
export const ADD_INCIDENT_SUCCESS = 'ADD_INCIDENT_SUCCESS';

export const FETCH_BEGIN = 'FETCH_INCIDENT_BEGIN';
export const FETCH_INCIDENTS_SUCCESS = 'FETCH_INCIDENTS_SUCCESS';
export const FETCH_FAILURE = 'FETCH_INCIDENT_FAILURE';

export const CLEAR = 'CLEAR_INCIDENT';

export class Actions {

	/*
	* action creators
	*/

	static clear() {
		return {
			type: CLEAR
		}
	}

	/*
	* action creator for [X] button. Stop editing and do not send any changes to DB
	*/
	static cancelEdit() {
		return {
			type: CANCEL_EDIT
		}
	}

	/*
	* action creator to start getting incidents
	*/
	static beginLoading = () => ({
		type: FETCH_BEGIN
	});

	/*
	* action creator to indicate incidents retrieved
	*/
	static fetchIncidentsSuccess = results => ({
		type: FETCH_INCIDENTS_SUCCESS,
		payload: { incidents: results.incidents, sources: results.sources }
	});

	/*
	* action creator to indicate incidents not retrieved
	*/
	static loadingFailure = error => ({
		type: FETCH_FAILURE,
		payload: { error }
	});

	/*
	* Calls controller endpoint to get all incidents for this scenario. Triggers another action creator to handle the returned incidents
	*/
	static fetchIncidents(scenarioId) {
		let headers = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		}

		return dispatch => {
			dispatch(this.beginLoading());
			return Requests.get(`/incident/scenario/${scenarioId}`)
				.then(json => {
					let sources = json.sources;
					// Loop through sources and parse geometry string to json
					this.correctSourceData(sources, scenarioId);

					for (let ind = 0; ind < json.incidents.length; ind++) {
						let incident = json.incidents[ind]; // eslint-disable-line security/detect-object-injection
						let source = sources[ind]; // eslint-disable-line security/detect-object-injection
						if (incident.geometry) {
							incident.geometry = JSON.parse(incident.geometry);
							incident.geoJson = { geometry: incident.geometry, properties: {}, type: "Feature" };

							// If the source contains a radius (Pool Source) add the radius to geoJson properties
							if (source && source.radius) {
								source.geoJson.properties.radius = source.radius;
							}
						}

						let incSources = incident.sources;
						this.correctSourceData(incSources, scenarioId);
					}

					dispatch(this.fetchIncidentsSuccess(json));
					return json;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.loadingFailure(error))
				});
		};
	}

	static correctSourceData(sources, scenarioId) {
		for (let i = 0; i < sources.length; i++) {
			var source = sources[i]; // eslint-disable-line security/detect-object-injection
			if (source.geometry) {
				source.geometry = JSON.parse(source.geometry);
				source.geoJson = { geometry: source.geometry, properties: {}, type: "Feature" };
			}

			source.scenarioId = scenarioId;
		}
	}

	/**
	 * Action creator to make a new default incident
	 */
	static createIncident() {
		let incident = { name: "Incident" }
		return {
			type: CREATE_OR_UPDATE_EDITING_INCIDENT,
			payload: incident
		}
	}

	/**
	 * Action creator to populate the type information of the currently editing incident
	 */
	static populateIncidentType(incidentType) {
		let incident = { incidentType: incidentType }
		return {
			type: CREATE_OR_UPDATE_EDITING_INCIDENT,
			payload: incident
		}
	}

	/**
	 * Gets the list of templates for a given incident type, eg a list of files specifying different sizes of explosion incident
	 * @param {the directory where the templates for this incident type are stored} directory 
	 */
	static getAvailableIncidentTemplates(directory) {

		let query = '?directory=' + directory
		return Requests.get("/incident/templates" + query)
			.then(incidentList => {
				return incidentList;
			})
			.catch(error => {
				console.error(error);
			});

	}

	/**
	 * Calls the server to open an incident file, returns the incident loaded from the file, and sets to the editing incident.
	 * Does not add the incident to the DB.
	 * @param {name of the file containing the incident to load} filename 
	 * @param {the directory in which the incident file is found} directory 
	 */
	static createComplexIncident(filename, directory) {

		let query = '?filename=' + filename + '&directory=' + directory
		return dispatch => {
			dispatch(this.beginLoading());
			return Requests.get("/incident/file" + query)
				.then(incident => {
					if (incident.geometry) {
						incident.geometry = JSON.parse(incident.geometry)
						incident.geoJson = { geometry: incident.geometry, properties: {}, type: "Feature" };
					}
					dispatch(this.createComplexIncidentSuccess(incident));
					return incident;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.loadingFailure(error))
				});
		};
	}

	/**
	 * Action creator to indicate that a complex incident template has been successfully fetched,
	 * and sets the incident as being currently edited
	 * @param {Object} incident the incident to set as being currently edited
	 */
	static createComplexIncidentSuccess = incident => ({
		type: SET_EDITING_FETCHED_INCIDENT,
		payload: incident
	});

	/**
	 * Action creator to finish a complex incident
	 * @param {the incident that is to be sent to the db} incidentIn 
	 * @param {the ID of the simulation the incident belongs to} simulationId
	 * @param {the ID of the scenario the simulation belongs to} scenarioId 
	 */
	static finishComplexIncident(incidentIn, simulationId, scenarioId) {
		// If the incident ID is not set then it is not yet in the DB
		if (!incidentIn.id) {
			return this.addIncident(incidentIn, simulationId, scenarioId)
		}
		else {
			return this.saveComplexIncident(incidentIn, simulationId, scenarioId)
		}
	}

	/**
	 * Action creator to update a complex incident in the DB
	 * @param {the incident that is to be updated} incidentIn 
	 */
	static saveComplexIncident(incidentIn, simulationId, scenarioId) {

		let d = new Date();
		incidentIn.lastUpdated = d.getTime();
		incidentIn.simulationId = simulationId;

		return dispatch => {
			dispatch(this.beginLoading());
			return Requests.put(`/incident/complex/simulation/${simulationId}/scenario/${scenarioId}`, incidentIn)
				.then(json => {
					let incidentOut = json.incident;
					incidentOut.geometry = JSON.parse(incidentOut.geometry);
					incidentOut.geoJson = { geometry: incidentOut.geometry, properties: {}, type: "Feature" };
					incidentOut.sources.forEach(s => {
						s.geometry = JSON.parse(s.geometry);
						s.geoJson = { geometry: s.geometry, properties: {}, type: "Feature" };
						// this is not passed back so put it on from the input
						s.agentType = incidentIn.agentType;
						s.materialName = incidentIn.materialName;
					});
					dispatch(this.onSaveIncidentSuccess(incidentOut));
					return incidentOut;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.loadingFailure(error))
				});
		};
	}

	/*
	* action creator for creating default source for UI edit
	*/
	static createSource(basicSource) {

		// Create a default incident startTime
		let d = new Date();
		let source = basicSource;
		let type = basicSource.incidentType;
		let baseSourceTerm = {
			sourceTermType: 'PointSource',
			agentType: basicSource.agentType,
			materialName: null,
			materialId: null,
			startTime: d,
			mass: 1,
			geoJson: null,
			lastUpdated: 0
		}

		// Set type-specific properties.
		switch (type) {
			case 'POINT':
				baseSourceTerm.sourceTermType = 'PointSource';
				baseSourceTerm.duration = 1;
				break;
			case 'LIQUID_POOL':
				baseSourceTerm.sourceTermType = 'PoolSource';
				baseSourceTerm.radius = 1;
				break;
			case 'LINE':
				baseSourceTerm.sourceTermType = 'LineSource';
				baseSourceTerm.duration = 60;
				break;
			case 'MOVING':
				baseSourceTerm.sourceTermType = 'MovingSource';
				baseSourceTerm.duration = 60;
				break;
		}

		// Set properties common to POINT, LINE, and MOVING types.
		if (type !== 'LIQUID_POOL') {
			// POINT, LINE and MOVING all have common properties
			baseSourceTerm.sigmaX = 1.0;
			baseSourceTerm.sigmaY = 1.0;
			baseSourceTerm.sigmaZ = 1.0;
			baseSourceTerm.releaseHeight = 1
			baseSourceTerm.temperature = ""
			baseSourceTerm.endHeight = 1
			baseSourceTerm.meanParticleDropletSize = 0.000001;
			baseSourceTerm.stdDevParticleDropletSize = 0.0;
		}

		source = baseSourceTerm;

		return {
			type: CREATE_SOURCE,
			payload: source
		}
	}

	/*
	* action creator for passing source info to next page
	*/
	static updateSource(source) {
		// Update timestamp on the incident
		let d = new Date();
		source.lastUpdated = d.getTime();
		return {
			type: UPDATE_SOURCE,
			payload: source
		}
	}

	/*
	* action creator to save changes to incident (finished button)
	*/
	static finishSource(source, simulationId, scenarioId) {
		// If the incident ID is not set then it is not yet in the DB
		if (!source.id) {
			return this.addSource(source, simulationId, scenarioId)
		}
		else {
			return this.saveSource(source, simulationId, scenarioId)
		}
	}

	/*
	* Call api to update source in db
	*/
	static saveSource(source, simulationId, scenarioId) {
		// Update timestamp on the incident
		let d = new Date();
		source.lastUpdated = d.getTime();

		// Create a json string to send to the backend, without mutating our object
		let sourceInput = Object.assign({}, source, { geometry: JSON.stringify(source.geoJson.geometry) })

		// The source contains the incident info at this stage, so re-structure the data 
		let incident = {
			id: source.incidentId,
			name: source.name,
			incidentType: source.incidentType,
			simulationId: simulationId,
			materialName: source.materialName,
			sources: [sourceInput]
		}

		return dispatch => {
			dispatch(this.beginLoading());
			return Requests.put(`/incident/simulation/${simulationId}/scenario/${scenarioId}`, incident)
				.then(() => {
					// Put json format for front-end on returned object
					incident.sources[0] = source
					dispatch(this.onSaveIncidentSuccess(incident));
					return incident;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.loadingFailure(error))
				});
		};
	}

	/*
	* action creator to indicate source updated 
	*/
	static onSaveIncidentSuccess = incident => {
		let d = new Date();
		incident.lastUpdated = d.getTime();
		return {
			type: SAVE_INCIDENT_SUCCESS,
			payload: incident
		}
	}

	/*
	* Call api to add incident and its source to db
	*/
	static addSource(source, simulationId, scenarioId) {
		// Update timestamp on the incident
		let d = new Date();
		source.lastUpdated = d.getTime();

		// Create a json string to send to the backend, without mutating our object
		let sourceInput = Object.assign({}, source, { geometry: JSON.stringify(source.geoJson.geometry) })

		// The source contains the incident info at this stage, so re-structure the data 
		let incident = {
			id: source.incidentId,
			name: source.name,
			incidentType: source.incidentType,
			sources: [sourceInput],
			agentType: source.agentType,
			materialName: source.materialName
		}
		return this.addIncident(incident, simulationId, scenarioId)
	}

	/**
	 * Action creator to add a complex incident to DB
	 * @param {the incident that is to be added} incidentIn 
	 * @param {the ID of the simulation the incident belongs to} simulationId 
	 * @param {the ID of the scenario the simulation belongs to} scenarioId 
	 */
	static addIncident(incidentIn, simulationId, scenarioId) {

		let d = new Date();
		incidentIn.lastUpdated = d.getTime();
		incidentIn.simulationId = simulationId;

		return dispatch => {
			dispatch(this.beginLoading());
			return Requests.post(`/incident/simulation/${simulationId}/scenario/${scenarioId}`, incidentIn)
				.then(incidentOut => {
					incidentOut.simulationId = simulationId;
					if (incidentOut.geometry != undefined) {
						incidentOut.geometry = JSON.parse(incidentOut.geometry);
						incidentOut.geoJson = { geometry: incidentOut.geometry, properties: {}, type: "Feature" };
					}

					incidentOut.sources.forEach(s => {
						s.geometry = JSON.parse(s.geometry);
						s.geoJson = { geometry: s.geometry, properties: {}, type: "Feature" };
						// this is not passed back so put it on from the input
						s.agentType = incidentIn.agentType;
						s.materialName = incidentIn.materialName;
					});
					dispatch(this.addIncidentSuccess(incidentOut));
					return incidentOut;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.loadingFailure(error));
				});
		};
	}

	// An incident has been correctly added in the DB
	static addIncidentSuccess = incident => ({
		type: ADD_INCIDENT_SUCCESS,
		payload: { incident }
	});

	/*
	* action creator to edit incident
	*/
	static editIncident(incidentId) {
		return {
			type: EDIT_INCIDENT,
			payload: incidentId
		}
	}

	/*
	* action creator to delete incident
	*/
	static removeIncident(incidentId, simulationId, scenarioId) {

		return dispatch => {
			return Requests.deleteRequest(`/incident/${incidentId}/simulation/${simulationId}/scenario/${scenarioId}`)
				.then(() => {
					dispatch(this.onDeleteIncidentSuccess(incidentId));
				})
				.catch(error => {
					console.error(error);
				});
		};

	}

	static onDeleteIncidentSuccess = incidentId => {
		return {
			type: REMOVE_INCIDENT,
			payload: incidentId
		}
	}


}
