import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form } from "semantic-ui-react";
import { Actions } from "../../../services/keyBuild/actions.jsx";
import "./styles.scss";

class KeyBuildTypeSelector extends React.Component {
    state = {};

    constructor(props) {
        super(props);

        const { t } = props;
        this.keyBuildTypes = [
            { key: "s", text: t("keyBuild.keyBuildTypes.fromDatabase"), value: "FROM_DATA" },
            { key: "a", text: t("keyBuild.keyBuildTypes.userDefined"), value: "MANUAL" },
        ];

        // Default the selection to be FROM_DATA
        this.state = {
            keyBuildType: "FROM_DATA",
        };
    }

    validate = () => {
        if (!this.state.keyBuildType) {
            return false;
        }
        return true;
    };

    onClick = (e) => {
        this.props.updateKeyBuild({
            keyBuildType: this.state.keyBuildType,
        });
        //this.props.selectType(this.state.keyBuildType);
    };

    getBuildingOptions = () => {
        const options = [];
        for (const buildIdx in this.keyBuildTypes) {
            const build = this.keyBuildTypes[buildIdx]; // eslint-disable-line security/detect-object-injection
            options.push(<option value={build.value}>{build.text}</option>);
        }
        return options.map((keyBuild) => {
            return keyBuild;
        });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="content-body build-type-selection">
                    <Form className="ua-form">
                        <Form.Group>
                            <Form.Field>
                                <Form.Select
                                    inline
                                    required={true}
                                    label={t('keyBuild.keyBuildType')}
                                    options={this.keyBuildTypes}
                                    placeholder={t('Select type')}
                                    value={this.state.keyBuildType}
                                    onChange={(e, data) => this.setState({ keyBuildType: data.value })}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
                <div className="bottom-bar">
                    <div className="finished">
                        <Button
                            type="button"
                            onClick={this.onClick}
                            disabled={!this.validate()}
                        >
                            {t("sidebar.next")}
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        keyBuildState: store.keyBuildState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateKeyBuild: (keyBuild) => {
            dispatch(Actions.updateKeyBuild(keyBuild));
        },
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(KeyBuildTypeSelector);
