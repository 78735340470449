import Requests from '../requests';

export class IncidentServices {

	/*
	* action creator for populating available material list
	*/
	static fetchMaterials(materialFilterInfo) {

		// If a list is specified, get them in preference of the whole lot
		if (materialFilterInfo.availableMaterials) {
			return this.fetchMaterialsByName(materialFilterInfo.availableMaterials)
		}

		const queryParam = {};
		if (materialFilterInfo.agentType) {
			queryParam.agentType = materialFilterInfo.agentType;
		}
		if (materialFilterInfo.materialType) {
			queryParam.materialType = materialFilterInfo.materialType;
		}
		if (materialFilterInfo.archived !== undefined) {
			queryParam.archived = materialFilterInfo.archived;
		}

		return Requests.get("/material/materials?" + new URLSearchParams(queryParam))
			.then(materials => {
				this.sortMaterials(materials)
				return materials;
			})
			.catch(error => {
				console.error("Error getting materials", error);
			});
	}

	static sortMaterials = (materials) => {
		materials.sort((a, b) => {
			var materialA = a.name.toUpperCase();
			var materialB = b.name.toUpperCase();
			if (materialA < materialB) {
				return -1;
			}
			if (materialA > materialB) {
				return 1;
			}
			return 0;
		})
	}

	static fetchMaterial(id) {

		return Requests.get("/material/id/" + id)
			.then(material => {
				return material;
			})
			.catch(error => {
				console.error("Error getting material", error)
			});
	}

	static fetchMaterialsByName(names) {

		return Requests.get("/material/name/" + names)
			.then(materials => {
				return materials
			})
			.catch(error => {
				console.error("Error getting material", error)
			});
	}

}