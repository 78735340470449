import 'leaflet';
import L from 'leaflet';
import 'leaflet-timedimension';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { LayersControl, Map, ZoomControl } from 'react-leaflet';
import "react-leaflet-draw";
import "leaflet-polylinedecorator";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Actions } from "../../services/map/actions.jsx";
import { MapLayerStyles } from "../common/MapLayerStyles";
import { SourceStyles } from "../common/SourceStyles";
import { BaseLayersUtil } from './layers/baseLayersUtil.jsx';
import { LayersUtil } from './layers/layersUtil';
import './map.scss';
import PopupDisplay from './popups/PopupDisplay.jsx';
import TimeSlider from './timeSlider.jsx';
import { CoordinatesControl } from '@takor/react-leaflet-coordinates'
import SidebarItemsEnum from "../../enums/sidebarItemsEnum.jsx";
import MetSourceTypeEnum from "../../enums/metSourceTypeEnum.jsx";
import { CoordinateUnits } from '../utils/Units.js';

const SHOW_HEADER = window.env.REACT_APP_SHOW_HEADER === 'true';
const SMITHS_REQUESTS = window.env.REACT_APP_SMITHS_REQUESTS === 'true';
// Default footer height is 30px but is elsewhere increased to 60px if a logo needs to be
// displayed within it which happens when the header is not shown or when not using Smiths requests
const APP_FOOTER_HEIGHT = SMITHS_REQUESTS && SHOW_HEADER ? 30 : 60;
const APP_HEADER_HEIGHT = SHOW_HEADER ? 60 : 0;

class MainMap extends Component {

    constructor(props) {
        super(props);

        // Get default organisation location
        let location = Object.assign([], this.props.userState.user.organisation.defaultLocation.coordinates).reverse();

        // Don't call this.setState() here!
        this.state = {
            viewport: {
                center: location,
                zoom: 7
            }
        }
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        if(window.env.REACT_APP_SMITHS_REQUESTS === 'true') {
            window.removeEventListener("message", this.flyToLoc.bind(this));
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (this.map && this.map !== null) {
            this.props.setMap(this.map.leafletElement);
            let leafMap = this.map.leafletElement;
            new L.control.scale().addTo(leafMap);
            if(window.env.REACT_APP_SMITHS_REQUESTS === 'true') {
                window.addEventListener("message", this.flyToLoc.bind(this));
            }
        }
        this.map.leafletElement.setMaxZoom(this.map.props.startingZoom);
    }

    flyToLoc(event) {
        if(event.data.lat != null && event.data.lon != null) {
            this.map.leafletElement.flyTo(new L.latLng(event.data.lat, event.data.lon), 17);
        }
    }

    updateDimensions() {
        this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
    }

    getMapSize = (x) => {
        var len = 0;
        for (var count in x) {
            len++;
        }

        return len;
    }

    getCoordinateStringForCoordinateControl = () => {
        switch(this.props.userState.user.preferences.coordinateUnit) {
            case CoordinateUnits.MGRS:
                return "mgrs";
            case CoordinateUnits.LAT_LON_DD:
            default:
                return "decimal";
            
        }
    }

    onViewportChanged = viewport => {
        this.setState({ viewport })
    }

    renderMarker() {
        var markers = [];
        var { t } = this.props;

        // User viewing scenario preset met profile markers
        const viewingScenarioMetProfiles = this.props.activeSetupMenuItem === SidebarItemsEnum.MET_PRESETS
        if (viewingScenarioMetProfiles) {
            const metPresets = Object.values(this.props.metPresetsState.metProfiles).filter(preset => preset.scenarioId === this.props.scenarioState.scenario.id);
            MapLayerStyles.addMarkersFromCollection(metPresets, this.props.metPresetsState.editing, markers, MapLayerStyles.getMetStyle, this.props.mapFilterState.hiddenLayerIds, t, this.props.userState.user.preferences);
        }

        // There is a selected simulation
        if (this.props.scenarioState.scenario && this.props.simulationState.selectedSimulation !== null) {
            var editingIncident = false;
            if (this.props.incidentState.editingIncident && this.props.incidentState.editingIncident.sources) {
                editingIncident = true;
            }

            const simulationId = this.props.simulationState.selectedSimulation.id;
            const metConfig = this.props.metState.metConfigs[simulationId];
            let metProfiles = [];
            // Don't show simulation met profile markers if the user is looking at scenario met profiles
            if (metConfig && !viewingScenarioMetProfiles) {
                if (metConfig.metSourceType === MetSourceTypeEnum.SIMULATION || this.props.simulationState.selectedSimulation.status === "FINISHED") {
                    // Show simulation met profile markers
                    metProfiles = Object.values(this.props.metState.metProfiles).filter(m => m.simulationId === simulationId);
                } else if (metConfig.metSourceType === MetSourceTypeEnum.SCENARIO_PRESETS) {
                    // Show scenario met preset profiles markers
                    metProfiles = Object.values(this.props.metPresetsState.metProfiles).filter(preset => preset.scenarioId === this.props.scenarioState.scenario.id);
                }
            }

            var incidents = [];
            // This will render out the complex incidents on the map
            Object.values(this.props.incidentState.incidents).forEach(inc => {
                if (inc.simulationId === simulationId) {
                    // TODO we may need to remove this for multiple sources. For now we will display both. 
                    if (inc.geoJson !== null && inc.geoJson !== undefined) {
                        incidents.push(inc);
                    }
                }
            });

            MapLayerStyles.addMarkersFromCollection(incidents, editingIncident, markers, SourceStyles.getStyle, this.props.mapFilterState.hiddenLayerIds, t, this.props.userState.user.preferences);
            MapLayerStyles.addMarkersFromCollection(metProfiles, this.props.metState.editing, markers, MapLayerStyles.getMetStyle, this.props.mapFilterState.hiddenLayerIds, t, this.props.userState.user.preferences);
        }
        
        return markers;
    }

    onMapClick = event => {
        var location = [event.latlng.lat, event.latlng.lng];
        var position = event.containerPoint;
        this.props.selectLocation({ location, position });
    }

    //Controlling maxZoom through onBaseLayerChange() rather than setting it within <BaseLayer> allows the user to switch between maps at any zoom level - even when 
    //beyond the maximum supported zoom of the layer being switched to
    onBaseLayerChange = event => {
        // Since the name of the layer can be translatable, we can't decide on max zoom levels
        // based on the name. Check the URL contains a specific string to deduce what zoom levels to use
        if (event.layer && event.layer.options && event.layer.options.url) {
            let zoomLevel = null;
            if (event.layer.options.url.includes("opentopomap.org")) {
                zoomLevel = 17;
            } else if (event.layer.options.url.includes("openstreetmap.org")) {
                zoomLevel = 18;
            } else if (event.layer.options.url.includes("api.os.uk")) {
                zoomLevel = 20;
            } else if (event.layer.options.url.includes("api.mapbox.com")) {
                zoomLevel = 20;
            }

            if (zoomLevel !== null) {
                if (this.map.props.viewport.zoom > zoomLevel) {
                    this.map.leafletElement.setZoom(zoomLevel);
                }
                this.map.leafletElement.setMaxZoom(zoomLevel);
            }
        }
    }

    render() {
    
        const { t } = this.props;
        const w = this.state.windowWidth;
        const h = this.state.windowHeight - APP_HEADER_HEIGHT - APP_FOOTER_HEIGHT;
        const baseLayersInfo = BaseLayersUtil.getBaseLayers(t, this.props.userState.user.organisation.servicesURLs);

        return (
            <div style={{ marginTop: APP_HEADER_HEIGHT, marginBottom: APP_FOOTER_HEIGHT }} className='map-position-div'>
                <div style={{ height: h, width: w }} className='map-div'>
                    <Map
                        ref={(ref) => this.map = ref}
                        zoom={13}
                        onBaseLayerChange={this.onBaseLayerChange}
                        onViewportChanged={this.onViewportChanged}
                        onclick={this.onMapClick}
                        viewport={this.state.viewport}
                        zoomControl={false}
                        timeDimension={true}
                        timeDimensionControl={false}
                        startingZoom={baseLayersInfo.startingZoom}
                    >
                        <CoordinatesControl position="bottomright" coordinates={this.getCoordinateStringForCoordinateControl()} />
                        <ZoomControl position="bottomright" />
                        <TimeSlider />
                        <LayersControl position="bottomright">

                            {baseLayersInfo.mapLayers}

                            {LayersUtil.getMapLayers(t, this.props.userState.user.organisation.servicesURLs)}
    
                        </LayersControl>
                        {LayersUtil.getMapLegends(t)}
                        {this.renderMarker()}
                    </Map>
                    <PopupDisplay />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        location: store.mapState.location,
        position: store.mapState.position,
        leafletMap: store.mapState.map,
        mapFilterState: store.mapFilterState,
        popupState: store.popupState,
        scenarioState: store.scenarioState,
        incidentState: store.incidentState,
        metState: store.metState,
        metPresetsState: store.metPresetsState,
        keyBuildState: store.keyBuildState,
        simulationState: store.simulationState,
        userState: store.userState,
        activeSetupMenuItem: store.setupMenuState.activeItem 
    }
}

/*
    * Maps properties to dispatch methods to send actions to the store reducers
    */
const mapDispatchToProps = (dispatch) => {
    return {
        selectLocation: (location) => {
            dispatch(Actions.selectLocation(location));
        },
        setMap: (leafletMap) => {
            dispatch(Actions.setMap(leafletMap));
        }
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MainMap);
