import L from "leaflet";
import React from "react";
import { GeoJSON } from "react-leaflet";
import { CustomIcon } from "../map/marker/customIcon";
import { CoordinateUtils } from "../utils/CoordinateUtils";
import { ImageURLs } from "../utils/ImagesUtils";

export class IncidentStyles {

  static getMarker(object, t, userPreference) {
    var materialName = "";
    if (object.sources.length > 0) {
      // Choose the first material from the sources, as they should all be the same
      materialName = object.sources[0].materialName;
    }
    var startTime = new Date(object.startTime)

    let geometry = object.geometry;
    return (
      <GeoJSON
        id={
          object.id +
          "-" +
          object.incidentType +
          "-" +
          object.startTime
        }
        key={
          object.id +
          "-" +
          object.incidentType +
          "-" +
          object.startTime +
          "-" +
          object.lastUpdated
        }
        data={geometry}
        pointToLayer={(feature, latlng) => {
          let iconUrl = ImageURLs.EXPLOSIVE;
          let agentType = object.agentType
          if (agentType === "BIOLOGICAL") {
            iconUrl = ImageURLs.BIOLOGICAL;
          } else if (agentType === "CHEMICAL") {
            iconUrl = ImageURLs.CHEMICAL;
          } else if (agentType === "RADIOLOGICAL") {
            iconUrl = ImageURLs.RADIOLOGICAL;
          }

          let incidentType = "";
          switch (object.incidentType) {
            case "Explosive":
              incidentType = "sidebar.explosive";
              break;
            case "Tear Gas Canister":
              incidentType = "sidebar.teargas";
              break; 
            case "ATP-45":
              incidentType = "sidebar.atp45";
              iconUrl = ImageURLs.ATP45;
              break;
          }

          let marker = new L.Marker(latlng, { icon: new CustomIcon(iconUrl) });
          let template = IncidentStyles.template;
          if (object.complexIncidentDataType === "NonDispersiveComplexIncidentData") {
            template = IncidentStyles.nonDispersiveTemplate;
          }

          template = template
            .replace(":label_incident", t("label.incident"))
            .replace(":label_name", t("label.incidentName")).replace(":incidentName", object.name)
            .replace(":label_type", t("label.type")).replace(":type", t(incidentType))
            .replace(":label_start_time", t("label.startTime")).replace(":startTime", startTime.toLocaleString())
            .replace(":label_material", t("label.material")).replace(":material", materialName)
            .replace(":label_coordinates", t("label.coordinates")).replace(
              ":coordinates",
              CoordinateUtils.convertLonLatToDisplayString(object.geoJson.geometry.coordinates, userPreference.coordinateUnit)
            )
          marker.bindPopup(template);

          return marker;
        }}
      />
    );
  }

  static template =
    '<div style="padding: 10px;">' +
    '<h2 style="color: #f7af09;">:label_incident</h2>' +
    '<ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">' +
    '<li style="clear: both;">:label_name: <em>:incidentName</em></li>' +
    '<li style="clear: both;">:label_type: <em>:type</em></li>' +
    '<li style="clear: both;">:label_coordinates: <em>:coordinates</em></li>' +
    '<li style="clear: both;">:label_start_time: <em>:startTime</em></li>' +
    '<li style="clear: both;">:label_material: <em>:material</em></li>' +
    "</ol></div>";

  static nonDispersiveTemplate =
    '<div style="padding: 10px;">' +
    '<h2 style="color: #f7af09;">:label_incident</h2>' +
    '<ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">' +
    '<li style="clear: both;">:label_name: <em>:incidentName</em></li>' +
    '<li style="clear: both;">:label_type: <em>:type</em></li>' +
    '<li style="clear: both;">:label_coordinates: <em>:coordinates</em></li>' +
    '<li style="clear: both;">:label_start_time: <em>:startTime</em></li>' +
    "</ol></div>";
}
