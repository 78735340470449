import {
  CLEAR, CREATE_SIMULATION_BEGIN,
  CREATE_SIMULATION_FAIL, CREATE_SIMULATION_SUCCESS, FETCH_SIMULATIONS_SUCCESS,
  SELECT_SIMULATION,
  SIMULATION_BEGIN_COPY,
  SIMULATION_FETCH_BEGIN,
  SIMULATION_FETCH_FAILURE,
  UPDATE_RUN_OPTIONS,
  UPDATE_RUN_OPTIONS_FAILURE, UPDATE_RUN_OPTIONS_SUCCESS, UPDATE_SIMULATION_BEGIN,
  UPDATE_SIMULATION_FAILURE, UPDATE_SIMULATION_SUCCESS
} from "./actions.jsx";

export class SimulationReducer {
  static simulationInfo(state, action) {
    const initialState = {
      simulations: [],
      selectedSimulation: null,
      loading: false,
      duplicating: false,
      error: null,
      isUpdatingSimulation: false,
      isUpdatingRunOptions: false,
      actionType: null
    };

    switch (action.type) {
      case CLEAR:
        return initialState;
      case UPDATE_SIMULATION_SUCCESS:
        var newState = Object.assign({}, state, {
          selectedSimulation: Object.assign(
            {},
            state.selectedSimulation,
            action.payload
          ),
          isUpdatingSimulation: false,
          error: null,
          actionType: action.type,
        });
        // Set the editted simulation onto scenario simulations
        var selectedSimulation = newState.selectedSimulation;
        // The simulations are stored in an array - loop through array and
        // update the selected simulation
        for (var index in newState.simulations) {
          if (newState.simulations[index].id === selectedSimulation.id) { // eslint-disable-line security/detect-object-injection
            newState.simulations[index] = selectedSimulation; // eslint-disable-line security/detect-object-injection
            break;
          }
        }

        return newState;
      case UPDATE_SIMULATION_FAILURE:
        return Object.assign({}, state, {
          isUpdatingSimulation: false,
          error: action.payload.error.message,
          actionType: action.type,
        });
      case UPDATE_SIMULATION_BEGIN:
        return Object.assign({}, state, {
          isUpdatingSimulation: true,
          error: null,
          actionType: action.type,
        });
      case CREATE_SIMULATION_BEGIN:
        return Object.assign({}, state, {
          creatingSimulation: true,
          error: null,
          actionType: action.type,
        });
      case CREATE_SIMULATION_SUCCESS:
        var newSims = state.simulations;
        newSims.push(action.payload);
        return Object.assign({}, state, {
          selectedSimulation: Object.assign({}, action.payload),
          simulations: newSims,
          creatingSimulation: false,
          duplicating: false,
          actionType: action.type
        });
      case CREATE_SIMULATION_FAIL:
        return Object.assign({}, state, {
          creatingSimulation: false,
          duplicating: false,
          error: action.payload.error,
          actionType: action.type,
        });
      case SIMULATION_BEGIN_COPY:
        return Object.assign({}, state, {
          duplicating: true,
          actionType: action.type,
        });
      case SELECT_SIMULATION:
        // If selectedId is not null - selected simulation, otherwise null it
        var selectedSim = null;
        if (action.payload !== null) {
          selectedSim = state.simulations.filter((sim) => {
            return sim.id === action.payload;
          })[0];
        }
        // Select the actual simulation object here (?)
        // Reset previously selected materials and data types
        return Object.assign({}, state, {
          selectedSimulation: selectedSim,
          selectedMaterials: [],
          selectedDataTypes: [],
          actionType: action.type,
        });
      case FETCH_SIMULATIONS_SUCCESS:
        var newSelectedSimulation = state.selectedSimulation
        // If there is already a simulation selected, it may have a new run state, so update this
        if (state.selectedSimulation) {
          for (var index in action.payload.simulations) {
            if (state.selectedSimulation.id === action.payload.simulations[index].id) { // eslint-disable-line security/detect-object-injection
              newSelectedSimulation = action.payload.simulations[index]; // eslint-disable-line security/detect-object-injection
              break;
            }
          }
        }

        return Object.assign({}, state, {
          selectedSimulation: newSelectedSimulation,
          loading: false,
          simulations: action.payload.simulations,
          actionType: action.type
        });
      case SIMULATION_FETCH_BEGIN:
        return Object.assign({}, state, {
          loading: true,
          error: null,
          actionType: action.type,
        });
      case SIMULATION_FETCH_FAILURE:
        return Object.assign({}, state, {
          loading: false,
          error: action.payload.error.message,
          actionType: action.type,
        });
      case UPDATE_RUN_OPTIONS:
        return Object.assign({}, state, {
          isUpdatingRunOptions: true,
          loading: true,
          error: null,
          actionType: action.type
        });
      case UPDATE_RUN_OPTIONS_SUCCESS:
        var newState = Object.assign({}, state, {
          selectedSimulation: Object.assign(
            {},
            state.selectedSimulation,
            action.payload
          ),
          isUpdatingRunOptions: false,
          loading: false,
          error: null,
          actionType: action.type,
        });
        // Set the editted simulation onto scenario simulations
        var selectedSimulation = newState.selectedSimulation;
        // The simulations are stored in an array - loop through array and
        // update the selected simulation
        for (var index in newState.simulations) {
          if (newState.simulations[index].id === selectedSimulation.id) { // eslint-disable-line security/detect-object-injection
            newState.simulations[index] = selectedSimulation; // eslint-disable-line security/detect-object-injection
            break;
          }
        }

        return newState;
      case UPDATE_RUN_OPTIONS_FAILURE:
        return Object.assign({}, state, {
          isUpdatingRunOptions: false,
          loading: false,
          error: action.payload.error.message,
          actionType: action.type,
        });
      default:
        return state || initialState;
    }
  }
}
