import { Component } from 'react';

export class FileDefinedSourceUtil extends Component {

    static generateSources(incident) {
        // We should always have atleast one source due to one always being defined in the files.
        let source = incident.sources[0]; // eslint-disable-line security/detect-object-injection
        incident.sources = [];
        for (let i = 0; i < incident.editableParameters.numOfCanisters.value; i++) {
            incident.sources[i] = Object.assign({}, source, {
                sourceTermType: "PointSource",
                startTime: incident.startTime,
                geometry: incident.geometry,
                geoJson: incident.geoJson,
                agentType: incident.agentType,
                materialName: incident.materialName,
                materialId: incident.materialId
            });
        }
    };
}