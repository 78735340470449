/*
* action types
*/
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const SELECT_CURRENT_TIME = 'SELECT_CURRENT_TIME';
export const SET_MAP = 'SET_MAP';

export const HIDE_LAYER = 'HIDE_LAYER';
export const SHOW_LAYER = 'SHOW_LAYER';

export class Actions {

	/*
	 * action creators
	 */
	static selectLocation(location) {
		return {
			type: SELECT_LOCATION,
			payload: location
		}
	}

	static selectCurrentTime(currentTimeInMs) {
		return {
			type: SELECT_CURRENT_TIME,
			payload: currentTimeInMs
		}
	}

	static setMap(map) {
		return {
			type: SET_MAP,
			payload: map
		}
	}

	static hideLayer(id) {
		return {
			type: HIDE_LAYER,
			payload: id
		}
	}

	static showLayer(id) {
		return {
			type: SHOW_LAYER,
			payload: id
		}
	}
}