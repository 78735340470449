import L from 'leaflet';
import 'leaflet.vectorgrid';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Form, List, Loader, Message } from 'semantic-ui-react';
import { Actions, ADD_KEY_BUILD_FAILURE, ADD_KEY_BUILD_SUCCESS, UPDATE_KEY_BUILD_FAILURE, UPDATE_KEY_BUILD_SUCCESS } from "../../../services/keyBuild/actions.jsx";
import { MapLayerStyles } from '../../common/MapLayerStyles';
import BvimConfigForm from './configs/bvimConfigForm.jsx';
import { KeyBuildUtils } from './keyBuildUtils';
import Requests from '../../../services/requests';
import './styles.scss';

class KeyBuildEditor extends React.Component {

    buildingTypes = [
        { key: 'a', text: 'All', value: 'ALL' },
        { key: 'e', text: 'Civic', value: 'CIVIC' },
        { key: 'b', text: 'Accommodation', value: 'ACCOMMODATION' },
        { key: 'c', text: 'Commercial', value: 'COMMERCIAL' },
        { key: 'd', text: 'Religious', value: 'RELIGIOUS' },
        { key: 'f', text: 'Entertainment', value: 'ENTERTAINMENT' },
        { key: 'g', text: 'Other', value: 'OTHER' },
    ]

    constructor(props) {
        super(props);
        var keyBuild = props.keyBuildState.editing;
        // Don't call this.setState() here!
        this.state = {
            keyBuildType: keyBuild.keyBuildType,
            name: keyBuild.name,
            geoJson: keyBuild.geoJson,
            notes: keyBuild.notes,
            error: null,
            selectedBuilding: null,
            loadingBuilding: false,
            currentZoom: props.mapState.map.getZoom(),
            loading: false,
            bvimConfig: null,
            fieldErrors: {}
        }
    }

    componentDidMount = () => {
        this.loadBuildingLayer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { t } = this.props;
        var currentZoom = prevState.currentZoom;
        var newZoom = this.state.currentZoom;
        // If currentZoom level is changing from below 15 to above 15
        // Display the layer on the map, otherwise hide it
        if (currentZoom < 15 && newZoom >= 15 && this.vectorLayers) {
            this.vectorLayers.forEach((vectorLayer => vectorLayer.addTo(this.props.mapState.map)));
        } else if (currentZoom >= 15 && newZoom < 15 && this.vectorLayers) {
            this.vectorLayers.forEach((vectorLayer => this.props.mapState.map.removeLayer(vectorLayer)));
        }

        // If the component is in loading state - check the progress of the source update/insertion
        if (this.state.loading) {
            if (prevProps.keyBuildState.adding && !this.props.keyBuildState.adding) {
                if (this.props.keyBuildState.actionType === ADD_KEY_BUILD_FAILURE) {
                    this.setState({ loading: false, error: t("keyBuild.messages.failedToAddKeyBuilding") });
                }
                if (this.props.keyBuildState.actionType === ADD_KEY_BUILD_SUCCESS) {
                    this.setState({ loading: false, error: null });
                }
                if (this.props.keyBuildState.actionType === UPDATE_KEY_BUILD_FAILURE) {
                    this.setState({ loading: false, error: t("keyBuild.messages.failedToAddKeyBuilding") });
                }
                if (this.props.keyBuildState.actionType === UPDATE_KEY_BUILD_SUCCESS) {
                    this.setState({ loading: false, error: null });
                }
            }
        }

        this.validateFields(prevState);
    }

    validateFields = (prevState) => {
        const { t } = this.props;
        var fieldErrors = {};
        let validated = false;

        if (prevState.name !== this.state.name) {
            validated = true;
            fieldErrors['name'] = false;
            if (!this.state.name || this.state.name.trim() === '') {
                fieldErrors['name'] = t('simulation.messages.nameIsEmpty');
            }
        }

        if (validated) {
            this.setState({ fieldErrors: fieldErrors });
        }
    }

    loadBuildingLayer = () => {
        // Remove previously loaded vector layer
        this.removeMapVectorLayer();

        // Remove the building footprints layer if user has it displayed
        var foundLayers = [];
        this.props.mapState.map.eachLayer((layer) => {
            // If the grouped layer has a child with a key containing building_height_occupancy_landcover - remove that layer
            if (layer.options.children) {
                // Check the children is an array and it contains a child with a key for building footprint
                // Or whether this child is the only child and is a footprint layer
                if ((Array.isArray(layer.options.children) && layer.options.children.some(c => c && c.key === "building_footprint")) ||
                    (layer.options.children.key && layer.options.children.key === "building_footprint")) {
                    foundLayers.push(layer);
                }
            } else if (layer._name === "onsdata_build_4326" || layer._name === "LondonBuildingHeightV2") {
                foundLayers.push(layer);
            }
        });

        foundLayers.forEach((layer) => {
            this.props.mapState.map.removeLayer(layer);
        });

        this.vectorLayers = KeyBuildUtils.getBuildingFootprintLayers(this.selectOSBuilding);

        // Only add the layer to the map if the current zoom level is 
        // above 14
        if (this.state.currentZoom >= 15 && this.vectorLayers) {
            this.vectorLayers.forEach((vectorLayer => vectorLayer.addTo(this.props.mapState.map)));
        }

        // Detect zoom changes to hide/show building footprints
        this.props.mapState.map.on("zoomend", this.zoomLevelChanged);
    }

    zoomLevelChanged = () => {
        this.setState({ currentZoom: this.props.mapState.map.getZoom() });
    }

    removeMapVectorLayer = () => {
        if (this.vectorLayers) {
            this.vectorLayers.forEach((vectorLayer => this.props.mapState.map.removeLayer(vectorLayer)));
            this.props.mapState.map.off("zoomend", this.zoomLevelChanged);
        }
    }

    selectOSBuilding = (selection) => {

        if (selection.features && selection.features.length > 0) {

            // Remove previous Building layer if exists and replace by new one
            if (this.state.buildingLayer) {
                this.props.mapState.map.removeLayer(this.state.buildingLayer);
            }

            let newLayer = L.Proj.geoJson(selection);
            // var newLayer = L.geoJSON(selection.features[0].geometry, {
            //     style: MapLayerStyles.getEditingStyle()
            // })
            newLayer.addTo(this.props.mapState.map);

            // Get height property (either 'height m' or 'mean_hght', depending on the source)
            let buildHeight = selection.features[0].properties['height m'];
            if(!buildHeight) {
                buildHeight = selection.features[0].properties['mean_hght'];
            }
            
            this.setState({
                loadingBuilding: false,
                selectedBuilding: { 'height m': buildHeight },
                error: null,
                geoJson: newLayer.toGeoJSON().features[0],
                buildingLayer: newLayer
            });
        }
    }

    select4EIBuilding = (e) => {
        const { t } = this.props;

        // Remove previous Building layer if exists and replace by new one
        if (this.state.buildingLayer) {
            this.props.mapState.map.removeLayer(this.state.buildingLayer);
        }
        // Comment this out in order to inspect the e.layer.properties to see what information is present
        // console.log("Clicked on building:", e);

        // Start loading of the building
        this.setState({ loadingBuilding: true });

        // Pass the lat lng into the query, in order to determine what area the building belongs to
        const queryParam = {};
        queryParam.lat = e.latlng.lat;
        queryParam.lon = e.latlng.lng;
        Requests.get('/buildings/' + e.layer.properties.id + "?" + new URLSearchParams(queryParam))
            .then(response => {
                if (response) {
                    var newLayer = L.geoJSON(response.features[0], {
                        style: MapLayerStyles.getEditingStyle()
                    })
                    newLayer.addTo(this.props.mapState.map);

                    this.setState({
                        loadingBuilding: false,
                        selectedBuilding: e.layer.properties,
                        error: null,
                        geoJson: response.features[0],
                        buildingLayer: newLayer
                    });
                } else {
                    console.error("Error getting building information");
                    this.setState({
                        error: t('keyBuild.messages.failedToFetchBuildingInfo'),
                        loadingBuilding: false,
                        selectedBuilding: null
                    });
                }
            })
            .catch(err => {
                console.error("Error getting building information");
                this.setState({
                    error: t('keyBuild.messages.failedToFetchBuildingInfo'),
                    loadingBuilding: false,
                    selectedBuilding: null
                });
            })
    }

    validate = () => {
        if (!this.state.geoJson) {
            return false;
        }
        if (!this.state.bvimConfig) {
            return false;
        }
        if (this.state.loading) {
            return false;
        }
        if (this.state.loadingBuilding) {
            return false;
        }
        if (this.state.fieldErrors['name']) {
            return false;
        }
        return true;
    }

    onClick = e => {
        const keyBuilding = {
            name: this.state.name,
            geoJson: this.state.geoJson,
            height: this.state.selectedBuilding['height m'],
            notes: this.state.notes,
            geometry: this.state.geoJson.geometry,
            simulationId: this.props.simulationState.selectedSimulation.id
        }
        const keyBuildingWithConfig = {
            keyBuilding: keyBuilding,
            bvimConfig: this.state.bvimConfig
        }

        this.setState({ loading: true });
        this.props.addKeyBuild(this.props.simulationState.selectedSimulation.id, this.props.scenarioState.scenario.id, keyBuildingWithConfig);
    }

    setBvimConfig = (config) => {
        this.setState({
            bvimConfig: config
        })
    }

    getBuildingBreakdown = () => {
        const { t } = this.props;

        // If the building info is loading - display a loader
        if (this.state.loadingBuilding) {
            return (
                <Loader active indeterminate inline='centered'>
                    {t('keyBuild.messages.loadingBuildingInfo')}
                </Loader>
            )
        }

        if (this.state.selectedBuilding !== null) {
            const buildingInfoFields = [];

            const population = this.state.selectedBuilding['total pop'];
            const landCover = this.state.selectedBuilding['landcover'];

            // Height is always expected to be present - future task may need to make this field editable in case height is missing from building info
            buildingInfoFields.push(
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            readOnly
                            label={t('keyBuild.buildingHeight')}
                            value={this.state.selectedBuilding['height m']}
                        />
                    </Form.Field>
                </Form.Group>
            );

            // If total population is defined - show its field
            if (population) {
                buildingInfoFields.push(
                    <Form.Group>
                        <Form.Field>
                            <Form.Input
                                inline
                                readOnly
                                label={t('keyBuild.buildingPopulation')}
                                value={population}
                            />
                        </Form.Field>
                    </Form.Group>
                );
            }

            // If landcover is defined - show its field
            if (landCover) {
                buildingInfoFields.push(
                    <Form.Group>
                        <Form.Field>
                            <Form.Input
                                inline
                                readOnly
                                label={t('keyBuild.buildingType')}
                                value={landCover}
                            />
                        </Form.Field>
                    </Form.Group>
                );
            }
            return buildingInfoFields;
        }

        return null;
    }

    render() {
        return (
            <List className="keyBuild-editor">
                {this.getContent()}
            </List>
        )
    }

    getContent = () => {
        const { t } = this.props;

        return (
            <>
                <div className="content-body">
                    <Form className="ua-form">
                        <Form.Group>
                            <Form.Field>
                                <Form.Input
                                    inline required={true}
                                    label={t('sidebar.name')}
                                    placeholder={t('sidebar.enterName')}
                                    error={this.state.fieldErrors['name']}
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                />
                            </Form.Field>
                        </Form.Group>

                        {this.getBuildingBreakdown()}

                        <BvimConfigForm setConfig={this.setBvimConfig} enabled={this.state.selectedBuilding !== null} />

                        <Form.Group>
                            <Form.Field>
                                <Form.TextArea
                                    inline
                                    label={t('sidebar.notes')}
                                    placeholder={t('sidebar.enterNotes')}
                                    value={this.state.notes}
                                    rows={2}
                                    onChange={(e) => this.setState({ notes: e.target.value })}
                                />
                            </Form.Field>
                        </Form.Group>

                        {/* Check if the zoom level is too small to display an error message to zoom in */}
                        {this.state.currentZoom < 15 ?
                            (<Message
                                error
                                header={t("keyBuild.cannotDisplayBuildings")}
                                content={t("keyBuild.messages.zoomToSeeBuildings")}
                            />) : null}

                        {/* Check there is an error on the state and display it*/}
                        {this.state.error ? (
                            <Message negative>
                                <Message.Header>{t("app.error")}</Message.Header>
                                <p>{this.state.error}</p>
                            </Message>
                        ) : null}
                    </Form>

                </div>
                <div className="bottom-bar">
                    <div className="finished">
                        <Button
                            type="button"
                            onClick={this.onClick}
                            disabled={!this.validate()}
                            loading={this.state.loading}
                        >
                            {t("sidebar.finished")}
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    componentWillUnmount() {
        this.removeMapVectorLayer();
        if (this.state.buildingLayer) {
            this.props.mapState.map.removeLayer(this.state.buildingLayer);
        }
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        keyBuildState: store.keyBuildState,
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        userState: store.userState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        addKeyBuild: (simulationId, scenarioId, keyBuild) => {
            dispatch(Actions.saveKeyBuilding(simulationId, scenarioId, keyBuild));
        }
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(KeyBuildEditor);