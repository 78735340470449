import React from "react";
import { DataUtil } from '../../common/DataUtil';

import "../sidebar.scss";

export default class TableCellTimer extends React.Component {

    state = {
        duration: '00:00:00'
    }

    constructor(props) {
        super(props);

        var durationString = DataUtil.calculateTimeBetween(new Date(props.startTime), new Date());
        this.state = {
            duration: durationString
        }
    }

    componentDidMount = () => {
        // Keep on updating the component every second to update the timer
        this.updateTimerTimer = setInterval(this.updateTimer, 1000);
    }

    componentWillUnmount() {
        // Remove the timer
        clearInterval(this.updateTimerTimer);
        this.updateTimerTimer = null;
    }

    updateTimer = () => {
        var durationString = DataUtil.calculateTimeBetween(new Date(this.props.startTime), new Date());
        this.setState({ duration: durationString });
    }

    render() {
        return (
            <div className="simulation-timer">
                {this.state.duration}
            </div>
        );
    }
}
