import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Message, Table } from "semantic-ui-react";
import { ContourActions } from "../../../../services/contours/actions.jsx";
import CommonButtonsBar from "../../../common/CentreEditAddRemoveButtonBar.jsx";
import { DataUtil } from "../../../common/DataUtil.jsx";
import { IncidentsUtils } from "../../../utils/IncidentsUtils.jsx";

class ContourOutputDisplay extends React.Component {
  constructor(props) {
    super(props);

    let incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
      (inc) => {
        return (
          inc.simulationId == this.props.simulationState.selectedSimulation.id
        );
      }
    );

    // Iterate through all incidents and add unique materials once to the list
    // of selectable incidents.
    var materials = [];
    for (var inc in incidentsForCurrentSimulation) {
      for (var sInd in incidentsForCurrentSimulation[inc].sources) { // eslint-disable-line security/detect-object-injection
        var source = incidentsForCurrentSimulation[inc].sources[sInd]; // eslint-disable-line security/detect-object-injection
        if (materials.every((m) => m.value !== source.materialId)) {
          {
            materials.push({
              key: source.materialId,
              text: source.materialName,
              value: source.materialId,
            });
          }
        }
      }
    }

    this.state = {
      materials: materials,
      selectedIndex: null
    };
  }

  hasMaterialInSources = (output) => {
    if (this.state.materials) {
      var mat = this.state.materials.filter(
        (m) => output.materialId === m.value
      );
      return mat.length > 0;
    }
  };

  getMaterial = (output) => {
    if (this.state.materials) {
      var mat = this.state.materials.filter(
        (m) => output.materialId === m.value
      );
      return mat[0].text;
    }
  };

  getDataTypeName = (output) => {
    var { t } = this.props;
    return t(`dataTypes.${output.dataType}`);
  };

  getSchemeName = (output) => {
    var { t } = this.props;
    var name = t(`run.outputConfig.interpolations.${output.interpolationScheme}`);
    return DataUtil.capitaliseFirstLetter(name);
  };

  getContourConfigsTable = () => {
    var { t } = this.props;

    return (
      <div className="contour-table">
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("run.outputConfig.dataType")}</Table.HeaderCell>
              <Table.HeaderCell>{t("run.outputConfig.scheme")}</Table.HeaderCell>
              <Table.HeaderCell>{t("label.material")}</Table.HeaderCell>
              <Table.HeaderCell>{t("run.outputConfig.numberOfContours")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.getRows()}</Table.Body>
        </Table>
      </div>
    );
  };

  getRows = () => {
    var { t } = this.props;

    var simulationId = this.props.simulationState.selectedSimulation.id;
    let contourConfigsForCurrentSimulation = this.props.contourConfigState.contourConfigs.filter(function (conf) {
      return conf.simulationId == simulationId;
    });

    let rows = [];
    for (var i in contourConfigsForCurrentSimulation) {
      var output = contourConfigsForCurrentSimulation[i]; // eslint-disable-line security/detect-object-injection
      let confId = output.id;

      // If the source with material has been removed - do not display this config entry
      if (!this.hasMaterialInSources(output)) {
        continue;
      }

      rows.push(
        <Table.Row
          key={i}
          onClick={(e) => this.selectRow(confId)}
          className={
            this.state.selectedIndex && this.state.selectedIndex === confId
              ? "active"
              : "incident-row"
          }
        >
          <Table.Cell key={confId + "name"} className={"incident-cell"}>
            {this.getDataTypeName(output)}
          </Table.Cell>
          <Table.Cell key={confId + "incidentType"} className={"incident-cell"}>
            {this.getSchemeName(output)}
          </Table.Cell>
          <Table.Cell className={"incident-cell"}>
            {this.getMaterial(output)}
          </Table.Cell>
          <Table.Cell className={"incident-cell"}>
            {output.numContourLevels}
          </Table.Cell>
        </Table.Row>
      );
    }

    if (rows.length === 0) {
      rows.push(
        <Table.Row key="default">
          <Table.Cell colSpan='4'>{t("run.messages.addNewOutputTxt")}</Table.Cell>
        </Table.Row>
      );
    }
    return rows;
  };

  selectRow = (index) => {
    var newSelectedIndex = index;
    if (this.state.selectedIndex === index) {
      newSelectedIndex = null;
    }
    this.setSelectedIndex(newSelectedIndex);
  };

  setSelectedIndex = (index) => {
    this.setState({
      selectedIndex: index,
    });
  };

  removeContourConfig = () => {
    if (this.state.selectedIndex != null) {
      let simId = this.props.simulationState.selectedSimulation.id;
      let scenarioId = this.props.scenarioState.scenario.id;
      this.props.removeContourConfig(this.state.selectedIndex, scenarioId, simId);
      this.setState({ selectedIndex: null });
    }
  };

  editContourConfig = async () => {
    if (this.state.selectedIndex != null) {
      this.props.editContourConfig(this.props.contourConfigState.contourConfigs[this.state.selectedIndex]);
    }
  };

  addContourOutput = async () => {
    this.props.editContourConfig();
  };

  getOutputNotice = () => {
    var { t } = this.props;

    let incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
      (inc) => {
        return (
          inc.simulationId == this.props.simulationState.selectedSimulation.id
        );
      }
    );
    if (incidentsForCurrentSimulation.length === 0) {
      return (
        <Message negative className="ua-error">
          <Message.Header>{t("app.error")}</Message.Header>
          <Message.List>
            {t("run.messages.incidentRequiredForOutput")}
          </Message.List>
        </Message>
      );
    }
    if (!IncidentsUtils.includesDispersion(incidentsForCurrentSimulation)) {
      return (
        <Message warning className="ua-error">
          <Message.Header>{t("messages.titles.warning")}</Message.Header>
          <Message.List>
            {t("run.messages.incidentRequiredForOutput")}
          </Message.List>
        </Message>
      );
    }
    return false;
  };

  render() {
    var { t } = this.props;
    let createConfig = this.addContourOutput;
    if (this.props.checkForValidIncident()) {
      createConfig = undefined;
    }

    return (
      <>
        <div className="content-body">
          {this.getContourConfigsTable()}
          {this.getOutputNotice()}
        </div>
        <div className="bottom-bar">
          <CommonButtonsBar
            editItem={this.editContourConfig}
            editItemDescription={t("run.outputConfig.editContourOutput")}
            removeItem={this.removeContourConfig}
            removeItemDescription={t("run.outputConfig.removeContourOutput")}
            createItem={createConfig}
            createItemDescription={t("run.outputConfig.createContourOutput")}
            selectedIndex={this.state.selectedIndex}
          />
        </div>
      </>
    );

  }

}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    simulationState: store.simulationState,
    scenarioState: store.scenarioState,
    contourConfigState: store.contourConfigState,
    incidentState: store.incidentState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    editContourConfig: (selectedContourConfig) => {
      dispatch(ContourActions.editContourConfig(selectedContourConfig));
    },
    removeContourConfig: (index, scenarioId, simulationId) => {
      dispatch(ContourActions.removeContourConfig(index, scenarioId, simulationId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ContourOutputDisplay);
