import { CLEAR, SELECT_MENU } from './actions.jsx';

export class SetupMenuReducer {

	static setupMenuInfo(state, action) {

		const initialState = {
			activeItem: null
		}

		switch (action.type) {
			case CLEAR:
				return initialState;
			case SELECT_MENU:
				return Object.assign({}, state, {
					activeItem: action.payload,
				})
			default:
				return state || initialState;
		}
	}

}