/*
        ./client/components/App.jsx
*/
import L from "leaflet";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Confirm } from 'semantic-ui-react';
import { ResultsActions } from "../../../services/results/actions.jsx";
import { SimulationActions } from "../../../services/simulation/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";
import PanelWrapper from "../../common/panelWrapper.jsx";
import SimulationEditPopup from "../../common/popups/simulationEditPopup.jsx";
import "../sidebar.scss";
import ArchivedSimulations from "./archivedSimulations.jsx";
import SimulationCreateEditor from "./simulationCreateEditor.jsx";
import SimulationDisplay from "./simulationDisplay.jsx";
import Requests from '../../../services/requests';

/**
 * View to contain logic determining if user is viewing or editing a simulation and display sub-panel accordingly
 */
class SimulationPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      next: props.next,
      newSimulation: false,
      modalOpen: false,
      simulationModalOpen: false
    };
  }

  componentDidMount() {
    // Initial fetch
    this.fetchSimulationsAndOutputs();

    // Start fetching simulations
    this.simulationsFetchTimer = setInterval(this.fetchSimulationsAndOutputs, 5000);
  }

  fetchSimulationsAndOutputs = () => {
    // Only fetch if the user is verified 
    if (this.props.userState.verified) {
      this.props.fetchSimulations(this.props.scenarioState.scenario.id);
      this.props.fetchSimulationOutputs(this.props.scenarioState.scenario.id);
    }
  }

  componentWillUnmount() {
    // Remove the fetch polling timer
    clearInterval(this.simulationsFetchTimer);
    this.simulationsFetchTimer = null;
  }

  editSimulation = () => {
    this.state.next();
  };

  flyTo = () => {
    if (this.props.simulationState.selectedSimulation != null) {
      // Collect locations of each sources
      let latitudes = [];
      let longitudes = [];
      Object.values(this.props.incidentState.incidents).forEach((s) => {
        if (
          s.simulationId === this.props.simulationState.selectedSimulation.id
        ) {
          if (s.sources.length > 0) {
            s.sources.forEach((source) => {
              this.getLatLonsFromGeometry(source.geometry, latitudes, longitudes);
            });
          } else {
            this.getLatLonsFromGeometry(s.geometry, latitudes, longitudes);
          }
        }
      });
      if (latitudes.length > 0) {
        let latMax = Math.max(...latitudes);
        let lonMax = Math.max(...longitudes);
        let latMin = Math.min(...latitudes);
        let lonMin = Math.min(...longitudes);
        let corner1 = L.latLng(latMax, lonMax);
        let corner2 = L.latLng(latMin, lonMin);
        let bounds = L.latLngBounds(corner1, corner2);
        this.props.mapState.map.flyToBounds(bounds, {
          padding: [50, 50],
          maxZoom: 15,
        });
      }
    }
  };

  getLatLonsFromGeometry(geometry, latitudes, longitudes) {
    if (geometry.type === "Point") {
      // Coordinates is just a lat lon                    
      latitudes.push(geometry.coordinates[1]);
      longitudes.push(geometry.coordinates[0]);
    } else {
      // Coordinates is an array of lat lons
      geometry.coordinates.forEach((coord) => {
        latitudes.push(coord[1]);
        longitudes.push(coord[0]);
      });
    }
  }

  displayCreateSimulation = () => {
    this.setState({ newSimulation: true });
  };

  renderSimulationTypes = () => {
    if (this.state.newSimulation === true) {
      return <SimulationCreateEditor next={this.state.next} />;
    }
  };

  setOpenSimulationModal = () => {
    this.setState({ simulationModalOpen: !this.state.simulationModalOpen });
  }

  restore = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  openArchiveConfirmation = () => {
    this.setState({ openConfirm: !this.state.openConfirm });
  }

  archiveSimulation = () => {
    var { t } = this.props;

    let headersAndBody = {
      method: 'PUT',
    }
    if (this.props.simulationState.selectedSimulation && this.props.simulationState.selectedSimulation.id) {
      this.setState({ loading: true, error: null, openConfirm: false });
      let simulationId = this.props.simulationState.selectedSimulation.id;
      let scenarioId = this.props.scenarioState.scenario.id;
      Requests.put(`/simulation/archive/${simulationId}/scenario/${scenarioId}`)
        .then(resp => {
          this.setState({ loading: false, error: null });
          // Re-fetch the simulations on success
          this.props.deselectSimulation();
          this.props.fetchSimulations(this.props.scenarioState.scenario.id);

        })
        .catch(err => {
          this.setState({ loading: false, error: t("simulation.messages.failedToArchive") });
        })
    }
  }

  getBottomBar = () => {
    const { t } = this.props;
    var isEdit = undefined;
    if (this.props.simulationState.selectedSimulation) {
      if (this.props.simulationState.selectedSimulation.status == "NOT_STARTED") {
        isEdit = this.editSimulation;
      } else {
        isEdit = this.setOpenSimulationModal;
      }
    }

    // Only allow archiving simulations that are not currently Running
    var archiveAction =
      this.props.simulationState.selectedSimulation != null &&
        this.props.simulationState.selectedSimulation.status !== "RUNNING"
        ? this.openArchiveConfirmation
        : undefined;
    return (
      <div className="bottom-bar">
        <CommonButtonsBar
          archive={archiveAction}
          archiveDescription={t("simulation.archived.archiveTitle")}
          restore={this.restore}
          restoreDescription={t("simulation.archived.restoreTitle")}
          flyTo={this.flyTo}
          flyToItemDescription={t("sidebar.flyToSimulation")}
          editItem={isEdit}
          editItemDescription={t("sidebar.editSimulation")}
          // Commented out until functionality is added
          // removeItem={this.removeSimulation}
          // removeItemDescription={t("sidebar.removeSimulation")}
          createItem={this.displayCreateSimulation}
          createItemDescription={t("sidebar.addSimulation")}
          selectedIndex={
            this.props.simulationState.selectedSimulation
              ? this.props.simulationState.selectedSimulation.id
              : null
          }
        />
      </div>
    )
  }

  getContent = () => {
    const { t } = this.props;
    return (
      <PanelWrapper
        key="simulation"
        title={t("sidebar.simulation")}
        cancel={this.props.cancel}
      >
        <SimulationDisplay />
        {this.renderSimulationTypes()}
        {this.getBottomBar()}
      </PanelWrapper>
    );
  };

  getConfirmContent = () => {
    var { t } = this.props;
    // In order to have a line break from translations this needs to be split into 2 separate
    // lines
    return (
      <div className="content">
        <div>
          {t("simulation.confirmation.content")}
        </div>
        <div>
          {t("simulation.confirmation.content2")}
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props;

    return (
      <div className="simulation-panel">
        {this.getContent()}
        <ArchivedSimulations
          open={this.state.modalOpen}
          setOpen={this.restore}
          fetchSimulations={() => this.props.fetchSimulations(this.props.scenarioState.scenario.id)}
          scenarioId={this.props.scenarioState.scenario.id}
        />
        <Confirm
          className="ua-modal"
          header={t("simulation.confirmation.header")}
          content={this.getConfirmContent()}
          cancelButton={t("simulation.confirmation.cancel")}
          confirmButton={t("simulation.confirmation.confirm")}
          open={this.state.openConfirm}
          onCancel={() => this.openArchiveConfirmation()}
          onConfirm={this.archiveSimulation}
        />
        <SimulationEditPopup
          trigger={<div />}
          open={this.state.simulationModalOpen}
          close={this.setOpenSimulationModal} />
      </div>);
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    scenarioState: store.scenarioState,
    simulationState: store.simulationState,
    incidentState: store.incidentState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    cancelEdit: () => {
      dispatch(SimulationActions.cancelEdit());
    },
    fetchSimulations: (id) => {
      dispatch(SimulationActions.fetchSimulations(id));
    },
    fetchSimulationOutputs: (scenarioId) => {
      dispatch(ResultsActions.fetchSimulationOutputs(scenarioId));
    },
    deselectSimulation: () => {
      dispatch(SimulationActions.selectSimulation(null));
    }
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SimulationPanel);
