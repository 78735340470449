import React, { Component } from "react";
import { withLeaflet } from "react-leaflet";
import L from 'leaflet';
import VectorGridDefault from "react-leaflet-vectorgrid";

/**
 * A React component that renders a Vector Grid layer, that allows features to be clicked
 * and display a generic popup consisting of the table of all feature properties
 */
class VectorGridC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: props.url,
            tileLayerName: props.tileLayerName,
            layerStyle: props.layerStyle,
            currentZoom: props.leaflet.map.getZoom()
        };
    }

    shouldComponentUpdate(nextProps) {
        // Only trigger update if either new zoom level is above 14 and current zoom is below,
        // or vice versa. 15 zoom level is optimal for performance at the time of writing this code
        var currentZoom = this.state.currentZoom;
        var newZoom = nextProps.leaflet.map.getZoom();
        return (currentZoom >= 15 && newZoom < 15) || (currentZoom < 15 && newZoom >= 15);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ currentZoom: nextProps.leaflet.map.getZoom() });
    }

    render() {

        if (this.state.currentZoom < 15) {
            // If the user zoomed out too far - the layer will stop rendering
            // Otherwise perfomance issues make the UI unstable
            return null;
        }

        const VectorGrid = withLeaflet(VectorGridDefault);
        const options = {
            type: "protobuf",
            url: this.state.url,
            subdomains: this.props.subdomains ? this.props.subdomains : "",
            zIndex: 598,
            vectorTileLayerStyles: {
                [this.state.layerStyle]: (prop, z) => {
                    return {
                        fill: true,
                    }
                }
            }
        };
        return <VectorGrid {...options}
            onClick={this.props.showPopup ? this.props.showPopup : this.showPopup}
        />;
    }

    showPopup = (e) => {

        var objectInfo = e.layer.properties;

        let table = "<table border='1' style='margin: 10px'>";
        for (let x in objectInfo) {
            table += "<tr><td>" + x + "<td><td>" + objectInfo[x] + "</td></tr>";
        }
        table += "</table>"

        var popup = L.popup()
            .setLatLng(e.latlng)
            .setContent(table)
            .openOn(this.props.leaflet.map);
    }
}

export default withLeaflet(VectorGridC);