import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Form } from 'semantic-ui-react';
import Requests from '../../../../services/requests';

class BvimConfigForm extends React.Component {

    modelTypes = ["NATURAL_VENT", "MECHANICAL_VENT", "FULL"];

    constructor(props) {
        super(props);
        if (props.bvimConfig === undefined || props.bvimConfig === null) {
            this.state = {
                buildingTypes: [],
                selectedBuildingType: undefined,
                selectedModelType: undefined
            }
        } else {
            this.state = {
                buildingTypes: [],
                selectedBuildingType: {
                    id: props.bvimConfig.buildingType.id,
                },
                selectedModelType: {
                    value: props.bvimConfig.bvimModelType
                }
            }
        }
    }

    componentDidMount = () => {
        this.fetchBuildingTypes();
    }

    fetchBuildingTypes = () => {
        var { t } = this.props;

        Requests.get("/bvim/buildings")
            .then(buildingTypes => {
                if (buildingTypes) {
                    buildingTypes.sort((a, b) => a.name.localeCompare(b.name));
                    this.setState({ buildingTypes: buildingTypes });
                } else {
                    this.setState({ error: t("keyBuild.messages.failedToFetchBvim") });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ error: t("keyBuild.messages.failedToFetchBvim") });
            });
    }

    setBuildingType = (e, data) => {
        let buildingType = this.state.buildingTypes.find(bt => data.value === bt.id);
        this.setState({ selectedBuildingType: buildingType }, this.updateParent);
    }

    updateParent = () => {
        // Only update parent with BVIM config when both building type and model type are defined.
        if (this.state.selectedBuildingType && this.state.selectedModelType) {
            this.props.setConfig({
                buildingType: this.state.selectedBuildingType,
                bvimModelType: this.state.selectedModelType.value
            });
        }
    }

    setModelType = (e, data) => {
        let modelType = data.options.find(option => data.value === option.value);
        this.setState({ selectedModelType: modelType }, this.updateParent);
    }

    render() {
        let { t } = this.props;
        return (
            <>
                <Form.Group>
                    <Form.Field>
                        <Form.Select
                            inline
                            required={true}
                            label={t("sidebar.bvimBuildingType")}
                            placeholder={t("sidebar.bvimBuildingType")}
                            options={this.state.buildingTypes.map(bt => {
                                return { key: bt.id, text: t('keyBuild.bvimBuildingTypes.' + bt.name), value: bt.id };
                            })}
                            value={this.state.selectedBuildingType ? this.state.selectedBuildingType.id : undefined}
                            onChange={(e, data) => this.setBuildingType(e, data)}
                            disabled={!this.props.enabled}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Form.Select
                            inline
                            required={true}
                            label={t("sidebar.bvimModelType")}
                            placeholder={t("sidebar.bvimModelType")}
                            options={this.modelTypes.map((mt, i) => {
                                return { key: i, text: t('keyBuild.bvimModelTypes.' + mt), value: mt };
                            })}
                            value={this.state.selectedModelType ? this.state.selectedModelType.value : undefined}
                            onChange={(e, data) => this.setModelType(e, data)}
                            disabled={!this.props.enabled}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    }
}

export default compose(withTranslation())(BvimConfigForm);
