import React from "react";
import Login from "./login.jsx";
import SmithsLogin from "./smithsLogin";
import Register from "./register.jsx";
import ResetLogin from "./resetLogin.jsx";

export default class LoginCard extends React.Component {

  state = {
    cardState: 'LOGIN'
  };

  registerClick = () => {
    this.setState({ cardState: 'REGISTER' });
  }

  resetPasswordClick = () => {
    this.setState({ cardState: 'RESET' });
  }

  loginClick = () => {
    this.setState({ cardState: 'LOGIN' });
  }

  getStateContent = () => {
    if (this.state.cardState === 'LOGIN') {
      return (<Login onlyLogin={this.props.onlyLogin} registerClick={this.registerClick} resetPasswordClick={this.resetPasswordClick} />);
    }
    else if (this.state.cardState === 'RESET') {
      return (<ResetLogin registerClick={this.registerClick} resetPasswordClick={this.resetPasswordClick} loginClick={this.loginClick} />);
    }
    if (this.state.cardState === 'REGISTER') {
      return (<Register loginClick={this.loginClick} />);
    }
  }

  render() {
    // If smiths - return a SmithsLogin component
    if (window.env.REACT_APP_SMITHS_REQUESTS === 'true') {
      return (<SmithsLogin />);
    }

    // Otherwise deduce the component from state
    return this.getStateContent();
  }
}
