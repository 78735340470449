/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Menu, Popup } from "semantic-ui-react";
import "whatwg-fetch";
import SimulationPanel from "./simulationPanel";
import {ReactComponent as SimulationIcon} from '../../../images/Simulation.svg';

class SimulationMenu extends React.Component {
  getContent = () => {
    if (this.props.activeItem === this.props.name) {
      return (
        <SimulationPanel cancel={this.props.cancel} next={this.props.next} />
      );
    }
  };

  render() {
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name={this.props.name}
              active={this.props.activeItem === this.props.name}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
            >
              <SimulationIcon className="sidebar-icon"/>
            </Menu.Item>
          }
          position={"right center"}
        >
          {this.props.title}
        </Popup>

        <div className="menu-option">{this.getContent()}</div>
      </>
    );
  }
}

export default compose(withTranslation())(SimulationMenu);
