import React from 'react';
import { LayersControl } from 'react-leaflet';
import WMSFeatureLayer from '../WMSFeatureLayer'

const { Overlay } = LayersControl

export class OSResearchLayers {

    static getMapLayers(t, servicesURLs) {

        let geoserverUrl = window.env.REACT_APP_GEOSERVER_URL;
        let mapLayers = [];
        if (servicesURLs != null && servicesURLs.some(s => s.layer === "os_data_research")) {

            mapLayers.push(
                <Overlay name={t("map.os_building")}>
                    <WMSFeatureLayer
                        key="os_building_footprint"
                        layer='onsdata_build_4326'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_gridded_pop")}>
                    <WMSFeatureLayer
                        key="os_gridded_pop"
                        layer='onsdata_gridded_pop_day_4326'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "opacity": 0.5,
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_permeability_population_newcastle_MSOA")}>
                    <WMSFeatureLayer
                        key="os_permeability_population_newcastle_MSOA"
                        layer='os_permeability_population_newcastle_MSOA'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "opacity": 0.5,
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_gridded_pop_day_central_newcastle")}>
                    <WMSFeatureLayer
                        key="os_gridded_pop_day_central_newcastle"
                        layer='os_gridded_pop_day_central_newcastle'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "opacity": 0.5,
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_gridded_pop_night_central_newcastle")}>
                    <WMSFeatureLayer
                        key="os_gridded_pop_night_central_newcastle"
                        layer='os_gridded_pop_night_central_newcastle'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "opacity": 0.5,
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_pop_build_day_central_newcastle")}>
                    <WMSFeatureLayer
                        key="os_pop_build_day_central_newcastle"
                        layer='os_pop_build_day_central_newcastle'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "opacity": 0.5,
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )

            mapLayers.push(
                <Overlay name={t("map.os_pop_build_night_central_newcastle")}>
                    <WMSFeatureLayer
                        key="os_pop_build_night_central_newcastle"
                        layer='os_pop_build_night_central_newcastle'
                        options={{
                            "format": "image/png",
                            "transparent": "true",
                            "opacity": 0.5,
                            "info_format": "application/json"
                        }}
                        url={geoserverUrl + '/geoserver/urban_aware/wms'}
                    />
                </Overlay>
            )
        }

        return mapLayers;
    }
}