/*
    ./client/components/App.jsx
*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Actions } from "../../../services/aoi/actions.jsx";
import PanelWrapper from '../../common/panelWrapper.jsx';
import AoiEditor from './aoiEditor.jsx';
import AoisDisplay from './aoisDisplay.jsx';
import './styles.scss';

class AoisPanel extends React.Component {

    getContent = (t) => {
        if (this.props.aoiState.editing != null && this.props.aoiState.editing.type === "AOI") {
            return (
                <PanelWrapper key={"aoi" + this.props.aoiState.editing.id} title={t("sidebar.editAoi")} cancel={this.props.cancelEdit} >
                    <AoiEditor />
                </PanelWrapper>
            );
        }
        return (
            <PanelWrapper key="aoi" title={t("sidebar.aoi")} cancel={this.props.cancel} >
                <AoisDisplay next={this.props.next} />
            </PanelWrapper>
        )
    }

    render() {
        var { t } = this.props;

        return (
            <div className="aois-panel">
                {this.getContent(t)}
            </div>
        )
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        aoiState: store.aoiState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        cancelEdit: () => {
            dispatch(Actions.cancelEdit());
        }
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AoisPanel);