import L from 'leaflet';
import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { Utils } from '../../services/utils/utils.jsx';
import { CustomIcon } from '../map/marker/customIcon';
import { DataUtil } from './DataUtil';
import { CoordinateUtils } from '../utils/CoordinateUtils.jsx';
import { ImageURLs } from '../utils/ImagesUtils.js';


export class AOIStyles {

    static getStyle = (object) => {

        const aoiStyle = {
            fillColor: 'yellow',
            color: 'grey',
            opacity: 0.6,
            weight: 3,
            fillOpacity: 0.4
        }
        if (object.type === "AOI") {
            if (object.aoiType === "CORDON") {
                aoiStyle.fillColor = "#80d26e";
            } else if (object.aoiType === "STAGING_AREA") {
                aoiStyle.fillColor = "#4194d2";
            } else if (object.aoiType === "ICP") {
                aoiStyle.fillColor = "#88d8b0";
            } else if (object.aoiType === "OTHER") {
                aoiStyle.fillColor = "#c58962";
            } else if (object.aoiType === "ENTRY_ROUTE") {
                aoiStyle.color = "#ff4a5bbf";
                aoiStyle.weight = 5;
            } else if (object.aoiType === "EXIT_ROUTE") {
                aoiStyle.color = "#80d26e";
                aoiStyle.weight = 5;
            } else if (object.aoiType === "HAZARD_AREA") {
                aoiStyle.color = "#f56c00";
                aoiStyle.fillColor = "none";
                aoiStyle.weight = 5;
            } else if (object.aoiType === "LINE") {
                aoiStyle.color = "#4194d2";
                aoiStyle.weight = 5;
            }
        }

        return aoiStyle;
    }

    static getMarker = (object, center, t, userPreference) => {
        let geometry = object.geometry;
        const data = {
            type: "Point",
            coordinates: [center.lng, center.lat]
        };
        if(object.aoiType === "ENTRY_ROUTE" || object.aoiType === "EXIT_ROUTE") {
            data.coordinates = geometry.coordinates[0];
        }
        return (
            <GeoJSON id={object.id}
                key={object.id + "-" + object.lastUpdated + 'marker'}
                data={data}
                pointToLayer={(feature, latlng) => {
                    let iconUrl = ImageURLs.DEFAULT_AOI;
                    if (object.aoiType === 'STAGING_AREA') {
                        iconUrl = ImageURLs.STAGING_AREA;
                    }
                    else if (object.aoiType === 'BASE') {
                        iconUrl = ImageURLs.BASE;
                    }
                    else if (object.aoiType === 'ICP') {
                        iconUrl = ImageURLs.ICP;
                    }
                    else if (object.aoiType === 'ENTRY_ROUTE') {
                        iconUrl = ImageURLs.ENTRY_ROUTE;
                    }
                    else if (object.aoiType === 'EXIT_ROUTE') {
                        iconUrl = ImageURLs.EXIT_ROUTE;
                    }
                    else if (object.aoiType === 'HAZARD_AREA') {
                        iconUrl = ImageURLs.HAZARD_AREA;
                    }
                    let marker = new L.Marker(latlng, { icon: new CustomIcon(iconUrl) });
                    const getCoordinatesTable = this.getCoordinatesTable;
                    marker.bindTooltip(object.name, { permanent: true }).openTooltip();
                    marker.bindPopup(AOIStyles.template
                        .replace(":area_of_interest", t("label.aoi"))
                        .replace(":label_type", t("label.type")).replace(':type', t("aoitypes." + object.aoiType))
                        .replace(":label_name", t("label.name")).replace(':name', object.name)
                        .replace(":label_perimeter", t("shape.perimeter")).replace(':perimeter', Math.ceil(DataUtil.getGeoJsonLength(object.geoJson)))
                        .replace(":label_area", t("label.area")).replace(':area', DataUtil.getArea(geometry))
                        .replace(":label_notes", t("label.notes")).replace(':notes', object.notes)
                        .replace(":label_population", t("label.population")).replace(':population', 'Loading...')
                        .replace(":label_coordinates", t("label.coordinates")).replace(':coordinates', getCoordinatesTable(object, userPreference)));

                    marker.on('click', function () {
                        // Make a call to population endpoint here
                        if(!Utils.OFFLINE_MAPS) {
                        var fet = AOIStyles.fetchPopulationInBounds(object.geoJson, t, (txt) => {
                            var totalPopulation = Math.floor(txt["population"]);

                            var populationTable = `<table style=" width: 90%;"><tbody>` +
                                `<tr><td>Total:</td> <td>${totalPopulation}</td></tr>` +
                                `</tbody></table>`;

                            marker.setPopupContent(AOIStyles.template
                                .replace(":area_of_interest", t("label.aoi"))
                                .replace(":label_type", t("label.type")).replace(':type', t("aoitypes." + object.aoiType))
                                .replace(":label_name", t("label.name")).replace(':name', object.name)
                                .replace(":label_perimeter", t("shape.perimeter")).replace(':perimeter', Math.ceil(DataUtil.getGeoJsonLength(object.geoJson)))
                                .replace(":label_area", t("label.area")).replace(':area', DataUtil.getArea(geometry))
                                .replace(":label_notes", t("label.notes")).replace(':notes', object.notes)
                                .replace(":label_population", t("label.population")).replace(':population', populationTable)
                                .replace(":label_coordinates", t("label.coordinates")).replace(':coordinates', getCoordinatesTable(object, userPreference)))
                        }, (err) => {
                            marker.setPopupContent(AOIStyles.template
                                .replace(":area_of_interest", t("label.aoi"))
                                .replace(":label_type", t("label.type")).replace(':type', t("aoitypes." + object.aoiType))
                                .replace(":label_name", t("label.name")).replace(':name', object.name)
                                .replace(":label_perimeter", t("shape.perimeter")).replace(':perimeter', Math.ceil(DataUtil.getGeoJsonLength(object.geoJson)))
                                .replace(":label_area", t("label.area")).replace(':area', DataUtil.getArea(geometry))
                                .replace(":label_notes", t("label.notes")).replace(':notes', object.notes)
                                .replace(":label_population", t("label.population")).replace(':population', err)
                                .replace(":label_coordinates", t("label.coordinates")).replace(':coordinates', getCoordinatesTable(object, userPreference)))
                        })
                    }});
                    return marker;
                }}
            />
        )
    }

    static getCoordinatesTable = (aoi, userPreference) => {
        let coordinates = CoordinateUtils.getGeometryCoordinates(aoi.geometry);
        // If the last coordinate is same as the first one - remove it, so that the polygon
        // coordinates do not repeat
        if(coordinates.length > 1) {
            // Remove the last entry from the array
            const lastEntry = coordinates.pop();
            // If it is different to the first entry - put it back into array
            if(coordinates[0][0] !== lastEntry[0] || coordinates[0][1] !== lastEntry[1]) {
                coordinates.push(lastEntry);
            }
        }
        let tableString = `<table style=" width: 90%;"><tbody>`;
        coordinates.forEach(latLonCoord => {
            tableString += `<tr><td>${CoordinateUtils.convertLonLatToDisplayString(latLonCoord, userPreference.coordinateUnit)}</td></tr>`
        });
        tableString += `</tbody></table>`;
        return tableString;
    }

    static template = '<div style="padding: 10px;"><h2 style="color: #2e6c80;">:area_of_interest</h2> <ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">' +
        '<li style="clear: both;">:label_name: <em>:name</em></li>' +
        '<li style="clear: both;">:label_type: <em>:type</em></li>' +
        '<li style="clear: both;">:label_perimeter: <em>:perimeter m</em></li>' +
        '<li style="clear: both;">:label_area: <em>:area m<sup>2</sup></em></li>' +
        '<li style="clear: both;">:label_notes: <em>:notes</em></li>' +
        (!Utils.OFFLINE_MAPS ? '<li style="clear: both;">:label_population: <em>:population</em></li>' : '')  +
        '<li style="clear: both;">:label_coordinates: <em>:coordinates</em></li></ol></div>'

    static fetchPopulationInBounds(geoJson, t, setPop, setError) {
        Utils.fetchPopulation(geoJson, setPop, () => {
            setError(t("measuringTool.failedToGetPopulation"))
        })
    }
}
