import moment from "moment";
import { DateFormats } from "./Units";


/**
 * Utilities class to convert between different formats of time
 */
export class DateUtils {

    static SLASHES_FORMAT = "DD/MM/YYYY HH:mm:ss"; // 22/04/2024 10:31:55
    static ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss"; // 2024-04-22T10:31:55
    static DTG_FORMAT = "DDHHmm[Z]MMMYYYY"; // 22 1031 Z APR2024
    static DTG_FORMAT_DISPLAY = "DD HHmm [Z] MMMYYYY"; // 22 1031 Z APR2024

    /**
     * Converts a datetime value to a display string in the appropriate format
     * @param {number | string} datetime date time in ms or as a string
     * @param {DateFormats} dateFormat true if the angle should be displayed in mils
     * @returns {String} the string to display
     */
    static getDateTimeStringForDisplay(datetime, dateFormat) {
        let format = this.SLASHES_FORMAT;
        if (dateFormat === DateFormats.ISO) {
            format = this.ISO_FORMAT;
        } else if (dateFormat === DateFormats.MIL_DATE_TIME_GROUP) {
            format = this.DTG_FORMAT_DISPLAY;
        }
        return moment(datetime).format(format).toUpperCase();
    }

}