// Importing from mgrs directly to access a later version than proj4js' mgrs dependency 
import { forward as mgrsForward, toPoint as mgrsToPoint } from "mgrs";
import { CoordinateUnits } from "./Units";

/**
 * Utilities class to convert between different forms of coordinates
 */
export class CoordinateUtils {

    /**
     * Converts an MGRS string to Lon/Lat values, or throws if input is invalid
     * 
     * @param {String} mgrs coordinates in MGRS string format
     * @returns {Array[Number]} [lon, lat]  
     */
    static convertMgrsToLonLat(mgrs) {
        // Add zeros to bring the accuracy to 5 digits within a grid
        // eg. 99AAA333444 => 99AAA3330044400
        if (mgrs.length >= 7 && mgrs.length < 15) {
            const countSectionDigits = (mgrs.length - 5) / 2;
            // Only alter if in an expected format
            if (countSectionDigits % 1 === 0) {
                // Work out how many zeros are required to bring the accuracy up to 5 digits
                const countNeededZeros = 5 - countSectionDigits;
                let zeros = ''
                while (zeros.length < countNeededZeros) {
                    zeros += '0';
                }
                mgrs =
                    mgrs.substring(0, 5 + countSectionDigits) + zeros +
                    mgrs.substring(5 + countSectionDigits) + zeros;
            }
        }

        const unroundedLonLatCoordinates = mgrsToPoint(mgrs)
        // Round Lon/Lat coordinate values to 6dp
        return unroundedLonLatCoordinates.map(number => Math.round(number * 1000000.0) / 1000000.0)
    }

    /**
     * Converts the lon-lat coordinates to a display string in the appropriate style
     * @param {Array[Number]} lonLatCoordinates the lon lat coordinates to create a display string for
     * @param {CoordinateUnits} coordinateUnit coordinate unit
     * @returns {String} the string to display
     */
    static convertLonLatToDisplayString(lonLatCoordinates, coordinateUnit) {
        if (coordinateUnit === CoordinateUnits.LAT_LON_DD) {
            // Convert to a lat-lon display string
            return `${lonLatCoordinates[1]}  ${lonLatCoordinates[0]}`
        } else {
            // Convert to a MGRS string
            const mgrsString = mgrsForward(lonLatCoordinates, 5);
            // Add a space between the three sections
            return `${mgrsString.slice(0, -10)} ${mgrsString.slice(-10, -5)} ${mgrsString.slice(-5)}`;
        }
    }

    /**
     * Gets an array of coordinates from geometry object
     * @param {geometry} geometry 
     * @returns returns array of coordinate arrays
     */
    static getGeometryCoordinates(geometry) {
        const coordinates = [];
        if (geometry.type === "Point") {
            coordinates.push(geometry.coordinates);
        }
        else if (geometry.type === "Polygon") {
            // Coordinates is an array of lat lons
            geometry.coordinates.forEach(coords => {
                coords.forEach(coord => {
                    coordinates.push(coord);
                });
            });
        }
        else {
            // Coordinates is an array of lat lons
            geometry.coordinates.forEach(coord => {
                coordinates.push(coord);
            });
        }
        return coordinates;
    }

}