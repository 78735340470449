import React from 'react';
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Button, Form, Modal } from 'semantic-ui-react';

/**
 * Modal react component to take user input to be used before exporting a PDF file
 */
export class PdfExportInputModal extends React.Component {

    /**
     * Constructor
     * @prop {Boolean} open true if the model should be visible and blocking
     * @prop {Function} onConfirm user confirmation callback that will be passed the user input
     * @prop {Function} onCancel user closure or cancellation callback method
     */
    constructor(props) {
        super(props);
        this.state = {
            input: ''
        }
    }

    /**
     * Propagates user closure or cancel
     */
    cancel = () => {
        this.props.onCancel();
        this.setState({ input: '' });
    }

    /**
     * Propagates user confirmation
     */
    confirm = () => {
        this.props.onConfirm(this.state.input);
        this.setState({ input: '' });
    }

    /**
     * React render method
     */
    render() {
        return <Modal
            className='ua-modal'
            open={this.props.open}
            onClose={this.cancel}
        >
            <Modal.Header>{this.props.t('pdfExport.options.pdfContentOptions')}</Modal.Header>
            <Modal.Content>
                <div className="content-body">
                    <Form className="ua-form">
                        <Form.Group>
                            <Form.Field>
                                <Form.TextArea
                                    inline
                                    label={`${this.props.t('pdfExport.options.additionalNotes')}:`}
                                    value={this.state.input}
                                    placeholder={this.props.t('pdfExport.options.additionalNotesPlaceholder')}
                                    rows={3}
                                    onChange={event => this.setState({ input: event.target.value })}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={this.props.t('dialogueOptions.cancel')}
                    onClick={this.cancel}
                />
                <Button
                    content={this.props.t('dialogueOptions.confirm')}
                    onClick={this.confirm}
                    positive
                />
            </Modal.Actions>
        </Modal>
    }
}

export default compose(withTranslation())(PdfExportInputModal);