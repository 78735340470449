/*
    ./client/components/App.jsx
*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, List } from 'semantic-ui-react';
import { UnitsHelper } from '../../common/unitsHelper';
import './styles.scss';

class LegendsTool extends React.Component {

    state = {
        legends: [],
        selectedLegend: ""
    }

    constructor(props) {
        super(props);

        const { t } = props;

        var legends = this.buildLegendDropdown(props);

        this.state = {
            legends: legends,
            selectedLegend: null
        }
    }

    buildLegendDropdown = (props) => {
        const { t } = props;

        // Clear the existing dropdown list
        var legends = [];

        var selectedSimulation = props.simulationState.selectedSimulation;
        if (selectedSimulation && props.resultsState.legendData && props.resultsState.legendData[selectedSimulation.id]) {

            for (var dataType in props.resultsState.legendData[selectedSimulation.id]) {
                var raKey = "ra-contour-legend-" + dataType;
                legends.push({ key: raKey, text: t("dataTypes." + dataType), value: dataType });
            }
        }

        return legends;
    }

    componentDidUpdate(prevProps) {
        var newLegends = this.buildLegendDropdown(this.props);

        // Extract values from old legends and new ones and sort them
        var legendValues = this.state.legends.map(ol => ol.value).sort();
        var newLegendValues = newLegends.map(nl => nl.value).sort();

        // Check the new legends haven't changed before setting on state to reduce re-renders
        if (legendValues.length !== newLegendValues.length || !legendValues.every((lv, ind) => newLegendValues[ind])) { // eslint-disable-line security/detect-object-injection
            // The available legends need to be updated, check the selected legend exists in the new legends
            var currentLegend = this.state.selectedLegend;
            if (!newLegendValues.includes(currentLegend)) {
                // Pick the first value as default 
                currentLegend = newLegendValues[0];
            }

            this.setState({
                selectedLegend: currentLegend,
                legends: newLegends
            });
        }
    }

    getLegend = () => {
        // Build the legend from data available
        var selectedSimulation = this.props.simulationState.selectedSimulation;
        if (selectedSimulation && this.props.resultsState.legendData) {
            var legendData = this.props.resultsState.legendData[selectedSimulation.id];
            if (selectedSimulation && legendData && legendData[this.state.selectedLegend]) {
                var legendList = [];
                var legendDataForSelectedDataType = legendData[this.state.selectedLegend];
                var unit = UnitsHelper.getUnits(this.state.selectedLegend);
                // Sort the legend data by the label value
                if(this.state.selectedLegend === "BLAST"){
                    // Perform a natural sort.
                    legendDataForSelectedDataType.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }));
                } else {
                    legendDataForSelectedDataType.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
                }
                for (var ind in legendDataForSelectedDataType) {
                    let indexedLegendDataForSelectedDataType = legendDataForSelectedDataType[ind] // eslint-disable-line security/detect-object-injection
                    legendList.push(
                        <li>
                            <span style={{ "background-color": indexedLegendDataForSelectedDataType.colour }}></span>
                            {indexedLegendDataForSelectedDataType.label}
                            {unit.unitName != "" ? <b>{" " + unit.unitName}<sup>{unit.exponent}</sup></b> : null}
                        </li>
                    )
                }

                return (
                    <ul class="legend-tool-legend">
                        {legendList}
                    </ul>
                );

            }
        }

        return null;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="content-body legends-card">
                <List>
                    <List.Item>
                        <Form className="ua-form">
                            <Form.Field >
                                <Form.Select
                                    fluid
                                    label={t('legendsTool.legend')}
                                    options={this.state.legends}
                                    placeholder={t('legendsTool.selectLegend')}
                                    value={this.state.selectedLegend}
                                    onChange={(e, data) => this.setState({ selectedLegend: data.value })}
                                />
                            </Form.Field>
                        </Form>
                    </List.Item>
                    <List.Item key={this.state.legend} className="legend-div">
                        {this.getLegend()}
                    </List.Item>
                </List>
            </div>
        )
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        simulationState: store.simulationState,
        resultsState: store.resultsState,
        ...props
    }
}

export default compose(withTranslation(), connect(mapStateToProps))(LegendsTool);