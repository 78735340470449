/*
    ./client/components/App.jsx
*/
import React from "react";
import { Menu, Popup } from "semantic-ui-react";
import "whatwg-fetch";
import ScenarioPanel from "./scenarioPanel";
import {ReactComponent as ScenarioIcon} from '../../../images/Calendar.svg';

export default class ScenarioMenu extends React.Component {
  getContent = () => {
    if (this.props.activeItem === this.props.name) {
      return <ScenarioPanel cancel={this.props.cancel} />;
    }
    return null;
  };

  render() {
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name={this.props.name}
              active={this.props.activeItem === this.props.name}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
            >
              <ScenarioIcon className="sidebar-icon"/>
            </Menu.Item>
          }
          position={"right center"}
        >
          {this.props.title}
        </Popup>

        <div className="menu-option">{this.getContent()}</div>
      </>
    );
  }
}
