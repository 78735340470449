import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Icon, Menu } from "semantic-ui-react";
import { Actions } from "../../services/setupMenu/actions";
import AoisMenu from "./aois/aoisMenu";
import MetPresetsMenu from "./metPresets/metPresetsMenu";
import IncidentsMenu from "./incidents/incidentsMenu.jsx";
import KeyBuildsMenu from "./keyBuilds/keyBuildsMenu.jsx";
import MetMenu from "./met/metMenu";
import SimulationConfigMenu from "./simulation-config/simulationConfigMenu.jsx";
import RunSimulationMenu from "./run-simulation/runSimulationMenu.jsx";
import ScenarioMenu from "./scenario/scenarioMenu";
import "./sidebar.scss";
import SimulationMenu from "./simulation/simulationMenu";
import SidebarItemsEnum from "../../enums/sidebarItemsEnum.jsx";

const INCLUDE_SCENARIOS = window.env.REACT_APP_INCLUDE_SCENARIOS === "true";
const INCLUDE_SIMULATIONS = INCLUDE_SCENARIOS && window.env.REACT_APP_INCLUDE_SIMULATIONS === "true";

class Sidebar extends React.Component {
  state = {
    mounted: false,
    showSimulationButtons: false,
  };

  handleItemClick = (e, { name }) => {
    if (this.props.activeItem === name) {
      this.props.selectMenu(null);
    } else {
      this.props.selectMenu(name);
    }
  };

  cancel = () => {
    this.props.selectMenu(null);
  };

  componentDidMount = () => {
    setTimeout(() => this.setState({ mounted: true }), 10);
    setTimeout(() => this.props.selectMenu(SidebarItemsEnum.SETUP), 500);
  };

  hideSimulationMenu = () => {
    this.props.selectMenu("simulation");
    this.setState({
      showSimulationButtons: false
    });
  };

  showSimulationMenu = () => {
    this.props.selectMenu(SidebarItemsEnum.INCIDENTS);
    this.setState({
      showSimulationButtons: true
    });
  };

  renderSimulationOptions = (t) => {
    if (this.state.showSimulationButtons) {
      return (
        <div className="simulation-wrapper">
          <IncidentsMenu
            name={SidebarItemsEnum.INCIDENTS}
            title={t("incident.incidents")}
            activeItem={this.props.activeItem}
            onClick={this.handleItemClick}
            next={() => this.props.selectMenu("met")}
            cancel={this.cancel}
          />

          <MetMenu
            name={SidebarItemsEnum.MET}
            title={t("met.metObservations")}
            activeItem={this.props.activeItem}
            onClick={this.handleItemClick}
            next={() => this.props.selectMenu("keyBuilds")}
            cancel={this.cancel}
          />

          <KeyBuildsMenu
            name={SidebarItemsEnum.KEY_BUILDS}
            title={t("keyBuild.keyBuilds")}
            activeItem={this.props.activeItem}
            onClick={this.handleItemClick}
            next={() => this.props.selectMenu("simulationConfig")}
            cancel={this.cancel}
          />

          <SimulationConfigMenu
            name={SidebarItemsEnum.SIMULATION_CONFIG}
            title={t("simulation.simulationConfig")}
            onClick={this.handleItemClick}
            activeItem={this.props.activeItem}
            next={() => this.props.selectMenu("runSimulation")}
            cancel={this.cancel}
          />

          <RunSimulationMenu
            name={SidebarItemsEnum.RUN_SIMULATION}
            title={t("simulation.runSimulation")}
            onClick={this.handleItemClick}
            next={() => this.hideSimulationMenu()}
            activeItem={this.props.activeItem}
            cancel={this.cancel}
          />

          <Icon
            color="black"
            name="window close outline"
            size="large"
            className="exit-icon"
            onClick={this.hideSimulationMenu}
          />
        </div>
      );
    }
  };

  render() {
    const { t } = this.props;

    var mountedStyling = "";
    if (this.state.mounted) {
      mountedStyling = "mounted";
    }

    return (
      <div className="left-sidebar">
        <div className={"sidebar-div " + mountedStyling}>
          <Menu vertical>
            <ScenarioMenu
              name={SidebarItemsEnum.SETUP}
              title={t("sidebar.scenarioMenu")}
              activeItem={this.props.activeItem}
              onClick={this.handleItemClick}
              cancel={this.cancel}
            />
            <AoisMenu
              name={SidebarItemsEnum.AOIS}
              title={t("sidebar.aoi")}
              activeItem={this.props.activeItem}
              onClick={this.handleItemClick}
              cancel={this.cancel}
            />
            <MetPresetsMenu
              name={SidebarItemsEnum.MET_PRESETS}
              title={t("sidebar.metPresets")}
              activeItem={this.props.activeItem}
              onClick={this.handleItemClick}
              cancel={this.cancel}
            />
            { INCLUDE_SIMULATIONS ?
              <SimulationMenu
                name={SidebarItemsEnum.SIMULATION}
                title={t("simulation.simulationTool")}
                activeItem={this.props.activeItem}
                onClick={this.handleItemClick}
                next={() => this.showSimulationMenu()}
                disabled={this.state.showSimulationButtons}
                cancel={this.cancel}
              /> : null}
            {this.renderSimulationOptions(t)}
          </Menu>
        </div>
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    activeItem: store.setupMenuState.activeItem,
    simulationState: store.simulationState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    selectMenu: (activeMenu) => {
      dispatch(Actions.selectMenu(activeMenu));
    }
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
