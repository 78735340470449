import L from 'leaflet';
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { FeatureGroup, GeoJSON, LayersControl, Pane, withLeaflet } from 'react-leaflet';
import Requests from '../../../services/requests';
import { ImageURLs } from '../../utils/ImagesUtils.js';
import { CustomIcon } from '../marker/customIcon.jsx';

const { Overlay } = LayersControl

const userPopupTemplate = '<div style="padding: 10px;"> <ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">' +
    '<li style="clear: both;">:name:</li>' +
    '<li style="clear: both;">:time:</li>' +
    '</ol></div>'

const THIRTY_S_IN_MS = 30 * 1000;
const TWO_HRS_IN_MS = 2 * 60 * 60 * 1000;

class UserLocationsLayer extends Overlay {

    state = {
        locations: []
    }

    componentDidMount() {
        this.getLocationsTask = setInterval(() => this.fetchLocations(), THIRTY_S_IN_MS);
    }

    componentWillUnmount() {
        clearInterval(this.getLocationsTask);
    }

    fetchLocations = () => {
        // Only fetch if the user is verified
        if (this.props.userState.verified) {
            // Build query to pass fromTime within last 2 hours (in miliseconds)
            const fromTime = new Date(new Date().getTime() - TWO_HRS_IN_MS);
            let queryParam = { fromTime: fromTime };
            const url = '/users/locations?' + new URLSearchParams(queryParam);

            Requests.get(url)
                .then(locations => {
                    if (locations) {
                        this.setState({ locations: locations });
                    } else {
                        console.log("Error getting locations");
                    }
                })
                .catch(err => {
                    console.log("error locations,", err);
                })
        }
    }

    getMarkers = () => {

        var markers = [];

        if (this.state.locations.length === 0) {
            return markers;
        }

        for (let ind in this.state.locations) {
            const location = this.state.locations[ind];

            // Determine what icon to use
            const currentTime = new Date().getTime();
            const locationTime = new Date(location.time).getTime();
            const differenceInMins = (currentTime - locationTime) / (1000 * 60);
            let userIcon = ImageURLs.GREEN_USER;
            if (differenceInMins > 10) {
                userIcon = ImageURLs.RED_USER;
            } else if (differenceInMins > 1) {
                userIcon = ImageURLs.ORANGE_USER;
            }

            markers.push(
                <GeoJSON
                    key={location.userId + "-" + location.time + '-position'}
                    data={JSON.parse(location.geometry)}
                    pointToLayer={(feature, latlng) => {
                        let marker = new L.Marker(latlng, { icon: new CustomIcon(userIcon) });
                        marker.bindPopup(userPopupTemplate.replace(":name:", location.displayName)
                            .replace(":time:", new Date(location.time).toLocaleString()));

                        return marker;
                    }}
                />
            )
        }

        return markers;
    }

    render() {
        return (<FeatureGroup>
            <Pane name="usersLocationsPane" style={{ zIndex: 210 }}>
                {this.getMarkers()}
            </Pane>
        </FeatureGroup>);
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
        ...props,
    };
};

export default connect(mapStateToProps)(withLeaflet(UserLocationsLayer));
