import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Tab } from "semantic-ui-react";
import PanelWrapper from '../common/panelWrapper.jsx';
import "./appHeader.scss";
import AdminPanel from './user/adminPanel.jsx';
import AccessLogPanel from './user/accessLogPanel.jsx';
import PasswordChange from './user/passwordChange.jsx';
import Preferences from './user/preferences.jsx';
import Profile from './user/profile.jsx';


class UserWindow extends React.Component {

    getTabs = () => {
        const { t } = this.props;
        const tabs = [];

        tabs.push({ menuItem: t('profile.profile'), render: () => <Tab.Pane><Profile close={this.props.close} /></Tab.Pane> });
        tabs.push({ menuItem: t('profile.preferences.preferences'), render: () => <Tab.Pane><Preferences /></Tab.Pane> });
        tabs.push({ menuItem: t('title.password'), render: () => <Tab.Pane><PasswordChange /></Tab.Pane> });

        // Admins get to see an admin panel
        if (this.props.userState.user && this.props.userState.user.role === "ROLE_ADMIN") {
            tabs.push({ menuItem: t('profile.userManagement'), render: () => <Tab.Pane><AdminPanel /></Tab.Pane> });
            tabs.push({ menuItem: t('profile.accessLog'), render: () => <Tab.Pane><AccessLogPanel /></Tab.Pane> });
        }

        return tabs;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="user-window">
                <PanelWrapper key="users" title={t('profile.account')} cancel={this.props.close} >
                    <Tab className="ua-tab account" panes={this.getTabs()} />
                </PanelWrapper>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
    }
}
export default compose(withTranslation(), connect(mapStateToProps))(UserWindow);
