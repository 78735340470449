/*
    ./client/components/App.jsx
*/
import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import { Dropdown, Form, Input } from "semantic-ui-react";
import { CoordinateUtils } from "../utils/CoordinateUtils";
import { CoordinateUnits } from "../utils/Units";

class CoordinatesInputField extends React.Component {

  /**
   * Constructor
   * @prop {Array[Number]} coordinates coordinates in lon-lat
   * @prop {CoordinateUnit} coordinateUnit default coordinate unit
   * @prop {Function} onCoordinatesChange (optional) function that takes new lon-lat coordinates if a valid one is entered into this input
   * @prop {Function} onErrorStateChange (optional) function that takes a boolean when the validation error state of this component changes
   * @prop {Boolean} disabled (optional) true to disable this component
   * @prop {Boolean} upward (optional) true to have the unit selection dropdown expand upwards instead
   * @prop {Boolean} readOnly (optional) true if this component should not allow the coordinates to be edited
   */
  constructor(props) {
    super(props);

    this.state = {
      coordinateUnit: props.coordinateUnit,
      amendedInputValue: null,
      inputValidationError: null
    };
  }

  validateAndPropagateAmendedInputValue = () => {
    const stringToValidate = this.state.amendedInputValue
    // Check if update necessary, value will be null if field not edited
    if (stringToValidate === null) {
      return
    }
    // Field is required, empty string is not valid
    if (stringToValidate === "") {
      this.setErrorState(this.props.t("source.messages.invalidCoordinates"))
      return
    }

    // Validate string and convert to lon lat coordinates
    let lonLatCoordinates;
    if (this.state.coordinateUnit === CoordinateUnits.LAT_LON_DD) {
      // Expect a string representing lat lon coordinates

      // Remove leading and trailing spaces, then split on spaces
      const latLonCoordinateStrings = stringToValidate.trim().split(/[ ]+/)
      // Expect exactly two values, or invalid
      if (latLonCoordinateStrings.length !== 2) {
        this.setErrorState(this.props.t("source.messages.invalidCoordinates"))
        return
      }
      // Expect both values to be numbers, or invalid
      lonLatCoordinates = [Number(latLonCoordinateStrings[1]), Number(latLonCoordinateStrings[0])]
      if (isNaN(lonLatCoordinates[0]) || isNaN(lonLatCoordinates[1])) {
        this.setErrorState(this.props.t("source.messages.invalidCoordinates"))
        return
      }
    } else {
      // Expect a MGRS string
      try {
        lonLatCoordinates = CoordinateUtils.convertMgrsToLonLat(stringToValidate)
      } catch(exception) {
        this.setErrorState(this.props.t("source.messages.invalidCoordinates"))
        return
      }
    }
    
    this.props.onCoordinatesChange(lonLatCoordinates)
    this.setErrorState(null)
  }

  setErrorState(newInputValidationError) {
    this.props.onErrorStateChange(!!newInputValidationError)
    this.setState({inputValidationError: newInputValidationError})
  }

  onDropdownChange = (coordinateUnit) => {
    this.props.onErrorStateChange(false)
    this.setState({
      coordinateUnit: coordinateUnit,
      amendedInputValue: null,
      inputValidationError: null
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coordinates !== this.props.coordinates) {
      this.setErrorState(false)
    }
  }

  render() {
    const defaultDisplayString = CoordinateUtils.convertLonLatToDisplayString(this.props.coordinates, this.state.coordinateUnit);

    return <Form.Field error={!!this.state.inputValidationError}>
      <label>{this.props.label}</label>
      <Input
        key={defaultDisplayString}
        label={
          <Dropdown
            className="units-dropdown"
            value={this.state.coordinateUnit}
            options={[
              {text: this.props.t("units.coordinate.LAT_LON_DD"), value: CoordinateUnits.LAT_LON_DD},
              {text: this.props.t("units.coordinate.MGRS"), value: CoordinateUnits.MGRS},
            ]}
            onChange={(event, data) => this.onDropdownChange(data.value)}
            disabled={this.props.disabled}
            upward={this.props.upward}
          />
        }
        className="input-bar"
        labelPosition="right"
        defaultValue={defaultDisplayString}
        onChange={event => this.setState({amendedInputValue: event.target.value})}
        onBlur={this.validateAndPropagateAmendedInputValue}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
      />
      {this.state.inputValidationError &&
        <div className="ui pointing above prompt label">
          {this.state.inputValidationError}
        </div>}
    </Form.Field>
  }
}

CoordinatesInputField.defaultProps = {
  onCoordinatesChange: () => {},
  onErrorStateChange: () => {}
};

export default compose(
  withTranslation()
)(CoordinatesInputField);