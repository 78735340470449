import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Actions as Actions } from "../../services/user/actions";
import Footer from "../common/footer.jsx";
import AppHeader from "../header/appHeader.jsx";
import LoginCard from "../header/loginCard.jsx";
import StartupWindow from "../header/startupWindow";
import MainMap from "../map/map.jsx";
import EndUserAgreement from "./endUserAgreement.jsx";
import StaticDataModal from './staticDataModal.jsx';
import MobileRedirectConfirm from "./MobileRedirectConfirm.jsx";
import Login from "../header/login"
import Requests from '../../services/requests';
import "./styles.scss";

const INCLUDE_SCENARIOS = window.env.REACT_APP_INCLUDE_SCENARIOS === "true";
const OFFLINE_MAPS = window.env.REACT_APP_OFFLINE_MAPS === "true";

/**
 * WelcomeScreen Components which renders the EULA, Login and
 * Scenarios.
 */
class WelcomeScreen extends React.Component {

    state = {
        unmount: false,
        editingScenario: false,
        showCreateOrLoadScenario: false,
        renderNavButtons: false,
        showLoginPage: false,
        showEULAPage: true,
        title: null,
        url: null
    };

    constructor(props) {
        super(props);
        // Initiate the state
        this.state = {
            unmount: false,
            editingScenario: false,
            showCreateOrLoadScenario: false,
            renderNavButtons: false,
            showLoginPage: false,
            showEULAPage: true,
            title: null,
            url: null
        };

        // If SMITHS - bypass the EULA acceptance and go straight to login
        if (window.env.REACT_APP_SMITHS_REQUESTS === 'true') {
            this.state.showEULAPage = false;
            this.state.showLoginPage = true;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userState.user !== null && prevProps.userState.user === null) {
            // User has just logged in - update the screen to remove login page and render home screen
            this.setState({ unmount: true, renderNavButtons: true, showCreateOrLoadScenario: true, editingScenario: false });
            setTimeout(() => this.setState({ showLoginPage: false }), 1000);
            // When user is logged in start calls to ui-server to verify users sessions, and keep it
            // fresh for an uninterupted session (every 25 seconds)
            this.refreshSession = setInterval(() => this.verifyAndRefreshSession(), 25 * 1000);
        }
        else if (this.props.userState.user === null && prevProps.userState.user !== null) {
            // User has just logged out - update the screen to show login page and hide home screen
            this.setState({ unmount: false, renderNavButtons: false, showCreateOrLoadScenario: false, editingScenario: false });
            setTimeout(() => this.setState({ showLoginPage: true }), 500);
            // When user logs out stop refreshing the session
            clearInterval(this.refreshSession);
        } else if (!this.props.scenarioState.scenario && prevProps.scenarioState.scenario) {
            // User has just clicked on the home button - take back to scenario loading
            // (Current scenario would be null and previous would not be null)
            this.setState({ showCreateOrLoadScenario: true, editingScenario: false });
        }
    }

    onSuccessLogin = () => {
        this.props.setVerified(true);
    }

    verifyAndRefreshSession = () => {
        // Only keep on re-verfidying if the user is currently verified
        if (this.props.userState.verified) {
            // Verify and refresh users session, the session should never randomly expire, as each
            // refresh should last 1 minute, and this method gets called every 25 seconds
            Requests.post('/users/verify')
                .then(response => {
                    this.props.setVerified(true);
                })
                .catch(err => {
                    console.error("Caught error verifying user session ", err);
                    this.props.setVerified(false);

                    // If on Smiths - attempt to refresh the parent page to the user is taken back to
                    // Smiths login page
                    if (window.env.REACT_APP_SMITHS_REQUESTS === 'true') {
                        window.opener.location.reload(false);
                    }
                });
        }
    }

    /**
     * Passed to end user agreement component. When the user accepts, allow them to log in.
     */
    acceptEULA = () => {
        this.setState({
            showLoginPage: true,
            showEULAPage: false
        });
    }

    /**
     * Passed to end user agreement component. When the user declines, redirect to riskaware homepage.
     */
    declineEULA = () => {
        window.location.replace("https://www.riskaware.co.uk");
    }

    /**
     * Passed to the StartingWindow component. When a new scenario is created
     * closes down the window.
     */
    closeScenarioLoader = () => {
        this.setState({
            showCreateOrLoadScenario: false,
            editingScenario: true
        });
    };

    /**
     * Unloads the scenario, hides scenario specific panels
     */
    unloadScenario = () => {
        this.setState({ showCreateOrLoadScenario: true, editingScenario: false });
    }

    renderStartupWindow() {
        /**
         * If the user has not started editing a scenario yet, then return the StartingWindow component.
         */

        if (this.state.showCreateOrLoadScenario === true && this.props.userState.user !== null) {
            return <StartupWindow closeScenarioLoader={() => this.closeScenarioLoader()} />;
        }
    }

    renderMainMap() {
        // If the user is logged in - render a map
        if (this.props.userState.user !== null) {
            return <MainMap />;
        }
    }

    showModal = (title, src) => {
        this.setState({ title: title, src: src });
    }

    hideModal = () => {
        this.setState({ title: null, src: null });
    }

    render() {
        const { t } = this.props;

        /* 
        Renders the login, EULA, and StartingWindow depending on 
        the log in conditions.
    */

        let unmountClass = "";
        let mountClass = "";
        if (this.state.unmount) {
            unmountClass = " unmount";
            mountClass = " mount";
        }

        return (
            <>
                <AppHeader
                    editingScenario={this.state.editingScenario}
                    unloadScenario={this.unloadScenario}
                    navButtons={this.state.renderNavButtons}
                />
                {this.state.showEULAPage ? (
                    <div className={"welcome-page"}>
                        <EndUserAgreement acceptEULA={() => this.acceptEULA()} declineEULA={() => this.declineEULA()} showModal={this.showModal} />
                    </div>
                ) : null
                }
                {this.state.showLoginPage ? (
                    <div className={"welcome-page" + unmountClass}>
                        <LoginCard onlyLogin={OFFLINE_MAPS}/>
                    </div>
                ) : null
                }
                <div className={"app-page" + mountClass}>
                    {INCLUDE_SCENARIOS ? this.renderStartupWindow() : null }
                    {this.renderMainMap()}
                </div>
                {this.state.title ? (
                    <StaticDataModal title={this.state.title} src={this.state.src} cancel={this.hideModal} />
                ) : null
                }
                {!this.state.showLoginPage && !this.state.showEULAPage && this.props.userState.user !== null && !this.props.userState.verified ?
                    <div className={"login-overlay"}>
                        <Login onlyLogin={true} error={t('messages.sessionExpired')} onSuccessLogin={this.onSuccessLogin} />
                    </div>
                    : null}
                <Footer showModal={this.showModal} />
                <MobileRedirectConfirm />
            </>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
        scenarioState: store.scenarioState,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        setVerified: (verified) => {
            dispatch(Actions.setVerified(verified));
        }
    };
};
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(WelcomeScreen);
