import Requests from '../requests';

/*
* action types
*/
export const SELECT_DATA_TYPE = 'SELECT_DATA_TYPE';
export const SELECT_MATERIAL = 'SELECT_MATERIAL';
export const FETCH_SIMULATION_OUTPUTS_SUCCESS = 'FETCH_SIMULATION_OUTPUTS_SUCCESS';
export const SIMULATION_OUTPUTS_FETCH_FAILURE = 'SIMULATION_OUTPUTS_FETCH_FAILURE';
export const SIMULATION_OUTPUTS_FETCH_BEGIN = 'SIMULATION_OUTPUTS_FETCH_BEGIN';

export const CLEAR = 'CLEAR_RESULTS';

export class ResultsActions {

	static clear() {
		return {
			type: CLEAR
		}
	}

	static selectDataTypes = (dataTypeIds) => ({
		type: SELECT_DATA_TYPE,
		payload: { dataTypeIds }
	});

	static selectMaterials = (materialIds) => ({
		type: SELECT_MATERIAL,
		payload: { materialIds }
	});

	static fetchSimulationOutputs(scenarioId) {

		return dispatch => {
			dispatch(this.fetchBegin());
			return Requests.get("/simulation/outputs/scenario/" + scenarioId)
				.then(json => {
					dispatch(this.fetchSimulationOutputsSuccess(json));
					return json;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.fetchFailure(error))
				});
		};
	}

	static fetchBegin = () => {
		return ({
			type: SIMULATION_OUTPUTS_FETCH_BEGIN
		});
	}

	static fetchSimulationOutputsSuccess = (simulations) => {
		return ({
			type: FETCH_SIMULATION_OUTPUTS_SUCCESS,
			payload: { simulations }
		});
	}

	static fetchFailure = error => {
		return ({
			type: SIMULATION_OUTPUTS_FETCH_FAILURE,
			payload: { error }
		});
	}

}