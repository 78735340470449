import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import '../map.scss';
import KeyBuildingChart from './KeyBuildingChart';

class KeyBuildingPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            position: props.position,
            width: window.innerWidth,
            height: window.innerHeight,
            selectedBuilding: props.keyBuildState.keyBuilds[props.id],
        }

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.hidePopup();
        }
    }

    getBuildingInfo = () => {
        const { t } = this.props;

        return (
            <div style={{ "fontSize": "14px", "paddingLeft": "20px" }}>
                <div>
                    <span style={{ "fontWeight": "800" }}>{t("keyBuild.height") + ": "}</span>{this.state.selectedBuilding.height}
                </div>
                {this.getBvimInfo()}
                <div>
                    <span style={{ "fontWeight": "800" }}>{t("keyBuild.notes") + ": "}</span>{this.state.selectedBuilding.notes}
                </div>
            </div>
        )
    }

    getBvimInfo = () => {
        let { t } = this.props;
        let bvimConfig = this.state.selectedBuilding.bvimConfig;

        if (this.state.selectedBuilding.bvimConfig) {
            return (
                <>
                    <div>
                        <span style={{ "fontWeight": "800" }}>{t("keyBuild.bvimBuildingType") + ": "}
                        </span>{bvimConfig.buildingType.name}
                    </div>
                    <div>
                        <span style={{ "fontWeight": "800" }}>{t("keyBuild.bvimModelType") + ": "}
                        </span>{t("keyBuild.bvimModelTypes." + bvimConfig.bvimModelType)}
                    </div>
                </>
            )
        }
        else {
            return null;
        }
    }

    getOutputChart = () => {
        if (this.props.simulationState.selectedSimulation.status === "FINISHED" || this.props.simulationState.selectedSimulation.status === "RUNNING") {
            return (
                <KeyBuildingChart
                    selectedBuilding={this.state.selectedBuilding}
                />
            )
        } else {
            return null;
        }
    }


    render() {
        const { t } = this.props;
        var building = this.state.selectedBuilding;
        // Create a Draggable component bounds that will not allow to drag the popup
        // outside the visible window, with 200 pixels of the popup always visble
        return (
            <Draggable
                cancel=".dropdown"
                bounds={{
                    left: -this.state.position.x - 200,
                    top: -this.state.position.y - 200,
                    right: this.state.width - this.state.position.x - 200,
                    bottom: this.state.height - this.state.position.y - 200,
                }}>
                <div className="leaflet-popup-content-wrapper ua-popup key-build" ref={this.wrapperRef}
                    style={{
                        left: this.state.position.x + 'px',
                        top: this.state.position.y + 'px'
                    }}>
                    <div style={{ "textAlign": "center" }}>
                        {t("keyBuild.building")}: <span style={{ "fontWeight": "800", "fontSize": "16px" }}>{building.name}</span>
                    </div>
                    <div style={{ "marginTop": "10px" }}>
                        {this.getOutputChart()}
                        {this.getBuildingInfo()}
                    </div>
                </div>
            </Draggable>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        keyBuildState: store.keyBuildState,
        simulationState: store.simulationState,
        position: store.mapState.position,
    }
}

export default compose(withTranslation(), connect(mapStateToProps))(KeyBuildingPopup);