import { WindDirectionUnits } from "./Units";

const DEG_TO_MILS_RATE = 6400.0 / 360.0;

/**
 * Utilities class to convert between different units of angles
 */
export class AngleUtils {

    /**
     * Converts a value in degrees to a display string in the appropriate style
     * @param {Number} degrees the value in degrees to create a display string for
     * @param {WindDirectionUnits} windDirectionUnit true if the angle should be displayed in mils
     * @returns {String} the string to display
     */
    static convertDegreesToDisplayString(degrees, windDirectionUnit) {
        if (windDirectionUnit === WindDirectionUnits.MILS) {
            return `${AngleUtils.roundTo6DP(degrees * DEG_TO_MILS_RATE)}`
        } else {
            return `${degrees}\u00B0`
        }
    }

    /**
     * Rounds a number to 6 decimal places
     * @param {Number} number the number to round 
     * @returns the rounded number
     */
    static roundTo6DP(number) {
        return  Math.round(number * 1000000.0) / 1000000.0;
    }

}