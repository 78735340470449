import L from "leaflet";
import React from "react";
import { GeoJSON } from "react-leaflet";
import { CustomIcon } from "../map/marker/customIcon";
import { DataUtil } from './DataUtil.jsx';
import { CoordinateUtils } from "../utils/CoordinateUtils";
import { ImageURLs } from "../utils/ImagesUtils.js";

export class SourceStyles {

  static getStyle = (source) => {

    var strokeWidth = 6;
    // Pool source will have a thinner stroke
    if (source.geoJson.geometry.type === 'Point' && source.radius) {
      strokeWidth = 3;
    }

    return {
      color: 'grey',
      fillColor: 'yellow',
      opacity: 0.6,
      weight: strokeWidth,
      fillOpacity: 0.4
    }
  }

  static calculateCentreOfMultiPointSource = (object) => {

    // Iterates through the coordinates and finds the distances between each coordinate
    var distances = [];
    var previousCoordinate = null;
    for (var idx in object.geometry.coordinates) {
      var coord = object.geometry.coordinates[idx]; // eslint-disable-line security/detect-object-injection

      if (!previousCoordinate) {
        previousCoordinate = coord;
        continue;
      }
      var distance = Math.sqrt(
        Math.pow((coord[0] - previousCoordinate[0]), 2) +
        Math.pow((coord[1] - previousCoordinate[1]), 2)
      );
      distances.push(distance);
    }

    // Finds the pair of coordinates where the centre of the line or multiline is
    // Then finds the centre of these two points and sets that the be where the popup shows
    var length = distances.reduce((a, b) => a + b, 0);
    for (var idx in distances) {
      var truncatedDistances = distances.slice(0, parseFloat(idx) + 1);
      if (truncatedDistances.reduce((a, b) => a + b, 0) > (length / 2)) {
        var pointCoord = [];
        for (var dim in object.geometry.coordinates[idx].slice(0, -1)) { // eslint-disable-line security/detect-object-injection
          pointCoord.push(
            (object.geometry.coordinates[idx][dim] + object.geometry.coordinates[parseFloat(idx) + 1][dim]) / 2 // eslint-disable-line security/detect-object-injection
          );
        }
        return pointCoord;
      }
    }
  }

  static getCoordinatesTemplate = (object, userPreference) => {
    if (object.sourceTermType === "PointSource" || object.sourceTermType === "PoolSource") {
      return CoordinateUtils.convertLonLatToDisplayString(object.geoJson.geometry.coordinates, userPreference.coordinateUnit);
    }
    return;
  }

  static getMarker(object, t, userPreference) {
    let startTime = new Date(object.startTime);

    let geometry = object.geometry;
    if (object.sourceTermType === "MovingSource" || object.sourceTermType === "LineSource") {
      geometry = {
        type: "Point",
        coordinates: this.calculateCentreOfMultiPointSource(object)
      }
    }
    let length = DataUtil.getLengthOfGeoJsonLine(object.geoJson);
    let numSigFigs = 6;

    return (
      <GeoJSON
        id={
          object.id +
          "-" +
          object.sourceTermType +
          "-" +
          object.startTime
        }
        key={
          object.id +
          "-" +
          object.sourceTermType +
          "-" +
          object.startTime +
          "-" +
          object.lastUpdated
        }
        data={geometry}
        pointToLayer={(feature, latlng) => {
          let iconUrl = ImageURLs.CHEMICAL;
          let agentType = object.agentType
          if (agentType === "BIOLOGICAL") {
            iconUrl = ImageURLs.BIOLOGICAL;
          } else if (agentType === "CHEMICAL") {
            iconUrl = ImageURLs.CHEMICAL;
          } else if (agentType === "RADIOLOGICAL") {
            iconUrl = ImageURLs.RADIOLOGICAL;
          }

          let marker = new L.Marker(latlng, { icon: new CustomIcon(iconUrl) });

          let incidentType = "";
          switch (object.sourceTermType) {
            case "PointSource":
              incidentType = "sidebar.PointSource";
              break;
            case "LineSource":
              incidentType = "sidebar.LineSource";
              break;
            case "MovingSource":
              incidentType = "sidebar.MovingSource";
              break;
            case "PoolSource":
              incidentType = "sidebar.PoolSource";
              break;
          }

          // Populate the template with common values of sources
          let template = SourceStyles.template
            .replace(":label_sourceTerm", t("label.sourceTerm"))
            .replace(":label_name", t("label.incidentName")).replace(":incidentName", object.incidentName)
            .replace(":label_type", t("label.type")).replace(":type", t(incidentType))
            .replace(":label_start_time", t("label.startTime")).replace(":startTime", startTime.toLocaleString())
            .replace(":label_material", t("label.material")).replace(":material", object.materialName)
            .replace(":label_agent_type", t("label.agentType")).replace(":agentType", t("sidebar." + object.agentType))
            .replace(":label_mass", t("label.mass")).replace(":mass", object.mass.toPrecision(numSigFigs));

          if (object.sourceTermType === "PoolSource") {
            template = template
              .replace(":label_coordinates: <em>:coordinates</em>", "")
              .replace(":label_sigma_x: <em>:sigmaX</em>", "")
              .replace(":label_sigma_y: <em>:sigmaY</em>", "")
              .replace(":label_sigma_z: <em>:sigmaZ</em>", "")
              .replace(":label_length: <em>:length</em>", "")
              .replace(":label_speed: <em>:speed</em>", "")
              .replace(":label_duration: <em>:duration</em>", "")
              .replace(":label_rate: <em>:rate</em>", "")
              .replace(":label_std_dev_droplet_size: <em>:stdDevParticleDropletSize</em>", "")
              .replace(":label_mean_droplet_size: <em>:meanParticleDropletSize</em>", "");
          }
          if (object.sourceTermType === "PointSource") {
            template = template
              .replace(":label_coordinates", t("label.coordinates")).replace(":coordinates", this.getCoordinatesTemplate(object, userPreference))
              .replace(":label_length: <em>:length</em>", "")
              .replace(":label_speed: <em>:speed</em>", "")
              .replace(":label_rate", t("label.rate")).replace(":rate", Number.parseFloat((object.mass / object.duration).toPrecision(numSigFigs)))
              .replace(":label_duration", t("label.duration")).replace(":duration", Number.parseFloat(object.duration.toPrecision(numSigFigs)))
              .replace(":label_mean_droplet_size", t("label.meanParticleDropletSize")).replace(":meanParticleDropletSize", object.meanParticleDropletSize)
              .replace(":label_std_dev_droplet_size", t("label.stdDevDropletSize")).replace(":stdDevParticleDropletSize", object.stdDevParticleDropletSize)
              .replace(":label_sigma_x", t("label.sigmaX")).replace(":sigmaX", object.sigmaX)
              .replace(":label_sigma_y", t("label.sigmaY")).replace(":sigmaY", object.sigmaY)
              .replace(":label_sigma_z", t("label.sigmaZ")).replace(":sigmaZ", object.sigmaZ);
          }
          if (object.sourceTermType === "LineSource") {
            template = template
              .replace(":label_speed: <em>:speed</em>", "")
              .replace(":label_coordinates: <em>:coordinates</em>", "")
              .replace(":label_rate", t("label.rate")).replace(":rate", Number.parseFloat((object.mass / object.duration).toPrecision(numSigFigs)))
              .replace(":label_length", t("label.length")).replace(":length", Number.parseFloat(length.toPrecision(numSigFigs)))
              .replace(":label_duration", t("label.duration")).replace(":duration", Number.parseFloat(object.duration.toPrecision(numSigFigs)))
              .replace(":label_mean_droplet_size", t("label.meanParticleDropletSize")).replace(":meanParticleDropletSize", object.meanParticleDropletSize)
              .replace(":label_std_dev_droplet_size", t("label.stdDevDropletSize")).replace(":stdDevParticleDropletSize", object.stdDevParticleDropletSize)
              .replace(":label_sigma_x", t("label.sigmaX")).replace(":sigmaX", object.sigmaX)
              .replace(":label_sigma_y", t("label.sigmaY")).replace(":sigmaY", object.sigmaY)
              .replace(":label_sigma_z", t("label.sigmaZ")).replace(":sigmaZ", object.sigmaZ);
          }
          if (object.sourceTermType === "MovingSource") {
            template = template
              .replace(":label_coordinates: <em>:coordinates</em>", "")
              .replace(":label_rate", t("label.rate")).replace(":rate", Number.parseFloat((object.mass / object.duration).toPrecision(numSigFigs)))
              .replace(":label_length", t("label.length")).replace(":length", Number.parseFloat(length.toPrecision(numSigFigs)))
              .replace(":label_duration", t("label.duration")).replace(":duration", Number.parseFloat(object.duration.toPrecision(numSigFigs)))
              .replace(":label_speed", t("label.speed")).replace(":speed", Number.parseFloat((length / object.duration).toPrecision(numSigFigs)))
              .replace(":label_mean_droplet_size", t("label.meanParticleDropletSize")).replace(":meanParticleDropletSize", object.meanParticleDropletSize)
              .replace(":label_std_dev_droplet_size", t("label.stdDevDropletSize")).replace(":stdDevParticleDropletSize", object.stdDevParticleDropletSize)
              .replace(":label_sigma_x", t("label.sigmaX")).replace(":sigmaX", object.sigmaX)
              .replace(":label_sigma_y", t("label.sigmaY")).replace(":sigmaY", object.sigmaY)
              .replace(":label_sigma_z", t("label.sigmaZ")).replace(":sigmaZ", object.sigmaZ);
          }
          marker.bindPopup(template);

          return marker;
        }}
      />
    );
  }

  static template =
    '<div style="padding: 10px;">' +
    '<h2 style="color: #f7af09;">:label_sourceTerm</h2>' +
    '<ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">' +
    '<li style="clear: both;">:label_name: <em>:incidentName</em></li>' +
    '<li style="clear: both;">:label_type: <em>:type</em></li>' +
    '<li style="clear: both;">:label_agent_type: <em>:agentType</em></li>' +
    '<li style="clear: both;">:label_mass: <em>:mass</em></li>' +
    '<li style="clear: both;">:label_duration: <em>:duration</em></li>' +
    '<li style="clear: both;">:label_rate: <em>:rate</em></li>' +
    '<li style="clear: both;">:label_start_time: <em>:startTime</em></li>' +
    '<li style="clear: both;">:label_material: <em>:material</em></li>' +
    '<li style="clear: both;">:label_coordinates: <em>:coordinates</em></li>' +
    '<li style="clear: both;">:label_length: <em>:length</em></li>' +
    '<li style="clear: both;">:label_speed: <em>:speed</em></li>' +
    '<li style="clear: both;">:label_sigma_x: <em>:sigmaX</em></li>' +
    '<li style="clear: both;">:label_sigma_y: <em>:sigmaY</em></li>' +
    '<li style="clear: both;">:label_sigma_z: <em>:sigmaZ</em></li>' +
    '<li style="clear: both;">:label_std_dev_droplet_size: <em>:stdDevParticleDropletSize</em></li>' +
    '<li style="clear: both;">:label_mean_droplet_size: <em>:meanParticleDropletSize</em></li>' +
    "</ol></div>";
}
