import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Message } from "semantic-ui-react";
import { Actions, ADD_KEY_BUILD_FAILURE, ADD_KEY_BUILD_SUCCESS, UPDATE_KEY_BUILD_FAILURE, UPDATE_KEY_BUILD_SUCCESS } from "../../../services/keyBuild/actions.jsx";
import InputFieldWithUnits from "../../common/inputFieldWithUnits.jsx";
import ShapeCreateEdit from "../sources/shapeCreateEditor.jsx";
import "../sources/sources.scss";
import BvimConfigForm from './configs/bvimConfigForm.jsx';
import "./styles.scss";

class KeyBuildManualEditor extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        var keyBuild = props.keyBuildState.editing;
        // Don't call this.setState() here!
        this.state = {
            keyBuildType: keyBuild.keyBuildType,
            name: keyBuild.name,
            geoJson: keyBuild.geoJson,
            notes: keyBuild.notes,
            height: keyBuild.height,
            bvimConfig: keyBuild.bvimConfig,
            loading: false,
            error: null,
            fieldErrors: {}
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        var { t } = this.props;

        if (this.state.loading) {
            if (prevProps.keyBuildState.adding && !this.props.keyBuildState.adding) {
                if (this.props.keyBuildState.actionType === ADD_KEY_BUILD_FAILURE) {
                    this.setState({ loading: false, error: t("keyBuild.messages.failedToAddKeyBuilding") });
                }
                if (this.props.keyBuildState.actionType === ADD_KEY_BUILD_SUCCESS) {
                    this.setState({ loading: false, error: null });
                }
                if (this.props.keyBuildState.actionType === UPDATE_KEY_BUILD_FAILURE) {
                    this.setState({ loading: false, error: t("keyBuild.messages.failedToAddKeyBuilding") });
                }
                if (this.props.keyBuildState.actionType === UPDATE_KEY_BUILD_SUCCESS) {
                    this.setState({ loading: false, error: null });
                }
            }
        }

        this.validateFields(prevState);
    }

    validateFields = (prevState) => {
        const { t } = this.props;
        var fieldErrors = {};
        let validated = false;

        if (prevState.name !== this.state.name) {
            validated = true;
            fieldErrors['name'] = false;
            if (!this.state.name || this.state.name.trim() === '') {
                fieldErrors['name'] = t('simulation.messages.nameIsEmpty');
            }
        }

        if (validated) {
            this.setState({ fieldErrors: fieldErrors });
        }
    }

    validate = () => {
        if (!this.state.geoJson) {
            return false;
        }
        if (!this.state.bvimConfig) {
            return false;
        }
        if (this.state.loading) {
            return false;
        }
        if (this.state.fieldErrors['name']) {
            return false;
        }
        return true;
    }

    onClick = e => {
        const keyBuilding = {
            name: this.state.name,
            geoJson: this.state.geoJson,
            height: this.state.height,
            notes: this.state.notes,
            geometry: this.state.geoJson.geometry,
            simulationId: this.props.simulationState.selectedSimulation.id
        }
        const keyBuildingWithConfig = {
            keyBuilding: keyBuilding,
            bvimConfig: this.state.bvimConfig
        }

        this.setState({ loading: true });
        // If in edit mode do something else
        if (this.props.keyBuildState.editMode) {
            keyBuilding.id = this.props.keyBuildState.editing.id;
            this.props.updateKeyBuild(this.props.simulationState.selectedSimulation.id, this.props.scenarioState.scenario.id, keyBuildingWithConfig);
        } else {
            this.props.addKeyBuild(this.props.simulationState.selectedSimulation.id, this.props.scenarioState.scenario.id, keyBuildingWithConfig);
        }
    }

    setBvimConfig = (config) => {
        if (this.state.bvimConfig === null || this.state.bvimConfig === undefined) {
            this.setState({
                bvimConfig: config
            });
        } else {
            const newBvimConfig = this.state.bvimConfig;
            newBvimConfig.buildingType = config.buildingType;
            newBvimConfig.bvimModelType = config.bvimModelType;
            newBvimConfig.buildingTypeId = config.buildingType.id;

            this.setState({
                bvimConfig: newBvimConfig
            });
        }
    }

    getBuildingLocationEditor = () => {
        const { t } = this.props;
        // Do not allow editing of multi polygons (buildings selected from data layer, as they are not supported
        // by the Leaflet Draw library)
        if (this.state.geoJson && this.state.geoJson.geometry && this.state.geoJson.geometry.type === 'MultiPolygon') {
            return (
                <Message warning>
                    <Message.Header>{t("messages.titles.warning")}</Message.Header>
                    <p>{t('keyBuild.messages.noEditingOfSelectedBuildingLocation')}</p>
                </Message>
            )
        }

        return (
            <Form.Group className="location-field">
                <Form.Field required={true}>
                    <label>{t("incident.location")}</label>
                    <ShapeCreateEdit
                        className="ui input"
                        infoClassName="full-width"
                        shape="POLYGON"
                        setGeoJson={(geoJ) => this.setState({ geoJson: geoJ })}
                        geoJson={this.state.geoJson}
                    />
                </Form.Field>
            </Form.Group>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Form className="ua-form content-body">
                    <Form.Group>
                        <Form.Field required={true}>
                            <Form.Input
                                inline required={true}
                                label={t('sidebar.name')}
                                placeholder={t('sidebar.enterName')}
                                error={this.state.fieldErrors['name']}
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                            />
                        </Form.Field>
                    </Form.Group>

                    {this.props.keyBuildState.editMode ? null :
                        <Form.Group>
                            <InputFieldWithUnits
                                inline required={true}
                                label={t("keyBuild.height")}
                                unit="m"
                                value={this.state.height}
                                type="SIZE"
                                setValue={(v) => this.setState({ height: v })}
                                disabled={false}
                            />
                        </Form.Group>}

                    {this.getBuildingLocationEditor()}

                    <BvimConfigForm setConfig={this.setBvimConfig} enabled={this.state.geoJson !== null} bvimConfig={this.state.bvimConfig} />

                    <Form.Group>
                        <Form.Field>
                            <Form.TextArea
                                inline
                                label={t('sidebar.notes')}
                                placeholder={t('sidebar.enterNotes')}
                                value={this.state.notes}
                                rows={2}
                                onChange={(e) => this.setState({ notes: e.target.value })}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>

                {this.state.error ? (
                    <Message negative className="ua-error">
                        <Message.Header>{t("app.error")}</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>
                ) : null}

                <div className="bottom-bar">
                    <div className="finished">
                        <Button
                            type="button"
                            onClick={this.onClick}
                            disabled={!this.validate()}
                            loading={this.state.loading}
                        >
                            {t("sidebar.finished")}
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        keyBuildState: store.keyBuildState,
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        addKeyBuild: (simulationId, scenarioId, keyBuild) => {
            dispatch(Actions.saveKeyBuilding(simulationId, scenarioId, keyBuild));
        },
        updateKeyBuild: (simulationId, scenarioId, keyBuild) => {
            dispatch(Actions.updateKeyBuilding(simulationId, scenarioId, keyBuild));
        },
        editKeyBuild: (id) => {
            dispatch(Actions.editKeyBuild(id));
        }
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(KeyBuildManualEditor);
