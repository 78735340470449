import {
  ADD_AOI_BEGIN, ADD_AOI_FAILURE, ADD_AOI_SUCCESS,
  CANCEL_EDIT,
  CLEAR, CREATE_AOI,
  EDIT_AOI,
  FETCH_AOIS_SUCCESS,
  FETCH_BEGIN,
  FETCH_FAILURE,
  REMOVE_AOI, UPDATE_AOI_BEGIN,
  UPDATE_AOI_FAILURE,
  UPDATE_AOI_SUCCESS
} from "./actions.jsx";

export class AoiReducer {
  static aoisInfo(state, action) {
    const initialState = {
      aois: {},
      error: null,
      editing: null,
      loading: false,
      isUpdating: false,
      actionType: null
    };

    switch (action.type) {
      case CLEAR:
        return initialState;
      case CANCEL_EDIT:
        state.editing = null;
        return Object.assign({}, state, state);
      case UPDATE_AOI_BEGIN:
      case ADD_AOI_BEGIN:
        return Object.assign({}, state, {
          isUpdating: true,
        });
      case UPDATE_AOI_FAILURE:
      case ADD_AOI_FAILURE:
        return Object.assign({}, state, {
          isUpdating: false,
          error: action.payload,
          actionType: action.type
        });
      case UPDATE_AOI_SUCCESS:
      case ADD_AOI_SUCCESS:
        var newState = Object.assign({}, state, {
          editing: Object.assign({}, state.editing, action.payload),
        });

        // Set the editted aoi onto scenario aois (this handles new aoi,
        // and replacing the original aoi)
        var editedAoi = newState.editing;
        newState.aois[editedAoi.id] = editedAoi;

        // No longer editing an aois so reset it to null
        newState.editing = null;
        newState.error = null;
        newState.isUpdating = false;
        newState.actionType = action.type;

        return newState;
      case CREATE_AOI:
        return Object.assign({}, state, {
          editing: Object.assign({}, state.editing, action.payload),
        });
      case EDIT_AOI:
        return Object.assign({}, state, {
          editing: Object.assign({}, state.editing, state.aois[action.payload]),
        });
      case REMOVE_AOI:
        delete state.aois[action.payload];
        return Object.assign({}, state, {
          loading: false,
          error: null,
        });
      case FETCH_AOIS_SUCCESS:
        var aois = {};

        // Loop though the new incidents and turn them into a map of id to object
        for (var i = 0; i < action.payload.aois.length; i++) {
          let object = action.payload.aois[i]; // eslint-disable-line security/detect-object-injection
          aois[object.id] = object;
        }

        return Object.assign({}, state, {
          loading: false,
          aois: aois,
        });
      case FETCH_BEGIN:
        return Object.assign({}, state, {
          loading: true,
          error: null,
        });
      case FETCH_FAILURE:
        return Object.assign({}, state, {
          loading: false,
          error: action.payload.error.message,
        });
      default:
        return state || initialState;
    }
  }
}
