import { LayersControl, TileLayer } from 'react-leaflet';
import { TerrainLayers } from './terrainLayers';
import { LandCoverLayers } from './landCoverLayers';
import { PopulationLayers } from './populationLayers';
import { BuildingLayers } from './buildingLayers';
import { OSLayers } from './osLayers';
import { OSResearchLayers } from './osResearchLayers';
import SourceLayer from '../popups/SourceLayer.jsx';
import UserLocationsLayer from '../popups/UserLocationsLayer.jsx';
import AOILayer from '../popups/AOILayer.jsx';
import KeyBuildingLayer from '../popups/KeyBuildingLayer.jsx';
import DetectorsLayer from '../detectors/DetectorsLayer.jsx';
import MGRSGrid, {MGRS_GRID_NAME} from './MGRSGrid.jsx';

const INCLUDE_DETECTORS = window.env.REACT_APP_INCLUDE_DETECTORS === "true";
const INCLUDE_SCENARIOS = window.env.REACT_APP_INCLUDE_SCENARIOS === "true";
const INCLUDE_SIMULATIONS = INCLUDE_SCENARIOS && window.env.REACT_APP_INCLUDE_SIMULATIONS === "true";
const INCLUDE_USERS = window.env.REACT_APP_INCLUDE_USER_LAYER === "true";
const OFFLINE_MAPS = window.env.REACT_APP_OFFLINE_MAPS === "true";

const { Overlay } = LayersControl

export class LayersUtil {

    static getMapLayers(t, servicesURLs) {
        let mapLayers = [];

        mapLayers = mapLayers.concat(TerrainLayers.getMapLayers(t));
        mapLayers = mapLayers.concat(LandCoverLayers.getMapLayers(t));
        mapLayers = mapLayers.concat(PopulationLayers.getMapLayers(t));

        mapLayers = mapLayers.concat(BuildingLayers.getMapLayers(t));

        mapLayers = INCLUDE_SIMULATIONS? mapLayers.concat(<KeyBuildingLayer />) : mapLayers;
        mapLayers = INCLUDE_SCENARIOS ? mapLayers.concat(<Overlay name={t("sidebar.aoi")} checked={true}><AOILayer/></Overlay>) : mapLayers;
        mapLayers = INCLUDE_SIMULATIONS ? mapLayers.concat(<SourceLayer />) : mapLayers;
        mapLayers = INCLUDE_USERS ?  mapLayers.concat(<Overlay name={t("map.users")} checked={true}><UserLocationsLayer /></Overlay>) : mapLayers;

        mapLayers = INCLUDE_DETECTORS ? mapLayers.concat(<Overlay name={"Detectors"} checked={true}>
            <DetectorsLayer setPopupObject={(object) => this.setState({ popupObject: object })} /></Overlay>) : mapLayers;

        {/* Put the Labels layer at the very bottom in order for it to appear above any other layer */}
        mapLayers = !OFFLINE_MAPS ? mapLayers.concat(LayersUtil.getLabelsLayer(t)) : mapLayers;

        mapLayers = mapLayers.concat(OSLayers.getMapLayers(t, servicesURLs));
        mapLayers = mapLayers.concat(OSResearchLayers.getMapLayers(t, servicesURLs));
        mapLayers = mapLayers.concat(<Overlay  name={MGRS_GRID_NAME}><MGRSGrid/></Overlay>);

        return mapLayers;
    }

    static getMapLegends(t) {
        let mapLegends = [];

        mapLegends = mapLegends.concat(PopulationLayers.getLegend(t));
        mapLegends = mapLegends.concat(TerrainLayers.getLegend(t));
        mapLegends = mapLegends.concat(LandCoverLayers.getLegend(t));

        return mapLegends;

    }

    static getLabelsLayer(t) { 
        return <Overlay name={t("map.cartoDBVoyagerOnlyLabels")}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    transparent={true}
                    maxZoom={19}
                />
            </Overlay>;
    }
}