import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Message, Select } from "semantic-ui-react";
import { Actions } from "../../../services/incident/actions.jsx";
import { IncidentServices } from "../../../services/incident/incidentServices.jsx";
import { DataUtil } from "../../common/DataUtil.jsx";
import { IncidentsUtils } from "../../utils/IncidentsUtils.jsx";
import FormFieldDate from "../../common/formFieldDate";
import InputFieldWithUnits from "../../common/inputFieldWithUnits.jsx";
import ShapeCreateEdit from "./shapeCreateEditor.jsx";
import "./sources.scss";

class PoolPropertiesCard extends React.Component {

  constructor(props) {
    super(props);
    var source = props.incidentState.editingIncident.sources[0];
    // Don't call this.setState() here!
    this.state = {
      name: props.incidentState.editingIncident.name,
      startTime: source.startTime ? source.startTime : new Date().toISOString(),
      id: source.id,
      incidentId: props.incidentState.editingIncident.id,
      geometry: source.geometry,
      geoJson: source.geoJson,
      materialId: source.materialId,
      radius: source.radius,
      materials: [],
      mass: source.mass,
      fieldErrors: [],

      sourceTermType: 'PoolSource',
      incidentType: props.incidentState.editingIncident.incidentType,
      agentType: source.agentType,
      materialName: source.materialName,
    };

    // If this is a fetch then we now need a geojson
    if (!this.state.geoJson && this.state.geometry) {
      this.state.geoJson = JSON.parse('{"type": "Feature","properties": {}}');

      this.state.geoJson.geometry = source.geometry;
    }

    // Pool sources have a radius property on them, set it onto the geoJson
    if (source.radius && this.state.geoJson) {
      this.state.geoJson.properties.radius = source.radius;
    }
  }

  componentDidMount() {
    IncidentServices.fetchMaterials({ agentType: this.state.agentType, materialType: "LIQUID", archived: false }).then((materials) => {
      let materialId = this.state.materialId;
      let materialName = this.state.materialName;
      if (materialId === null && materials.length > 0) {
        materialId = materials[0].id;
        materialName = materials[0].name;
      } else {
        // Verify the selected materialId is present in available materials:
        const material = materials.find((m) => m.id === materialId);
        if (!material) {
          materialId = null;
          materialName = null;
        }
      }

      this.setState({
        materialId: materialId,
        materialName: materialName,
        materials: materials,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    var { t } = this.props;

    this.validateFields(prevState);

    // If the component is in loading state - check the progress of the source update/insertion
    if (this.state.loading) {
      // if the incidentState has changed its loading property from true to false - check for errors
      // No need to check for success as on success the panel will change back to main panel
      if (prevProps.incidentState.loading && !this.props.incidentState.loading) {
        if (this.props.incidentState.error) {
          this.setState({ loading: false, error: t("source.messages.failedToAddUpdate") })
        }
      }
    }
  }

  validateFields = (prevState) => {
    var { t } = this.props;
    var fieldErrors = {};
    if (prevState.name != this.state.name) {
      fieldErrors['name'] = this.getValidMessage(this.state.name, () => { return true }, "");
    }
    if (prevState.materialId !== this.state.materialId) {
      if (!this.state.materialId) {
        fieldErrors["material"] = t("source.messages.material");
      } else {
        fieldErrors["material"] = false;
      }
    }
    if (prevState.mass !== this.state.mass) {
      fieldErrors['mass'] = this.getValidMessage(this.state.mass, this.isNumberGreaterThanZero, t("source.messages.mass"));
    }
    if (Object.keys(fieldErrors).length) {
      fieldErrors = Object.assign({}, this.state.fieldErrors, fieldErrors);
      this.setState({ fieldErrors: fieldErrors });
    }
  }

  getValidMessage = (field, valid, message) => {
    let emptyMessage = this.getEmptyMessage(field)
    if (emptyMessage) {
      return emptyMessage;
    }
    if (!valid(field)) {
      return message
    } else {
      return false
    }
  }

  getEmptyMessage = (field) => {
    var { t } = this.props;
    let nonwhitespace = /[^\s]/;
    let valid = field != undefined && field.toString().match(nonwhitespace);
    return !valid ? t("source.messages.emptyError") : "";
  }

  isNumberGreaterThanZero = (value) => {
    return value > 0 && !isNaN(value)
  };

  validate = () => {
    if (!this.state.materialId) {
      return false;
    }
    if (!this.state.startTime) {
      return false;
    }
    if (!this.state.geoJson) {
      return false;
    }
    if (!this.state.geoJson.properties.radius || this.state.geoJson.properties.radius <= 0) {
      return false;
    }
    if (!Array.from(Object.values(this.state.fieldErrors)).every(v => v === false)) {
      return false;
    }
    return true;
  };

  finishSource = () => {
    this.setState({ loading: true });

    var geoJson = DataUtil.addHeightToGeoJson(
      this.state.geoJson,
      this.state.releaseHeight
    );

    var source = Object.assign({}, this.state, {
      startTime: this.state.startTime,
      releaseHeight: this.state.releaseHeight,
      geoJson: geoJson,
      geometry: geoJson.geometry,
    });

    this.props.finishSource(
      source,
      this.props.simulationState.selectedSimulation.id,
      this.props.scenarioState.scenario.id
    );
  };

  setGeoJson = (geoJ) => {
    if (geoJ) {
      this.setState({ geoJson: geoJ, radius: geoJ.properties.radius });
    } else {
      this.setState({ radius: this.state.geoJson.properties.radius });
    }
  };

  setMaterial = (e, data) => {
    this.setState({
      materialId: data.value,
      materialName: data.options.find(option => option.value === data.value).text,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Form className="ua-form content-body unset-overflow">
          <Form.Group>
            <Form.Field>
              <Form.Input
                inline required={true}
                label={t('sidebar.name')}
                placeholder={t('sidebar.enterName')}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                error={this.state.fieldErrors['name']}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field
              required={true}
              label={t("sidebar.material")}
              inline
              search={IncidentsUtils.searchThroughMaterials}
              selected
              control={Select}
              options={IncidentsUtils.createMaterialsDropdownOptions(this.state.materials)}
              placeholder={t("sidebar.selectMaterial")}
              value={this.state.materialId}
              onChange={(e, data) => this.setMaterial(e, data)}
              error={this.state.fieldErrors["material"]}
            />
          </Form.Group>

          <Form.Group>
            <FormFieldDate
              t={t}
              dateFormat={this.props.userState.user.preferences.dateFormat}
              label={t('incident.date')}
              required={true}
              initialValue={new Date(this.state.startTime)}
              onChange={(value) => {
                  this.setState({ startTime: value && value.toISOString() })
              }}
            />
          </Form.Group>

          <Form.Group className="location-field">
            <Form.Field required={true}>
              <label>{t('incident.location')}</label>
              <ShapeCreateEdit
                className="ui input"
                infoClassName="full-width"
                shape="CIRCLE"
                setGeoJson={(geoJ) => this.setGeoJson(geoJ)}
                geoJson={this.state.geoJson}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <InputFieldWithUnits
              inline required={true}
              upward={true}
              label={t("incident.totalMass")}
              unit="kg"
              value={this.state.mass}
              type="MASS"
              setValue={(v) => this.setState({ mass: v })}
              disabled={false}
              error={this.state.fieldErrors['mass']}
            />
          </Form.Group>

          {this.state.error ? (
            <Message negative className="ua-error">
              <Message.Header>{t("app.error")}</Message.Header>
              <p>{this.state.error}</p>
            </Message>
          ) : null}

        </Form>

        <div className="bottom-bar">
          <div className="finished">
            <Button
              type="button"
              disabled={!this.validate() || this.state.loading}
              onClick={this.finishSource}
              loading={this.state.loading}
            >
              {t("sidebar.finished")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    simulationState: store.simulationState,
    incidentState: store.incidentState,
    scenarioState: store.scenarioState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    finishSource: (source, simulationId, scenarioId) => {
      dispatch(Actions.finishSource(source, simulationId, scenarioId));
    }
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PoolPropertiesCard);
