import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Confirm, List, Loader, Popup } from 'semantic-ui-react';
import { ReactComponent as ArchiveIcon } from '../../images/Archive.svg';
import { Actions, CLEAR } from "../../services/scenario/actions.jsx";
import './appHeader.scss';
import ScenarioLoader from './loaders/ScenarioLoader';
import Requests from '../../services/requests';

class SelectScenario extends React.Component {

    state = {
        loading: false,
        error: null,
        openConfirm: false,
        confirmScenario: null
    }

    componentDidMount() {
        // Start fetching available scenarios
        this.fetchScenarios();
    }

    componentDidUpdate(prevProps) {
        // If the update happened due to a CLEAR action - re-fetch the scenarios
        if (prevProps.scenarioState.actionType !== this.props.scenarioState.actionType && this.props.scenarioState.actionType === CLEAR) {
            this.fetchScenarios();
        }
    }

    fetchScenarios = () => {
        // Start fetching available scenarios
        this.props.fetchScenarios(this.props.userState.user.organisation.id);
    }

    selectScenario = (scenario) => {
        // Set the scenario
        this.props.selectScenario(scenario);
    }

    archiveScenario = () => {
        var { t } = this.props;

        if (this.state.confirmScenario) {
            this.setState({ loading: true, error: null, confirmScenario: null, openConfirm: false });
            Requests.put("/scenario/archive/" + this.state.confirmScenario)
                .then(resp => {
                    this.setState({ loading: false, error: null });
                    // Re-fetch the scenarios on success
                    this.fetchScenarios();

                })
                .catch(err => {
                    this.setState({ loading: false, error: t("scenario.messages.failedToArchive") });
                })
        }
    }

    getContent = () => {
        const { t } = this.props;
        if (this.props.scenarioState.scenario && this.props.scenarioState.scenario.id) {
            return (
                <ScenarioLoader closeScenarioLoader={this.props.closeScenarioLoader} />
            )
        }
        // If the data is loading - display a loader
        if (this.props.scenarioState.loading || this.state.loading) {
            return (
                <Loader active inline='centered' size='massive'>Loading</Loader>
            )
        } else if (this.props.scenarioState.error) {
            // If there was an error : display the error message
            return (
                <List className="point-properties-card">
                    <List.Item className="error-content">
                        <label className="error-label">{this.props.scenarioState.error + ""}</label>
                        <div className="center">
                            <button className="scenario-button" onClick={this.fetchScenarios}>
                                {t("scenario.retry")}
                            </button>
                        </div>
                    </List.Item>
                </List>
            )
        } else {
            // If the scenario list is empty - let the user know
            if (this.props.scenarioState.scenarios && this.props.scenarioState.scenarios.length === 0) {
                return (
                    <List className="point-properties-card">
                        <List.Item className="error-content">
                            {t("scenario.noScenariosToDisplay")}
                        </List.Item>
                    </List>
                )
            } else {
                // Display the list of the scenarios
                var data = [];
                for (let scenarioNum in this.props.scenarioState.scenarios) {
                    let scenario = this.props.scenarioState.scenarios[scenarioNum] // eslint-disable-line security/detect-object-injection
                    data.push(
                        <tr key={"scenario" + scenario.id} className="scenario-row">
                            <td className="scenario" onClick={() => this.selectScenario(scenario)}>
                                <div className="scenario-name">
                                    {scenario.name}
                                </div>
                                <div className="scenario-created">
                                    {new Date(scenario.createdDate).toLocaleString()}
                                </div>
                                <div className="scenario-user">
                                    {scenario.userName}
                                </div>
                            </td>
                            <td className="scenario-archive" onClick={() => this.confirm(scenario.id)}>
                                <div className="archive-icon">
                                    <Popup
                                        content='Archive Scenario'
                                        className="popup"
                                        trigger={<ArchiveIcon className="data-loader-icon" />}
                                        position={"right center"} />
                                </div>
                            </td>
                        </tr>
                    )
                }

                return (
                    <table className="scenario-table">
                        <tbody>
                            {data}
                        </tbody>
                    </table>
                )
            }
        }
    }

    confirm = (scenarioId) => {
        this.setState({ openConfirm: scenarioId ? true : false, confirmScenario: scenarioId });
    }

    getConfirmContent = () => {
        var { t } = this.props;
        // In order to have a line break from translations this needs to be split into 2 separate
        // lines
        return (
            <div className="content">
                <div>
                    {t("scenario.confirmation.content")}
                </div>
                <div>
                    {t("scenario.confirmation.content2")}
                </div>
            </div>
        )
    }

    render() {
        var { t } = this.props;
        return (
            <div >
                <div className="panel-wrapper-head">
                </div>
                <div className="scenario-select">
                    {this.getContent()}
                    <Confirm
                        className="ua-modal"
                        header={t("scenario.confirmation.header")}
                        content={this.getConfirmContent()}
                        cancelButton={t("scenario.confirmation.cancel")}
                        confirmButton={t("scenario.confirmation.confirm")}
                        open={this.state.openConfirm}
                        onCancel={() => this.confirm(null)}
                        onConfirm={this.archiveScenario}
                    />
                </div>
            </div>
        )
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        userState: store.userState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        selectScenario: (scenario) => {
            dispatch(Actions.selectScenario(scenario));
        },
        fetchScenarios: (organisationId) => {
            dispatch(Actions.fetchScenarios(organisationId));
        }
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SelectScenario);
