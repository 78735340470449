import L from "leaflet";
import React from "react";
import { GeoJSON } from "react-leaflet";
import units from "./units.json";
import UnitConverter from "./UnitConverter";
import { CoordinateUtils } from "../utils/CoordinateUtils";
import { AngleUtils } from "../utils/AngleUtils";

/**
 * A met observation displays on the leaflet map in the form of a custom marker at the location of the observation.
 * This class defines the style of the marker.
 */
export class MetStyles {

  static getIconUrl(object) {
    // Calculate the ratio of how many 22.5 are in the provided wind direction value
    // Round it, and take a modulo of 16, in order to show 360 as 0.
    let ratio = Math.round(object.windDirection / 22.5) % 16;
    let directionName = (ratio * 22.5).toString().replace('.', '-');
    return "/images/met/met-" + directionName + ".png";
  }

  static getMarker(object, t, userPreference) {
    let geometry = object.geometry;
    const iconUrl = MetStyles.getIconUrl(object);
    return (
      <GeoJSON
        id={object.id}
        key={object.id + "-" + object.lastUpdated + "marker"}
        data={geometry}
        pointToLayer={(feature, latlng) => {
          let numDecPlaces = 3;
          let icon = new L.Icon({
            iconUrl: iconUrl,
            iconRetinaUrl: iconUrl,
            iconAnchor: [25, 48], // point of the icon which will correspond to marker's location
            popupAnchor: [1, -45], // point from which the popup should open relative to the iconAnchor
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(50, 50)
          });
          let marker = new L.Marker(latlng, { icon: icon });
          // Convert stored K to C
          let celsius = UnitConverter.convertTemperature(object.temperature, units["TEMPERATURE"][0], units["TEMPERATURE"][1]);
          marker.bindPopup(
            MetStyles.template
              .replace(":label_wind_speed", t("label.windSpeed")).replace(":speed", object.windSpeed)
              .replace(":label_wind_direction", t("label.windDirection")).replace(
                ":direction",
                AngleUtils.convertDegreesToDisplayString(object.windDirection, userPreference.windDirectionUnit)
              )
              .replace(":label_coordinates", t("label.coordinates")).replace(
                ":coordinates",
                CoordinateUtils.convertLonLatToDisplayString(object.geoJson.geometry.coordinates, userPreference.coordinateUnit)
              )
              .replace(":label_start_time", t("label.startTime")).replace(":startTime", object.time.toLocaleString())
              .replace(":label_reference_height", t("label.referenceHeight")).replace(":referenceHeight", object.referenceHeight)
              .replace(":label_temperature", t("label.temperature")).replace(":temperature", Number.parseFloat(celsius.toFixed(numDecPlaces)))
              .replace(":label_stability", t("label.stability")).replace(":stability", object.stability)
          );
          return marker;
        }}
      />
    );
  }

  static template =
    '<div style="padding: 10px;">' +
    '<h2 style="color: #2e6c80;">Met Profile Observation</h2>' +
    '<ol style="list-style: none; font-size: 14px; line-height: 24px; padding-inline-start: 10px; font-weight: bold;">' +
    '<li style="clear: both;">:label_coordinates: <em>:coordinates</em></li>' +
    '<li style="clear: both;">:label_wind_speed: <em>:speed</em></li>' +
    '<li style="clear: both;">:label_wind_direction: <em>:direction</em></li>' +
    '<li style="clear: both;">:label_start_time: <em>:startTime</em></li>' +
    '<li style="clear: both;">:label_reference_height: <em>:referenceHeight</em></li>' +
    '<li style="clear: both;">:label_temperature: <em>:temperature</em></li>' +
    '<li style="clear: both;">:label_stability: <em>:stability</em></li>' +
    "</ol></div>";
}
