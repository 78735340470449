import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Button, Form, List, Message } from "semantic-ui-react";
import "./appHeader.scss";
import Logo from './ua-logo.png';
import Requests from '../../services/requests';

class ResetLogin extends React.Component {
  state = {
    email: "",
    actionSelected: false,
    message: null,
    loading: false,
    reset: false
  };

  reset = () => {
    const { t } = this.props;
    this.setState({ message: null, loading: true });

    Requests.post('/accounts/reset', { email: this.state.email })
      .then(response => {
        this.setState({ message: { isError: false, content: t('login.messages.resetGenericResponse') }, loading: false, reset: true });
      })
      .catch(err => {
        console.log("Caught error, ", err);
        this.setState({ message: { isError: true, content: t('messages.errorCheckLogs') }, loading: false });
      });
  }

  getMessage = () => {
    const { t } = this.props;
    if (this.state.message) {
      if (this.state.message.isError) {
        return (
          <Message negative className="ua-error">
            <Message.Header>{t('app.error')}</Message.Header>
            <p>{this.state.message.content}</p>
          </Message>
        )
      } else {
        return (
          <Message positive>
            <Message.Header>{t('app.success')}</Message.Header>
            <p>{this.state.message.content}</p>
          </Message>
        )
      }
    }
    return null;
  }

  validate = () => {
    if (!this.state.email || this.state.email === "") {
      return false;
    }
    return true;
  };

  getResetSuccess = () => {
    const { t } = this.props;
    return (
      <List className="point-properties-card">
        <List.Item>
          {this.getMessage()}
          <Button
            className="login-button"
            onClick={this.props.loginClick}
          >
            {t("app.ok")}
          </Button>
        </List.Item>
      </List>
    );
  }

  getLoginForm = () => {
    const { t } = this.props;

    return (
      <List className="point-properties-card">
        <List.Item>
          <Form className="ua-login-form">
            <Form.Field>
              <Form.Input
                required={true}
                id="register-username"
                label={t('title.email')}
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value })
                } />
            </Form.Field>
            {this.getMessage()}
            <div className="two-btns">
              <Button
                className="login-button"
                onClick={this.reset}
                loading={this.state.loading}
                disabled={!this.validate()}
              >
                {t("login.reset")}
              </Button>

              <Button
                className="login-button"
                onClick={this.props.loginClick}
              >
                {t("app.cancel")}
              </Button>
            </div>
          </Form>
        </List.Item>
      </List>
    );
  }

  getContent = () => {
    if (this.state.reset) {
      return this.getResetSuccess();
    }
    return this.getLoginForm();
  }

  render() {
    /**
     * Render the Login Form
     */

    const { t } = this.props;
    return (
      <div className={"login-panel"}>
        <div className="login-title-panel">
          {t("title.resetPasswordTitle")}
          <img src={Logo} alt="UrbanAware logo" />
        </div>
        <div className="login-content">
          {this.getContent()}
        </div>
      </div>
    );
  }
}


export default compose(withTranslation())(ResetLogin);
