
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form } from "semantic-ui-react";
import "whatwg-fetch";
import { Actions } from "../../../services/met/actions.jsx";

class MetServiceDropdown extends React.Component {

    setMetService = (metServiceId) => {
        const metService = this.props.availableMetServices.filter(
            (m) => m.id === metServiceId
        )[0];
        if (this.selectedServiceId != metService.id) {
            this.props.setMetServiceForSimulation(this.props.scenarioId, this.props.simulationId, metService);
        }
    };

    render() {
        const { t } = this.props;

        const selectedMetService = this.props.metConfigs[this.props.simulationId] ? this.props.metConfigs[this.props.simulationId].metService : null;
        this.selectedServiceId = selectedMetService ? selectedMetService.id : null;

        return <div >
            <Form.Group className="met-dropdown">
                <Form.Field>
                    <Form.Select
                        inline
                        required={true}
                        label={t("met.chooseMetService")}
                        placeholder={t("met.chooseMetService")}
                        options={this.props.availableMetServices.map((m) => {
                            return { key: m.id, text: m.name, value: m.id };
                        })}
                        value={this.selectedServiceId}
                        onChange={(e, data) => this.setMetService(data.value)}
                    />
                </Form.Field>
            </Form.Group>
        </div>
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        metConfigs: store.metState.metConfigs,
        availableMetServices: store.metState.availableMetServices,
        ...props
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        setMetServiceForSimulation: (scenarioId, simId, service) => {
            dispatch(Actions.setMetServiceForSimulation(scenarioId, simId, service));
        }
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(MetServiceDropdown);
