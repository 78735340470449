import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Button, Form, List, Message } from "semantic-ui-react";
import "./appHeader.scss";
import Logo from './ua-logo.png';
import Requests from '../../services/requests';

class Register extends React.Component {
  state = {
    email: "",
    displayName: "",
    password: "",
    passwordRepeat: "",
    orgKey: "",
    registered: false,
    fieldErrors: {},
    error: null,
    loading: false
  };

  componentDidMount() {
    // Component mounted - do the field validation
    this.validateFields();
  }

  componentDidUpdate(prevProps, prevState) {
    var performValidation = false;
    // Check if any of the input has changed
    if (this.state.email !== prevState.email) {
      performValidation = true;
    }
    if (this.state.displayName !== prevState.displayName) {
      performValidation = true;
    }
    if (this.state.orgKey !== prevState.orgKey) {
      performValidation = true;
    }
    if (this.state.password !== prevState.password) {
      performValidation = true;
    }
    if (this.state.passwordRepeat !== prevState.passwordRepeat) {
      performValidation = true;
    }

    if (performValidation) {
      this.validateFields();
    }
  }

  /**
   * Validate the registration button.
   */
  validateRegisterButton = () => {
    return !this.state.fieldErrors['email'] && !this.state.fieldErrors['displayName'] &&
      !this.state.fieldErrors['orgKey'] &&
      !this.state.fieldErrors['password'] && !this.state.fieldErrors['passwordRepeat'];
  };

  register = (e) => {
    const { t } = this.props;

    let user = {
      email: this.state.email.trim(),
      displayName: this.state.displayName,
      password: this.state.password,
      role: 'ROLE_USER'
    };

    this.setState({ error: null, loading: true });

    Requests.post('/accounts/register/organisation/' + this.state.orgKey, user)
      .then(response => {
        // Registration was successful
        this.setState({ registered: true, loading: false });
      })
      .catch(err => {
        let errorMessage = err.message;
        let message = t('registration.messages.failedToRegister');
        if (errorMessage) {
          if (errorMessage.includes('Duplicate email')) {
            message = t('registration.messages.userExists');
          } else if (errorMessage.includes("Invalid email")) {
            message = t('registration.messages.invalidEmail');
          } else if (errorMessage.includes("Organisation not found")) {
            message = t('registration.messages.organisationNotFound');
          } else if (errorMessage.includes("organisation has reached its user limit")) {
            message = t('registration.messages.userLimitReached');
          } else {
            console.log("Caught error, ", err);
            message = t('messages.errorCheckLogs');
          }
        }
        this.setState({ error: message, loading: false });
      });
  }

  setDisplayName = (e) => {
    this.setState({ displayName: e.target.value });
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  setOrgKey = (e) => {
    this.setState({ orgKey: e.target.value });
  }

  setPassword = (e) => {
    this.setState({ password: e.target.value });
  }

  setPasswordRepeat = (e) => {
    this.setState({ passwordRepeat: e.target.value });
  }

  validateFields = () => {
    const { t } = this.props;

    // Copy existing field errors array
    var fieldErrors = Object.assign({}, this.state.fieldErrors);

    // Validate email
    fieldErrors['email'] = false;
    if (!this.state.email || this.state.email === '') {
      fieldErrors['email'] = t('registration.messages.emailEmpty');
    }

    // Validate display name
    fieldErrors['displayName'] = false;
    if (!this.state.displayName || this.state.displayName === '') {
      fieldErrors['displayName'] = t('registration.messages.displayNameEmpty');
    }

    // Validate organisation key
    fieldErrors['orgKey'] = false;
    if (!this.state.orgKey || this.state.orgKey === '') {
      fieldErrors['orgKey'] = t('registration.messages.organisationKeyEmpty');
    } else if (!this.state.orgKey.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$")) {
      fieldErrors['orgKey'] = t('registration.messages.organisationKeyInvalid');
    }

    // Validate password
    fieldErrors['password'] = false;
    // Check the complexity of the password
    if (this.state.password.length < 8) {
      fieldErrors['password'] = t('registration.messages.passwordMinLengthWarning');
    } else if ((!this.state.password.match(/[a-z]/)) || (!this.state.password.match(/[A-Z]/))) {
      fieldErrors['password'] = t('registration.messages.passwordLowerAndUpperCaseWarning');
    } else if (!this.state.password.match(/\d+/)) {
      fieldErrors['password'] = t('registration.messages.passwordNumberWarning');
    } else if (!this.state.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      fieldErrors['password'] = t('registration.messages.passwordSpecialCharWarning');
    }

    // Validate repeated password
    fieldErrors['passwordRepeat'] = false;
    if (!this.state.passwordRepeat || this.state.passwordRepeat === '') {
      fieldErrors['passwordRepeat'] = t('registration.messages.passwordRepeatIsEmpty');
    } else if (this.state.passwordRepeat !== this.state.password) {
      fieldErrors['passwordRepeat'] = t('registration.messages.passwordsDoNotMatch');
    }

    this.setState({ fieldErrors: fieldErrors });
  }

  getRegistrationForm = () => {
    const { t } = this.props;
    return (
      <List className="point-properties-card">
        <List.Item>
          <Form className="ua-login-form">
            <Form.Field>
              <Form.Input
                required={true}
                id="register-username"
                error={this.state.fieldErrors['email']}
                label={t('title.email')}
                placeholder={t('registration.placeholder.enterEmail')}
                value={this.state.email}
                onChange={this.setEmail} />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required={true}
                id="register-display-name"
                error={this.state.fieldErrors['displayName']}
                label={t('title.displayName')}
                placeholder={t('registration.placeholder.enterDisplayName')}
                value={this.state.displayName}
                onChange={this.setDisplayName} />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required={true}
                id="register-org-key"
                error={this.state.fieldErrors['orgKey']}
                label={t('title.organisationKey')}
                placeholder={t('registration.placeholder.enterOrganisationKey')}
                value={this.state.orgKey}
                onChange={this.setOrgKey} />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required={true}
                id="register-password"
                type="password"
                error={this.state.fieldErrors['password']}
                label={t('title.password')}
                placeholder={t('registration.placeholder.enterPassword')}
                value={this.state.password}
                onChange={this.setPassword} />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required={true}
                id="register-repeat-password"
                type="password"
                error={this.state.fieldErrors['passwordRepeat']}
                label={t('title.repeatPassword')}
                placeholder={t('registration.placeholder.repeatPassword')}
                value={this.state.passwordRepeat}
                onChange={this.setPasswordRepeat} />
            </Form.Field>
            {this.state.error ?
              (
                <Message negative className="ua-error">
                  <Message.Header>{t("app.error")}</Message.Header>
                  <p>{this.state.error}</p>
                </Message>
              ) : null
            }
            <div className="two-btns">
              <Button
                className="login-button"
                onClick={this.register}
                disabled={!this.validateRegisterButton() || this.state.loading}
                loading={this.state.loading}
              >
                {t("title.register")}
              </Button>

              <Button
                className="login-button"
                onClick={this.props.loginClick}
              >
                {t("app.cancel")}
              </Button>
            </div>
          </Form>
        </List.Item>
      </List>
    )
  }

  /**
   * The user has registered, display information message that registration
   * is waiting to be approved by administrator
   */
  getUserRegisteredView = () => {
    const { t } = this.props;
    return (
      <List className="point-properties-card">
        <List.Item>
          <Message positive>
            <Message.Header>{t("registration.messages.registrationCompleted")}</Message.Header>
            <p>{t('registration.messages.registrationAwaiting')}</p>
          </Message>

          <Button
            className="login-button"
            onClick={this.props.loginClick}
          >
            {t("app.ok")}
          </Button>

        </List.Item>
      </List>
    )
  }

  getRenderContent = () => {
    if (this.state.registered) {
      return this.getUserRegisteredView();
    }
    return this.getRegistrationForm();
  }

  render() {
    /**
     * Render the Registration Form
     */
    const { t } = this.props;
    return (
      <div className={"login-panel"}>
        <div className="login-title-panel">
          {t("title.registerTitle")}
          <img src={Logo} alt="UrbanAware logo" />
        </div>
        <div className="login-content">
          {this.getRenderContent()}
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(Register);
