import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Popup } from "semantic-ui-react";
import "./styles.scss";

class SaveCancelButtonsBar extends React.Component {

  render() {
    var { t } = this.props;

    return (
      <div className="bottom-bar two-btns">
        <Button
          type="button"
          onClick={this.props.cancel}
        >
          Cancel
          </Button>

        <Button
          type="button"
          onClick={this.props.save}
          disabled={this.props.saveDisabled}
        >
          Save
          </Button>
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(SaveCancelButtonsBar);
