import jsPDF from "jspdf";
import urbanAwareLogoDark from '../../../../logo1.png'
import urbanAwareLogoLight from '../../../../logo2.png'
import { CoordinateUtils } from "../../../utils/CoordinateUtils";
import autoTable from 'jspdf-autotable'
import moment from 'moment';
import { AngleUtils } from "../../../utils/AngleUtils";
import { DateUtils } from "../../../utils/DateUtils";

const PAGE_WIDTH = 210;
const MARGIN = 10;
const SECTION_DIVIDER_HEIGHT = 3;
const URBANAWARE_LOGO_WIDTH = 46;
const URBANAWARE_LOGO_HEIGHT = 15;


/**
 * Class that builds a PDF from a screenshot and data from the store
 */
export default class PdfBuilder {

    /**
     * Constructor
     * @param {Function} t the translation function to use
     * @param {Object} userPreferences user preferences
     */
    constructor(t, userPreferences) {
      this.t = t;
      this.buildCursorY = 0;
      this.doc = new jsPDF();
      this.userPreferences = userPreferences;
      this.pageHeight = this.doc.internal.pageSize.height;
    }

    /**
     * Builds a jsPDF to convey simulation data
     * 
     * @param {String} mapPngData a domtoimage screenshot of the map to include in the pdf
     * @param {Number} mapWidth the width of the map inlcuded in the png data
     * @param {Number} mapHeight the height of the map included in the png data
     * @param {Number} selectedMapTime the time in the simulation that the screenshot corresponds to as a unix timestamp
     * @param {String} additionalNotes free text value to be displayed on the exported PDF
     * @param {Object} scenarioState the scenarioState section of the redux store
     * @param {Object} simulationState the simulationState section of the redux store
     * @param {Object} resultsState the resultsState section of the redux store
     * @param {Object} incidentState the incidentState section of the redux store
     * @param {Object} metState the metState section of the redux store
     * @param {Array[Object]} atp45ContourData contourData retrieved from the results controller for ATP45 results
     * @param {Object} aoiState the aoiState section of the redux store
     * @param {Object} userState the userState section of the redux store
     * @returns {jsPDF} a finished jsPDF doc ready to be saved
     */
    build(mapPngData, mapWidth, mapHeight, selectedMapTime, additionalNotes, scenarioState, simulationState, resultsState, incidentState,
      metState, atp45ContourData, aoiState, userState) {
      const simulationId = simulationState.selectedSimulation.id;

      // TODO - There are some hardcoded display strings in this class that will appear in the export, ensure these are replaced with 
      // translations or the strings are provided elsewhere like a template or, if this class is refactored, a pdfContents object

      // BANNER SECTION
      this._addUrbanAwareBanner(false);

      // METADATA SECTION
      this._addWrappedText( 
        `Organisation: ${userState.user.organisation.name} | User: ${userState.user.displayName} | ` +
        `App version: ${window.env.REACT_APP_FOOTER_VERSION} | Exported: ${this._formatTime(moment())}`,
        MARGIN
      );
      this._addSectionDivider();
      
      // IMAGE SECTION
      this._addMapSection(mapPngData, mapWidth, mapHeight, simulationId, resultsState, selectedMapTime);

      // ADDITIONAL NOTES SECTION
      if (additionalNotes !== '') {
        this._addWrappedText(additionalNotes, MARGIN);
        this._addSectionDivider()
      };

      // TABLES SECTION
      this._addTitle(this.t('label.scenario'));
      this._addTable([
        ['Scenario', 'Scenario notes'],
        [scenarioState.scenario.name, scenarioState.scenario.notes]
      ]);
      this._addSectionDivider();

      this._addTitle(this.t('label.simulation'));
      this._addTable([
        ['Simulation', 'Last updated'],
        [simulationState.selectedSimulation.name, this._formatTime(moment(simulationState.selectedSimulation.lastUpdatedDate, true))]
      ]);
      this._addSectionDivider();

      const aois = Object.values(aoiState.aois).filter(aoi => aoi.scenarioId === scenarioState.scenario.id && aoi.aoiType === "HAZARD_AREA");
      if (aois.length > 0) {
        this._addHazardAreaAois(aois);
      }

      this._addTitle(this.t('label.incidents'));
      const incidents = Object.values(incidentState.incidents).filter(incident => !!incident && incident.simulationId === simulationId);
      // Create a section for each incident
      for (const incident of incidents) {
        switch(incident.incidentType){
          case("ATP-45"):
            this._addAtp45Incident(incident, atp45ContourData);
            break;
          case("POINT"):
            this._addPointSourceIncident(incident);
            break;
          default:
            break;
        }
      }

      const metProfiles = Object.values(metState.metProfiles).filter(metProfile => metProfile.simulationId === simulationId);
      this._addMetProfiles(metProfiles);

      return this.doc;
    }

    /**
     * Creates a display string in the appropriate format for the given coordinates
     * @param {Number[]} lonLat the lon lat coordinates to format
     * @returns {String} a formatted display string for the coordinates
     */
    _formatCoordinates(lonLat) {
      return CoordinateUtils.convertLonLatToDisplayString(lonLat, this.userPreferences.coordinateUnit);
    }

    /**
     * Creates a display string in the appropriate format for the given time
     * @param {Moment} time the time to format 
     * @returns {String} the formatted display string for the time
     */
    _formatTime(time) {
        return DateUtils.getDateTimeStringForDisplay(time, this.userPreferences.dateFormat);
    }

    /**
     * Adds an UrbanAware banner to the PDF
     * @param {Boolean} printerFriendly true to create a banner style appropriate for printing
     */
    _addUrbanAwareBanner(printerFriendly) {
      let paddingTop, logo, backgroundColour;
      if (printerFriendly) {
        paddingTop = MARGIN;
        logo = urbanAwareLogoDark;
        backgroundColour = '#FFFFFF';
      } else {
        paddingTop = 2;
        logo = urbanAwareLogoLight;
        backgroundColour = '#444444';
      }
      const paddingLeft = MARGIN;
      const paddingBottom = 2;
      const bannerHeight = URBANAWARE_LOGO_HEIGHT + paddingTop + paddingBottom;
      this.doc.setFillColor(backgroundColour); // UrbanAware primary-dark
      this.doc.rect(0, this.buildCursorY, PAGE_WIDTH, bannerHeight, 'F');
      this.doc.addImage(logo, 'PNG', paddingLeft, this.buildCursorY + paddingTop, URBANAWARE_LOGO_WIDTH, URBANAWARE_LOGO_HEIGHT);
      this._incrementCursor(bannerHeight);
      this._addSectionDivider();
    }
    
    /**
     * Adds a section to the PDF that shows a screen capture of the UrbanAware map
     * @param {String} mapPngData a domtoimage screenshot of the map to include in the pdf
     * @param {Number} mapWidth the width of the map inlcuded in the png data
     * @param {Number} mapHeight the height of the map included in the png data
     * @param {Number} simulationId the id of the currently selected simulation
     * @param {Object} resultsState the resultsState section of the redux store
     * @param {Number} selectedMapTime the time in the simulation that the screenshot corresponds to as a unix timestamp
     */
    _addMapSection(mapPngData, mapWidth, mapHeight, simulationId, resultsState, selectedMapTime) {
      // Scale the image in the PDF to fill the space allocated for the image, maintaining aspect ratio
      const imageSpaceWidth = PAGE_WIDTH - MARGIN - MARGIN;
      const imageWidth = imageSpaceWidth;
      const imageHeight = imageSpaceWidth * (mapHeight / mapWidth);
      this.doc.addImage(mapPngData, 'PNG', MARGIN, this.buildCursorY, imageWidth, imageHeight);
      this._incrementCursor(imageHeight);

      // Get data type friendly names
      const dataTypes = resultsState.dataTypes[simulationId];
      const selectedDataTypeIds = resultsState.selectedDataTypes;
      const selectedDataTypes = [];
      for (const dataType of dataTypes) {
        if (selectedDataTypeIds.includes(dataType.id)) {
          selectedDataTypes.push(this.t(`dataTypes.${dataType.name}`));
        }
      }

      // Get Material friendly names
      const materials = resultsState.materials[simulationId];
      const selectedMaterialIds = resultsState.selectedMaterials;
      const selectedMaterials = [];
      if (selectedMaterialIds.length > 0) {
        for (const material of materials) {
          if (selectedMaterialIds.includes(material.id)) {
            selectedMaterials.push(material.name);
          }
        }
      } else {
        // If no materials are selected all are shown
        selectedMaterials.push(this.t('simulationFilterTool.all'));
      }

      this._addWrappedText(
        `${this.t('simulationFilterTool.contourType')}: ${selectedDataTypes.join(', ')} | ${this.t('simulationFilterTool.contourMaterial')}: ` +
        `${selectedMaterials.join(', ')} | Simulation Time: ${this._formatTime(moment(selectedMapTime))}`,
        MARGIN
      );

      this._addSectionDivider();
    }

    /**
     * Adds a section to the PDF to convey hazard area aois
     * @param {Object[]} hazardAreaAois array of aois, as they would be in the redux store
     */
    _addHazardAreaAois(hazardAreaAois) {
      const tableRows = [['Name', 'Notes', 'Coordinates']];
      for (const aoi of hazardAreaAois) {
        const coordinates = aoi.geometry.coordinates[0].slice(0, -1).map(coord => this._formatCoordinates(coord));
        const coordinatesString = coordinates.join('\n');
        tableRows.push([aoi.name, aoi.notes, coordinatesString]);
      }    

      this._addTitle(this.t('label.aois'));
      this._addTable(tableRows);
      this._addSectionDivider();  
    }

    /**
     * Adds a section to the PDF to convey a point source incident
     * @param {Object} pointSourceIncident the point source incident to add, as it would be in the redux store
     */
    _addPointSourceIncident(pointSourceIncident) {
      const tableRows = [
        [pointSourceIncident.name, ''],
        [this.t('sidebar.type'), this.t('sidebar.PointSource')],
        [this.t('label.startTime'), this._formatTime(moment(pointSourceIncident.sources[0].startTime, true))],
        [this.t('label.location'), this._formatCoordinates(pointSourceIncident.sources[0].geometry.coordinates)],
        [this.t('sidebar.agentType'), pointSourceIncident.sources[0].agentType],
        [this.t('sidebar.material'), pointSourceIncident.sources[0].materialName],
        [this.t('label.mass'), pointSourceIncident.sources[0].mass]
      ];
      this._addTable(tableRows, true, true);

      this._addSectionDivider();
    }

    /**
     * Returns the processedGeometry from the atp45ContourData that is the closest match to the given atp45Incident
     * @param {Object} atp45Incident the atp-45 incident to get the closest match processed geometry for
     * @param {Array[Object]} atp45ContourData contourData retrieved from the results controller for ATP45 results
     * @returns {Object} the processedGeometry from the atp45ContourData that is the closest match for the given atp45Incident
     */
    _getClosestMatchProcessedGeometry(atp45Incident, atp45ContourData) {
      // Be able to return a default in case the ATP45 type/case wasn't recognised/supported
      if (atp45ContourData.length === 0) {
        return {
          centre: atp45Incident.geometry.coordinates,
          points: [],
          radii: []
        }
      }

      let runningSmallestMismatch = Infinity;
      let runningClosest = null;
      const coords1 = atp45Incident.geometry.coordinates;
      // Find the closest Release area contour for the incident
      for (const contour of atp45ContourData) {
        if(contour.processedGeometry.centre && contour.label === "Release") {
          const coords2 = contour.processedGeometry.centre;
          const mismatch = Math.pow(coords1[0] - coords2[0], 2) + Math.pow(coords1[1] - coords2[1], 2);
          if (runningClosest === null || mismatch < runningSmallestMismatch) {
            runningSmallestMismatch = mismatch;
            runningClosest = contour;
          }
        }
      }

      // If found the closest release area to the incident, use its contourId to find its matching hazard lines
      // Hazard area is added before Release area, so it should be the contour ID before this one
      // TODO: this is currently a hack, as there is no other way to easily identify which contours belong to each other
      // specifically in case of single simulation containing multiple ATP45 incidents.
      // For now the only possible cases of ATP45 are Type A or B, Case 1, 2, 3 or 4, which only contain
      // single release area and single hazard area, so this hack works for now.
      if(runningClosest) {
        // Create initial incident description from release contour
        const incidentDescription = {
          centre: runningClosest.processedGeometry.centre,
          points: [],
          radii: runningClosest.processedGeometry.radii
        };
        // Find the previous contour
        const hazardArea = atp45ContourData.find(c => c.contourId === (runningClosest.contourId - 1));
        if(hazardArea && hazardArea.label === "Hazard") {
          // Add the points and radii from the hazard area into the incident description
          incidentDescription.points = hazardArea.processedGeometry.points;
          incidentDescription.radii = incidentDescription.radii.concat(hazardArea.processedGeometry.radii);
        }

        return incidentDescription;
      }

      return {
        centre: atp45Incident.geometry.coordinates,
        points: [],
        radii: []
      };
    }

    /**
     * Adds a section to the PDF to convey an atp-45 incident
     * @param {Object} atp45Incident the atp-45 incident to add, as it would be in the redux store
     * @param {Array[Object]} atp45ContourData contourData retrieved from the results controller for ATP45 results
     */
    _addAtp45Incident(atp45Incident, atp45ContourData) {
      const tableRows = [
        [atp45Incident.name, ''],
        [this.t('sidebar.type'), this.t('sidebar.atp45')],
        [this.t('label.startTime'), this._formatTime(moment(atp45Incident.startTime, true))]
      ]

      // Add editable parameters to table rows
      for (const paramName of ['containerType', 'attackType', 'burstHeight', 'persistency']) {
        tableRows.push([atp45Incident.editableParameters[paramName].label, atp45Incident.editableParameters[paramName].value]);
      }

      // Add coordinates to table rows
      const { centre, points, radii } = this._getClosestMatchProcessedGeometry(atp45Incident, atp45ContourData);
      const coordinatesLines = [`${this.t("shape.centre")}: ${this._formatCoordinates(centre)}`];
      for (const point of points) {
        coordinatesLines.push(`${this.t("shape.point")}: ${this._formatCoordinates(point)}`);
      }
      for (const radius of radii) {
        coordinatesLines.push(`${this.t("shape.radius")}: ${radius}km`);
      }
      tableRows.push([this.t('label.location'), coordinatesLines.join('\n')])

      this._addTable(tableRows, true, true);

      this._addSectionDivider();
    }

    /**
     * Adds a section to the PDF to convey the meteorological profiles
     */
    _addMetProfiles(metProfiles) {
      this._addTitle(this.t('label.metProfiles'));
      
      const tableRows = [[
        this.t('label.windDirection'),
        this.t('label.windSpeed'),
        this.t('label.stability'),
        this.t('label.temperature'),
        this.t('label.temperatureKelvin')
      ]]

      for (const metProfile of metProfiles) {
        tableRows.push([
          AngleUtils.convertDegreesToDisplayString(metProfile.windDirection, this.userPreferences.windDirectionUnit),
          metProfile.windSpeed,
          metProfile.stability,
          metProfile.temperature - 273.15, // kelvin to degrees celcius
          metProfile.temperature
        ]);
      }
      
      this._addTable(tableRows);

      this._addSectionDivider();
    }

    /**
     * Adds text to the PDF that is allowed to wrap into multiple lines
     * @param {String} text the text to add 
     * @param {Number} margin the amount of margin to allow at either side of the page for the added text section 
     */
    _addWrappedText(text, margin) {
      this.doc.setFontSize(10);
      const singleLineHeight = 3;
      const maxWidth = PAGE_WIDTH - margin - margin
      const heightTaken = this.doc.getTextDimensions(this.doc.splitTextToSize(text, maxWidth)).h;
      this.doc.text(text, margin, this.buildCursorY + singleLineHeight, { maxWidth: maxWidth});
      this._incrementCursor(heightTaken);
    }

    /**
     * Adds text to the PDF as a new line, styled as a title
     * @param {String} text the text to add
     */
    _addTitle(text) {
      this.doc.setFontSize(14);
      const lineHeight = 7;
      this.doc.text(text, MARGIN, this._incrementCursor(lineHeight));
    }

    /**
     * Adds a table to the PDF
     * @param {String[][]} rows table row data where rows[rowIndex][cellIndex] is the content to display in that cell 
     * @param {Boolean} includesHeader true if the zeroth row is to be displayed as a table header
     * @param {Boolean} avoidPageBreak true if it would be preferable to move the table rather than split it over multiple pages
     */
    _addTable(rows, includesHeader = true, avoidPageBreak = false) {
      autoTable(this.doc, {
        startY: this._incrementCursor(4),
        head: includesHeader ? [rows[0]] : null,
        body: includesHeader ? rows.slice(1) : rows,
        theme: 'striped',
        fontsize: 8,
        pageBreak: avoidPageBreak ? 'avoid' : 'auto',
        rowPageBreak: 'avoid',
        showHead: includesHeader,
        headStyles: {
          textColor: 20, // 0-255 greyscale
          fillColor: '#ffc02e' // UrbanAware light-dark-gold
        }
      })
      this.buildCursorY = this.doc.lastAutoTable.finalY;
    }

    /**
     * Adds vertical space to the PDF
     */
    _addSectionDivider() {
      this._incrementCursor(SECTION_DIVIDER_HEIGHT);
    }

    /**
     * Updates the buildCursorY so that items added to the pdf using it will be added lower or on a new page
     * @param {Number} amount the amount to increase the y value of the build cursor by
     * @returns the new cursor y value
     */
    _incrementCursor(amount) {
      this.buildCursorY += amount;
      if (this.buildCursorY >= this.pageHeight - MARGIN) {
        this.doc.addPage();
        this.buildCursorY = MARGIN + amount;
      } 
      return this.buildCursorY;
    }

}