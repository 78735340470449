import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Popup } from "semantic-ui-react";
import { ReactComponent as ArchiveIcon } from '../../images/Archive.svg';
import { ReactComponent as RestoreIcon } from '../../images/Restore.svg';
import "./styles.scss";

class CommonButtonsBar extends React.Component {

  getArchvivedButtons = () => {
    var { t } = this.props;
    if (this.props.restore || this.props.archive) {
      return (
        <Button.Group>
          <Popup
            className="popup"
            trigger={
              <Button
                type="button"
                className="button-svg-icon"
                icon={<ArchiveIcon fill="white" stroke="white" style={{ width: '22px', height: '24px' }} />}
                onClick={this.props.archive}
                disabled={
                  (this.props.archive !== undefined &&
                    this.props.selectedIndex === null) ||
                  this.props.archive === undefined
                }
              />
            }
            position={"bottom center"}
          >
            {this.props.archiveDescription}
          </Popup>

          <Popup
            className="popup"
            trigger={
              <Button
                type="button"
                className="button-svg-icon"
                icon={<RestoreIcon fill="white" stroke="white" style={{ width: '22px', height: '24px' }} />}
                onClick={this.props.restore}
                disabled={
                  this.props.restore === undefined
                }
              />
            }
            position={"bottom center"}
          >
            {this.props.restoreDescription}
          </Popup>
        </Button.Group>
      )
    }
  }

  render() {
    return (
      <div className="table-buttons">
        {this.getArchvivedButtons()}
        <Button.Group>
          <Popup
            className="popup"
            trigger={
              <Button
                type="button"
                icon="map marker alternate"
                onClick={this.props.flyTo}
                disabled={
                  (this.props.flyTo !== undefined &&
                    this.props.selectedIndex === null) ||
                  this.props.flyTo === undefined
                }
              />
            }
            position={"bottom center"}
          >
            {this.props.flyToItemDescription}
          </Popup>

          <Popup
            className="popup"
            trigger={
              <Button
                type="button"
                icon="pencil"
                onClick={this.props.editItem}
                disabled={
                  this.props.editDisabled || (this.props.editItem !== undefined &&
                    this.props.selectedIndex === null) ||
                  this.props.editItem === undefined
                }
              />
            }
            position={"bottom center"}
          >
            {this.props.editItemDescription}
          </Popup>
        </Button.Group>
        <Button.Group>
          <Popup
            className="popup"
            trigger={
              <Button
                type="button"
                icon="minus"
                onClick={this.props.removeItem}
                disabled={(this.props.removeItem &&
                  this.props.selectedIndex === null) ||
                  this.props.removeItem === undefined
                }
              />
            }
            position={"bottom center"}
          >
            {this.props.removeItemDescription}
          </Popup>

          <Popup
            className="popup"
            trigger={
              <Button
                type="button"
                icon="plus"
                onClick={this.props.createItem}
                disabled={this.props.createItem === undefined}
              />
            }
            position={"bottom center"}
          >
            {this.props.createItemDescription}
          </Popup>
        </Button.Group>
      </div>
    );
  }
}

CommonButtonsBar.defaultProps = {
  editDisabled : false
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(CommonButtonsBar);
