import Requests from '../requests';
/*
 * action types
 */
export const UPDATE_AOI_SUCCESS = "UPDATE_AOI_SUCCESS";
export const UPDATE_AOI_FAILURE = "UPDATE_AOI_FAILURE";
export const UPDATE_AOI_BEGIN = "UPDATE_AOI_BEGIN";

export const ADD_AOI_SUCCESS = "ADD_AOI_SUCCESS";
export const ADD_AOI_FAILURE = "ADD_AOI_FAILURE";
export const ADD_AOI_BEGIN = "ADD_AOI_BEGIN";

export const CREATE_AOI = "CREATE_AOI";
export const EDIT_AOI = "EDIT_AOI";
export const REMOVE_AOI = "REMOVE_AOI";

export const CANCEL_EDIT = "CANCEL_EDIT_AOI";

export const FETCH_BEGIN = "FETCH_AOI_BEGIN";
export const FETCH_AOIS_SUCCESS = "FETCH_AOIS_SUCCESS";
export const FETCH_FAILURE = "FETCH_AOI_FAILURE";

export const CLEAR = 'CLEAR_AOI';

export class Actions {
  /*
   * action creators
   */

  static clear() {
    return {
      type: CLEAR
    }
  }

  static cancelEdit() {
    return {
      type: CANCEL_EDIT,
    };
  }

  static fetchBegin = () => ({
    type: FETCH_BEGIN,
  });

  static fetchAoisSuccess = (aois) => ({
    type: FETCH_AOIS_SUCCESS,
    payload: { aois },
  });

  static fetchFailure = (error) => ({
    type: FETCH_FAILURE,
    payload: { error },
  });

  static fetchAois = (scenarioId) => {
    return (dispatch) => {
      dispatch(this.fetchBegin());
      return Requests.get("/aois/scenario/" + scenarioId)
        .then((json) => {
          // Loop through met and parse geometry string to json
          for (let i = 0; i < json.length; i++) {
            var aoi = json[i]; // eslint-disable-line security/detect-object-injection
            if (aoi.geometry) {
              aoi.geometry = JSON.parse(aoi.geometry);
              aoi.geoJson = {
                geometry: aoi.geometry,
                properties: {},
                type: "Feature",
              };
            }
            // Create tag for marker display type
            aoi.type = "AOI";
          }
          dispatch(this.fetchAoisSuccess(json));
          return json;
        })
        .catch((error) => {
          console.error("Error occurred fetching areas of interest ", error);
          dispatch(this.fetchFailure(error));
        });
    };
  };

  /*
   * action creators
   */
  static editAoi(aoiId) {
    return {
      type: EDIT_AOI,
      payload: aoiId,
    };
  }

  /*
   * action creators
   */
  static removeAoi(aoiId, scenarioId) {
    return (dispatch) => {
      dispatch(this.fetchBegin());
      return Requests.deleteRequest("/aois/" + aoiId + "/scenario/" + scenarioId)
        .then(() => {
          dispatch(this.onDeleteSuccess(aoiId));
        })
        .catch((error) => {
          console.error(error);
        });
    };
  }

  /*
   * action creator to indicate aoi updates
   */
  static onDeleteSuccess = (aoiId) => {
    return {
      type: REMOVE_AOI,
      payload: aoiId,
    };
  };

  /*
   * action creators
   */
  static createAoi() {
    // Create a default aoi
    let d = new Date();

    // Create a barebones aoi object with a random ID
    var aoi = {
      type: "AOI",
      aoiType: "STAGING_AREA",
      geoJson: null,
      name: "Area Of Interest",
      notes: "",
      lastUpdated: d.getTime(),
    };

    return {
      type: CREATE_AOI,
      payload: aoi,
    };
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */
  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static onAddBegin = () => {
    return {
      type: ADD_AOI_BEGIN,
    };
  };

  /*
   * action creators
   */
  static addAoi(aoi, scenarioId) {
    aoi.geometry = JSON.stringify(aoi.geoJson.geometry);
    return (dispatch) => {
      dispatch(this.onAddBegin());
      return Requests.post("/aois/scenario/" + scenarioId, aoi)
        .then((json) => {
          json.type = "AOI"; // Needed for display of correct location pin
          json.geometry = JSON.parse(json.geometry);
          json.geoJson = aoi.geoJson;
          dispatch(this.onAddSuccess(json));
          return json;
        })
        .catch((error) => {
          console.error(error);
          dispatch(this.onAddFailure(error));
        });
    };
  }

  static updateAoi(aoi, scenarioId) {
    // If the aoi ID is not set then it is not yet in the DB
    if (!aoi.id) {
      return this.addAoi(aoi, scenarioId);
    } else {
      return this.update(aoi, scenarioId);
    }
  }

  static update(aoi, scenarioId) {
    aoi.geometry = JSON.stringify(aoi.geoJson.geometry);
    return (dispatch) => {
      dispatch(this.beginUpdate());
      return Requests.put("/aois/scenario/" + scenarioId, aoi)
        .then(() => {
          // Geometry got strinigfied for the update - reverse it back to JSON
          aoi.geometry = JSON.parse(aoi.geometry);
          dispatch(this.onUpdateSuccess(aoi));
        })
        .catch((error) => {
          console.error(error);
          dispatch(this.onUpdateFailure(error));
        });
    };
  }

  static beginUpdate = () => {
    return {
      type: UPDATE_AOI_BEGIN,
    };
  };

  static onUpdateFailure = (error) => {
    return {
      type: UPDATE_AOI_FAILURE,
      payload: error,
    };
  };

  /*
   * action creator to indicate met updates
   */
  static onAddSuccess = (aoi) => {
    let d = new Date();
    aoi.lastUpdated = d.getTime();
    return {
      type: ADD_AOI_SUCCESS,
      payload: aoi,
    };
  };

  static onAddFailure = (error) => {
    return {
      type: ADD_AOI_FAILURE,
      payload: error,
    };
  };

  /*
   * action creator to indicate aoi updates
   */
  static onUpdateSuccess = (aoi) => {
    let d = new Date();
    aoi.lastUpdated = d.getTime();
    return {
      type: UPDATE_AOI_SUCCESS,
      payload: aoi,
    };
  };
}
