/*
    ./client/components/App.jsx
*/
import React from "react";
import { Menu, Popup } from "semantic-ui-react";
import "whatwg-fetch";
import AoisPanel from "./aoisPanel";
import {ReactComponent as AOIIcon} from '../../../images/Flag.svg';

export default class AoisMenu extends React.Component {
  getContent = () => {
    if (this.props.activeItem === this.props.name) {
      return <AoisPanel cancel={this.props.cancel} next={this.props.next} />;
    }
    return null;
  };

  render() {
    return (
      <>
        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Menu.Item
              className="icon-container"
              name={this.props.name}
              active={this.props.activeItem === this.props.name}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
            >
              <AOIIcon className="sidebar-icon"/>
            </Menu.Item>
          }
          position={"right center"}
        >
          {this.props.title}
        </Popup>

        <div className="menu-option">{this.getContent()}</div>
      </>
    );
  }
}
