import { Actions as MetActions } from "../met/actions.jsx"
import Requests from '../requests';

/*
 * action types
 */
export const CREATE_SIMULATION_BEGIN = "CREATE_SIMULATION_BEGIN";
export const CREATE_SIMULATION_SUCCESS = "CREATE_SIMULATION_SUCCESS";
export const CREATE_SIMULATION_FAIL = "CREATE_SIMULATION_FAIL";

export const UPDATE_SIMULATION_SUCCESS = "UPDATE_SIMULATION_SUCCESS";
export const UPDATE_SIMULATION_FAILURE = "UPDATE_SIMULATION_FAILURE";
export const UPDATE_SIMULATION_BEGIN = "UPDATE_SIMULATION_BEGIN";

export const SELECT_SIMULATION = "SELECT_SIMULATION";
export const SIMULATION_FETCH_BEGIN = "SIMULATION_FETCH_BEGIN";
export const FETCH_SIMULATIONS_SUCCESS = "FETCH_SIMULATIONS_SUCCESS";
export const SIMULATION_FETCH_FAILURE = "SIMULATION_FETCH_FAILURE";
export const SIMULATION_BEGIN_COPY = "SIMULATION_BEGIN_COPY";
export const UPDATE_RUN_OPTIONS = "UPDATE_RUN_OPTIONS";
export const UPDATE_RUN_OPTIONS_SUCCESS = "UPDATE_RUN_OPTIONS_SUCCESS";
export const UPDATE_RUN_OPTIONS_FAILURE = "UPDATE_RUN_OPTIONS_FAILURE";

export const CLEAR = 'CLEAR_SIMULATIONS';

export class SimulationActions {

	static clear() {
		return {
			type: CLEAR
		}
	}

	static selectSimulation = (simulationId) => ({
		type: SELECT_SIMULATION,
		payload: simulationId,
	});

	static fetchSimulations(scenarioId) {
		return (dispatch) => {
			dispatch(this.fetchBegin());
			return Requests.get("/simulation/scenario/" + scenarioId)
				.then((json) => {
					// Sort the list by descending ID
					json.sort((a, b) => b.id - a.id);
					dispatch(this.fetchSimulationsSuccess(json));
					return json;
				})
				.catch((error) => {
					console.error(error);
					dispatch(this.fetchFailure(error));
				});
		};
	}

	static fetchBegin = () => {
		return {
			type: SIMULATION_FETCH_BEGIN,
		};
	};

	static fetchSimulationsSuccess = (simulations) => {
		return {
			type: FETCH_SIMULATIONS_SUCCESS,
			payload: { simulations },
		};
	};

	static fetchFailure = (error) => {
		console.error("Error getting simulations");
		console.error(error);
		return {
			type: SIMULATION_FETCH_FAILURE,
			payload: { error },
		};
	};

	/*
	 * Call api to add simulation to db
	 */
	static createSimulation(scenarioId) {
		// TODO set simulation info through UI
		let sim = {
			"name": "Simulation Name",
			"scenarioId": scenarioId,
			"modelTypes": ["UDM"],
			"outputInterval": 300,
			"modelDuration": 1800,
			"computationTimeElapsed": 0,
			"status": "NOT_STARTED",
			"progress": 0
		};

		return (dispatch) => {
			dispatch(this.createSimulationBegin());
			return Requests.post("/simulation/scenario/" + scenarioId, sim)
				.then((simulationJson) => {
					dispatch(MetActions.fetchMetsForSimulation(simulationJson.id, scenarioId, true));
					dispatch(this.onCreateSimulationSuccess(simulationJson));
					return simulationJson;
				})
				.catch((error) => {
					dispatch(this.onCreateSimulationFailure(error));
				});
		};
	}

	static createSimulationBegin = () => {
		return {
			type: CREATE_SIMULATION_BEGIN,
		};
	};

	static onCreateSimulationSuccess = (simulation) => ({
		type: CREATE_SIMULATION_SUCCESS,
		payload: simulation,
	});

	static onCreateSimulationFailure = (error) => {
		console.error("Error creating simulation", error);
		return {
			type: CREATE_SIMULATION_FAIL,
			payload: { error },
		};
	};

	/*
	 * Call api to create a copy of the simulation with this ID
	 */
	static copySimulation(simulationId, scenarioId) {
		return (dispatch) => {
			dispatch(this.onBeginCopy());
			return Requests.post("/simulation/copy/" + simulationId + "/scenario/" + scenarioId)
				.then((simulationJson) => {
					dispatch(MetActions.fetchMetsForSimulation(simulationJson.id, scenarioId, true));
					dispatch(this.onCreateSimulationSuccess(simulationJson));
					return simulationJson;
				})
				.catch((error) => {
					dispatch(this.onCreateSimulationFailure(error));
				});
		};
	}

	static onBeginCopy = () => {
		return {
			type: SIMULATION_BEGIN_COPY,
		};
	};

	/*
	 * Action creator to update simulation
	 */
	static updateSimulation(simulationDto, scenarioId) {

		return (dispatch) => {
			dispatch(this.onUpdateSimulationBegin());
			return Requests.patch(`/simulation/${simulationDto.id}/scenario/${scenarioId}`, simulationDto)
				.then((json) => {
					// If patching the simulation status to pending, a successful response will only
					// be received once simulation initialisation has completed in the backend
					if (simulationDto.status === "PENDING") {
						dispatch(MetActions.fetchMetsForSimulation(simulationDto.id, scenarioId, false));
					}
					dispatch(this.onUpdateSimulationSuccess(json));
					return json;
				})
				.catch((error) => {
					console.error(error);
					dispatch(this.onUpdateSimulationFailure(error));
				});
		};
	}

	static onUpdateSimulationBegin = () => {
		return {
			type: UPDATE_SIMULATION_BEGIN,
		};
	};

	static onUpdateSimulationFailure = (error) => {
		return {
			type: UPDATE_SIMULATION_FAILURE,
			payload: { error },
		};
	};

	/*
	 * action creator to indicate simulation updated
	 */
	static onUpdateSimulationSuccess = (simulation) => {
		let d = new Date();
		simulation.lastUpdated = d.getTime();
		return {
			type: UPDATE_SIMULATION_SUCCESS,
			payload: simulation,
		};
	};

	static updateRunOptions = (runOptions, scenarioId) => {
		var simulationId = runOptions.simulationId;
		return (dispatch) => {
			dispatch(this.beginUpdateRunOptions());
			return Requests.post(`/simulation/${simulationId}/scenario/${scenarioId}/runOptions`, runOptions)
				.then(() => {
					dispatch(this.updateRunOptionsSuccess(runOptions));
				})
				.catch((error) => {
					console.error(error);
					dispatch(this.updateRunOptionsFailure(error));
				});
		};
	};

	static beginUpdateRunOptions = () => {
		return {
			type: UPDATE_RUN_OPTIONS,
		};
	};

	static updateRunOptionsSuccess = (runOptions) => {
		return {
			type: UPDATE_RUN_OPTIONS_SUCCESS,
			payload: runOptions,
		};
	};

	static updateRunOptionsFailure = (error) => {
		return {
			type: UPDATE_RUN_OPTIONS_FAILURE,
			payload: { error },
		};
	};
}
