import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Message } from "semantic-ui-react";
import {
  Actions,
  ADD_AOI_FAILURE,
  UPDATE_AOI_FAILURE
} from "../../../services/aoi/actions.jsx";
import ShapeCreateEdit from "../sources/shapeCreateEditor.jsx";
import "./styles.scss";

class AoiEditor extends React.Component {

  constructor(props) {
    super(props);

    var aoi = props.aoiState.editing;
    // Don't call this.setState() here!
    this.state = {
      id: aoi.id,
      aoiType: aoi.aoiType,
      name: aoi.name,
      geoJson: aoi.geoJson,
      coordinateTextInvalid: false,
      notes: aoi.notes,
      error: ""
    };
  }

  aoiTypes = [
    { key: "ha", value: "HAZARD_AREA" },
    { key: "a", value: "STAGING_AREA" },
    { key: "i", value: "ICP" },
    { key: "b", value: "BASE" },
    { key: "s", value: "CORDON" },
    { key: "en", value: "ENTRY_ROUTE" },
    { key: "ex", value: "EXIT_ROUTE" },
    { key: "po", value: "POINT" },
    { key: "li", value: "LINE" },
    { key: "o", value: "OTHER" },
  ];

  validate = () => {
    if (!this.state.aoiType) {
      return false;
    }
    if (!this.state.geoJson) {
      return false;
    }
    if (this.state.coordinateTextInvalid) {
      return false;
    }
    if (this.props.aoiState.isUpdating) {
      return false
    }
    return true;
  };

  componentDidUpdate = (prevProps, prevState) => {
    var { t } = this.props;

    if (prevProps.aoiState.isUpdating && !this.props.aoiState.isUpdating) {
      if (this.props.aoiState.actionType === ADD_AOI_FAILURE) {
        this.setState({ error: t("scenario.messages.failedToCreateAOI") })
      }
      if (this.props.aoiState.actionType === UPDATE_AOI_FAILURE) {
        this.setState({ error: t("scenario.messages.failedToUpdateAOI") })
      }
    }
  };

  onClick = (e) => {
    this.setState({ error: null })

    this.props.updateAoi(
      {
        id: this.state.id,
        aoiType: this.state.aoiType,
        name: this.state.name,
        geoJson: this.state.geoJson,
        notes: this.state.notes,
        geometry: this.state.geoJson.geometry,
        scenarioId: this.props.scenarioState.scenario.id,
      },
      this.props.scenarioState.scenario.id
    );
  };

  getShape = () => {
    if(this.state.aoiType) {
      if(this.state.aoiType === "POINT") {
        return "MARKER";
      }
      if(this.state.aoiType === "LINE") {
        return "LINE";
      }
      if(this.state.aoiType.includes("_ROUTE")) {
        return "POLYLINE";
      }
    }
    return "POLYGON";
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="content-body">
          <Form className="ua-form">
            <Form.Group>
              <Form.Field>
                <Form.Select
                  inline
                  required={true}
                  label={t("sidebar.type")}
                  options={this.aoiTypes.map((aoi) => {
                    return { key: aoi.key, text: t("aoitypes." + aoi.value), value: aoi.value };
                  })}
                  placeholder={t("sidebar.type")}
                  value={this.state.aoiType}
                  onChange={(e, data) => this.setState({ aoiType: data.value, geoJson: null })}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <Form.Input
                  inline
                  required={true}
                  label={t("sidebar.name")}
                  placeholder={t("sidebar.enterName")}
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group className="location-field">
              <Form.Field required={true}>
                <label>{t("incident.location")}</label>
                <ShapeCreateEdit
                  key={"sce-" + this.state.aoiType}
                  className="ui input"
                  infoClassName="full-width"
                  shape={this.getShape()}
                  setGeoJson={(geoJ) => this.setState({ geoJson: geoJ })}
                  geoJson={this.state.geoJson}
                  onCoordinatesInputErrorStateChange={(value) =>
                    this.setState({ coordinateTextInvalid: value })}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <Form.TextArea
                  inline
                  label={t("sidebar.notes")}
                  placeholder={t("sidebar.enterNotes")}
                  value={this.state.notes}
                  rows={2}
                  onChange={(e) => this.setState({ notes: e.target.value })}
                />
              </Form.Field>
            </Form.Group>

            {this.state.error ? (
              <Message negative className="ua-error">
                <Message.Header>{t("app.error")}</Message.Header>
                <p>{this.state.error}</p>
              </Message>
            ) : null}

          </Form>
        </div>
        <div className="bottom-bar">
          <div className="finished">
            <Button
              type="button"
              onClick={this.onClick}
              disabled={!this.validate() || this.props.aoiState.isUpdating}
              loading={this.props.aoiState.isUpdating}
            >
              {t("sidebar.finished")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    aoiState: store.aoiState,
    scenarioState: store.scenarioState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateAoi: (aoi, scenarioId) => {
      dispatch(Actions.updateAoi(aoi, scenarioId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AoiEditor);
