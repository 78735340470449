import { CLEAR, FETCH_SIMULATION_OUTPUTS_SUCCESS, SELECT_DATA_TYPE, SELECT_MATERIAL, SIMULATION_OUTPUTS_FETCH_BEGIN, SIMULATION_OUTPUTS_FETCH_FAILURE } from './actions.jsx';

export class ResultsReducer {

	static resultsInfo(state, action) {

		const initialState = {
			simulationTimes: {},
			simulationData: [],
			dataTypes: [],
			materials: [],
			selectedMaterials: [],
			selectedDataTypes: [],
			legendData: {},
			loading: false,
			error: null
		}

		switch (action.type) {
			case CLEAR:
				return initialState;
			case SELECT_MATERIAL:
				return Object.assign({}, state, {
					selectedMaterials: action.payload.materialIds
				})
			case SELECT_DATA_TYPE:
				return Object.assign({}, state, {
					selectedDataTypes: action.payload.dataTypeIds
				})
			case SIMULATION_OUTPUTS_FETCH_BEGIN:
				return Object.assign({}, state, {
					loading: true,
					error: null,
				});
			case FETCH_SIMULATION_OUTPUTS_SUCCESS:
				return Object.assign({}, state, {
					loading: false,
					simulationTimes: action.payload.simulations.simulationTimes,
					simulationData: action.payload.simulations.simulationData,
					dataTypes: action.payload.simulations.dataTypes,
					materials: action.payload.simulations.materials,
					legendData: action.payload.simulations.legendData
				})
			case SIMULATION_OUTPUTS_FETCH_FAILURE:
				return Object.assign({}, state, {
					loading: false,
					error: action.payload.error.message,
				})
			default:
				return state || initialState;
		}
	}

}