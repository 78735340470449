import React from 'react';
import { Button, Confirm, Popup } from 'semantic-ui-react';

export default class ConfirmButton extends React.Component {

    state = {
        openConfirm: false
    }

    onClick = () => {
        this.setState({ openConfirm: !this.state.openConfirm });
    }

    onConfirm = () => {
        // Close the confirm window
        this.setState({ openConfirm: false });

        // Perform the confim action
        this.props.onConfirm();
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Popup
                    className="popup"
                    content={this.props.popupText}
                    trigger={
                        <Button
                            content={this.props.buttonContent}
                            disabled={this.props.buttonDisabled}
                            onClick={this.onClick}
                        />}
                />
                <Confirm
                    className="ua-modal"
                    header={this.props.confirmHeader}
                    content={this.props.confirmContent}
                    cancelButton={this.props.cancelButtonText}
                    confirmButton={this.props.confirmButtonText}
                    open={this.state.openConfirm}
                    onCancel={this.onClick}
                    onConfirm={this.onConfirm}
                />

            </>
        )
    }
}