
import React from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { Popup, Loader, Dimmer } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Actions as AoiActions } from "../../../services/aoi/actions.jsx";
import { ReactComponent as AOIIcon } from '../../../images/Flag.svg';

class AOILoader extends React.Component {

    state = {
        loading: true,
        error: false
    };

    componentDidMount = () => {
        // Start fetching data
        this.props.fetchAois(this.props.scenarioState.scenario.id);
    };

    componentDidUpdate(prevProps) {
        // Check fetching finished
        if (!this.props.aoiState.loading && prevProps.aoiState.loading) {
            if (this.props.aoiState.error) {
                // Error occurred - notify parent:
                this.props.fetchError("AOI");
                this.setState({ loading: false, error: true });
            } else {
                this.props.fetchFinished("AOI");
                this.setState({ loading: false, error: false });
            }
        }
    }

    render() {
        const { t } = this.props;
        var additionalClass = "";
        if (!this.state.loading) {
            if (!this.state.error) {
                additionalClass = " success";
            } else {
                additionalClass = " error";
            }
        }

        return (
            <div className="data-loader">
                <Popup
                    content={t("sidebar.aoi")}
                    position={"right center"}
                    size='mini'
                    trigger={
                        <div style={{ position: "relative" }}>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader size='small' />
                            </Dimmer>
                            <AOIIcon className={"data-loader-icon" + additionalClass} />
                        </div>
                    }
                />
            </div>
        );
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        aoiState: store.aoiState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAois: (scenarioId) => {
            dispatch(AoiActions.fetchAois(scenarioId));
        },
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AOILoader);