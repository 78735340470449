import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SimulationEditPopup from "../../common/popups/simulationEditPopup.jsx";

class SimulationTitle extends React.Component {

    render() {
        const { t } = this.props;
        if (this.props.simulationState.selectedSimulation && this.props.simulationState.selectedSimulation.id) {
            return (
                <div className="simulation-title">
                    <SimulationEditPopup trigger={
                        <div className="title-div">
                            {this.props.simulationState.selectedSimulation.name}
                        </div>}
                    />
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (store, props) => {
    return {
        simulationState: store.simulationState,
        ...props,
    };
};

export default compose(withTranslation(), connect(mapStateToProps))(SimulationTitle);
