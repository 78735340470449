import L from 'leaflet';
import React from 'react';
import { GeoJSON, LayerGroup, Marker } from 'react-leaflet';
import { AOIStyles } from './AOIStyles';
import { IncidentStyles } from './IncidentStyles';
import { MetStyles } from './MetStyles';
import { SourceStyles } from './SourceStyles';


export class MapLayerStyles {

    static addMarkersFromCollection = (map, editing, collection, styleFunc, hiddenIds, t, userPreference) => {
        for (var m in map) {
            var object = map[m]; // eslint-disable-line security/detect-object-injection
            if (editing && object.id === editing.id || hiddenIds.includes(object.id)) {
                continue;
            }
            if (object.geoJson.geometry.type === 'Point' && !object.radius) {
                collection.push(this.getPointGeoJson(object, styleFunc, t, userPreference));
            } else {
                var mainLayer = this.getNonPointGeoJson(object, styleFunc);
                var leafLayer = new L.GeoJSON(object.geoJson.geometry);
                var center = leafLayer.getBounds().getCenter();
                if (object.geoJson.geometry.type === 'LineString') {
                    var lngLat = object.geoJson.geometry.coordinates[0];
                    center = [lngLat[1], lngLat[0]];
                }
                var markerLayer = this.getMarker(object, center, t, userPreference);
                collection.push(
                    <LayerGroup>
                        {mainLayer}
                        {markerLayer}
                    </LayerGroup>
                );
            }
        }

        if (editing && editing.geoJson) {
            collection.push(<GeoJSON key={editing.id + "EDIT" + editing.lastUpdated}
                data={editing.geoJson}
                style={MapLayerStyles.getLightEditingStyle()}
                pointToLayer={(feature, latlng) => {
                    if (feature.properties.radius) {
                        return new L.Circle(latlng, feature.properties.radius).bindTooltip(feature.properties.poiType);
                    }
                    if (feature.geometry.type === 'Point') {
                        return new L.circleMarker(latlng);
                    }
                    return;
                }}
            />);
        }
    }

    static getPolylineStart(layer) {
        var lineLatLngs = layer.getLatLngs();
        return lineLatLngs[0];
    }

    static getMarker(object, center, t, userPreference) {
        if (object.sourceTermType) {
            return SourceStyles.getMarker(object, t, userPreference);
        }
        if (object.incidentType) {
            return IncidentStyles.getMarker(object, t, userPreference);
        }
        if (object.aoiType) {
            return AOIStyles.getMarker(object, center, t, userPreference);
        }
        if (object.metType) {
            return MetStyles.getMarker(object, t, userPreference);
        }
        return (
            <Marker position={center} >
            </Marker>
        )
    }

    static getPointGeoJson(object, styleFunc, t, userPreference) {

        if (object.sourceTermType) {
            return SourceStyles.getMarker(object, t, userPreference);
        }
        if (object.incidentType) {
            return IncidentStyles.getMarker(object, t, userPreference);
        }
        if (object.metType) {
            return MetStyles.getMarker(object, t, userPreference);
        }
        return null;
    }

    static getNonPointGeoJson(object, styleFunc, pane = "overlayPane") {
        return (
            <GeoJSON id={object.id}
                key={object.id + "-" + object.lastUpdated}
                data={object.geoJson.geometry}
                style={styleFunc(object)}
                pointToLayer={(feature, latlng) => {
                    if (object.radius) {
                        return new L.Circle(latlng, { radius: object.radius, pane: pane })
                    }
                    return;
                }}
            />
        )
    }

    static getPointToLayer(feature, latlng) {
        if (feature.properties.radius) {
            return new L.Circle(latlng, feature.properties.radius).bindTooltip(feature.properties.poiType);
        }
        if (feature.geoJson.geometry.type === 'Point') {
            return new L.circleMarker(latlng);
        }
        return;
    }

    static getLightEditingStyle() {
        return { color: 'grey', fillColor: 'lightgrey', opacity: 0.4, weight: 5, fillOpacity: 0.7 };
    }

    static getEditingStyle() {
        return { color: 'grey', fillColor: 'grey', opacity: 0.6, weight: 6, fillOpacity: 0.5 };
    }

    static getMetStyle() {
        return { color: 'grey', fillColor: 'yellow', opacity: 0.6, weight: 5, fillOpacity: 0.5 };
    }

    static getKeyBuildStyle() {
        return { color: 'grey', fillColor: 'yellow', opacity: 0.6, weight: 5, fillOpacity: 0.5 };
    }

    static getAoiStyle(object) {

        var color = "yellow";
        if (object.type === "AOI") {
            if (object.aoiType === "CORDON") {
                color = "#80d26e";
            } else if (object.aoiType === "STAGING_AREA") {
                color = "#4194d2";
            } else if (object.aoiType === "OTHER") {
                color = "#c58962";
            }
        }

        return { fillColor: color, color: 'grey', opacity: 0.6, strokeWidth: 5, fillOpacity: 0.5 };
    }
}
