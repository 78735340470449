/*
    ./client/components/App.jsx
*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Loader, Message } from "semantic-ui-react";
import { Actions } from "../../../services/met/actions.jsx";
import PanelWrapper from '../../common/panelWrapper.jsx';
import MetDisplay from './metDisplay.jsx';
import MetDataEditor from './metDataEditor.jsx';
import MetServiceDropdown from './metServiceDropdown.jsx';
import MetTypeChoice from './metTypeChoice.jsx';
import { IncidentsUtils } from '../../utils/IncidentsUtils.jsx';
import MetSourceTypeEnum from "../../../enums/metSourceTypeEnum.jsx";

/**
 * View to contain logic determining if user is viewing or editing a met observation, and display sub-panel accordingly 
 */
class MetPanel extends React.Component {

    state = {
        simulationId: null
    };

    constructor(props) {
        super(props);
        this.state = {
            simulationId: props.simulationState.selectedSimulation.id,
            scenarioId: props.scenarioState.scenario.id
        };
    }

    componentDidMount() {
        this.props.fetchMetServices();
    }

    getInformationMessage = () => {
        const incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
             inc => inc.simulationId == this.state.simulationId
        );
        if (!IncidentsUtils.requiresMetData(incidentsForCurrentSimulation)) {
            const { t } = this.props;
            return (
                <Message warning >
                    <Message.Header>{t('messages.titles.warning')}</Message.Header>
                    <p>{t('met.messages.sourcesDoNotRequireMet')}</p>
                </Message>
            );
        }
        return null;
    }

    getChooseManualOrServiceMet = () => {
        const { t } = this.props;
        if (this.props.metState.loading) {
            return <div >
                <Loader active inline='centered' size='medium' />
                <div className="bottom-bar" />
            </div>;
        }
        const metSourceType = this.props.metState.metConfigs[this.state.simulationId].metSourceType;
        if (metSourceType === MetSourceTypeEnum.MET_SERVICE) {
            return <div >
                <MetServiceDropdown simulationId={this.props.simulationState.selectedSimulation.id} scenarioId={this.state.scenarioId} />
                <div className="bottom-bar" />
            </div>;
        } else if (metSourceType === MetSourceTypeEnum.SIMULATION) {
            return <MetDisplay next={this.props.next} />
        }
        return <div >
            <div className="bottom-bar" />
        </div>;
    }

    getContent = () => {
        const { t } = this.props;

        const scenarioId = this.props.scenarioState.scenario.id;
        const simulationId = this.props.simulationState.selectedSimulation.id;

        if (this.props.metState.editing) {
            return (
                <PanelWrapper key={"met" + this.props.metState.editing.id} title={t("met.editTimeSeries")} cancel={this.props.cancelEdit} >
                    <MetDataEditor
                        userPreferences={this.props.userState.user.preferences}
                        specificMetState={this.props.metState}
                        onMetDataUpdate={(metData) => this.props.updateMetProfile(
                            simulationId, scenarioId, { ...metData, simulationId: simulationId }
                        )}
                    />
                </PanelWrapper>
            );
        }
        return (
            <PanelWrapper key={"metServiceChoice" + this.state.simulationId} title={t("met.meteorology")} cancel={this.props.cancel}>
                <Form className="ua-form content-body">
                    {this.getInformationMessage()}
                    <MetTypeChoice 
                        metConfig={this.props.metState.metConfigs[this.state.simulationId]}
                        scenarioId={this.state.scenarioId}
                    />
                </Form>
                {this.getChooseManualOrServiceMet()}
            </PanelWrapper>
        );
    }

    render() {
        return (
            <div className="met-panel">
                {this.getContent()}
            </div>
        )
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        metState: store.metState,
        simulationState: store.simulationState,
        incidentState: store.incidentState,
        scenarioState: store.scenarioState,
        userState: store.userState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        cancelEdit: () => {
            dispatch(Actions.cancelEdit());
        },
        fetchMetServices: () => {
            dispatch(Actions.fetchMetServices());
        },
        updateMetProfile: (simulationId, scenarioId, metProfile) => {
            dispatch(Actions.updateMetProfile(simulationId, scenarioId, metProfile));
        }
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MetPanel);