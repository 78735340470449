import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Button, Checkbox, Loader, Message, Modal, Table } from 'semantic-ui-react';
import ConfirmButton from '../../common/confirmButton';
import { DataUtil } from '../../common/DataUtil';
import Requests from '../../../services/requests';

class ArchivedSimulations extends React.Component {

    state = {
        simulations: [],
        selectedSimulations: [],
        loading: true,
        error: null,
        sortByProperty: "id",
        sortDirection: "ascending"
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.setOpen();
        }
    }

    setOpen = () => {
        // fetch simulations if modal is being opened
        this.fetchArchivedSimulations();
        this.setState({ loading: true, selectedSimulations: [] });
    }

    fetchArchivedSimulations = () => {
        var { t } = this.props;
        // fetch archived simulations
        Requests.get("/simulation/scenario/" + this.props.scenarioId + "?archived=true")
            .then(simulations => {
                if (simulations) {
                    // Convert simulations into data for the table display:
                    for (var ind in simulations) {
                        let sim = simulations[ind]; // eslint-disable-line security/detect-object-injection
                        sim.status = t("simulation.runStatus." + sim.status);
                        sim.createdDate = new Date(sim.createdDate);
                        sim.lastUpdatedDate = new Date(sim.lastUpdatedDate);
                    }

                    this.setState({
                        simulations: simulations,
                        error: null,
                        loading: false,
                        selectedSimulations: []
                    });
                } else {
                    this.setState({
                        error: t("simulation.messages.failedToFetch"),
                        loading: false
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    error: t("simulation.messages.failedToFetch"),
                    loading: false
                });
            })
    }

    restoreSelected = () => {
        var { t } = this.props;

        let simIds = this.state.selectedSimulations.join();
        let scenarioId = this.props.scenarioId;

        Requests.put(`/simulation/restore/${simIds}/scenario/${scenarioId}`)
            .then(resp => {
                // If successfully restored - refresh the store simulations
                this.props.fetchSimulations();
                // Close the modal
                this.props.setOpen();
            })
            .catch(err => {
                this.setState({ error: t("simulation.archived.failedToRestore") });
            })
    }

    deleteSelected = () => {
        var { t } = this.props;

        this.setState({ loading: true });
        Requests.deleteRequest("/simulation/" + this.state.selectedSimulations.join() + "/scenario/" + this.props.scenarioId)
            .then(resp => {
                // If successfully restored - refresh the store simulations
                this.props.fetchSimulations();
                this.fetchArchivedSimulations();
            })
            .catch(err => {
                this.setState({ error: t("simulation.archived.failedToDelete"), loading: false });
            })
    }

    sortBy = (property) => {
        // If sorting again by same property - reverse the sort order
        if (this.state.sortByProperty == property) {
            var newSortDirection = this.state.sortDirection === 'ascending' ? 'descending' : 'ascending';
            this.setState({
                sortDirection: newSortDirection,
                simulations: this.state.simulations.slice().reverse()
            })
        } else {
            // Sorting by new property
            this.setState({
                sortByProperty: property,
                sortDirection: 'ascending',
                simulations: this.sortSimulationsByProperty(property)
            })
        }
    }

    sortSimulationsByProperty = (property) => {
        var { t } = this.props;
        if (property === "__proto__") {
            console.error(t("messages.unableToAccessProperty"));
            return;
        }
        return this.state.simulations.slice().sort((a, b) => {
            // If the property is a date - handle separately
            var aProperty = a[property]; // eslint-disable-line security/detect-object-injection
            var bProperty = b[property]; // eslint-disable-line security/detect-object-injection
            if (aProperty instanceof Date) {
                return bProperty - aProperty;
            }

            if (aProperty < bProperty) {
                return -1;
            }
            if (aProperty > bProperty) {
                return 1;
            }
            return 0;
        });
    }

    getContent = () => {
        const { t } = this.props;
        const { sortByProperty, sortDirection } = this.state;
        if (this.state.loading) {
            return (
                <Loader active inline='centered' size='massive'>{t("scenario.loading")}</Loader>
            );
        }

        if (this.state.error) {
            return (
                <Message negative>
                    {this.state.error}
                </Message>
            )
        }

        // Build a list of simulations
        if (this.state.simulations.length === 0) {
            return (
                <p> {t("simulation.archived.noData")} </p>
            );
        }

        var tableEntries = [];
        for (var ind in this.state.simulations) {
            let simulation = this.state.simulations[ind]; // eslint-disable-line security/detect-object-injection
            tableEntries.push(
                <Table.Row key={"archived-sim-row-" + simulation.id}>
                    <Table.Cell collapsing>
                        <Checkbox
                            checked={this.state.selectedSimulations.includes(simulation.id)}
                            onClick={() => this.selectsimulation(simulation.id)}
                        />
                    </Table.Cell>
                    <Table.Cell>{simulation.name}</Table.Cell>
                    <Table.Cell>{simulation.modelType}</Table.Cell>
                    <Table.Cell>{simulation.status}</Table.Cell>
                    <Table.Cell>{DataUtil.formatDate(simulation.createdDate)}</Table.Cell>
                    <Table.Cell>{DataUtil.formatDate(simulation.lastUpdatedDate)}</Table.Cell>
                </Table.Row>
            );
        }

        return (
            <Table sortable compact className="ua-table">
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell
                            sorted={sortByProperty === 'name' ? sortDirection : null}
                            onClick={() => this.sortBy('name')}
                        >
                            {t("sidebar.name")}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortByProperty === 'modelType' ? sortDirection : null}
                            onClick={() => this.sortBy('modelType')}
                        >
                            {t("sidebar.modelType")}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortByProperty === 'status' ? sortDirection : null}
                            onClick={() => this.sortBy('status')}
                        >
                            {t("sidebar.status")}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortByProperty === 'createdDate' ? sortDirection : null}
                            onClick={() => this.sortBy('createdDate')}
                        >
                            {t("sidebar.creationDate")}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortByProperty === 'lastUpdatedDate' ? sortDirection : null}
                            onClick={() => this.sortBy('lastUpdatedDate')}
                        >
                            {t("sidebar.archivedDate")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {tableEntries}
                </Table.Body>
            </Table>
        )
    }

    selectsimulation = (id) => {
        this.setState(prevState => {
            // If this id is laready selected - deselect it
            if (prevState.selectedSimulations.includes(id)) {
                const selectedSimulations = prevState.selectedSimulations.filter(ss => ss !== id);
                return {
                    ...prevState,
                    selectedSimulations: selectedSimulations
                }
            }

            // Else add the id to selected simulations
            return {
                ...prevState,
                selectedSimulations: [...prevState.selectedSimulations, id]
            }
        })
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                className="ua-modal"
                onClose={() => this.props.setOpen()}
                onOpen={() => this.setOpen()}
                open={this.props.open}
                trigger={<div />}
            >
                <Modal.Header>{t("simulation.archived.title")}</Modal.Header>
                <Modal.Content>
                    {this.getContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.setOpen()}>
                        {t("simulation.archived.cancel")}
                    </Button>
                    <ConfirmButton
                        buttonContent={t("simulation.archived.delete")}
                        buttonDisabled={this.state.loading || this.state.selectedSimulations.length === 0}
                        confirmHeader={t("simulation.archived.deleteAlertHeader")}
                        confirmContent={t("simulation.archived.deleteAlertBody")}
                        cancelButtonText={t("simulation.archived.cancel")}
                        confirmButtonText={t("simulation.archived.delete")}
                        onConfirm={this.deleteSelected}

                    />
                    <Button
                        content={t("simulation.archived.restoreSelected")}
                        disabled={this.state.loading || this.state.selectedSimulations.length === 0}
                        onClick={this.restoreSelected}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default compose(withTranslation())(ArchivedSimulations);
