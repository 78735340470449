import {
  CANCEL_CONTOUR_EDIT,
  CLEAR,
  COMPLETE_CONTOURS_BEGIN, COMPLETE_CONTOURS_FAILURE, EDIT_CONTOUR_CONFIG, FETCH_BEGIN, FETCH_CONTOUR_CONFIGS_SUCCESS, FETCH_FAILURE, INSERT_CONTOUR_SUCCESS,
  REMOVE_CONTOUR_CONFIG,
  UPDATE_CONTOUR_CONFIG
} from "./actions.jsx";

export class ContourConfigReducer {

  static contourConfigInfo(state, action) {

    const initialState = {
      loading: false,
      error: null,
      isUpdatingContourOptions: false,
      isEditingContourOption: false,
      editContourConfig: null,
      contourConfigs: [],
      actionType: null,
    };

    switch (action.type) {
      case CLEAR:
        return initialState;
      case COMPLETE_CONTOURS_BEGIN:
        return Object.assign({}, state, {
          isUpdatingContourOptions: true,
          error: null,
          loading: true,
          actionType: action.type,
        });
      case INSERT_CONTOUR_SUCCESS:
        var configs = state.contourConfigs;
        // Note payload is a list
        for (var i = 0; i < action.payload.length; i++) {
          const object = action.payload[i]; // eslint-disable-line security/detect-object-injection
          configs[object.id] = object;
        }

        return Object.assign({}, state, {
          contourConfigs: configs,
          isEditingContourOption: false,
          editContourConfig: null,
          isUpdatingContourOptions: false,
          loading: false,
          error: null
        });
      case COMPLETE_CONTOURS_FAILURE:
        return Object.assign({}, state, {
          isUpdatingContourOptions: false,
          loading: false,
          error: action.payload.error.message,
          actionType: action.type,
        });
      case EDIT_CONTOUR_CONFIG:
        return Object.assign({}, state, {
          isEditingContourOption: true,
          editContourConfig: action.payload
        });
      case CANCEL_CONTOUR_EDIT:
        return Object.assign({}, state, {
          isEditingContourOption: false,
          editContourConfig: null
        });
      case REMOVE_CONTOUR_CONFIG:
        var newState = state;
        delete newState.contourConfigs[action.payload];
        return Object.assign({}, newState);
      case UPDATE_CONTOUR_CONFIG:
        var contourConfigs = state.contourConfigs;
        for (var i in contourConfigs) {
          var contourConfig = contourConfigs[i]; // eslint-disable-line security/detect-object-injection
          if (contourConfig.id === action.payload.id) {
            contourConfig = action.payload
          }
        }
        return Object.assign({}, state, {
          contourConfigs: contourConfigs,
          isEditingContourOption: false,
          editContourConfig: null,
          isUpdatingContourOptions: false,
          loading: false,
          error: null
        });
      case FETCH_CONTOUR_CONFIGS_SUCCESS:
        var newContourConfigs = [];

        // Loop though the contours and turn them into a map of id to object
        for (var i = 0; i < action.payload.contourConfigs.length; i++) {
          const object = action.payload.contourConfigs[i]; // eslint-disable-line security/detect-object-injection
          newContourConfigs[object.id] = object;
        }

        return Object.assign({}, state, {
          loading: false,
          editing: null,
          contourConfigs: newContourConfigs
        });
      case FETCH_BEGIN:
        return Object.assign({}, state, {
          loading: true,
          error: null
        });
      case FETCH_FAILURE:
        return Object.assign({}, state, {
          loading: false,
          editing: null,
          error: action.payload.error,
        });
      default:
        return state || initialState;
    }
  }
}
