import { SET_USER, SET_VERIFIED, UPDATE_USER_PREFERENCE } from './actions.jsx';

export class UserReducer {

    static userInfo(state, action) {

        const initialState = {
            user: null,
            verified: false
        }

        switch (action.type) {
            case SET_USER:
                let verified = false;
                if (action.payload) {
                    verified = true;
                }
                return Object.assign({}, state, {
                    user: action.payload,
                    verified: verified
                })
            case SET_VERIFIED:
                return Object.assign({}, state, {
                    verified: action.payload
                })
            case UPDATE_USER_PREFERENCE:
                return Object.assign({}, state, {
                    user: {
                        ...state.user,
                        preferences: action.payload
                    }
                })
            default:
                return state || initialState;
        }
    }

}