import Requests from '../requests';
import MetStabilityEnum from "../../enums/metStabilityEnum.jsx";

export const SIMULATION_MET_PROFILE_TYPE = "SimulationMetProfileObservation";

/*
* action types
*/
export const UPDATE_MET = 'UPDATE_MET';
export const CREATE_MET = 'CREATE_MET';
export const EDIT_MET = 'EDIT_MET';
export const REMOVE_MET = 'REMOVE_MET';

export const CANCEL_EDIT = 'CANCEL_EDIT';

export const CLEAR = 'CLEAR_MET';

export const SET_MET_SERVICE_BEGIN = 'SET_MET_SERVICE_BEGIN';
export const SET_MET_SERVICE_SUCCESS = 'SET_MET_SERVICE_SUCCESS';
export const SET_MET_SERVICE_FAILURE = 'SET_MET_SERVICE_FAILURE';

export const FETCH_MET_SERVICES_BEGIN = 'FETCH_MET_SERVICES_BEGIN';
export const FETCH_MET_SERVICES_SUCCESS = 'FETCH_MET_SERVICES_SUCCESS';
export const FETCH_MET_SERVICES_FAILURE = 'FETCH_MET_SERVICES_FAILURE';

export const SET_MET_CONFIG_SUCCESS = 'SET_MET_CONFIG_SUCCESS';

export const FETCH_MET_FOR_SIMULATION_BEGIN = 'FETCH_MET_FOR_SIMULATION_BEGIN';
export const FETCH_MET_FOR_SIMULATION_SUCCESS = 'FETCH_MET_FOR_SIMULATION_SUCCESS';
export const FETCH_MET_FOR_SIMULATION_FAILURE = 'FETCH_MET_FOR_SIMULATION_FAILURE';

/**
 * Creates actions for met 
 */
export class Actions {

	static clear() {
		return {
			type: CLEAR
		}
	}

	/*
	* action creator for [X] button
	*/
	static cancelEdit() {
		return {
			type: CANCEL_EDIT
		}
	}

	/*
	* action creator to indicate met updates
	*/
	static onUpdateMetSuccess = met => {
		let d = new Date();
		met.lastUpdated = d.getTime();
		return {
			type: UPDATE_MET,
			payload: met
		}
	}

	/*
	* action creator to make a new empty met (+ button)
	*/
	static addMet = simulationId => {
		const met = {
			simulationId: simulationId,
			time: new Date(),
			geoJson: null,
			geometry: null,
			windSpeed: 4,
			windDirection: 0,
			referenceHeight: 10,
			temperature: 293.15,
			stability: MetStabilityEnum.D
		}

		return {
			type: CREATE_MET,
			payload: met
		}
	}

	/*
	* action creator to save changes to met (finished button)
	*/
	static updateMetProfile(simulationId, scenarioId, met) {

		// If the met ID is not set then it is not yet in the DB
		if (!met.id) {
			return this.createMetProfile(simulationId, scenarioId, met);
		}
		else {
			return this.saveMetProfile(simulationId, scenarioId, met);
		}
	}

	/*
	* Call api to add met to db
	*/
	static createMetProfile(simulationId, scenarioId, met) {
		met.metProfileType = SIMULATION_MET_PROFILE_TYPE;
		return dispatch => {
			return Requests.post(`/met/met-profile/simulation/${simulationId}/scenario/${scenarioId}`, met)
				.then(json => {
					json.metType = "profile"; // Needed for display of correct location pin 
					json.geometry = met.geometry;
					json.geoJson = met.geoJson;
					json.time = new Date(json.time)
					dispatch(this.onUpdateMetSuccess(json));
					return json;
				})
				.catch(error => {
					console.error(error);
				});
		}
	}

	/*
	* Call api to update met in db
	*/
	static saveMetProfile(simulationId, scenarioId, met) {

		// Create a json string to send to the backend, without mutating our object
		const jsonMet = {
			metProfileType: SIMULATION_MET_PROFILE_TYPE,
			id: met.id,
			time: met.time,
			geoJson: met.geoJson,
			geometry: met.geoJson.geometry,
			windSpeed: met.windSpeed,
			windDirection: met.windDirection,
			referenceHeight: met.referenceHeight,
			temperature: met.temperature,
			simulationId: met.simulationId,
			stability: met.stability
		}

		return dispatch => {
			return Requests.put(`/met/met-profile/simulation/${simulationId}/scenario/${scenarioId}`, jsonMet)
				.then(() => {
					dispatch(this.onUpdateMetSuccess(met));
				})
				.catch(error => {
					console.error(error);
				});
		}
	}

	/*
	* action creator to edit met
	*/
	static editMetProfile(metId) {
		return {
			type: EDIT_MET,
			payload: metId
		}
	}

	/*
	* action creator to delete met
	*/
	static removeMetProfile(metId, simulationId, scenarioId) {

		return dispatch => {
			return Requests.deleteRequest(`/met/met-profile/${metId}/simulation/${simulationId}/scenario/${scenarioId}`)
				.then(() => {
					dispatch(this.onDeleteMetSuccess(metId));
				})
				.catch(error => {
					console.error(error);
				});
		};

	}

	static onDeleteMetSuccess = metId => {
		return {
			type: REMOVE_MET,
			payload: metId
		}
	}

	/*
	* action creator to indicate met services retrieved
	*/
	static fetchMetServicesSuccess = metServices => ({
		type: FETCH_MET_SERVICES_SUCCESS,
		payload: metServices
	});

	static fetchMetServicesBegin = () => ({
		type: FETCH_MET_SERVICES_BEGIN
	})

	static fetchMetServicesFailure = error => ({
		type: FETCH_MET_SERVICES_FAILURE,
		payload: error
	})

	
	static fetchMetForSimulationBegin = () => ({
		type: FETCH_MET_FOR_SIMULATION_BEGIN
	})

	static fetchMetForSimulationSuccess = (simulationId, metProfiles, metConfigs) => ({
		type: FETCH_MET_FOR_SIMULATION_SUCCESS, 
		payload: {
			simulationId: simulationId,
			metProfiles: metProfiles,
			metConfigs: metConfigs,
		}
	})

	static fetchMetForSimulationFailure = error => ({
		type: FETCH_MET_FOR_SIMULATION_FAILURE,
		payload: { error }
	})

	static fetchMetServices() {

		return dispatch => {
			dispatch(this.fetchMetServicesBegin());
			return Requests.get("/met/met-services")
				.then(json => {
					dispatch(this.fetchMetServicesSuccess(json));
					return json;
				})
				.catch(error => {
					dispatch(this.fetchMetServicesFailure(error));
				});
		};
	}

	static setMetServiceSuccess = (simulationId, metService) => ({
		type: SET_MET_SERVICE_SUCCESS,
		payload: {
			simulationId: simulationId,
			metService: metService
		}
	});

	static setMetServiceForSimulation(scenarioId, simulationId, metService) {

		return dispatch => {
			dispatch(this.settingBegin());
			return Requests.post("/met/met-services/simulation/" + simulationId + "/scenario/" + scenarioId, { metService: metService })
				.then(res => {
					dispatch(this.setMetServiceSuccess(simulationId, metService));
					return;
				})
				.catch(error => {
					dispatch(this.settingFailure(error));
				});
		};
	}

	static settingBegin = () => ({
		type: SET_MET_SERVICE_BEGIN
	});

	static settingFailure = error => ({
		type: SET_MET_SERVICE_FAILURE,
		payload: { error }
	});

	static setMetConfigSuccess = metConfig => ({
		type: SET_MET_CONFIG_SUCCESS,
		payload: metConfig
	});

	static setMetConfig(metConfig, scenarioId) {
		return dispatch => {
			dispatch(this.fetchMetServicesBegin());
			return Requests.put("/met/met-config/simulation/" + metConfig.simulationId + "/scenario/" + scenarioId, metConfig)
				.then(res => {
					dispatch(this.setMetConfigSuccess(metConfig));
					return;
				})
				.catch(error => {
					console.error(error)
					dispatch(this.fetchMetServicesFailure(error));
				});
		}
	}

	/**
	 * Lambda generator that creates a function that takes dispatch and fetches simulation mets, and config if desired,
	 * using the dispatch to update the redux store as appropriate during the process. Can be treated as an
	 * action creator by passing this fetchMetsForSimulation to dispatch. 
	 * 
	 * @param {Number} simulationId the id of the simulation that the returned function is to fetch met data for
	 * @param {Number} scenarioId the id of the scenario that the simulation is under
	 * @param {Boolean} includeMetConfig true if the met config should also be fetched and updated for this simulation
	 * @returns A function that takes dispatch and fetches simulation mets, and conditionally the config, using the
	 * dispatch to update the redux store as appropriate during the process.
	 */
	static fetchMetsForSimulation(simulationId, scenarioId, includeMetConfig) {
		return dispatch => {
			dispatch(this.fetchMetForSimulationBegin());
			return Requests.get(`/met/simulation/${simulationId}/scenario/${scenarioId}?includeMetConfig=${includeMetConfig}`)
				.then(json => {
					const metProfiles = json.metProfiles;
					// Loop through met and parse geometry string to json
					for (const metProfile of metProfiles) {
						if (metProfile.geometry) {
							metProfile.geometry = metProfile.geometry;
							metProfile.geoJson = { geometry: metProfile.geometry, properties: {}, type: "Feature" };
						}
						metProfile.scenarioId = scenarioId
						metProfile.time = new Date(metProfile.time)
						metProfile.metType = "profile"; // Create tag for marker display type
					}
					dispatch(this.fetchMetForSimulationSuccess(simulationId, metProfiles, json.metConfigs));
					json.metProfiles = metProfiles;
					return json;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.fetchMetForSimulationFailure(error))
				});
		};
	}
}