import Requests from '../requests';

/*
* action types
*/
export const UPDATE_SCENARIO_SUCCESS = 'UPDATE_SCENARIO_SUCCESS';
export const UPDATE_SCENARIO_BEGIN = "UPDATE_SCENARIO_BEGIN";
export const UPDATE_SCENARIO_FAILURE = "UPDATE_SCENARIO_FAILURE"

export const SELECT_SCENARIO = 'SELECT_SCENARIO';

export const FETCH_BEGIN = 'FETCH_SCENARIO_BEGIN';
export const FETCH_SCENARIO_SUCCESS = 'FETCH_SCENARIO_SUCCESS';
export const FETCH_FAILURE = 'FETCH_SCENARIO_FAILURE';

export const PERSIST_SCENARIO_BEGIN = 'PERSIST_SCENARIO_BEGIN';
export const PERSIST_SCENARIO_SUCCESS = 'PERSIST_SCENARIO_SUCCESS';
export const PERSIST_SCENARIO_FAILURE = 'PERSIST_SCENARIO_FAILURE';

export const CLEAR = 'CLEAR_SCENARIO';

/**
 * Creates actions for scenario 
 */
export class Actions {

	static clear() {
		return {
			type: CLEAR
		}
	}

	/*
	 * action creator to save changes to scenario (finished button)
	 */
	static updateScenario(scenario) {

		return dispatch => {
			dispatch(this.onUpdateScenarioBegin());
			return Requests.put("/scenario/" + scenario.id, scenario)
				.then(() => {
					dispatch(this.onUpdateScenarioSuccess(scenario));
				})
				.catch(error => {
					console.error(error);
					dispatch(this.onUpdateScenarioFailure(error))
				});
		}
	}

	static onUpdateScenarioBegin() {
		return {
			type: UPDATE_SCENARIO_BEGIN
		}
	}

	static onUpdateScenarioFailure(error) {
		return {
			type: UPDATE_SCENARIO_FAILURE,
			payload: error
		}
	}

	/*
	 * action creator to indicate scenario updates
	 */
	static onUpdateScenarioSuccess = scenario => {
		return {
			type: UPDATE_SCENARIO_SUCCESS,
			payload: scenario
		}
	}

	static selectScenario(scenario) {

		return {
			type: SELECT_SCENARIO,
			payload: scenario
		}
	}

	/*
	 * action creator to start getting all scenarios
	 */
	static fetchBegin = () => ({
		type: FETCH_BEGIN
	});

	/*
	 * action creator to indicate scenarios not retrieved
	 */
	static fetchFailure = error => ({
		type: FETCH_FAILURE,
		payload: { error }
	});

	/*
	 * action creator to indicate scenarios were retrieved
	 */
	static onFetchScenariosSuccess = scenarios => {
		return {
			type: FETCH_SCENARIO_SUCCESS,
			payload: scenarios
		}
	}

	static fetchScenarios(organisationId) {

		return dispatch => {
			dispatch(this.fetchBegin());
			return Requests.get("/scenario/?organisationId=" + organisationId)
				.then(json => {
					dispatch(this.onFetchScenariosSuccess(json));
					return json;
				})
				.catch(error => {
					dispatch(this.fetchFailure(error));
				});
		};
	}

	/*
	 * action creator to make a new scenario and add to db (Create new scenario button)
	 */
	static createScenario(scenario) {

		return dispatch => {
			dispatch(this.persistScenarioBegin());
			return Requests.post("/scenario/", scenario)
				.then(json => {
					dispatch(this.createScenarioSuccess(json));
					return json;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.createScenarioFailure(error));
				});
		};
	}

	static persistScenarioBegin = () => ({
		type: PERSIST_SCENARIO_BEGIN
	});

	static createScenarioSuccess = scenario => ({
		type: PERSIST_SCENARIO_SUCCESS,
		payload: { scenario }
	});

	static createScenarioFailure = error => ({
		type: PERSIST_SCENARIO_FAILURE,
		payload: { error }
	});

}