import Requests from '../../../services/requests';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    ChartLabel,
    Crosshair,
    DiscreteColorLegend, HorizontalGridLines,
    LineSeries, VerticalGridLines, XAxis, XYPlot,
    YAxis
} from 'react-vis';
import { compose } from "redux";
import { Dropdown, Loader } from 'semantic-ui-react';
import '../map.scss';

class KeyBuildingChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedDataType: null,
            selectedSubType: null,
            crosshairIndex: null,
        }
    }

    componentDidMount() {
        let scenarioId = this.props.scenarioState.scenario.id;
        let simulationId = this.props.simulationState.selectedSimulation.id;
        // Fetch the contours for the building
        let url = `/output/scenario/${scenarioId}/simulation/${simulationId}/buildings/${this.props.selectedBuilding.id}`;
        Requests.get(url).then(results => {
            let resultsMap = {};
            let dataTypes = [];

            // Loop through the results and format them to be used for the chart
            for (let i = 0; i < results.length; i++) {
                let result = results[i]; // eslint-disable-line security/detect-object-injection
                if (result.dataType === "__proto__") {
                    console.error("Unable to access object property");
                    continue;
                }

                // IF the results map does not contain the data type - add it
                if (!resultsMap[result.dataType]) {
                    resultsMap[result.dataType] = {};
                    dataTypes.push(result.dataType)
                }

                // Find material name and add it to the results map
                let materialName = this.getMaterialNameFromIncidents(result.materialId);
                if (materialName === "__proto__") {
                    console.error("Unable to access object property");
                    continue;
                }
                if (!resultsMap[result.dataType][materialName]) { // eslint-disable-line security/detect-object-injection
                    resultsMap[result.dataType][materialName] = []; // eslint-disable-line security/detect-object-injection
                }
                // Populate the map entry for data type and material name with X and Y values for the chart
                result.timeSeries.forEach(timeSerie => {
                    let timeSerieValue = timeSerie.value;
                    // Check the value is not infinity or not a number, in that case set value to 0 in order to render chart successfully
                    if (timeSerieValue === "Infinity" || isNaN(timeSerieValue)) {
                        timeSerieValue = 0;
                    }
                    resultsMap[result.dataType][materialName].push({ // eslint-disable-line security/detect-object-injection
                        x: new Date(timeSerie.time).getTime(),
                        y: timeSerieValue,
                        type: result.dataType,
                        material: materialName
                    })
                })
            };

            // Default dataType and subDataType selection will be based on available types
            // Prioritize CONCENTRATION and sub type BOTH
            let selectedDataType = null;
            let selectedSubType = null;

            // Separate available data types into categorized map
            let dataTypesAndSubTypes = {};
            // Check concentration data types, and sort by length ascending
            let concentrationDataTypes = dataTypes.filter(dt => dt.includes("CONCENTRATION"));
            concentrationDataTypes.sort((a, b) => a.length - b.length);
            if (concentrationDataTypes.length > 0) {
                selectedDataType = 'CONCENTRATION';
                selectedSubType = concentrationDataTypes[0];
                dataTypesAndSubTypes['CONCENTRATION'] = concentrationDataTypes;
                // If both concentration types were available - add 'BOTH' as an option
                if (concentrationDataTypes.length === 2) {
                    dataTypesAndSubTypes['CONCENTRATION'].push("BOTH");
                    selectedSubType = "BOTH";
                }
            }

            // Check dosage data types and sort by length ascending
            let dosageDataTypes = dataTypes.filter(dt => dt.includes("DOSAGE"));
            dosageDataTypes.sort((a, b) => a.length - b.length);
            if (dosageDataTypes.length > 0) {
                dataTypesAndSubTypes['DOSAGE'] = dosageDataTypes;
                // If both dosage types were available - add 'BOTH' as an option
                if (dosageDataTypes.length === 2) {
                    dataTypesAndSubTypes['DOSAGE'].push("BOTH");
                }
            }

            // If for some reason CONCENTRATION output was not created - default selection to first values from the map
            if ((!selectedDataType || !selectedSubType) && Object.keys(dataTypesAndSubTypes).length > 0) {
                selectedDataType = Object.keys(dataTypesAndSubTypes)[0];
                selectedSubType = dataTypesAndSubTypes[selectedDataType][0];
            }

            this.setState({
                resultsMap: resultsMap,
                selectedDataType: selectedDataType,
                selectedSubType: selectedSubType,
                dataTypesAndSubTypes: dataTypesAndSubTypes,
                loading: false
            })
        })
        .catch(err => {
            console.error(err);
            this.setState({
                resultsMap: {},
                loading: false
            })
        })
    }

    getMaterialNameFromIncidents = (materialId) => {
        for (var incInd in this.props.incidentState.incidents) {
            var incident = this.props.incidentState.incidents[incInd]; // eslint-disable-line security/detect-object-injection
            for (var sourceInd in incident.sources) {
                if (incident.sources[sourceInd].materialId === materialId) { // eslint-disable-line security/detect-object-injection
                    return incident.sources[sourceInd].materialName; // eslint-disable-line security/detect-object-injection
                }
            }
        }
        return "Unknown";
    }

    /**
     * Event handler for onNearestX.
     * @param {Object} value Selected value.
     * @param {index} index Index of the value in the data array.
     * @private
     */
    onNearestX = (value, { index }) => {

        // Only process the data for crosshair of the current crosshair index is different
        if (this.state.crosshairIndex !== index) {
            this.setState({ crosshairIndex: index });
        }
    };

    /**
     * Event handler for onMouseLeave.
     * @private
     */
    onMouseLeave = () => {
        // Comment out below to hide crosshair when mouse leaves plot
        this.setState({ crosshairIndex: null });
    };

    getDataTypeSelection = () => {
        const { t } = this.props;
        const options = [];
        for (var dataType in this.state.dataTypesAndSubTypes) {
            options.push({ text: t('dataTypes.' + dataType), value: dataType, key: "main-" + dataType });
        }
        return (
            <Dropdown
                className="ua-dropdown dark"
                value={this.state.selectedDataType}
                options={options}
                onChange={this.selectDataType}
            />);
    }

    getSubDataTypeSelection = () => {
        const { t } = this.props;
        if (this.state.selectedDataType) {

            const options = [];
            for (var dataTypeInd in this.state.dataTypesAndSubTypes[this.state.selectedDataType]) { // eslint-disable-line security/detect-object-injection
                var dataType = this.state.dataTypesAndSubTypes[this.state.selectedDataType][dataTypeInd]; // eslint-disable-line security/detect-object-injection
                options.push({ text: t('keyBuild.output.dataTypes.' + dataType), value: dataType, key: "sub-" + dataType });
            }

            return (
                <div className="chart-data-type-selection">
                    <div className="label">{t('keyBuild.output.chart.selectSubDataType')}</div>
                    <Dropdown
                        className="ua-dropdown dark"
                        value={this.state.selectedSubType}
                        options={options}
                        onChange={this.selectSubDataType}
                    />
                </div>
            )
        }
        return null;
    }

    formatTime = (v) => {
        return new Date(v).toLocaleTimeString('en-GB', {
            hour: "numeric",
            minute: "numeric"
        });
    }

    formatYValues = (v) => {
        return v.toExponential();
    }

    getCrossHair = () => {
        const { t } = this.props;
        if (this.state.crosshairIndex) {
            const index = this.state.crosshairIndex;
            if (this.state.selectedSubType === 'BOTH') {
                const indoorValues = {};
                const outdoorValues = {};
                // Render the crosshair to display a table of values for indoor and outdoor
                for (let subDataTypeInd in this.state.dataTypesAndSubTypes[this.state.selectedDataType]) { // eslint-disable-line security/detect-object-injection
                    let subDataType = this.state.dataTypesAndSubTypes[this.state.selectedDataType][subDataTypeInd]; // eslint-disable-line security/detect-object-injection
                    // subDataType is a safe string.
                    for (let ind in this.state.resultsMap[subDataType]) { // eslint-disable-line security/detect-object-injection
                        const result = this.state.resultsMap[subDataType][ind]; // eslint-disable-line security/detect-object-injection
                        if (subDataType.includes("INDOOR")) {
                            indoorValues[result[index].material] = (result[index]); // eslint-disable-line security/detect-object-injection
                        } else {
                            outdoorValues[result[index].material] = (result[index]); // eslint-disable-line security/detect-object-injection
                        }
                    }
                }

                let table = [];
                let time = null;
                var numSigFigs = 4
                for (let resultIndex in indoorValues) {
                    const indoorResult = indoorValues[resultIndex].y.toPrecision(numSigFigs); // eslint-disable-line security/detect-object-injection
                    const outdoorResult = outdoorValues[resultIndex].y.toPrecision(numSigFigs); // eslint-disable-line security/detect-object-injection
                    time = new Date(indoorValues[resultIndex].x).toLocaleTimeString('en-GB', { // eslint-disable-line security/detect-object-injection
                        hour: "numeric",
                        minute: "numeric", second: "numeric"
                    });
                    table.push(
                        <tr key={"crosshair-entry-" + this.state.selectedDataType + "-" + this.state.selectedSubType + "-" + resultIndex}>
                            <td>{resultIndex}</td>
                            <td>{indoorResult}</td>
                            <td>{outdoorResult}</td>
                        </tr>
                    )
                }

                return (
                    <Crosshair key={index} values={Object.values(indoorValues)}>
                        <div style={{ width: '130%' }} className="rv-crosshair__inner__content">
                            <div className="rv-crosshair__title">{time}</div>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>{t('keyBuild.output.chart.material')}</th>
                                        <th>{t('keyBuild.output.chart.indoor')}</th>
                                        <th>{t('keyBuild.output.chart.outdoor')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {table}
                                </tbody>
                            </table>
                        </div>
                    </Crosshair>
                )

            } else {
                const crossHairValues = [];
                if (this.state.selectedSubType === "__proto__") {
                    console.error("Unable to access object property");
                    return;
                }
                var mapOfDataType = this.state.resultsMap[this.state.selectedSubType]; // eslint-disable-line security/detect-object-injection
                for (let ind in mapOfDataType) {
                    if (mapOfDataType[ind].length >= index) { // eslint-disable-line security/detect-object-injection
                        crossHairValues.push(mapOfDataType[ind][index]); // eslint-disable-line security/detect-object-injection
                    }
                }

                return (
                    <Crosshair
                        key={index}
                        values={crossHairValues}
                        titleFormat={this.titleFormat}
                        itemsFormat={this.itemsFormat}
                        className={'test-class-name'}
                    />
                )
            }
        }
        return null;
    }

    itemsFormat = (items) => {
        return items.map(item => {
            return {
                'title': item.material,
                'value': item.y.toPrecision(4)
            }
        })
    }

    titleFormat = (title) => {
        var dateTime = new Date(title[0].x).toLocaleTimeString('en-GB', {
            hour: "numeric",
            minute: "numeric", second: "numeric"
        });
        return { title: "Time", value: dateTime }
    }

    intToRGB = (str) => {

        let hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        return `hsl(${hash % 360}, 100%, ${55 + (hash % 22)}%)`;
    }

    selectDataType = (e, { value }) => {
        // subTypeIndex finds the index of the currently selected sub type so that the corresponding sub type is chosen when selectedDataType changes. The below logic
        // is reliant on all selectedDataTypes having corresponding subTypes in identical orders.
        let subTypeIndex = this.state.dataTypesAndSubTypes[this.state.selectedDataType].indexOf(this.state.selectedSubType);
        this.setState({ selectedDataType: value, selectedSubType: this.state.dataTypesAndSubTypes[value][subTypeIndex] }); // eslint-disable-line security/detect-object-injection
    }

    selectSubDataType = (e, { value }) => {
        this.setState({ selectedSubType: value });
    }

    render() {
        const { t } = this.props;
        if (this.state.loading) {
            return (
                <div className="chart-div">
                    <Loader active inline='centered'>{t("keyBuild.loadingData")}...</Loader>
                </div>
            )
        } else if (this.state.resultsMap && this.state.selectedDataType) {
            const lineSeries = [];
            const legends = [];
            const legendTitleLegend = [];
            let dashStroke = 0;
            let minY = 0;
            let maxY = 0;
            for (let subDataTypeInd in this.state.dataTypesAndSubTypes[this.state.selectedDataType]) {
                // Does not access sensitive information.
                let subDataType = this.state.dataTypesAndSubTypes[this.state.selectedDataType][subDataTypeInd]; // eslint-disable-line security/detect-object-injection
                if (subDataType === "__proto__") {
                    console.error("Unable to access object property");
                    continue;
                }
                var mapOfDataType = this.state.resultsMap[subDataType]; // eslint-disable-line security/detect-object-injection
                if (!mapOfDataType || (this.state.selectedSubType !== "BOTH" && this.state.selectedSubType !== subDataType)) {
                    continue;
                }
                for (let ind in mapOfDataType) {
                    const result = mapOfDataType[ind]; // eslint-disable-line security/detect-object-injection
                    let onNearestXFunction = this.onNearestX;
                    if (lineSeries.length > 0) {
                        onNearestXFunction = null;
                    }
                    lineSeries.push(
                        <LineSeries
                            key={"line-series-" + this.state.selectedDataType + "-" + subDataType + "-" + ind}
                            data={result}
                            color={this.intToRGB(ind)}
                            strokeDasharray={dashStroke}
                            onNearestX={onNearestXFunction}
                        />
                    )
                    // Update min and max y values
                    let yValues = result.map(data => data.y)
                    minY = Math.min(minY, ...yValues);
                    maxY = Math.max(maxY, ...yValues);
                }
                legendTitleLegend.push({
                    title: subDataType.includes("INDOOR") ? t("keyBuild.output.chart.indoor") : t("keyBuild.output.chart.outdoor"),
                    color: "white",
                    strokeDasharray: "" + dashStroke
                });

                // The materials are going to be the same colour between Indoor and Outdoor data types, so only add them once
                // The indication to full/dashed line is added separately to the legend indicating the data type
                if (legends.length === 0) {
                    legends.push(
                        <DiscreteColorLegend
                            key={"legend-" + this.state.selectedDataType + "-" + subDataType}
                            className="legend-title"
                            style={{ title: { fill: "white" } }}
                            items={
                                Object.keys(mapOfDataType).sort().map((materialName, index) => {
                                    return { title: materialName, strokeDasharray: "" + dashStroke, color: this.intToRGB(materialName) }
                                })
                            }
                        />
                    )
                }
                dashStroke = + 2;

            }
            // When all y values == 0 then react-vis defaults the y axis to [-1,0]. This covers the line so the following axis limits are chosen:
            if (minY === maxY && minY === 0) {
                minY = -0.1;
                maxY = 1.0;
            }
            // When all y values are the same but non zero, react-vis defaults the axis limits to [-y, y]. The following two if statements ensure that axis limits are either
            // [0, y] or [-y, 0] depending on if y is postive or negative
            else if (minY === maxY && minY < 0) {
                maxY = 0;
                minY = minY - 0.1 * minY;
            }
            else if (minY === maxY && minY > 0) {
                minY = 0;
                maxY = maxY + 0.1 * maxY
            }
            // Folowwing limits ensure that the y axis is always slightly larger than the line so that the axes never cover up the line
            else {
                minY = minY - 0.1 * minY;
                maxY = maxY + 0.1 * maxY
            }
            if (lineSeries.length === 0) {
                return null;
            }
            if (legendTitleLegend.length > 1) {
                legends.unshift(
                    <DiscreteColorLegend
                        key="legend-data-type-titles"
                        className="legend-title"
                        style={{ title: { fill: "white" } }}
                        items={legendTitleLegend}
                    />
                )
            }
            return (
                <div className="chart-div">
                    <div className="chart-selection">
                        <div className="chart-data-type-selection">
                            <div className="label">{t('keyBuild.output.chart.selectDataType')}</div>
                            {this.getDataTypeSelection()}
                        </div>
                        {this.getSubDataTypeSelection()}
                    </div>
                    <div className="plot-with-legend">
                        <XYPlot
                            key={this.state.selectedDataType + "-" + this.state.selectedSubType}
                            width={500}
                            height={260}
                            margin={{ left: 50, top: 20 }}
                            onMouseLeave={this.onMouseLeave}
                            yDomain={[minY, maxY]}
                        >
                            <HorizontalGridLines />
                            <VerticalGridLines />
                            <XAxis
                                tickFormat={this.formatTime}
                                tickLabelAngle={-45}
                                title={t('keyBuild.output.chart.time')}
                                style={
                                    {
                                        title: { top: 20, fill: "white" },
                                        ticks: { fill: "white" }
                                    }
                                }
                            />
                            <ChartLabel
                                text={t('keyBuild.output.chart.title.' + this.state.selectedDataType)}
                                className="label"
                                includeMargin={false}
                                xPercent={-0.08}
                                yPercent={0.08}
                                style={{ fill: "white" }}
                            />
                            <YAxis
                                tickFormat={this.formatYValues}
                                tickLabelAngle={-45}
                                style={
                                    { ticks: { fill: "white" } }
                                }
                            />
                            {this.getCrossHair()}
                            {lineSeries}
                        </XYPlot>

                        {legends}

                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = (store) => {
    return {
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        incidentState: store.incidentState,
    }
}

export default compose(withTranslation(), connect(mapStateToProps))(KeyBuildingChart);