import React from 'react';
import { withTranslation } from 'react-i18next';
import { Marker } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Actions as PopupActions } from "../../../services/popups/actions.jsx";
import { AmberDetectorIcon } from './AmberDetectorIcon';
import { GreenDetectorIcon } from './GreenDetectorIcon';
import { GreyDetectorIcon } from './GreyDetectorIcon';
import { RedDetectorIcon } from './RedDetectorIcon';

class DetectorMarker extends React.Component {

    state = {
        icon: GreyDetectorIcon,
        location: null
    }

    constructor(props) {
        super(props);

        let currentReading = this.getCurrentReading();
        let coordinates = this.getCoordinatesFromReading(currentReading);
        let icon = this.getIcon(currentReading);
        let status = this.getStatus()
        this.state = {
            location: coordinates,
            icon: icon,
            concentrationReading: currentReading.concentrationReading,
            status: status
        };
    }

    componentDidUpdate(prevProps) {
        const currentTime = this.props.currentTime;
        const prevTime = prevProps.currentTime;
        const fromNullToSelection = prevTime === null && currentTime !== null;
        const fromSelectionToNull = prevTime !== null && currentTime === null;

        // Comparing the length doesn't really solve the issue that we just need to see if there are different sensors in the list
        let newReadings = this.props.sensor.readings;
        let oldReadings = prevProps.sensor.readings;

        if (fromNullToSelection || fromSelectionToNull || (currentTime !== prevTime) || newReadings !== oldReadings) {
            let currentReading = this.getCurrentReading();
            let icon = this.getIcon(currentReading);
            let currentStatus = this.getStatus();
            let coordinates = this.getCoordinatesFromReading(currentReading);
            this.setState({ icon: icon, location: coordinates, concentrationReading: currentReading.concentrationReading, status: currentStatus });
        }
    }

    getCoordinatesFromReading(currentReading) {
        const geoJson = JSON.parse(currentReading.location);
        let coordinates = [geoJson.coordinates[1], geoJson.coordinates[0]];
        return coordinates;
    }

    getSelectedTime = (readings) => {
        
        let times = readings.map(r => { return new Date(r.time).getTime() });
        let sortedTimes = times.sort((a, b) => { return b - a });

        let currentTime = this.props.currentTime;

        // Default to most recent 
        let selectedTime = sortedTimes[0];

        // If there is a selected simulation then check the time slider
        const selectedSimulation = this.props.simulationState.selectedSimulation;
        if (currentTime && selectedSimulation && (selectedSimulation.status === "FINISHED")) {
            // Get the nearest time to that shown on the slider
            let margin = Number.MAX_VALUE;
            for (let t in sortedTimes) {
                let time = sortedTimes[t];
                if (Math.abs(time - currentTime) < margin) {
                    margin = Math.abs(time - currentTime);
                    selectedTime = time;
                }
            }
        }

        return selectedTime;
    }

    getCurrentReading = () => {
        // Sort latest first
        let readings = this.props.sensor.readings;

        let selectedTime = this.getSelectedTime(readings);

        // Return the reading at this time with the highest value
        let readingsAtSelectedTime = readings.filter(x => { return new Date(x.time).getTime() == selectedTime });
        return readingsAtSelectedTime.reduce((max, reading) => max.concentrationReading > reading.concentrationReading ? max : reading);
    }

    getStatus = () => {
        // Sort latest first
        let readings = this.props.sensor.readings;

        let selectedTime = this.getSelectedTime(readings);

        // Return the reading at this time with the highest value
        let readingsAtSelectedTime = readings.filter(x => { return new Date(x.time).getTime() == selectedTime });

        let statusesAtTime = readingsAtSelectedTime.map(reading => reading.status)

        if (statusesAtTime.includes('ERROR')) {
            return 'ERROR';
        }
        if (statusesAtTime.includes('ALERT')) {
            return 'ALERT';
        }
        if (statusesAtTime.includes('OK')) {
            return 'OK';
        }
        return 'UNKNOWN';
    }
    
    getIcon = (currentReading) => {
        let icon = GreyDetectorIcon;
        if (currentReading) {
            switch (currentReading.status) {
                case 'OK': icon = GreenDetectorIcon; // OK
                        break;
                case 'ERROR': icon = AmberDetectorIcon; // Alert
                        break;
                case 'ALERT': icon = RedDetectorIcon; // Down
                        break;
                case 'DISCONNECTED':
                default: icon = GreyDetectorIcon; // Unknown
                        break;
            }
        } else {
            icon = GreyDetectorIcon;
        }
        return icon;
    }

    onClick = () => {
        this.props.showSensorsPopup({ sensor: this.props.sensor, sensorErrors: this.props.sensorErrors, location: this.state.location, concentration: this.state.concentrationReading, status: this.state.status });
    }

    render() {
        return (
            <Marker
                onClick={this.onClick}
                key={this.props.key}
                position={this.state.location}
                icon={this.state.icon}
            >
            </Marker>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        showSensorsPopup: (data) => {
            dispatch(PopupActions.showSensorsPopup(data));
        }
    }
}

const mapStateToProps = (store) => {
    return {
        simulationState: store.simulationState
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DetectorMarker);