/*
./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Message, Select } from "semantic-ui-react";
import { Actions } from "../../../../services/incident/actions.jsx";
import { IncidentServices } from "../../../../services/incident/incidentServices.jsx";
import { DataUtil } from '../../../common/DataUtil';
import { IncidentsUtils } from "../../../utils/IncidentsUtils.jsx";
import FormFieldDate from "../../../common/formFieldDate";
import InputFieldWithInteger from "../../../common/InputFieldWithInteger.jsx";
import InputFieldWithUnits from "../../../common/inputFieldWithUnits.jsx";
import ShapeCreateEdit from "../shapeCreateEditor.jsx";
import "../sources.scss";
import { FileDefinedSourceUtil } from "./fileDefinedSourceUtil.jsx";

class ComplexSourcePropertiesCard extends React.Component {
    state = {
        name: "",
        materialName: "",
    };

    constructor(props) {
        super(props);
        var incident = props.incidentState.editingIncident

        // When these come in from file, we need to sanitise numbers
        this.state = {
            id: incident.id,
            name: incident.name,
            sources: incident.sources,
            geometry: incident.geometry,
            geoJson: incident.geoJson,
            coordinateTextInvalid: false,
            startTime: incident.startTime ? incident.startTime : new Date().toISOString(),
            incidentType: incident.incidentType,
            releaseHeight: incident.releaseHeight
                ? parseFloat(incident.releaseHeight)
                : incident.geometry.coordinates[2],
            editableParameters: incident.editableParameters,
            fieldErrors: [],
            saveError: null,
            loading: false,
            materialName: incident.materialName,
            materialType: incident.materialType,
            agentType: incident.agentType,
            materialId: incident.materialId,
            materials: [],
            // This is a list of DB material IDs
            availableMaterials: incident.availableMaterials ? incident.availableMaterials : [],
            complexIncidentDataType: incident.complexIncidentDataType ? incident.complexIncidentDataType : "DispersiveComplexIncidentData"
        };
    }

    componentDidMount() {
        // Cop the array so we do not mutate the state directly
        let availableMaterialNames = [...this.state.availableMaterials];
        // If there are available materials, get them by ID
        if (availableMaterialNames != undefined && availableMaterialNames.length > 0) {
            IncidentServices.fetchMaterials({ availableMaterials: availableMaterialNames }).then(
                (materials) => {
                    this.initialiseMaterialState(materials, availableMaterialNames);
                }
            );
        } // Otherwise get using filter info
        else {
            // Check the material filter is present
            if (this.state.agentType && this.state.materialType) {
                IncidentServices.fetchMaterials({ agentType: this.state.agentType, materialType: this.state.materialType }).then(
                    (materials) => {
                        materials.forEach(m => availableMaterialNames.push(m.name))
                        this.initialiseMaterialState(materials, availableMaterialNames);
                    }
                );
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { t } = this.props;
        this.validateFields(prevState);
        // If the component is in loading state - check the progress of the source update/insertion
        if (this.state.loading) {
            // if the incidentState has changed its loading property from true to false - check for errors
            // No need to check for success as on success the panel will change back to main panel
            if (prevProps.incidentState.loading && !this.props.incidentState.loading) {
                if (this.props.incidentState.error) {
                    this.setState({ loading: false, saveError: t("source.messages.failedToAddUpdate") })
                }
            }
        }
    }

    validateFields = (prevState) => {
        let { t } = this.props;
        let fieldErrors = {};
        if (prevState.name != this.state.name) {
            fieldErrors['name'] = this.getValidMessage(this.state.name, () => { return true }, "");
        }
        if (prevState.releaseHeight !== this.state.releaseHeight) {
            fieldErrors['releaseHeight'] = this.getValidMessage(this.state.releaseHeight, this.isNumberGreaterOrEqZero, t("source.messages.releaseHeight"));
        }
        if (this.state.editableParameters) {
            for (let p in this.state.editableParameters) {
                let newParam = this.state.editableParameters[p]; // eslint-disable-line security/detect-object-injection
                let oldParam = prevState.editableParameters[p]; // eslint-disable-line security/detect-object-injection
                if (newParam !== oldParam) {
                    if (newParam.editableParameterType === "DROPDOWN") {
                        fieldErrors[newParam.label] = newParam.value ? false : t("source.messages.emptyError");
                    } else {
                        // Assume integer input field
                        fieldErrors[newParam.label] = this.getValidMessage(
                            newParam.value,
                            this.isNumberGreaterThanZero,
                            t("source.messages.customField")
                        )
                    }
                }
            }
        }

        if (Object.keys(fieldErrors).length) {
            fieldErrors = Object.assign({}, this.state.fieldErrors, fieldErrors);
            this.setState({ fieldErrors: fieldErrors });
        }
    }

    getValidMessage = (field, valid, message) => {
        let emptyMessage = this.getEmptyMessage(field)
        if (emptyMessage) {
            return emptyMessage;
        }
        if (!valid(field)) {
            return message
        } else {
            return false
        }
    }

    getEmptyMessage = (field) => {
        let { t } = this.props;
        let nonwhitespace = /[^\s]/;
        let valid = field != undefined && field.toString().match(nonwhitespace);
        return !valid ? t("source.messages.emptyError") : "";
    }

    isNumberGreaterThanZero = (value) => {
        return value > 0 && !isNaN(value)
    };

    isNumberGreaterOrEqZero = (value) => {
        return value >= 0 && !isNaN(value)
    };

    initialiseMaterialState(materialList, availableMaterialNames) {
        // Select first in list if none already selected
        if (this.state.materialId === undefined && materialList.length > 0) {
            this.setState({
                materialId: materialList[0].id,
                materialName: materialList[0].name,
                agentType: materialList[0].agentType,
                materialType: materialList[0].materialType
            });
        } else {
            materialList.forEach(m => {
                if (m.id == this.state.materialId) {
                    this.setState({
                        materialName: m.name,
                        agentType: m.agentType,
                        materialType: m.materialType
                    });
                }
            })
        }
        this.setState({
            materials: materialList,
            availableMaterials: availableMaterialNames
        });
    }

    finishIncident = (e) => {
        this.setState({ loading: true });
        let incident = this.state
        let geoJson = DataUtil.addHeightToGeoJson(
            incident.geoJson,
            incident.releaseHeight
        );
        incident = Object.assign({}, incident, {
            geometry: JSON.stringify(geoJson.geometry),
            geoJson: geoJson,
            requiredModels: this.props.incidentState.editingIncident.requiredModels
        })

        // Generate tear gas sources the same way.
        if (incident.incidentType === "Tear Gas Canister") {
            FileDefinedSourceUtil.generateSources(incident)
        }

        this.props.finishComplexIncident(
            incident,
            this.props.simulationState.selectedSimulation.id,
            this.props.scenarioState.scenario.id
        );
    };

    validate = () => {
        if (!this.state.name) {
            return false;
        }
        if (!this.state.startTime) {
            return false;
        }
        if (!this.state.geoJson) {
            return false;
        }
        if (isNaN(this.state.releaseHeight) || this.state.releaseHeight < 0) {
            return false;
        }
        if (this.state.coordinateTextInvalid) {
          return false;
        }
        if (
            !Array.from(Object.values(this.state.fieldErrors)).every(
                (v) => v === false
            )
        ) {
            return false;
        }

        return true;
    };

    setMaterial = (e, data) => {
        this.setState({
            materialId: data.value,
            materialName: data.options.find(option => option.value === data.value).text,
        });
    };

    renderMaterial() {
        const { t } = this.props;
        if (this.state.complexIncidentDataType === "NonDispersiveComplexIncidentData") {
            return null;
        }
        if (this.state.materials.length === 1) {
            return (<Form.Group >
                <Form.Field>
                    <Form.Input
                        inline
                        label={t('sidebar.material')}
                        value={this.state.materialName} />
                </Form.Field></Form.Group>)
        }
        else {
            return (<Form.Group >
                <Form.Field
                    required={true}
                    label={t("sidebar.material")}
                    inline
                    search={IncidentsUtils.searchThroughMaterials}
                    selected
                    control={Select}
                    options={IncidentsUtils.createMaterialsDropdownOptions(this.state.materials)}
                    placeholder={t("sidebar.selectMaterial")}
                    value={this.state.materialId}
                    onChange={(e, data) => this.setMaterial(e, data)}
                    error={this.state.fieldErrors["material"]}
                />
            </Form.Group>)

        }

    }

    setEditableParameter = (v, p) => {
        var editableParams = JSON.parse(JSON.stringify(this.state.editableParameters));
        if (p === "__proto__") {
            console.error(t("messages.unableToAccessProperty"));
            return;
        }
        editableParams[p].value = v // eslint-disable-line security/detect-object-injection
        this.setState({ editableParameters: editableParams })
    }

    renderEditableParams() {
        let paramInputs = [];
        for (let p in this.state.editableParameters) {
            let param = this.state.editableParameters[p]; // eslint-disable-line security/detect-object-injection
            if (param.editableParameterType == "DROPDOWN") {
                paramInputs.push(
                    <Form.Group key={p + "custominput"}>
                        <Form.Select
                            inline
                            label={param.label}
                            required={true}
                            value={param.value}
                            options={param.options}
                            onChange={(event, data) => this.setEditableParameter(data.value, p)}
                        />
                    </Form.Group>
                )
            } else if (param.label === "Number of canisters") {
                paramInputs.push(
                    <Form.Group key={p + "custominput"}>
                        <InputFieldWithInteger
                            inline required={true}
                            label={param.label}
                            value={param.value}
                            setValue={(v) => this.setEditableParameter(v, p)}
                            disabled={false}
                            error={this.state.fieldErrors[param.label]}
                        />
                    </Form.Group>
                )
            } else {
                paramInputs.push(
                    <Form.Group key={p + "custominput"}>
                        <InputFieldWithUnits
                            inline required={true}
                            label={param.label}
                            unit={param.unit}
                            value={param.value}
                            type={param.type}
                            setValue={(v) => this.setEditableParameter(v, p)}
                            disabled={false}
                            error={this.state.fieldErrors[param.label]}
                        />
                    </Form.Group>
                )
            }

        }
        return paramInputs
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Form className="ua-form content-body unset-overflow">
                    <Form.Group>
                        <Form.Field>
                            <Form.Input
                                inline required={true}
                                label={t('sidebar.name')}
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                error={this.state.fieldErrors['name']}
                            />
                        </Form.Field>
                    </Form.Group>

                    {this.renderMaterial()}

                    <Form.Group>
                        <FormFieldDate
                            t={t}
                            dateFormat={this.props.userState.user.preferences.dateFormat}
                            label={t('incident.date')}
                            required={true}
                            initialValue={new Date(this.state.startTime)}
                            onChange={(value) => {
                                this.setState({ startTime: value && value.toISOString() })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="location-field">
                        <Form.Field required={true}>
                            <label>{t('incident.location')}</label>
                            <ShapeCreateEdit
                                className="ui input"
                                shape="MARKER"
                                setGeoJson={(geoJ) => this.setState({ geoJson: geoJ })}
                                geoJson={this.state.geoJson}
                                onCoordinatesInputErrorStateChange={(value) =>
                                  this.setState({ coordinateTextInvalid: value })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group>
                        <InputFieldWithUnits
                            inline required={true}
                            label={t("incident.releaseHeight")}
                            unit="m"
                            value={this.state.releaseHeight}
                            type="SIZE"
                            setValue={(v) => this.setState({ releaseHeight: v })}
                            disabled={false}
                            error={this.state.fieldErrors['releaseHeight']}
                        />
                    </Form.Group>

                    {this.renderEditableParams()}

                    {this.state.saveError ? (
                        <Message negative className="ua-error">
                            <Message.Header>{t("app.error")}</Message.Header>
                            <p>{this.state.saveError}</p>
                        </Message>
                    ) : null}

                </Form>

                <div className="bottom-bar">
                    <div className="finished">
                        <Button
                            type="button"
                            disabled={!this.validate() || this.state.loading}
                            onClick={this.finishIncident}
                            loading={this.state.loading}
                        >
                            {t("sidebar.finished")}
                        </Button>
                    </div>
                </div>

            </>
        );
    };
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        simulationState: store.simulationState,
        incidentState: store.incidentState,
        scenarioState: store.scenarioState,
        userState: store.userState,
        ...props,
    };
};

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        finishComplexIncident: (incident, simulationId, scenarioId) => {
            dispatch(Actions.finishComplexIncident(incident, simulationId, scenarioId));
        }
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(ComplexSourcePropertiesCard);
