/*
    ./client/components/App.jsx
*/
import L from 'leaflet';
import React from "react";
import { withTranslation } from "react-i18next";
import { FeatureGroup, GeoJSON, LayersControl, Pane } from 'react-leaflet';
import { connect } from "react-redux";
import { compose } from "redux";
import { Actions as MapActions } from "../../../services/map/actions.jsx";
import { Actions as PopupActions } from "../../../services/popups/actions.jsx";
import { KeyBuildStyles } from '../../common/KeyBuildStyles.jsx';
import { MapLayerStyles } from '../../common/MapLayerStyles.jsx';

const { BaseLayer, Overlay } = LayersControl

class KeyBuildingLayer extends Overlay {

    getKeyBuildings = () => {

        var markers = [];

        if (!this.props.simulationState.selectedSimulation) {
            return markers;
        }

        // filter out buildings for selected simulation only
        var simulationId = this.props.simulationState.selectedSimulation.id;
        var builds = [];
        Object.values(this.props.keyBuildState.keyBuilds).forEach(m => {
            if (m.simulationId === simulationId) {
                builds.push(m);
            }
        });

        var editing = this.props.keyBuildState.editing;
        var hiddenIds = this.props.mapFilterState.hiddenLayerIds;
        for (var m in builds) {
            var building = builds[m]; // eslint-disable-line security/detect-object-injection
            let buildingId = building.id;
            if (editing && buildingId === editing.id || hiddenIds.includes(buildingId)) {
                continue;
            }

            let showKeyBuildingPopup = this.props.showKeyBuildingPopup;
            let selectLocation = this.props.selectLocation;

            if (building.geoJson.geometry) {
                markers.push(
                    <GeoJSON id={"key-build-layer-" + buildingId}
                        key={"building-" + buildingId + "-" + building.lastUpdated}
                        data={building.geoJson.geometry}
                        onEachFeature={(feature, layer) => {
                            //bind click
                            layer.on('click', function (e) {
                                var location = [e.latlng.lat, e.latlng.lng];
                                var position = e.containerPoint;
                                selectLocation({ location, position });
                                showKeyBuildingPopup(buildingId);
                            })
                        }
                        }
                        style={KeyBuildStyles.getStyle()}
                    />
                );
                var leafLayer = new L.GeoJSON(building.geoJson.geometry);
                var center = leafLayer.getBounds().getCenter();

                var markerLayer = KeyBuildStyles.getMarker(
                    building, center, selectLocation, showKeyBuildingPopup, buildingId
                );
                markers.push(markerLayer);
            }

            if (editing && editing.geoJson) {
                markers.push(
                    <GeoJSON key={"building-" + editing.id + "EDIT" + editing.lastUpdated}
                        data={editing.geoJson}
                        style={MapLayerStyles.getLightEditingStyle()}
                    />
                );
            }
        }

        return markers;
    }

    render() {
        return (
            <FeatureGroup>
                <Pane name="keyBuildingPane" style={{ zIndex: 201 }}>
                    {this.getKeyBuildings()}
                </Pane>
            </FeatureGroup>
        );
    }

    componentWillUnmount() {
        console.log("key build use will unmount")
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        simulationState: store.simulationState,
        keyBuildState: store.keyBuildState,
        mapFilterState: store.mapFilterState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        selectLocation: (location) => {
            dispatch(MapActions.selectLocation(location));
        },
        showKeyBuildingPopup: (id) => {
            dispatch(PopupActions.showKeyBuildingPopup(id));
        }
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(KeyBuildingLayer);
