
/*
* action types
*/
export const SET_USER = 'SET_USER';
export const SET_VERIFIED = 'SET_VERIFIED';
export const UPDATE_USER_PREFERENCE = 'UPDATE_USER_PREFERENCE';

/**
 * Creates actions for user 
 */
export class Actions {

    /*
     * action to set the user
     */
    static setUser(user) {
        return {
            type: SET_USER,
            payload: user
        }
    }

    /**
     * Update user preference
     * @param {UserPreference} userPreference
     */
    static updateUserPreference(userPreference) {
        return {
            type: UPDATE_USER_PREFERENCE,
            payload: userPreference
        }
    }

    /*
     * action to set the user
     */
    static setVerified(verified) {
        return {
            type: SET_VERIFIED,
            payload: verified
        }
    }
}