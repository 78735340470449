import Requests from '../requests';

/*
* action types
*/
export const UPDATE_KEY_BUILD = 'UPDATE_KEY_BUILD';
export const ADD_KEY_BUILD_SUCCESS = 'ADD_KEY_BUILD_SUCCESS';
export const ADD_KEY_BUILD_BEGIN = 'ADD_KEY_BUILD_BEGIN';
export const ADD_KEY_BUILD_FAILURE = 'ADD_KEY_BUILD_FAILURE';
export const UPDATE_KEY_BUILD_SUCCESS = 'UPDATE_KEY_BUILD_SUCCESS';
export const UPDATE_KEY_BUILD_BEGIN = 'UPDATE_KEY_BUILD_BEGIN';
export const UPDATE_KEY_BUILD_FAILURE = 'UPDATE_KEY_BUILD_FAILURE';
export const CREATE_KEY_BUILD = 'CREATE_KEY_BUILD';
export const EDIT_KEY_BUILD = 'EDIT_KEY_BUILD';

export const CANCEL_EDIT = 'CANCEL_EDIT';

export const DELETE_KEY_BUILD_BEGIN = 'DELETE_KEY_BUILD_BEGIN';
export const DELETE_KEY_BUILD_SUCCESS = 'DELETE_KEY_BUILD_SUCCESS';
export const DELETE_KEY_BUILD_FAILURE = 'DELETE_KEY_BUILD_FAILURE';

export const FETCH_KEY_BEGIN = 'FETCH_KEY_B_BEGIN';
export const FETCH_KEY_SUCCESS = 'FETCH_KEY_B_SUCCESS';
export const FETCH_KEY_FAILURE = 'FETCH_KEY_B_FAILURE';

export const CLEAR = 'CLEAR_KEY_BUILD';

export class Actions {

	static clear() {
		return {
			type: CLEAR
		}
	}

	/*
	 * action creators
	 */
	static cancelEdit() {
		return {
			type: CANCEL_EDIT
		}
	}

	/*
	 * action creator to start fetching
	 */
	static fetchBegin = () => ({
		type: FETCH_KEY_BEGIN
	});

	/*
	 * action creator to indicate successful fetch
	 */
	static fetchSuccess = keyBuildings => ({
		type: FETCH_KEY_SUCCESS,
		payload: { keyBuildings }
	});

	/*
	 * action creator to indicate fetch failed
	 */
	static fetchFailure = error => ({
		type: FETCH_KEY_FAILURE,
		payload: { error }
	});

	/*
	 * action creator for populating table
	 */
	static fetchKeyBuildings(scenarioId) {

		return dispatch => {
			dispatch(this.fetchBegin());
			return Requests.get("simulation/features/keyBuildings/scenario/" + scenarioId)
				.then(json => {
					// Loop through buildings and parse geometry string to json
					for (let i = 0; i < json.length; i++) {
						var keyBuilding = json[i]; // eslint-disable-line security/detect-object-injection
						if (keyBuilding.geometry) {
							keyBuilding.geoJson = { geometry: keyBuilding.geometry, properties: {}, type: "Feature" };
						}
					}
					dispatch(this.fetchSuccess(json));
					return json;
				})
				.catch(error => {
					dispatch(this.fetchFailure(error))
				});
		}
	}

	/*
	 * action creators
	 */
	static updateKeyBuild(keyBuild) {
		// Update timestamp on the keyBuild
		let d = new Date();
		keyBuild.lastUpdated = d.getTime();
		return {
			type: UPDATE_KEY_BUILD,
			payload: keyBuild
		}
	}

	/*
	 * action creators
	 */
	static editKeyBuild(keyBuildId) {
		return {
			type: EDIT_KEY_BUILD,
			payload: keyBuildId
		}
	}

	/*
	* action creator to delete met
	*/
	static deleteKeyBuilding(keyBuildId, simulationId, scenarioId) {

		return dispatch => {
			dispatch(this.deleteBegin());
			return Requests.deleteRequest(`/simulation/features/keyBuildings/${keyBuildId}/simulation/${simulationId}/scenario/${scenarioId}`)
				.then(() => {
					dispatch(this.onDeleteSuccess(keyBuildId));
				})
				.catch(error => {
					console.error(error);
					dispatch(this.onDeleteFailure(error));
				});
		};

	}

	static deleteBegin = () => ({
		type: DELETE_KEY_BUILD_BEGIN
	});

	static onDeleteSuccess = keyBuildId => {
		return {
			type: DELETE_KEY_BUILD_SUCCESS,
			payload: keyBuildId
		}
	}

	static onDeleteFailure = error => ({
		type: DELETE_KEY_BUILD_FAILURE,
		payload: { error }
	});

	/*
	 * action creators
	 */
	static createKeyBuild() {

		// Create a default keyBuild
		let d = new Date();

		// Create a barebones keyBuild object with a random ID
		var keyBuild = {
			type: "KEY_BUILD",
			keyBuildType: null,
			geoJson: null,
			name: "Key Building",
			height: 4,
			notes: "",
			lastUpdated: d.getTime()
		}

		return {
			type: CREATE_KEY_BUILD,
			payload: keyBuild
		}
	}

	/**
	 * Returns a random integer between min (inclusive) and max (inclusive).
	 * The value is no lower than min (or the next integer greater than min
	 * if min isn't an integer) and no greater than max (or the next integer
	 * lower than max if max isn't an integer).
	 * Using Math.round() will give you a non-uniform distribution!
	 */
	static getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/*
	* Call api to save building to DB.
	*/
	static saveKeyBuilding(simulationId, scenarioId, keyBuildWithBvimConfig) {

		return dispatch => {
			dispatch(this.addKeyBuildBegin());
			return Requests.post(`/simulation/features/keyBuildings/simulation/${simulationId}/scenario/${scenarioId}`, keyBuildWithBvimConfig)
				.then(json => {
					let building = json[0];
					building.geoJson = keyBuildWithBvimConfig.keyBuilding.geoJson;
					building.simulationId = simulationId;
					dispatch(this.addKeyBuildSuccess(building));
					return building;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.addKeyBuildFailure());
				});
		}
	}

	/*
	* Call api to update key building.
	*/
	static updateKeyBuilding(simulationId, scenarioId, keyBuildWithBvimConfig) {

		return dispatch => {
			dispatch(this.updateKeyBuildBegin());
			return Requests.put(`/simulation/features/keyBuildings/simulation/${simulationId}/scenario/${scenarioId}`, keyBuildWithBvimConfig)
				.then(json => {
					let building = json[0];
					building.geoJson = keyBuildWithBvimConfig.keyBuilding.geoJson;
					building.simulationId = simulationId;
					dispatch(this.updateKeyBuildSuccess(building));
					return building;
				})
				.catch(error => {
					console.error(error);
					dispatch(this.updateKeyBuildFailure());
				});
		}
	}

	static addKeyBuildBegin() {
		return {
			type: ADD_KEY_BUILD_BEGIN
		}
	}

	static addKeyBuildFailure() {
		return {
			type: ADD_KEY_BUILD_FAILURE
		}
	}

	static updateKeyBuildBegin() {
		return {
			type: UPDATE_KEY_BUILD_BEGIN
		}
	}

	static updateKeyBuildFailure() {
		return {
			type: UPDATE_KEY_BUILD_FAILURE
		}
	}

	/*
	 * action creators
	 */
	static addKeyBuildSuccess(keyBuild) {
		// Update timestamp on the keyBuild
		let d = new Date();
		keyBuild.lastUpdated = d.getTime();

		return {
			type: ADD_KEY_BUILD_SUCCESS,
			payload: keyBuild
		}
	}

	static updateKeyBuildSuccess(keyBuild) {
		// Update timestamp on the keyBuild
		let d = new Date();
		keyBuild.lastUpdated = d.getTime();

		return {
			type: UPDATE_KEY_BUILD_SUCCESS,
			payload: keyBuild
		}
	}
}