/*
    ./client/components/App.jsx
*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

class PanelWrapper extends React.Component {

    state = {
        mounted: false
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ mounted: true }), 10);
    }

    cancel = () => {
        setTimeout(() => this.setState({ mounted: false }), 10);
        setTimeout(() => this.props.cancel(), 400);
    }

    render() {
        var { t } = this.props;

        var mountedStyling = "";
        if (this.state.mounted) {
            mountedStyling = "mounted";
        }
        return (
            <div className={"panel-wrapper " + mountedStyling}>
                <div className="panel-wrapper-head">
                    <div className="panel-wrapper-header">
                        <div className="title">{this.props.title}</div>
                        <Icon name="close" onClick={this.cancel} />
                        {this.props.back ? (
                            <Icon name="angle left" onClick={this.props.back} />
                            ) : null
                        }
                    </div>
                </div>
                <div className={"panel-wrapper-content " + mountedStyling}>
                    {this.state.mounted ? this.props.children : null}
                </div>
            </div>
        )
    }
}

export default withTranslation()(PanelWrapper);