import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Dimmer, Loader, Message } from "semantic-ui-react";
import { Actions, PERSIST_SCENARIO_FAILURE, PERSIST_SCENARIO_SUCCESS } from "../../services/scenario/actions.jsx";
import "./appHeader.scss";
import ArchivedScenarios from "./archivedScenarios.jsx";
import SelectScenario from "./selectScenario.jsx";

class CreateOrLoadScenario extends React.Component {
  /**
   * Panel which shows allows the user to create a new
   * scenario or to view historic scenarios.
   */
  state = {
    /**
     * State items which keep track of which panel is expanded
     */
    expandedPanel: null,
    loading: false,
    error: null
  };

  componentDidUpdate(prevProps) {
    const { t } = this.props;
    // If the scenario creation finished loading - check the status of it and
    // proceed accordingly
    if (!this.props.scenarioState.creatingScenario && prevProps.scenarioState.creatingScenario) {
      if (this.props.scenarioState.actionType === PERSIST_SCENARIO_SUCCESS) {
        this.props.onClick();
      }
      else if (this.props.scenarioState.actionType === PERSIST_SCENARIO_FAILURE) {
        this.setState({ loading: false, error: t("scenario.messages.failedToCreateScenario") });
      }
    }
  }

  fetchScenarios = () => {
    this.props.fetchScenarios(this.props.userState.user.organisation.id);
  }

  create = () => {
    const { t } = this.props;

    var scenario = {
      name: t("scenario.scenarioName"),
      notes: "",
      userId: this.props.userState.user.id,
      organisationId: this.props.userState.user.organisation.id
    };

    // Begin loading state on the component, and clear any previous error
    this.setState({ loading: true, error: null });

    // Request new scenario creation
    this.props.createScenario(scenario);
  };

  showLiveScenarios = () => {
    const { t } = this.props;
    /**
     * Render method which holds the logic rendering the dropdown
     * menu for the live scenarios.
     */
    if (this.state.expandedPanel === "LIVE") {
      let tempLiveList = [];

      let scenarios = tempLiveList.map((scenario) => {
        return <button className="scenario-button">{scenario.name}</button>;
      });

      return <div className="live-scenarios">{t("scenario.noData")}</div>;
    }
  };

  showHistoricScenarios = () => {
    const { t } = this.props;
    /**
     * Render method which holds the logic rendering the dropdown
     * menu for the historic scenarios.
     */
    if (this.state.expandedPanel === "HIST") {
      let tempHistoricList = [];

      let scenarios = tempHistoricList.map((scenario) => {
        return (
          <button>
            <h5>{scenario.name}</h5>
          </button>
        );
      });

      return <div className="live-scenarios">{t("scenario.noData")}</div>;
    }
  };

  onSelect = () => {
    this.props.onClick();
  };

  showAnalysisScenarios = () => {
    /**
     * Render method which holds the logic rendering the dropdown
     * menu for the historic scenarios.
     */
    if (this.state.expandedPanel === "ANALYSIS") {
      return <SelectScenario closeScenarioLoader={this.onSelect} />;
    }
  };

  /**
   * Expands selected panel, or collapses if null is passed
   * @param {String} panelName
   */
  expandPanel = (panelName) => {
    this.setState({
      expandedPanel: this.state.expandedPanel === panelName ? null : panelName,
    });
  };

  /**
   * Gets the arrow direction for given panel
   * @param {String} panel
   */
  getArrowDirection = (panel) => {
    return this.state.expandedPanel === panel ? " down" : " right";
  };

  render() {
    /**
     * Render method which displays the list of live scenarios, historic scenarios.
     */

    const { t } = this.props;
    return (
      <div className={"login-actions"}>
        <Dimmer active={this.state.loading} inverted>
          <Loader>{t("scenario.messages.creating")}</Loader>
        </Dimmer>
        <div className="panel-wrapper-head">
          <div className="panel-wrapper-header">
            <div className="title">{t("scenario.actions")}</div>
          </div>
        </div>
        <div className={"login-actions-content"}>
          <div className="ui buttons">
            <button
              type="button"
              className="scenario-button"
              onClick={this.create}
            >
              {t("scenario.create")}
            </button>
          </div>
          {this.state.error ?
            <Message negative>
              {this.state.error}
            </Message>
            : null
          }
          <SelectScenario closeScenarioLoader={this.onSelect} />
          {/* Just commenting out this section in case we bring back the feature */}
          {/*  <List className="point-properties-card">
           <List.Item className="startup-actions-li" disabled>
              <div
                className="scenarios-dropdown"
                onClick={() => this.expandPanel("LIVE")}
              >
                <Icon
                  name={"angle" + this.getArrowDirection("LIVE")}
                  size="big"
                />
                <p>{t("scenario.live")}</p>
              </div>
              {this.showLiveScenarios()}
            </List.Item>

            <List.Item className="startup-actions-li" disabled>
              <div
                className="scenarios-dropdown"
                onClick={() => this.expandPanel("HIST")}
              >
                <Icon
                  name={"angle" + this.getArrowDirection("HIST")}
                  size="big"
                />
                <p>{t("scenario.historic")}</p>
              </div>
              {this.showHistoricScenarios()}
            </List.Item>

            <List.Item className="startup-actions-li">
              <div
                className="scenarios-dropdown"
                onClick={() => this.expandPanel("ANALYSIS")}
              >
                <Icon
                  name={"angle" + this.getArrowDirection("ANALYSIS")}
                  size="big"
                />
                <p>{t("scenario.analysis")}</p>
              </div>
              {this.showAnalysisScenarios()}
            </List.Item> 
          </List>*/}
          <ArchivedScenarios
            fetchScenarios={this.fetchScenarios}
            organisationId={this.props.userState.user.organisation.id}
          />
        </div>
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    mapState: store.mapState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    createScenario: (scenario) => {
      dispatch(Actions.createScenario(scenario));
    },
    fetchScenarios: (organisationId) => {
      dispatch(Actions.fetchScenarios(organisationId));
    }
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateOrLoadScenario);
