import React, { Component } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import WelcomeScreen from "./components/welcome/welcomeScreen.jsx";
// Import css schemes to ensure they are applied
import "./colourSchemes.scss";
import "./layoutSchemes.scss";

class App extends Component {

  /**
   * A visual banner notifying the user of UrbanAware's use of cookies and with a button allowing the user to confirm their acceptance 
   * @param { Function } t translation method given to props via react-i18nex withTranslation
   * @returns { JSX } the cookie banner react component
   */
  cookieBanner = t => <CookieConsent containerClasses="ua-cookie-banner" location={'top'} overlay={true}>
    {t('app.cookie-banner-main')}
    <ul>
      <li>{t('app.cookie-banner-token-use')}</li>
      <li>{t('app.cookie-banner-geoserver-use')}</li>
      <li>{t('app.cookie-banner-cookie-accepted-use')}</li>
    </ul>
  </CookieConsent>

  render() {
    var { t } = this.props;
    const showHeader = window.env.REACT_APP_SHOW_HEADER == 'true';
    const usingSmithsRequests = window.env.REACT_APP_SMITHS_REQUESTS == 'true';
    const showCookieBanner = !usingSmithsRequests;

    // See layoutSchemes.scss or colourSchemes.scss for how the root class name affects css variables
    let rootClassName = usingSmithsRequests ? "riskaware" : "smiths";
    rootClassName = showHeader ? rootClassName + " show-header" : rootClassName;
    document.documentElement.className = rootClassName;

    return (
      <main>
        <Helmet>
          <title>{t("title.appTitle")}</title>
        </Helmet>
        <WelcomeScreen />
        {showCookieBanner && this.cookieBanner(t)}
      </main>
    );
  }
}

export default withTranslation("translations")(App);
