import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Message, Dropdown } from "semantic-ui-react";
import Requests from '../../../services/requests';
import "../appHeader.scss";
import { Actions as UserActions } from "../../../services/user/actions";
import { CoordinateUnits, WindDirectionUnits, DateFormats } from "../../utils/Units";

/**
 * Component to display Preferences panel in the UI
 */
class Preferences extends React.Component {

    state = {
        iconography: "",
        coordinateUnit: CoordinateUnits.LAT_LON_DD,
        windDirectionUnit: WindDirectionUnits.DEGREES,
        dateFormat: DateFormats.ISO,
        message: null
    };

    constructor(props) {

        super(props);
        // TODO: Once nato icons are available - return the NATO option
        this.iconographyOptions = [
            { key: 'urbanaware', text: 'Urbanaware', value: 'urbanaware' },
            //{ key: 'nato', text: 'NATO', value: 'nato' }
        ];

        this.state.iconography = this.props.userState.user.preferences.iconography;
        this.state.coordinateUnit = this.props.userState.user.preferences.coordinateUnit;
        this.state.windDirectionUnit = this.props.userState.user.preferences.windDirectionUnit;
        this.state.dateFormat = this.props.userState.user.preferences.dateFormat;
    }


    change = () => {
        const { t } = this.props;

        // Create the user preference object that is going to be updated in the backend
        const userPreference = {
            userId: this.props.userState.user.id,
            iconography: this.state.iconography,
            coordinateUnit: this.state.coordinateUnit,
            windDirectionUnit: this.state.windDirectionUnit,
            dateFormat: this.state.dateFormat
        }

        // Set the state to be in loading state
        this.setState({ loading: true, message: null });

        // Create a PUT request for the user preference
        Requests.put("/users/preference", userPreference)
            .then(response => {
                this.setState({
                    loading: false,
                    message: { isError: false, content: t('profile.messages.updateSuccess') }
                });
                this.props.updatePreference(userPreference);
            })
            .catch(err => {
                console.log("Caught error, ", err);
                if (err.message) {
                    this.setState({ loading: false, message: { isError: true, content: err.message } });
                }
                this.setState({ loading: false, message: { isError: true, content: t('messages.errorCheckLogs') } });
            });
    }

    getMessage = () => {
        const { t } = this.props;
        if (this.state.message) {
            if (this.state.message.isError) {
                return (
                    <Message negative className="ua-error">
                        <Message.Header>{t('app.error')}</Message.Header>
                        <p>{this.state.message.content}</p>
                    </Message>
                )
            } else {
                return (
                    <Message positive>
                        <Message.Header>{t('app.success')}</Message.Header>
                        <p>{this.state.message.content}</p>
                    </Message>
                )
            }
        }
        return null;
    }

    render() {
        const { t } = this.props;

        return (
            <Form className="ua-form padding-ten">
                <Form.Group>
                    <Form.Field inline>
                        <label>{t("profile.preferences.iconography")}:</label>
                        <Dropdown
                            inline
                            className="ua-dropdown selection"
                            value={this.state.iconography}
                            options={this.iconographyOptions}
                            onChange={(e, {value}) => {
                                this.setState({ iconography: value});
                            }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <label>{t("profile.preferences.coordinateUnit")}:</label>
                        <Dropdown
                            inline
                            className="ua-dropdown selection"
                            value={this.state.coordinateUnit}
                            options={Object.values(CoordinateUnits).map(u => {
                                return {key: u, text: t(`units.coordinate.${u}`), value: u }}
                            )}
                            onChange={(e, {value}) => {
                                this.setState({ coordinateUnit: value});
                            }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <label>{t("profile.preferences.windDirectionUnit")}:</label>
                        <Dropdown
                            inline
                            className="ua-dropdown selection"
                            value={this.state.windDirectionUnit}
                            options={Object.values(WindDirectionUnits).map(u => {
                                return {key: u, text: t(`units.windDirection.${u}`), value: u }}
                            )}
                            onChange={(e, {value}) => {
                                this.setState({ windDirectionUnit: value});
                            }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <label>{t("profile.preferences.dateFormat")}:</label>
                        <Dropdown
                            inline
                            className="ua-dropdown selection"
                            value={this.state.dateFormat}
                            options={Object.values(DateFormats).map(u => {
                                return {key: u, text: t(`units.dateFormat.${u}`), value: u }}
                            )}
                            onChange={(e, {value}) => {
                                this.setState({ dateFormat: value});
                            }}
                        />
                    </Form.Field>
                </Form.Group>
                {this.getMessage()}
                <div className="right-side">
                    <Button
                        className="login-button"
                        onClick={this.change}
                        loading={this.state.loading}
                    >
                        {t('profile.passwordChange.change')}
                    </Button>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePreference: (userPreference) => {
            dispatch(UserActions.updateUserPreference(userPreference));
        }
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Preferences);
