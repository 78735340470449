import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationCze from "./locales/cs/translation.json";
import translationDeu from "./locales/de/translation.json";
import translationEng from "./locales/en/translation.json";
import translationEsp from "./locales/es/translation.json";
import translationJap from "./locales/jp/translation.json";
import translationPol from "./locales/pl/translation.json";
import translationUkr from "./locales/uk/translation.json";
import translationTest from "./locales/test/translation.json";


i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(XHR)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        resources: {
            en: {
                translations: translationEng
            },
            cs: {
                translations: translationCze
            },
            de: {
                translations: translationDeu
            },
            es: {
                translations: translationEsp
            },
            jp: {
                translations: translationJap
            },
            pl: {
                translations: translationPol
            },
            uk: {
                translations: translationUkr
            },
            test: {
                translations: translationTest
            }
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;