import L from "leaflet";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Table } from "semantic-ui-react";
import { Actions } from "../../../services/incident/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";
import "../sidebar.scss";

class IncidentsDisplay extends React.Component {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      selectedIndex: null,
    }
  }

  getTable = () => {
    var { t } = this.props;
    if (this.props.incidentState.loading) {
      return (<Table celled structured></Table>);
    }

    return (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("sidebar.name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("sidebar.sourceType")}</Table.HeaderCell>
            <Table.HeaderCell>{t("sidebar.agentType")}</Table.HeaderCell>
            <Table.HeaderCell>{t("sidebar.material")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.getRows()}</Table.Body>
      </Table>
    );
  };

  editButtonDisabled = (selectedIndex) => {
    const orgHasBlast = this.props.userState.user.organisation.models.includes("BLAST");
    const orgHasAtp45 = this.props.userState.user.organisation.models.includes("ATP45");
    if (!selectedIndex || (window.env.REACT_APP_INCLUDE_COMPLEX_INCIDENTS === "true" && orgHasBlast) || (window.env.REACT_APP_INCLUDE_TEMPLATES === "true" && orgHasAtp45)) {
      return false;
    }

    var incident = this.props.incidentState.incidents[
      selectedIndex
    ];

    if (incident.incidentType == "Explosive" || incident.incidentType == "Tear Gas Canister" || incident.incidentType == "ATP-45") {
      return true;
    } else {
      return false;
    }
  }

  getIncidentTypeTranslation = (incidentType) => {
    switch (incidentType) {
      case "POINT":
        return "sidebar.PointSource";
      case "LINE":
        return "sidebar.LineSource";
      case "MOVING":
        return "sidebar.MovingSource";
      case "LIQUID_POOL":
        return "sidebar.PoolSource";
      case "Explosive":
        return "sidebar.explosive";
      case "Tear Gas Canister":
          return "sidebar.teargas";
      case "Template":
            return "sidebar.atp45";
      default:
        return "";
    }
  }

  getRows = () => {
    const { t } = this.props;
    const orgHasBlast = this.props.userState.user.organisation.models.includes("BLAST");
    const orgHasAtp45 = this.props.userState.user.organisation.models.includes("ATP45");
    const complexIncidentsEnabled = (window.env.REACT_APP_INCLUDE_COMPLEX_INCIDENTS === "true") && orgHasBlast;
    const templatesEnabled = (window.env.REACT_APP_INCLUDE_TEMPLATES === "true") && orgHasAtp45;

    let rows = [];
    var simulationId = this.props.simulationState.selectedSimulation
      ? this.props.simulationState.selectedSimulation.id
      : -1;

    let incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(function (inc) {
      return inc.simulationId == simulationId
    });

    for (const i in incidentsForCurrentSimulation) {
      var incident = incidentsForCurrentSimulation[i]; // eslint-disable-line security/detect-object-injection
      let ind = incident.id;
      // Don't display incidents with no sources (change when we have many sources)
      if (templatesEnabled && incident.incidentType === "ATP-45") {
        // templates may not have sources - show
      } else if (!complexIncidentsEnabled) {
        if (incident.sources.length === 0 && incident.incidentType != "Explosive") {
          continue;
        }
      } else {
        if (incident.sources.length === 0) {
          continue;
        }
      }

      let rowCells = [];
      // Add common cells
      rowCells.push(<Table.Cell key={i + "name"} className={"incident-cell"}>{incident.name}</Table.Cell>);
      rowCells.push(
        <Table.Cell key={i + "incidentType"} className={"incident-cell"}>
          {t(this.getIncidentTypeTranslation(incident.incidentType))}
        </Table.Cell>
      );

      // If the source has no material name - it's non dispersive
      if (!complexIncidentsEnabled && incident.incidentType === "Explosive" || incident.incidentType === "Tear Gas Canister") {
        rowCells.push(<Table.Cell key={i + "agent-type"} className={"incident-cell"}>-</Table.Cell>);
        rowCells.push(<Table.Cell key={i + "material"} className={"incident-cell"}>-</Table.Cell>);
      } else if (templatesEnabled && incident.incidentType === "ATP-45") {
        rowCells.push(<Table.Cell key={i + "agent-type"} className={"incident-cell"}>-</Table.Cell>);
        rowCells.push(<Table.Cell key={i + "material"} className={"incident-cell"}>-</Table.Cell>);
      } else if (!incident.sources[0].materialName) {
        rowCells.push(<Table.Cell key={i + "agent-type"} className={"incident-cell"}>-</Table.Cell>);
        rowCells.push(<Table.Cell key={i + "material"} className={"incident-cell"}>-</Table.Cell>);
      } else {
        rowCells.push(
          <Table.Cell key={i + "agent-type"} className={"incident-cell"}>
            {t("sidebar." + incident.sources[0].agentType)}
          </Table.Cell>
        );
        rowCells.push(<Table.Cell key={i + "material"} className={"incident-cell"}>{incident.sources[0].materialName}</Table.Cell>);
      }

      rows.push(
        <Table.Row
          key={ind}
          onClick={(e) => this.selectRow(ind)}
          className={
            this.state.selectedIndex && this.state.selectedIndex === ind
              ? "active"
              : "incident-row"
          }
        >
          {rowCells}
        </Table.Row>
      );
    }

    if (rows.length === 0) {
      rows.push(
        <Table.Row key="default">
          <Table.Cell>{t("sidebar.addNewIncidentTxt")}</Table.Cell>
        </Table.Row>
      );
    }
    return rows;
  };

  selectRow = (index) => {
    var newSelectedIndex = index;
    if (this.state.selectedIndex === index) {
      newSelectedIndex = null;
    }
    this.setState({
      selectedIndex: newSelectedIndex,
    });
  };

  createIncident = () => {
    this.props.createIncident();
  };

  removeIncident = () => {
    if (this.state.selectedIndex != null) {
      this.props.removeIncident(this.state.selectedIndex, this.props.simulationState.selectedSimulation.id, this.props.scenarioState.scenario.id);
      this.setState({ selectedIndex: null });
    }
  };

  calculateCoordinateCentre = (coordinates) => {
    let lat = 0;
    let lon = 0;

    for (var i = 0; i < coordinates.length; i++) {
      lat += coordinates[i][0] // eslint-disable-line security/detect-object-injection
      lon += coordinates[i][1] // eslint-disable-line security/detect-object-injection
    }

    let latLon = [lat / coordinates.length, lon / coordinates.length];
    return latLon;
  }

  getLonLatFromGeometry(geometry) {
    if (geometry.type === "Point") {
      return geometry.coordinates;
    } else {
      return this.calculateCoordinateCentre(geometry.coordinates);
    }
  }

  flyTo = () => {
    if (this.state.selectedIndex != null) {
      var incident = this.props.incidentState.incidents[
        this.state.selectedIndex
      ];
      let coord;
      if (incident.sources && incident.sources.length > 0) {
        coord = this.getLonLatFromGeometry(incident.sources[0].geometry);
      } else {
        coord = this.getLonLatFromGeometry(incident.geometry);
      }
      this.props.mapState.map.flyTo(new L.latLng(coord[1], coord[0]), 15);
    }
  };

  editIncident = () => {
    if (this.state.selectedIndex) {
      this.props.editIncident(this.state.selectedIndex);
    }
  };

  render() {
    var { t } = this.props;

    return (
      <div className="met-display">
        <div className="sources-table content-body">{this.getTable()}</div>
        <div className="bottom-bar">
          <CommonButtonsBar
            flyTo={this.flyTo}
            flyToItemDescription={t("sidebar.flyToIncident")}
            editItem={this.editIncident}
            editDisabled={this.editButtonDisabled(this.state.selectedIndex)}
            editItemDescription={t("sidebar.editIncident")}
            removeItem={this.removeIncident}
            removeItemDescription={t("sidebar.removeIncident")}
            createItem={this.createIncident}
            createItemDescription={t("sidebar.addIncident")}
            selectedIndex={this.state.selectedIndex}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    incidentState: store.incidentState,
    simulationState: store.simulationState,
    scenarioState: store.scenarioState,
    userState: store.userState,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createIncident: () => {
      dispatch(Actions.createIncident());
    },
    removeIncident: (incidentId, simulationId, scenarioId) => {
      dispatch(Actions.removeIncident(incidentId, simulationId, scenarioId));
    },
    editIncident: (incidentId) => {
      dispatch(Actions.editIncident(incidentId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(IncidentsDisplay);
