/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Message, Popup } from "semantic-ui-react";
import { ReactComponent as RunSimulationIcon } from '../../../images/Run.svg';
import { SimulationActions } from "../../../services/simulation/actions.jsx";
import SimulationEditPopup from "../../common/popups/simulationEditPopup.jsx";
import { IncidentsUtils } from "../../utils/IncidentsUtils";
import MetSourceTypeEnum from "../../../enums/metSourceTypeEnum.jsx";

/**
 * Button to initiate simulation run
 */
class RunSimulationMenu extends React.Component {

  getRunErrors = (incidentsForCurrentSimulation) => {
    // Check whether the simulation should be allowed to run
    const { t } = this.props;
    const errors = [];
    // Check whether the simulation has at least one incident
    if (incidentsForCurrentSimulation.length === 0) {
      errors.push(t("simulation.messages.simulationRequiresSources"));
    }

    // Before checking met, check the added sources. Non Dispersive sources do not require
    // Met and can be allowed to run
    if (IncidentsUtils.requiresMetData(incidentsForCurrentSimulation)) {

      // Check that the simulation mets are available per the simulations configuration
      const metConfigForCurrentSimulation = this.props.metState.metConfigs[this.props.simulationState.selectedSimulation.id];
      if (!metConfigForCurrentSimulation) {
        errors.push(t("simulation.messages.simulationRequiresMet"));
      } else {

        switch(metConfigForCurrentSimulation.metSourceType) {
          case MetSourceTypeEnum.MET_SERVICE:
            if (!metConfigForCurrentSimulation.metService) {
              errors.push(t("simulation.messages.simulationRequiresMet"));
            }
            break;
          case MetSourceTypeEnum.SCENARIO_PRESETS:
            const metPresetProfiles = Object.values(this.props.metPresetsState.metProfiles)
              .filter( met => met.scenarioId === this.props.scenarioState.scenario.id);
            if (metPresetProfiles.length === 0) {
              errors.push(t("simulation.messages.simulationRequiresMet"));
            }
            break;
          case MetSourceTypeEnum.SIMULATION:
            const metProfiles = Object.values(this.props.metState.metProfiles)
              .filter( met => met.simulationId == this.props.simulationState.selectedSimulation.id );
            if (metProfiles.length === 0) {
              errors.push(t("simulation.messages.simulationRequiresMet"));
            }
            break;
        }
      }
    }

    return errors;
  }

  getPopupContent = (runErrors) => {
    var { t } = this.props;
    if (runErrors.length > 0) {
      return (
        <Message negative className="ua-error">
          <Message.Header>{t("app.error")}</Message.Header>
          <Message.List>
            {runErrors.map((error, index) => {
              return <Message.Item key={"run-simulation-error-" + index}>{error}</Message.Item>;
            })}
          </Message.List>
        </Message>
      );
    }

    return t("simulation.runSimulation");
  }

  onClick = () => {
    // Notify parent that this was clicked
    this.props.onClick(null, { name: this.props.name });

    const simulationDto = {
      type: 'SimulationStatus',
      id: this.props.simulationState.selectedSimulation.id,
      status: 'PENDING'
    };

    // Perform the run
    this.props.updateSimulation(simulationDto, this.props.scenarioState.scenario.id);
    this.props.next();
  }

  render() {
    var { t } = this.props;

    const incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
      inc => inc.simulationId == this.props.simulationState.selectedSimulation.id
    );
    const runErrors = this.getRunErrors(incidentsForCurrentSimulation);

    return (
      <Popup
        className="popup"
        mouseEnterDelay={300}
        trigger={
          <div>
            <SimulationEditPopup
              onUpdateSuccess={this.onClick}
              headerTitle={t("simulation.runSimulation")}
              updateButtonText={t("run.label.run")}
              updateModelTypes={true}
              modelTypesToUpdate={IncidentsUtils.getRequiredModels(incidentsForCurrentSimulation)}
              trigger={
                <Menu.Item
                  className={"icon-container"}
                  name={this.props.name}
                  disabled={runErrors.length > 0}
                  active={this.props.activeItem === this.props.name}
                >
                  <RunSimulationIcon className="sidebar-icon" />
                </Menu.Item>
              } />
          </div>
        }
        position={"right center"}
      >
        {this.getPopupContent(runErrors)}
      </Popup>
    );
  }
}
/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    simulationState: store.simulationState,
    contourConfigState: store.contourConfigState,
    metState: store.metState,
    metPresetsState: store.metPresetsState,
    incidentState: store.incidentState,
    resultsState: store.resultsState,
    scenarioState: store.scenarioState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateSimulation: (simulationDto, scenarioId) => {
      dispatch(SimulationActions.updateSimulation(simulationDto, scenarioId));
    },
  };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(RunSimulationMenu);