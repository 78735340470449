import React from 'react';
import { Message } from "semantic-ui-react";

/**
 * Dictates the styling of a message and header depending on error state
 * @param { isError } - Error state (boolean)
 * @param { messageHeader } - Text to be displayed in bold above message content
 * @param { messageContent } - Content to be displayed
 */

class StatusMessage extends React.Component {
    render() {
        
        const messageName=( this.props.isError ? "ua-error" : "ua-success")
        
        return (
            <Message
            negative={this.props.isError}
            positive={!this.props.isError}
            className={messageName}>
                <Message.Header>{this.props.messageHeader}</Message.Header>
                <p>{this.props.messageContent}</p>
            </Message>
        )
    }
}
    
export default (StatusMessage);