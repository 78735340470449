import SmithsRequests from "./smithsRequests";
import FetchRequests from "./fetchRequests";

class Requests {

    constructor() {
        this.requests = new FetchRequests();
        if (window.env.REACT_APP_SMITHS_REQUESTS === 'true') {
            this.requests = new SmithsRequests();
        }
    }

    /**
     * Make a get request
     * 
     * @param url - URL for get request
     * @param isBlob - flag whether request should return a blob or JSON, defaults to false.
     * @returns if isBlob is true returns a blob, otherwise returns JSON
     */
    get = async (url, isBlob = false) => {
        return this.requests.get(url, isBlob);
    };

    post = async (url, body) => {
        return this.requests.post(url, body);
    };

    patch = async (url, body) => {
        return this.requests.patch(url, body);
    };

    put = async (url, body) => {
        return this.requests.put(url, body);
    };

    deleteRequest = async (url) => {
        return this.requests.deleteRequest(url);
    };

}

export default new Requests();