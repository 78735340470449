import {
	ADD_INCIDENT_SUCCESS, CANCEL_EDIT,


	CLEAR, CREATE_OR_UPDATE_EDITING_INCIDENT, SET_EDITING_FETCHED_INCIDENT, CREATE_SOURCE, EDIT_INCIDENT,
	FETCH_BEGIN, FETCH_FAILURE, FETCH_INCIDENTS_SUCCESS,
	REMOVE_INCIDENT,
	SAVE_INCIDENT_SUCCESS, UPDATE_SOURCE
} from './actions.jsx';

export class IncidentReducer {

	static incidentsInfo(state, action) {

		const initialState = {
			incidents: [],
			loading: false,
			error: null,
			editingIncident: null,
			sources: []
		}

		switch (action.type) {
			case CLEAR:
				return initialState;
			case CANCEL_EDIT:
				state.editingIncident = null;
				return Object.assign({}, state, state);
			//INCIDENTS
			case CREATE_OR_UPDATE_EDITING_INCIDENT:
				var newEditingIncident = Object.assign({}, state.editingIncident, action.payload)
				return Object.assign({}, state, {
					editingIncident: Object.assign({}, newEditingIncident),
				})
			case SET_EDITING_FETCHED_INCIDENT:
				return {
					...state,
					editingIncident: action.payload,
					loading: false
				}
			case EDIT_INCIDENT:
				return Object.assign({}, state, {
					editingIncident: Object.assign({}, state.editingIncident, state.incidents[action.payload])
				})
			case ADD_INCIDENT_SUCCESS:
				var newState = Object.assign({}, state, {
					editingIncident: Object.assign({}, state.editingIncident, action.payload.incident)
				})

				// Set the editted incident onto simulation incidents (this handles new incidents,
				// and replacing the original incidents)
				var editedIncident = newState.editingIncident;
				newState.incidents[editedIncident.id] = editedIncident;

				// No longer editing an incident so reset it to null
				newState.editingIncident = null;
				newState.loading = false;

				// Add sources from new incident to list TODO check?
				if (action.payload.incident.sources[0]) {
					newState.sources[action.payload.incident.sources[0].id] = action.payload.incident.sources[0]
				}

				return newState;
			case REMOVE_INCIDENT:
				delete state.incidents[action.payload];
				return Object.assign({}, state, state);
			// SOURCES
			case UPDATE_SOURCE:
				state.editingIncident.sources[0] = Object.assign({}, state.editingIncident.sources[0], action.payload)
				return Object.assign({}, state, {
					editingIncident: Object.assign({}, state.editingIncident)
				})
			case CREATE_SOURCE:
				state.editingIncident.sources = []
				state.editingIncident.sources.push(action.payload)
				return Object.assign({}, state, {
					editingIncident: Object.assign({}, state.editingIncident),
				})
			case SAVE_INCIDENT_SUCCESS:
				// Initialise old and new source values
				let updatedIncident = action.payload
				let updatedSource = updatedIncident.sources[0]

				var prevIncident = state.editingIncident;
				let prevSource = state.editingIncident.sources[0];

				var newState = Object.assign({}, state)

				// Copy the new source values
				if (newState.editingIncident.sources[0]) {
					updatedSource = Object.assign({}, prevSource, updatedSource)
					newState.editingIncident.sources[0] = updatedSource
				}

				// Set the editted incident onto scenario incidents 
				newState.incidents[prevIncident.id] = Object.assign({}, prevIncident, updatedIncident);

				if (updatedSource && newState.sources[updatedSource.id]) {
					// Update the source on state
					newState.sources[updatedSource.id] = updatedSource
				}

				// No longer editing an incident so reset it to null
				newState.editingIncident = null;
				newState.loading = false;
				return newState;
			// FETCHING
			case FETCH_INCIDENTS_SUCCESS:
				var newIncidents = [];
				var newSources = [];

				// Loop though the new incidents and turn them into a map of id to object
				for (var i = 0; i < action.payload.incidents.length; i++) {
					let object = action.payload.incidents[i]; // eslint-disable-line security/detect-object-injection
					newIncidents[object.id] = object;
				}

				// Loop though the new sources and turn them into a map of id to object
				for (var i = 0; i < action.payload.sources.length; i++) {
					let object = action.payload.sources[i]; // eslint-disable-line security/detect-object-injection
					newSources[object.id] = object;
				}

				return Object.assign({}, state, {
					loading: false,
					editingIncident: null,
					incidents: newIncidents,
					sources: newSources
				})
			case FETCH_BEGIN:
				return Object.assign({}, state, {
					loading: true,
					error: null
				})
			case FETCH_FAILURE:
				return Object.assign({}, state, {
					loading: false,
					error: action.payload.error.message,
				})
			default:
				return state || initialState;
		}
	}

}