import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Icon } from "semantic-ui-react";
import Logo from "../../logo2.png";
import { Actions as AoiActions } from "../../services/aoi/actions";
import { ContourActions } from '../../services/contours/actions';
import { Actions as IncidentActions } from '../../services/incident/actions';
import { Actions as KeyBuildActions } from "../../services/keyBuild/actions";
import { Actions as MetActions } from '../../services/met/actions';
import { ResultsActions } from '../../services/results/actions';
import { Actions as ScenarioActions } from "../../services/scenario/actions";
import { Actions as AuditsActions } from "../../services/audits/actions";
import { Actions as SetupActions } from '../../services/setupMenu/actions';
import { SimulationActions } from '../../services/simulation/actions';
import { default as RightSidebar } from "../map-sidebar/sidebar.jsx";
import Sidebar from "../setup-sidebar/sidebar.jsx";
import "./appHeader.scss";
import ScenarioTitle from './titles/scenarioTitle';
import SimulationTitle from './titles/simulationTitle';
import UserHeaderButton from './user/userHeaderButton';
import UserWindow from './userWindow';

const SHOW_HEADER = window.env.REACT_APP_SHOW_HEADER === 'true';
const INCLUDE_SCENARIOS = window.env.REACT_APP_INCLUDE_SCENARIOS === "true";
const INCLUDE_SIMULATIONS = INCLUDE_SCENARIOS && window.env.REACT_APP_INCLUDE_SIMULATIONS === "true";
const INCLUDE_USER_INFO = window.env.REACT_APP_INCLUDE_USER_INFO === "true";
const SMITHS_REQUESTS = window.env.REACT_APP_SMITHS_REQUESTS === 'true';

class AppHeader extends React.Component {
    state = {
        currentUserState: "login"
    };

    handleItemClick = (e, name) => this.setState({ currentUserState: name });

    onClick = (e) => {
        this.props.toNextPanel();
    };

    componentDidUpdate(prevProps) {

        if (this.state.currentUserState == "goHome") {
            // Notify parent about scenario unloading
            this.props.unloadScenario();
            // Unload scenario from the stores
            this.unloadScenario();
        }
        else if (this.state.currentUserState == "userGuide") {
            window.open('/user-guide', '_blank');
            // Set to home state as any further updates will cause the user guide to reopen.
            this.setState({ currentUserState: "home" });
        }

        /**
         * Checks to see if the user has logged in and
         * changes the currentUserState accordingly.
         */
        if (this.props.editingScenario !== prevProps.editingScenario) {
            this.setState({ currentUserState: "home" });
        }
    }

    getMainDisplay = () => {
        /**
         * Load the main navigation bars when the scenario is loaded
         */
        if (INCLUDE_SCENARIOS && this.props.editingScenario) {
            return (
                <>
                    <Sidebar />
                    <RightSidebar />
                </>
            );
        } else if (this.props.navButtons) { 
            // Show the map side bar if we are logged in
            return (
                <>
                    <RightSidebar />
                </>
            );
        }
    };

    unloadScenario() {
        this.setState({ currentUserState: "home" });
        this.props.clear();
    }

    renderHeaderTitles = () => {
        return (
            <div className="ua-header-title">
                {INCLUDE_SCENARIOS? <ScenarioTitle /> : null}
                {INCLUDE_SIMULATIONS? <SimulationTitle />: null}
            </div>
        )
    }

    getUserHeaderButton = (currentUserState) => {

        if (SMITHS_REQUESTS || !INCLUDE_USER_INFO) {
            // The header should not include the User button, because User Management happens at
            // Smiths Platform level, and should be hidden from UrbanAware level, in order to
            // give a feeling that UA is not a separate app to Smiths Platform
            return null;
        }
        return (
            <UserHeaderButton
                name="user"
                active={currentUserState === "user"}
                handleItemClick={this.handleItemClick}
            />
        );
    }

    renderNavButtons = (currentUserState) => {
        const { t } = this.props;
        if (this.props.navButtons) {
            return (
                <>
                { INCLUDE_SCENARIOS ?                 
                    <button
                        className="navigation-button"
                        name="home"
                        onClick={(e) => this.handleItemClick(e, "goHome")}
                    >
                        <Icon name="home" />
                        {t('header.home')}
                    </button> : null}

                { INCLUDE_SCENARIOS ?
                    <button
                        className="navigation-button"
                        name="userGuide"
                        onClick={(e) => this.handleItemClick(e, "userGuide")}
                    >
                        <Icon name="info circle" />
                        {t('header.userGuide')}
                    </button> : null}

                    {this.getUserHeaderButton(currentUserState)}
                </>
            );
        }
    };

    getSelection = () => {
        if (this.state.currentUserState === 'user') {
            return <UserWindow close={(e) => this.handleItemClick(e, "home")} />;
        }
    }

    render() {
        /**
         * Render and deal with the navigation buttons.
         */
        var { t } = this.props
        const { currentUserState } = this.state;

        let markerPosition = this.props.position;
        if (markerPosition == null) {
            // Note that this also catches undefined
            markerPosition = ["", ""];
        }

        // If we don't show the header, make it invisuble and don't show the logo
        let headerStyle = "ua-header";
        let logo = {};
        if (!SHOW_HEADER) {
            headerStyle += " ua-header-invisible";
            logo = <div/>
        } else {
            logo = <img src={Logo} alt={t("header.uaLogo")} />
        }
        
        return (
            <div className="main-app">
                <div className={headerStyle}>
                        {logo}
                        {this.renderHeaderTitles()}
                        {this.renderNavButtons(currentUserState)}
                    </div>
                <div className="main-window">{this.getMainDisplay()}</div>
                {this.getSelection()}
            </div>
        );
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        scenarioState: store.scenarioState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        clear: () => {
            dispatch(AoiActions.clear());
            dispatch(IncidentActions.clear());
            dispatch(KeyBuildActions.clear());
            dispatch(MetActions.clear());
            dispatch(ResultsActions.clear());
            dispatch(SetupActions.clear());
            dispatch(ScenarioActions.clear());
            dispatch(AuditsActions.clear());
            dispatch(SimulationActions.clear());
            dispatch(ContourActions.clear());
        }
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AppHeader);
