/*
    ./client/components/App.jsx
*/
import React from 'react';
import { Form } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { DateFormats } from '../utils/Units';
import { DateUtils } from '../utils/DateUtils';

export default class FormFieldDate extends React.Component {

    /**
     * Constructor
     * @prop {String} label the label to display for this input
     * @prop {Boolean} required true if the input is required 
     * @prop {Date} initialValue the initial date value on the input field
     * @prop {Function} onChange callback function that takes the inputted date as a Date object, or null if invalid or not provided
     * @prop {Function} t the translation function to use 
     * @prop {DateFormats} dateFormat date format
     */
    constructor(props) {
        super(props);
        
        this.formatToUse = DateUtils.DTG_FORMAT;
        if(props.dateFormat === DateFormats.ISO) {
            this.formatToUse = DateUtils.ISO_FORMAT;
        }

        let initialInputText = "";
        if (this.props.initialValue) {
            initialInputText = moment(this.props.initialValue)
                .format(this.formatToUse)
                .toUpperCase();
        }

        this.state = {
            inputBoxText: initialInputText,
            validationError: null
        }
    }

    /**
     * Handles the DateTimeInput onChange event
     * @param {Object} event the event given by the DateTimeInput onChange event
     * @param {Object} data the data given by the DateTimeInput onChange event
     */
    handleChange = (event, data) => {
        const newInputBoxText = data.value;

        let dateToPropagate;
        let validationError;
        if (newInputBoxText === "") {

            dateToPropagate = null;
            if (this.props.required) {
                validationError = this.props.t("source.messages.emptyError");
            } else {
                validationError = null;
            }

        } else {

            const momentValue = moment(newInputBoxText, this.formatToUse, true);
            if (momentValue.isValid()) {
                dateToPropagate = new Date(momentValue.valueOf());
                validationError = null;
            } else {
                dateToPropagate = null;
                validationError = this.props.t("source.messages.invalidDate");
            }

        }

        this.props.onChange(dateToPropagate);
        this.setState({
            inputBoxText: newInputBoxText.toUpperCase(),
            validationError: validationError
        });
    }

    /**
     * React render method
     */
    render() {
        return <Form.Field error={!!this.state.validationError}>
            <DateTimeInput
                required={this.props.required}
                inlineLabel={true}
                label={this.props.label}
                dateTimeFormat={this.formatToUse}
                value={this.state.inputBoxText}
                onChange={this.handleChange}
                popupPosition='bottom right'
                animation='none'
                closable={true}
                icon={false}
                preserveViewMode={false}
            />
            {!!this.state.validationError && <div className="ui pointing above prompt label">
                {this.state.validationError}
            </div>}
        </Form.Field>
    }
}