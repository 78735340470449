import L from 'leaflet';
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Table } from "semantic-ui-react";
import { Actions } from "../../../services/aoi/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";

class AoisDisplay extends React.Component {
  state = {
    selectedIndex: null,
  };

  getTable = () => {
    var { t } = this.props;

    return (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("sidebar.type")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("sidebar.name")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.getRows()}</Table.Body>
      </Table>
    );
  };

  selectRow = (index) => {
    var newSelectedIndex = index;
    if (this.state.selectedIndex === index) {
      newSelectedIndex = null;
    }
    this.setState({
      selectedIndex: newSelectedIndex,
    });
  };

  getRows = () => {
    var { t } = this.props;

    let aois = [];

    for (var m in this.props.aoiState.aois) {
      let ind = m;
      var aoi = this.props.aoiState.aois[m]; // eslint-disable-line security/detect-object-injection
      aois.push(
        <Table.Row
          key={ind}
          onClick={(e) => this.selectRow(ind)}
          className={
            this.state.selectedIndex && this.state.selectedIndex === ind
              ? "active"
              : "incident-row"
          }
        >
          <Table.Cell className={"incident-cell"}>{t("aoitypes." + aoi.aoiType)}</Table.Cell>
          <Table.Cell className={"incident-cell"}>{aoi.name}</Table.Cell>
        </Table.Row>
      );
    }

    if (aois.length === 0) {
      aois.push(
        <Table.Row key="default">
          <Table.Cell>{t("sidebar.addNewAoiTxt")}</Table.Cell>
        </Table.Row>
      );
    }
    return aois;
  };

  removeAoi = () => {
    if (this.state.selectedIndex != null) {
      this.props.removeAoi(this.state.selectedIndex, this.props.scenarioState.scenario.id);
      this.setState({ selectedIndex: null });
    }
  };

  editAoi = () => {
    if (this.state.selectedIndex != null) {
      this.props.editAoi(this.state.selectedIndex);
    }
  };

  createAoi = () => {
    this.props.createAoi();
  };

  flyTo = () => {
    if (this.state.selectedIndex != null) {
      var source = this.props.aoiState.aois[this.state.selectedIndex];
      let latitudes = [];
      let longitudes = [];
      this.getLatLonsFromGeometry(source.geometry, latitudes, longitudes);
      if (latitudes.length > 0) {
        let corner1 = L.latLng(Math.max(...latitudes), Math.max(...longitudes));
        let corner2 = L.latLng(Math.min(...latitudes), Math.min(...longitudes));
        let bounds = L.latLngBounds(corner1, corner2);
        this.props.mapState.map.flyToBounds(bounds, {
          padding: [50, 50],
          maxZoom: 15
        });
      }
    }
  };

  getLatLonsFromGeometry(geometry, latitudes, longitudes) {
    if (geometry.type === "Point") {
      // Coordinates is just a lat lon                    
      latitudes.push(geometry.coordinates[1]);
      longitudes.push(geometry.coordinates[0]);
    }
    else if (geometry.type === "Polygon") {
      // Coordinates is an array of lat lons
      geometry.coordinates.forEach(coords => {
        coords.forEach(coord => {
          latitudes.push(coord[1]);
          longitudes.push(coord[0]);
        });
      });
    }
    else {
      // Coordinates is an array of lat lons
      geometry.coordinates.forEach(coord => {
        latitudes.push(coord[1]);
        longitudes.push(coord[0]);
      });
    }
  }

  render() {
    var { t } = this.props;

    return (
      <div className="aois-display">
        <div className="sources-table">{this.getTable()}</div>

        <div className="bottom-bar">
          <CommonButtonsBar
            flyTo={this.flyTo}
            flyToItemDescription={t("sidebar.flyToAoi")}
            editItem={this.editAoi}
            editItemDescription={t("sidebar.editAoi")}
            removeItem={this.removeAoi}
            removeItemDescription={t("sidebar.removeAoi")}
            createItem={this.createAoi}
            createItemDescription={t("sidebar.addAoi")}
            selectedIndex={this.state.selectedIndex}
          />
        </div>
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    aoiState: store.aoiState,
    scenarioState: store.scenarioState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    createAoi: () => {
      dispatch(Actions.createAoi());
    },
    removeAoi: (id, scenarioId) => {
      dispatch(Actions.removeAoi(id, scenarioId));
    },
    editAoi: (id) => {
      dispatch(Actions.editAoi(id));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AoisDisplay);
