/*
* action types
*/
export const SELECT_MENU = 'SELECT_MENU';

export const CLEAR = 'CLEAR_SELECT';

export class Actions {

	static clear() {
		return {
			type: CLEAR
		}
	}

	static selectMenu = (activeMenu) => ({
		type: SELECT_MENU,
		payload: activeMenu
	});

}