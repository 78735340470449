import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Message } from "semantic-ui-react";
import { Actions as AoiActions } from "../../../services/aoi/actions";
import { ContourActions } from '../../../services/contours/actions';
import { Actions as IncidentActions } from '../../../services/incident/actions';
import { Actions as KeyBuildActions } from "../../../services/keyBuild/actions";
import { Actions as MetActions } from '../../../services/met/actions';
import { ResultsActions } from '../../../services/results/actions';
import { Actions as AuditsActions } from "../../../services/audits/actions";
import { Actions as ScenarioActions } from "../../../services/scenario/actions";
import { Actions as SetupActions } from '../../../services/setupMenu/actions';
import { SimulationActions } from '../../../services/simulation/actions';
import { Actions as UserActions } from "../../../services/user/actions";
import Requests from '../../../services/requests';
import "../appHeader.scss";

class Profile extends React.Component {

    state = {
        oldPassword: "",
        newPassword: "",
        fieldErrors: {},
        readOnly: true
    };

    logOut = () => {
        this.props.close(null);
        Requests.post('/accounts/logout')
            .finally(() => {
                this.props.setUser(null);
                this.props.clear();
            });
    }

    render() {
        const { t } = this.props;

        return (
            <Form className="ua-form padding-ten">
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            readOnly
                            label={t('title.email')}
                            value={this.props.userState.user.email}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            inline
                            readOnly
                            label={t('title.displayName')}
                            value={this.props.userState.user.displayName}
                        />
                    </Form.Field>
                </Form.Group>
                {this.state.error ?
                    (
                        <Message negative className="ua-error">
                            <Message.Header>{t('app.error')}</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                    ) : null
                }
                <div className="right-side">
                    <button type="button"
                        className="scenario-button logout"
                        onClick={this.logOut}>{t('profile.logOut')}</button>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch(UserActions.setUser(user));
        },
        clear: () => {
            dispatch(AoiActions.clear());
            dispatch(IncidentActions.clear());
            dispatch(KeyBuildActions.clear());
            dispatch(MetActions.clear());
            dispatch(ResultsActions.clear());
            dispatch(SetupActions.clear());
            dispatch(ScenarioActions.clear());
            dispatch(AuditsActions.clear());
            dispatch(SimulationActions.clear());
            dispatch(ContourActions.clear());
        }
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Profile);
