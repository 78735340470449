import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ComplexSourcePropertiesCard from "../sources/complexSources/complexSourcePropertiesCard";
import LinePropertiesCard from "../sources/linePropertiesCard";
import MovingPropertiesCard from "../sources/movingPropertiesCard";
import PointPropertiesCard from "../sources/pointPropertiesCard";
import PoolPropertiesCard from "../sources/poolPropertiesCard";
import IncidentTypeSelection from "./incidentTypeSelection";

/**
 * Definition of panel on which user creates/edits a met observation, inputting all values on this one view
 */
class IncidentEditor extends React.Component {

  renderSourceEditCard = () => {
    switch (this.props.incidentState.editingIncident.incidentType) {
      case "POINT":
        return <PointPropertiesCard />;
      case "LINE":
        return <LinePropertiesCard />;
      case "MOVING":
        return <MovingPropertiesCard />;
      case "LIQUID_POOL":
        return <PoolPropertiesCard />;
      default:
        return <ComplexSourcePropertiesCard />;
    }
  };

  getContent = () => {
    if (this.props.incidentState.editingIncident.sources) {
      return <div>{this.renderSourceEditCard()}</div>;
    } else {
      return <IncidentTypeSelection next={this.renderSourceEditCard} />;
    }
  };

  render() {
    return this.getContent();
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    incidentState: store.incidentState,
    simulationState: store.simulationState,
    ...props,
  };
};


export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(IncidentEditor);
