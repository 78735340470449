import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Dimmer } from "semantic-ui-react";
import { Actions } from "../../services/user/actions";
import "./appHeader.scss";
import Requests from '../../services/requests';

class SmithsLogin extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      loading: true
    };
  }

  login = () => {
    const { t } = this.props;
    // Attempt to login without providing a user, as Smiths library
    // will intercept and provide us with user details in the request that comes into server
    Requests.post('/accounts/login').then(response => {
      if (response && response.user) {
        this.props.setUser(response.user);
        if (this.props.onSuccessLogin) {

          if (window.parent !== window) {
            window.parent.postMessage('loaded', document.referrer);
          }
          this.props.onSuccessLogin();
        }
      } else {
        // For now also notify the parent iframe if the sign in failed
        if (window.parent !== window) {
          window.parent.postMessage('loaded', document.referrer);
        }
        this.setState({ error: t('messages.errorCheckLogs'), loading: false });
      }
    })
      .catch(err => {
        console.log("Caught error, ", err);
        let errMessage = t('messages.errorCheckLogs');
        if (err.message) {
          errMessage = err.message;
          if (err.message.includes("User has been archived")) {
            errMessage = t('login.messages.archivedUser');
          }
        }
        // For now also notify the parent iframe if the sign in failed
        if (window.parent !== window) {
          window.parent.postMessage('loaded', document.referrer);
        }
        this.setState({ error: errMessage, loading: false });
      });
  }

  componentDidMount = () => {
    // Attempt to login when rendered
    this.login();
  }

  render() {
    const { t } = this.props;
    return (
      <div className={"login-panel"}>
        <Dimmer active>
          {this.state.error ? this.state.error : ''}
        </Dimmer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(Actions.setUser(user));
    },
  };
};

export default compose(withTranslation(), connect(null, mapDispatchToProps))(SmithsLogin);
