import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Actions } from "../../../services/incident/actions.jsx";
import PanelWrapper from '../../common/panelWrapper.jsx';
import "../sidebar.scss";
import IncidentEditor from './incidentEditor.jsx';
import IncidentsDisplay from './incidentsDisplay.jsx';

/**
 * View to contain logic determining if user is viewing or editing a incidents observation, and display sub-panel accordingly 
 */
class IncidentsPanel extends React.Component {

  finishSource = () => {
    this.setState({ newIncident: false });
  };

  getContent = () => {
    const { t } = this.props;
    if (this.props.incidentState.editingIncident) {
      return (
        <PanelWrapper key={"incidents" + this.props.incidentState.editingIncident.id} title={t("incident.editIncident")} cancel={this.props.cancelEdit} >
          <IncidentEditor />
        </PanelWrapper>
      );
    }
    else {
      return (
        <PanelWrapper key="incidents" title={t("incident.incidents")} cancel={this.props.cancel} >
          <IncidentsDisplay />
        </PanelWrapper>
      )
    }
  }

  render() {
    return (
      <div className="incidents-panel">
        {this.getContent()}
      </div>
    )
  }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
  return {
    incidentState: store.incidentState,
    ...props
  }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
  return {
    cancelEdit: () => {
      dispatch(Actions.cancelEdit());
    }
  }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(IncidentsPanel);