import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form } from "semantic-ui-react";
import { ContourActions } from "../../../../services/contours/actions.jsx";
import InputFieldWithStaticUnit from "../../../common/inputFieldWithStaticUnit";
import SaveCancelButtonsBar from "../../../common/SaveCancelButtonsBar.jsx";
import { UnitsHelper } from "../../../common/unitsHelper.jsx";

class ContourEditForm extends React.Component {
  constructor(props) {
    super(props);

    var { t } = props;

    var interpolationSchemes = [
      { id: "LINEAR", name: t("simulation.linear"), value: "LINEAR" },
      { id: "LOG", name: t("simulation.logarithmic"), value: "LOG" },
    ];

    var dataTypes = [
      {
        id: "CONCENTRATION",
        name: t("dataTypes.CONCENTRATION"),
        value: "CONCENTRATION",
      },
      {
        id: "DEPOSITION",
        name: t("dataTypes.DEPOSITION"),
        value: "DEPOSITION",
      },
      {
        id: "DOSAGE",
        name: t("dataTypes.DOSAGE"),
        value: "DOSAGE",
      }
    ];

    let incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
      (inc) => {
        return (
          inc.simulationId == this.props.simulationState.selectedSimulation.id
        );
      }
    );

    // Set the materialId to be the first material in the list of available incidents
    // Cannot access this component unless there is an incident, so checks are not needed.
    var materialId = incidentsForCurrentSimulation[0].sources[0].materialId;

    // Iterate through all incidents and add unique materials once to the list
    // of selectable incidents.
    var materials = [];
    for (var inc in incidentsForCurrentSimulation) {
      for (var sInd in incidentsForCurrentSimulation[inc].sources) { // eslint-disable-line security/detect-object-injection
        var source = incidentsForCurrentSimulation[inc].sources[sInd]; // eslint-disable-line security/detect-object-injection
        if (materials.every((m) => m.value !== source.materialId)) {
          {
            materials.push({
              key: source.materialId,
              text: source.materialName,
              value: source.materialId,
            });
          }
        }
      }
    }
    let contourConfig = this.props.contourConfigState.editContourConfig;
    this.state = {
      interpolationSchemes: interpolationSchemes,
      dataTypes: dataTypes,
      simulationIncidents: incidentsForCurrentSimulation,
      materials: materials,
      isEditingContourOption: contourConfig ? true : false,
      id: contourConfig ? contourConfig.id : null,
      materialId: contourConfig ? contourConfig.materialId : materialId,
      numContourLevels: contourConfig ? contourConfig.numContourLevels : 6,
      minContourLevel: contourConfig ? contourConfig.minContourLevel : 1e-7,
      maxContourLevel: contourConfig ? contourConfig.maxContourLevel : 1e-2,
      interpolationScheme: contourConfig
        ? contourConfig.interpolationScheme
        : "LOG",
      dataType: contourConfig ? contourConfig.dataType : null,
      selectedIndex: contourConfig ? contourConfig.selectedIndex : null,
      fieldErrors: []
    };
  }

  componentWillUnmount() {
    // Cancel the editing/creation if the component is unmounting
    this.props.cancelContourEdit();
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.validateForm(prevState);
  };

  validateForm = (prevState) => {
    var { t } = this.props;

    var fieldErrors = {};

    if (prevState.numContourLevels !== this.state.numContourLevels) {
      fieldErrors["numContourLevels"] = this.getValidMessage(
        this.state.numContourLevels,
        this.validateNumContourLevels,
        t("simulation.messages.numContourLevels")
      );
    }

    if (
      prevState.minContourLevel !== this.state.minContourLevel ||
      prevState.maxContourLevel !== this.state.maxContourLevel
    ) {
      fieldErrors["minContourLevel"] = this.getValidMessage(
        this.state.minContourLevel,
        this.validateMinContourLevel,
        t("simulation.messages.minContourLevel")
      );
    }

    if (
      prevState.maxContourLevel !== this.state.maxContourLevel ||
      prevState.minContourLevel !== this.state.minContourLevel
    ) {
      fieldErrors["maxContourLevel"] = this.getValidMessage(
        this.state.maxContourLevel,
        this.validateMaxContourLevel,
        t("simulation.messages.maxContourLevel")
      );
    }
    if (Object.keys(fieldErrors).length) {
      fieldErrors = Object.assign({}, this.state.fieldErrors, fieldErrors);
      this.setState({ fieldErrors: fieldErrors });
    }
  };

  validate = () => {
    // If all values held in field errors are false - input is valid
    return Object.values(this.state.fieldErrors).every((v) => v === false);
  };

  getValidMessage = (field, valid, message) => {
    let emptyMessage = this.getEmptyMessage(field);
    if (emptyMessage) {
      return emptyMessage;
    }
    if (!valid(field)) {
      return message;
    } else {
      return false;
    }
  };

  getEmptyMessage = (field) => {
    var { t } = this.props;
    let nonwhitespace = /[^\s]/;
    let valid = field != undefined && field.toString().match(nonwhitespace);
    return !valid ? t("source.messages.emptyError") : "";
  };

  validateNumContourLevels = () => {
    let value = parseInt(this.state.numContourLevels);
    return value > 0 && value <= 10;
  };

  validateMinContourLevel = () => {
    return parseFloat(this.state.minContourLevel) > 0;
  };

  validateMaxContourLevel = () => {
    return (
      parseFloat(this.state.maxContourLevel) >
      parseFloat(this.state.minContourLevel)
    );
  };

  setScheme = (e, data) => {
    this.setState({
      interpolationScheme: data.value,
    });
  };

  setDataType = (e, data) => {
    this.setState({
      dataType: data.value,
    });
  };

  getDataTypes = () => {
    // maps through all dataTypes and only returns if has an output for all
    // material types.
    var dataTypes = [];
    this.state.dataTypes.map((dataType) => {
      // Gets contour configs for selected simulation
      var contourConfigs = this.props.contourConfigState.contourConfigs.filter(
        (contourConfig) =>
          contourConfig.simulationId ===
          this.props.simulationState.selectedSimulation.id
      );

      // Gets all the contour configs which have the same
      // data type as the one we are mapped to
      var returnDataType = contourConfigs.filter(
        (e) => e.dataType === dataType.value
      );

      // If there are the same number of unique contour outputs as
      // there are materials, do not allow me of this particular
      // data type to be selected.
      if (returnDataType.length < this.state.materials.length) {
        dataTypes.push({
          key: dataType.value,
          text: dataType.name,
          value: dataType.id,
        });
      }
    });
    return dataTypes;
  };

  setMaterial = (e, data) => {
    this.setState({
      materialId: data.value,
    });
  };

  getMaterials = () => {
    if (
      this.props.contourConfigState.contourConfigs.every(
        (con) => con.dataType !== this.state.dataType
      )
    ) {
      return this.state.materials;
    }

    var contourConfigs = this.props.contourConfigState.contourConfigs.filter(
      (c) => c.simulationId === this.props.simulationState.selectedSimulation.id
    );

    var materials = [];
    for (var mat in this.state.materials) {
      var material = this.state.materials[mat]; // eslint-disable-line security/detect-object-injection
      var addMaterial = true;
      for (var con in contourConfigs) {
        var output = contourConfigs[con]; // eslint-disable-line security/detect-object-injection
        if (
          output.materialId === material.value &&
          output.dataType === this.state.dataType
        ) {
          addMaterial = false;
          if (this.state.isEditingContourOption) {
            if (material.value === this.state.materialId) {
              addMaterial = true;
            }
          }
        }
      }
      if (addMaterial) {
        materials.push(material);
      }
    }

    if (this.state.materialId !== materials[0].value) {
      this.setState({
        materialId: materials[0].value,
      });
    }

    return materials;
  };

  checkIfNaN = (value, allowScientificNotation) => {
    // Checks to see if scientific notation is allowed
    if (allowScientificNotation) {
      // Ensures that correct scientific notation is input
      // only allows one e and -, and e must come before -.
      if (value.includes("e")) {
        if (!value.slice(0, -1).includes("e")) {
          return false;
        }
        if (value.includes("-")) {
          if (!value.slice(0, -1).includes("-")) {
            return false;
          }
        }
      }
    }

    if (isNaN(value)) {
      console.warn("Cannot parse input to a number", value);
      return true;
    }
    return false;
  };

  setNumContourLevels = (e) => {
    if (e.target.value === "") {
      this.setState({ numContourLevels: null });
      return;
    }
    var value = parseInt(e.target.value);
    if (!this.checkIfNaN(value, false)) {
      this.setState({ numContourLevels: value });
    }
  };

  setMinContourLevel = (data) => {
    var value = data.target.value;
    if (!this.checkIfNaN(value, true)) {
      this.setState({ minContourLevel: value });
    }
  };

  setMaxContourLevel = (data) => {
    var value = data.target.value;
    if (!this.checkIfNaN(value, true)) {
      this.setState({ maxContourLevel: value });
    }
  };

  addOnClick = () => {
    let simId = this.props.simulationState.selectedSimulation.id;
    let scenarioId = this.props.scenarioState.scenario.id;
    let contourConfig = {
      id: this.state.id,
      simulationId: simId,
      numContourLevels: this.state.numContourLevels,
      minContourLevel: parseFloat(this.state.minContourLevel),
      maxContourLevel: parseFloat(this.state.maxContourLevel),
      interpolationScheme: this.state.interpolationScheme,
      dataType: this.state.dataType,
      materialId: this.state.materialId,
    };
    if (!this.state.isEditingContourOption) {
      this.props.insertNewContourConfig(contourConfig, scenarioId, simId);
    } else {
      this.props.updateContourConfig(contourConfig, scenarioId, simId);
    }
  };

  cancelContourConfigEdit = () => {
    this.props.cancelContourEdit();
  };

  getAddButtonTranslation = () => {
    var { t } = this.props;

    if (this.state.isEditingContourOption) {
      return t("run.outputConfig.updateContourConfig");
    }
    return t("run.outputConfig.addContourOutput");
  };

  render() {
    var { t } = this.props;

    if (this.state.dataType === null) {
      return (
        <>
          <div className="content-body">
            <Form className="ua-form ua-inline-form">
              <Form.Group>
                <Form.Field>
                  <Form.Select
                    inline
                    className="absolute"
                    label={t("filteringTool.dataType")}
                    options={this.getDataTypes()}
                    placeholder={"Select data type"}
                    value={this.state.dataType}
                    onChange={(e, data) => this.setDataType(e, data)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </div>

          <SaveCancelButtonsBar
            cancel={this.cancelContourConfigEdit}
            saveDisabled={true}
          />
        </>
      );
    }

    const unit = UnitsHelper.getUnits(this.state.dataType);

    return (
      <>
        <div className="content-body">
          <Form className="ua-form ua-inline-form">
            <Form.Group>
              <Form.Select
                inline
                label={t("simulation.interpolationScheme")}
                options={this.state.interpolationSchemes.map((m) => {
                  return { key: m.value, text: m.name, value: m.id };
                })}
                value={this.state.interpolationScheme}
                onChange={(e, data) => this.setScheme(e, data)}
              />

              <Form.Select
                inline
                label={t("label.material")}
                options={this.getMaterials()}
                placeholder={t("label.material")}
                value={this.state.materialId}
                onChange={(e, data) => this.setMaterial(e, data)}
              />

              <Form.Input
                error={this.state.fieldErrors["numContourLevels"]}
                inline
                label={t("simulation.numberOfContours")}
                value={this.state.numContourLevels}
                onChange={(e) => this.setNumContourLevels(e)}
              />

              <InputFieldWithStaticUnit
                label={t("simulation.minValue")}
                updateFunc={this.setMinContourLevel}
                value={this.state.minContourLevel}
                unit={unit}
                fieldErrors={this.state.fieldErrors["minContourLevel"]}
              />

              <InputFieldWithStaticUnit
                label={t("simulation.maxValue")}
                updateFunc={this.setMaxContourLevel}
                value={this.state.maxContourLevel}
                unit={unit}
                fieldErrors={this.state.fieldErrors["maxContourLevel"]}
              />

            </Form.Group>
          </Form>
        </div>

        <SaveCancelButtonsBar
          cancel={this.cancelContourConfigEdit}
          save={this.addOnClick}
          saveDisabled={!this.validate()}
        />
      </>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    simulationState: store.simulationState,
    scenarioState: store.scenarioState,
    incidentState: store.incidentState,
    contourConfigState: store.contourConfigState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    insertNewContourConfig: (contourConfig, scenarioId, simulationId) => {
      dispatch(
        ContourActions.insertContourConfig(contourConfig, scenarioId, simulationId)
      );
    },
    cancelContourEdit: () => {
      dispatch(ContourActions.cancelContourEdit());
    },
    updateContourConfig: (contourConfig, scenarioId, simulationId) => {
      dispatch(ContourActions.updateContourConfig(contourConfig, scenarioId, simulationId)
      );
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ContourEditForm);
