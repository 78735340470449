/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { Menu, Popup } from "semantic-ui-react";
import PanelWrapper from "../common/panelWrapper.jsx";
import DataFilterMenu from "./panels/dataFilterMenu";
import LegendsTool from "./panels/legendsTool.jsx";
import MeasuringTool from "./panels/measuringTool.jsx";
import AuditMenu from './panels/audit/auditMenu.jsx';
import SearchBar from './panels/search/searchbar.jsx'
import OutputMenu from "./panels/output/outputMenu";
import { ReactComponent as RulerIcon } from "../../images/Ruler.svg";
import { ReactComponent as LegendIcon } from "../../images/Legends.svg";
import "./sidebar.scss";

const INCLUDE_SCENARIOS = window.env.REACT_APP_INCLUDE_SCENARIOS === "true";
const INCLUDE_SIMULATIONS = INCLUDE_SCENARIOS && window.env.REACT_APP_INCLUDE_SIMULATIONS === "true";

class Sidebar extends React.Component {
  state = {
    activeItem: null,
    mounted: false,
  };

  componentDidMount = () => {
    setTimeout(() => this.setState({ mounted: true }), 10);
  };

  componentDidUpdate(prevProps) {
    const currentSelectedSimulationState = this.props.simulationState.selectedSimulation;
    const previouslySelectedSimulationState = prevProps.simulationState.selectedSimulation;

    // Need to close the open legends if we switch between simulations or create / copy a new one
    if (this.state.activeItem === "simulationFilterTool" || this.state.activeItem === "legendsTool") {
      // If unselecting simulation
      if (!currentSelectedSimulationState && previouslySelectedSimulationState) {
        this.setState({ activeItem: null });
      }
      // If switching from to a simulation that is not in FINISHED or RUNNING status
      else if (currentSelectedSimulationState && currentSelectedSimulationState.status !== "RUNNING"
        && currentSelectedSimulationState.status !== "FINISHED") {
        this.setState({ activeItem: null });
      }
    }
  }

  handleItemClick = (e, { name }) => {
    if (this.state.activeItem === name) {
      this.setState({ activeItem: null });
    } else {
      this.setState({ activeItem: name });
    }
  };

  cancel = () => {
    this.setState({ activeItem: null });
  };

  getContent = (t) => {
    if (this.state.activeItem === "measuringTool") {
      return (
        <PanelWrapper
          key="p1"
          title={t("measuringTool.measuringTool")}
          cancel={() => this.handleItemClick(null, { name: null })}
        >
          <MeasuringTool />
        </PanelWrapper>
      );
    }
    if (this.state.activeItem === "legendsTool") {
      return (
        <PanelWrapper
          key="p2"
          title={t("legendsTool.legendsTool")}
          cancel={() => this.handleItemClick(null, { name: null })}
        >
          <LegendsTool />
        </PanelWrapper>
      );
    }
    return null;
  };

  render() {
    const { t } = this.props;
    const selectedSimulationState = this.props.simulationState.selectedSimulation;

    var mountedStyling = "";
    if (this.state.mounted) {
      mountedStyling = "mounted";
    }

    return (
      <div className="right-sidebar">
        <div className={"right sidebar-div " + mountedStyling}>
          <Menu vertical className="left">
            
            <SearchBar
              activeItem={this.state.activeItem}
              onClick={this.handleItemClick}
              cancel={() => this.handleItemClick(null, { name: null })}
            />

            <Popup
              className="popup"
              mouseEnterDelay={300}
              trigger={
                <Menu.Item
                  className="icon-container"
                  name="measuringTool"
                  active={this.state.activeItem === "measuringTool"}
                  onClick={this.handleItemClick}
                >
                  <RulerIcon className="sidebar-icon" />
                </Menu.Item>
              }
              position={"right center"}
            >
              {t("measuringTool.measuringTool")}
            </Popup>

            {INCLUDE_SIMULATIONS && selectedSimulationState && (selectedSimulationState.status === "RUNNING" || selectedSimulationState.status === "FINISHED") ?
              <Popup
                className="popup"
                mouseEnterDelay={300}
                trigger={
                  <Menu.Item
                    className="icon-container"
                    name="legendsTool"
                    active={this.state.activeItem === "legendsTool"}
                    onClick={this.handleItemClick}
                  >
                    <LegendIcon className="sidebar-icon" />
                  </Menu.Item>
                }
                position={"right center"}
              >
                {t("legendsTool.legendsTool")}
              </Popup> : null}

              {INCLUDE_SIMULATIONS ?
                <OutputMenu
                  name="simulationFilterTool"
                  title={t("simulationFilterTool.simulationFilterTool")}
                  activeItem={this.state.activeItem}
                  onClick={this.handleItemClick}
                  cancel={() => this.handleItemClick(null, { name: null })}
                /> : null}

              {INCLUDE_SCENARIOS && this.props.scenarioState.scenario !== null ?
                <AuditMenu
                  activeItem={this.state.activeItem}
                  onClick={this.handleItemClick}
                  cancel={() => this.handleItemClick(null, { name: null })}
                /> : null}

          </Menu>
        </div>

        {this.getContent(t)}
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    simulationState: store.simulationState,
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {})
)(Sidebar);
