/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Accordion, Button, Checkbox, Form, Icon, Message, Popup, Radio, Select } from "semantic-ui-react";
import { Actions } from "../../../services/incident/actions.jsx";
import { IncidentServices } from "../../../services/incident/incidentServices.jsx";
import { DataUtil } from "../../common/DataUtil.jsx";
import { IncidentsUtils } from "../../utils/IncidentsUtils.jsx";
import FormFieldDate from "../../common/formFieldDate";
import InputFieldWithUnits from "../../common/inputFieldWithUnits.jsx";
import ShapeCreateEdit from "./shapeCreateEditor.jsx";
import "./sources.scss";

const TEN_MINS_IN_S = 10 * 60;
const INSTANTANEOUS = "instantaneous";
const CONTINUOUS = "continuous";

class PointPropertiesCard extends React.Component {

  constructor(props) {
    super(props);
    var source = props.incidentState.editingIncident.sources[0];

    // Don't call this.setState() here!
    this.state = {
      name: props.incidentState.editingIncident.name,
      id: source.id,
      incidentId: props.incidentState.editingIncident.id,
      materials: [],
      geometry: source.geometry,
      geoJson: source.geoJson,
      coordinateTextInvalid: false,
      mass: source.mass,
      sigmaX: source.sigmaX,
      sigmaY: source.sigmaY,
      sigmaZ: source.sigmaZ,
      duration: source.duration,
      materialId: source.materialId,
      isLiquidVapour: false,
      isLiquidMaterial: false,
      rate: this.getRate(source.mass, source.duration),
      radioChoice: source.duration > 1 ? CONTINUOUS : INSTANTANEOUS,
      loading: false,
      error: null,
      startTime: source.startTime ? source.startTime : new Date().toISOString(),
      sourceTermType: "PointSource",
      meanParticleDropletSize: source.meanParticleDropletSize,
      stdDevParticleDropletSize: source.stdDevParticleDropletSize,
      showAdvancedParameters: false,
      fieldErrors: [],

      incidentType: props.incidentState.editingIncident.incidentType,
      agentType: source.agentType,
      materialName: source.materialName,
      releaseHeight: source.releaseHeight
        ? source.releaseHeight
        : source.geometry.coordinates[2],
      temperature: source.temperature ? source.temperature : ""
    };
  }

  componentDidMount() {
    IncidentServices.fetchMaterials({ agentType: this.state.agentType, archived: false }).then(
      (materials) => {
        let materialId = this.state.materialId;
        let materialName = this.state.materialName;
        if (materialId === null && materials.length > 0) {
          materialId = materials[0].id;
          materialName = materials[0].name;
        }

        // Determines whether the material is a liquid and whether it is a vapour
        // based on it's values of stdDevParticleDropletSize and meanParticleDropletSize
        let isLiquidVapour = false;
        let isLiquidMaterial = false;
        const source = this.props.incidentState.editingIncident.sources[0];
        const material = materials.find((m) => m.id === materialId);
        if (material) {
          if (material.materialType === "LIQUID") {
            isLiquidMaterial = true;
            if (
              source.stdDevParticleDropletSize === 0 &&
              source.meanParticleDropletSize === -1
            ) {
              isLiquidVapour = true;
            }
          }
        } else {
          materialId = null;
          materialName = null;
        }

        this.setState({
          materialId: materialId,
          materialName: materialName,
          materials: materials,
          isLiquidMaterial: isLiquidMaterial,
          isLiquidVapour: isLiquidVapour,
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    var { t } = this.props;
    this.validateFields(prevState);

    if (this.state.materialId !== prevState.materialId && this.state.materialId !== null) {
      var material = this.state.materials.filter(
        (m) => m.id === this.state.materialId
      )[0];
      if (material.materialType === "LIQUID") {
        this.setState({ isLiquidMaterial: true, isLiquidVapour: false });
      } else {
        this.setState({ isLiquidMaterial: false, isLiquidVapour: false });
      }
    }

    // If the component is in loading state - check the progress of the source update/insertion
    if (this.state.loading) {
      // if the incidentState has changed its loading property from true to false - check for errors
      // No need to check for success as on success the panel will change back to main panel
      if (prevProps.incidentState.loading && !this.props.incidentState.loading) {
        if (this.props.incidentState.error) {
          this.setState({ loading: false, error: t("source.messages.failedToAddUpdate") })
        }
      }
    }
  }

  validateFields = (prevState) => {
    var { t } = this.props;
    var fieldErrors = {};

    if (prevState.name != this.state.name) {
      fieldErrors["name"] = this.getValidMessage(
        this.state.name,
        () => {
          return true;
        },
        ""
      );
    }
    if (prevState.materialId !== this.state.materialId) {
      if (!this.state.materialId) {
        fieldErrors["material"] = t("source.messages.material");
      } else {
        fieldErrors["material"] = false;
      }
    }
    if (
      prevState.mass !== this.state.mass ||
      prevState.radioChoice !== this.state.radioChoice
    ) {
      if (this.state.radioChoice === INSTANTANEOUS) {
        fieldErrors["mass"] = this.getValidMessage(
          this.state.mass,
          this.isNumberGreaterThanZero,
          t("source.messages.mass")
        );
        fieldErrors["rate"] = false;
      } else {
        fieldErrors["rate"] = this.getValidMessage(
          this.state.rate,
          this.isNumberGreaterThanZero,
          t("source.messages.rate")
        );
        fieldErrors["mass"] = false;
      }
    }
    if (
      prevState.duration !== this.state.duration ||
      prevState.radioChoice !== this.state.radioChoice
    ) {
      fieldErrors["duration"] = this.getValidMessage(
        this.state.duration,
        this.validateDuration,
        t("source.messages.duration")
      );
    }

    var hasLiquidVapourChanged = prevState.isLiquidVapour !== this.state.isLiquidVapour;
    var hasStdDevChanged = prevState.stdDevParticleDropletSize !== this.state.stdDevParticleDropletSize;
    var hasMeanChanged = prevState.meanParticleDropletSize !== this.state.meanParticleDropletSize;

    if (hasLiquidVapourChanged || hasStdDevChanged || hasMeanChanged) {
      if (this.state.isLiquidVapour) {
        fieldErrors["meanDropletSize"] = false;
        fieldErrors["stdDevSize"] = false;
      }
      else {
        if (hasStdDevChanged || hasLiquidVapourChanged) {
          fieldErrors["stdDevSize"] = this.getValidMessage(
            this.state.stdDevParticleDropletSize,
            this.isNumberGreaterOrEqZero,
            t("source.messages.stdDevSize")
          );
        }
        if (hasMeanChanged || hasLiquidVapourChanged) {
          fieldErrors["meanDropletSize"] = this.getValidMessage(
            this.state.meanParticleDropletSize,
            this.validateSmallSize,
            t("source.messages.meanParticleDropletSize")
          );
        }
      }
    }

    if (prevState.sigmaX !== this.state.sigmaX) {
      fieldErrors["sigmaX"] = this.getValidMessage(
        this.state.sigmaX,
        this.isNumberGreaterThanZero,
        t("source.messages.sigma")
      );
    }
    if (prevState.sigmaY !== this.state.sigmaY) {
      fieldErrors["sigmaY"] = this.getValidMessage(
        this.state.sigmaY,
        this.isNumberGreaterThanZero,
        t("source.messages.sigma")
      );
    }
    if (prevState.sigmaZ !== this.state.sigmaZ) {
      fieldErrors["sigmaZ"] = this.getValidMessage(
        this.state.sigmaZ,
        this.isNumberGreaterThanZero,
        t("source.messages.sigma")
      );
    }
    if (prevState.releaseHeight !== this.state.releaseHeight) {
      fieldErrors["releaseHeight"] = this.getValidMessage(
        this.state.releaseHeight,
        this.isNumberGreaterOrEqZero,
        t("source.messages.releaseHeight")
      );
    }
    if (prevState.temperature !== this.state.temperature) {
      let nonwhitespace = /[^\s]/;
      let isEmpty = this.state.temperature === null
        || this.state.temperature === undefined
        || !this.state.temperature.toString().match(nonwhitespace);
      let isValid = this.isNumberGreaterOrEqZero(this.state.temperature) && this.state.temperature < 5000.0;

      if (isEmpty || isValid) {
        fieldErrors["temperature"] = false;
      } else {
        fieldErrors["temperature"] = t("source.messages.temperature");
      }
    }
    if (Object.keys(fieldErrors).length) {
      fieldErrors = Object.assign({}, this.state.fieldErrors, fieldErrors);
      this.setState({ fieldErrors: fieldErrors });
    }
  };

  getValidMessage = (field, valid, message) => {
    let emptyMessage = this.getEmptyMessage(field);
    if (emptyMessage) {
      return emptyMessage;
    }
    if (!valid(field)) {
      return message;
    } else {
      return false;
    }
  };

  getEmptyMessage = (field) => {
    var { t } = this.props;
    let nonwhitespace = /[^\s]/;
    let valid = field != undefined && field.toString().match(nonwhitespace);
    return !valid ? t("source.messages.emptyError") : "";
  };

  validateDuration = () => {
    let validInst =
      this.state.radioChoice === INSTANTANEOUS &&
      this.state.duration === 1 &&
      !isNaN(this.state.duration);
    let validCont =
      this.state.radioChoice === CONTINUOUS &&
      this.state.duration > 1 &&
      !isNaN(this.state.duration);
    return validCont || validInst;
  };

  validateSmallSize = (value) => {
    return value >= 0.000001 && !isNaN(value);
  };

  isNumberGreaterOrEqZero = (value) => {
    return !isNaN(value) && value >= 0;
  };

  isNumberGreaterThanZero = (value) => {
    return value > 0 && !isNaN(value);
  };

  getRate = (mass, duration) => {
    return mass / duration;
  };

  getMass = (rate, duration) => {
    return rate * duration;
  };

  handleInstOrContChange = (value) => {
    let newState = Object.assign({}, this.state, {
      radioChoice: value,
    });
    // Instantaneous duration is exactly 1
    if (value == INSTANTANEOUS) {
      newState.duration = 1;
    } else {
      // If continuous, default to 10 mins
      newState.duration = TEN_MINS_IN_S;
    }
    // Default mass to 1 kg
    newState.mass = 1;
    // Calculate rate
    newState.rate = this.getRate(newState.mass, newState.duration);
    this.setState(newState);
  };

  finishSource = (e) => {
    this.setState({ loading: true });

    var geoJson = DataUtil.addHeightToGeoJson(
      this.state.geoJson,
      this.state.releaseHeight
    );

    var source = Object.assign({}, this.state, {
      startTime: this.state.startTime,
      releaseHeight: this.state.releaseHeight,
      temperature: this.state.temperature,
      meanParticleDropletSize: this.state.meanParticleDropletSize,
      stdDevParticleDropletSize: this.state.stdDevParticleDropletSize,
      geoJson: geoJson,
      geometry: geoJson.geometry,
      sigmaX: this.state.sigmaX,
      sigmaY: this.state.sigmaY,
      sigmaZ: this.state.sigmaZ,
    });

    if (this.state.isLiquidVapour) {
      source = Object.assign({}, source, {
        meanParticleDropletSize: -1,
        stdDevParticleDropletSize: 0,
      });
    }

    this.props.finishSource(
      source,
      this.props.simulationState.selectedSimulation.id,
      this.props.scenarioState.scenario.id
    );
  };

  validate = () => {
    if (!this.state.materialId) {
      return false;
    }
    if (!this.state.startTime) {
      return false;
    }
    if (!this.state.geoJson) {
      return false;
    }
    if (this.state.coordinateTextInvalid) {
      return false;
    }
    if (
      !Array.from(Object.values(this.state.fieldErrors)).every(
        (v) => v === false
      )
    ) {
      return false;
    }

    return true;
  };

  setMass = (value) => {
    this.setState({
      mass: value,
      rate: this.getRate(value, this.state.duration),
    });
  };

  setDuration = (value) => {
    this.setState({
      duration: value,
      mass: this.getMass(this.state.rate, value)
    });
  };

  setRate = (value) => {
    this.setState({
      rate: value,
      mass: this.getMass(value, this.state.duration),
    });
  };

  selectInstOrCont = (value) => {
    if (value === this.state.radioChoice) {
      return "trapezoid";
    }
    return;
  };

  showAdvancedParameters = () => {
    this.setState({
      showAdvancedParameters: !this.state.showAdvancedParameters,
    });
  };

  setMaterial = (e, data) => {
    this.setState({
      materialId: data.value,
      materialName: data.options.find(option => option.value === data.value).text,
    });
  };

  liquidSourceCheckBox = () => {
    var { t } = this.props;

    if (this.state.isLiquidMaterial) {
      return (
        <Form.Group>
          <Form.Field>
            <Checkbox
              checked={this.state.isLiquidVapour}
              label={t("label.isLiquidVapour")}
              onChange={(e, value) =>
                this.setState({ isLiquidVapour: value.checked })
              }
            />
          </Form.Field>
        </Form.Group>
      );
    }
    return null;
  };

  getAdvancedParameters = () => {
    const { t } = this.props;
    return (
      <>
        <Form.Group>
          <InputFieldWithUnits
            inline
            required={true}
            label={t("incident.releaseHeight")}
            unit="m"
            value={this.state.releaseHeight}
            type="SIZE"
            setValue={(v) => this.setState({ releaseHeight: v })}
            disabled={false}
            error={this.state.fieldErrors["releaseHeight"]}
          />
        </Form.Group>

        <Popup
          className="popup"
          mouseEnterDelay={300}
          trigger={
            <Form.Group>
              <InputFieldWithUnits
                inline
                required={false}
                label={t("incident.temperature")}
                unit="degC"
                value={this.state.temperature}
                type="TEMPERATURE"
                setValue={(v) => this.setState({ temperature: v })}
                disabled={false}
                error={this.state.fieldErrors["temperature"]}
              />
            </Form.Group>
          }
          content={t("incident.temperatureTooltip")} >
        </Popup>

        {this.liquidSourceCheckBox()}

        <Form.Group>
          <InputFieldWithUnits
            inline
            required={true}
            label={t("incident.meanDropletSize")}
            unit="μm"
            value={this.state.meanParticleDropletSize}
            type="SIZE"
            setValue={(v) => this.setState({ meanParticleDropletSize: v })}
            disabled={this.state.isLiquidVapour}
            error={this.state.fieldErrors["meanDropletSize"]}
          />
        </Form.Group>

        <Form.Group>
          <InputFieldWithUnits
            inline
            required={true}
            label={t("incident.stdDevSize")}
            unit="μm"
            value={this.state.stdDevParticleDropletSize}
            type="SIZE"
            setValue={(v) => this.setState({ stdDevParticleDropletSize: v })}
            disabled={this.state.isLiquidVapour}
            error={this.state.fieldErrors["stdDevSize"]}
          />
        </Form.Group>

        <Form.Group>
          <InputFieldWithUnits
            inline
            required={true}
            upward={true}
            label={t("incident.releaseSizeX")}
            unit="m"
            value={this.state.sigmaX}
            type="SIZE"
            setValue={(v) => this.setState({ sigmaX: v })}
            disabled={false}
            error={this.state.fieldErrors["sigmaX"]}
          />
        </Form.Group>

        <Form.Group>
          <InputFieldWithUnits
            inline
            required={true}
            upward={true}
            label={t("incident.releaseSizeY")}
            unit="m"
            value={this.state.sigmaY}
            type="SIZE"
            setValue={(v) => this.setState({ sigmaY: v })}
            disabled={false}
            error={this.state.fieldErrors["sigmaY"]}
          />
        </Form.Group>

        <Form.Group>
          <InputFieldWithUnits
            inline
            required={true}
            upward={true}
            label={t("incident.releaseSizeZ")}
            unit="m"
            value={this.state.sigmaZ}
            type="SIZE"
            setValue={(v) => this.setState({ sigmaZ: v })}
            disabled={false}
            error={this.state.fieldErrors["sigmaZ"]}
          />
        </Form.Group>
      </>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="content-body">
          <Form className="ua-form">
            <Form.Group>
              <Form.Field>
                <Form.Input
                  inline
                  required={true}
                  label={t("sidebar.name")}
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  error={this.state.fieldErrors["name"]}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field
                required={true}
                label={t("sidebar.material")}
                inline
                search={IncidentsUtils.searchThroughMaterials}
                selected
                control={Select}
                options={IncidentsUtils.createMaterialsDropdownOptions(this.state.materials)}
                placeholder={t("sidebar.selectMaterial")}
                value={this.state.materialId}
                onChange={(e, data) => this.setMaterial(e, data)}
                error={this.state.fieldErrors["material"]}
              />
            </Form.Group>

            <Form.Group>
              <FormFieldDate
                t={t}
                dateFormat={this.props.userState.user.preferences.dateFormat}
                label={t("incident.date")}
                required={true}
                initialValue={new Date(this.state.startTime)}
                onChange={(value) => {
                    this.setState({ startTime: value && value.toISOString() })
                }}
              />
            </Form.Group>

            <Form.Group className="location-field">
              <Form.Field required={true}>
                <label>{t("incident.location")}</label>
                <ShapeCreateEdit
                  className="ui input"
                  shape="MARKER"
                  setGeoJson={(geoJ) => this.setState({ geoJson: geoJ })}
                  geoJson={this.state.geoJson}
                  onCoordinatesInputErrorStateChange={(value) =>
                    this.setState({ coordinateTextInvalid: value })}
                />
              </Form.Field>
            </Form.Group>

            <div className="separator" />

            <Form.Group inline>
              <Form.Field inline>
                <label>{t("label.type")}</label>
                <div className="ui input radio">
                  <Radio
                    label={t("incident.instantaneous")}
                    name="nameGroup"
                    value={INSTANTANEOUS}
                    checked={this.state.radioChoice === INSTANTANEOUS}
                    onChange={(e, { value }) =>
                      this.handleInstOrContChange(INSTANTANEOUS)
                    }
                  />
                  <Radio
                    label={t("incident.continuous")}
                    name="nameGroup"
                    value={CONTINUOUS}
                    checked={this.state.radioChoice === CONTINUOUS}
                    onChange={(e, { value }) =>
                      this.handleInstOrContChange(CONTINUOUS)
                    }
                  />
                </div>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <InputFieldWithUnits
                inline
                required={true}
                label={t("incident.totalMass")}
                unit="kg"
                value={this.state.mass}
                type="MASS"
                setValue={(v) => this.setMass(v)}
                disabled={this.state.radioChoice === CONTINUOUS}
                error={this.state.fieldErrors["mass"]}
              />
            </Form.Group>

            <Form.Group>
              <InputFieldWithUnits
                inline
                required={true}
                upward={true}
                label={t("incident.duration")}
                unit="min"
                value={this.state.duration}
                displayBlank={this.state.radioChoice === INSTANTANEOUS}
                type="DURATION"
                setValue={(v) => this.setDuration(v)}
                disabled={this.state.radioChoice === INSTANTANEOUS}
                error={this.state.fieldErrors["duration"]}
              />
            </Form.Group>

            <Form.Group>
              <InputFieldWithUnits
                inline
                required={true}
                upward={true}
                label={t("incident.rate")}
                unit="kg/min"
                value={this.state.rate}
                displayBlank={this.state.radioChoice === INSTANTANEOUS}
                type="RATE"
                setValue={(v) => this.setRate(v)}
                disabled={this.state.radioChoice === INSTANTANEOUS}
                error={this.state.fieldErrors["rate"]}
              />
            </Form.Group>

            <label className="error-label">
              {this.state.error ? this.state.error : null}
            </label>

            <Accordion>
              <Accordion.Title
                onClick={this.showAdvancedParameters}
                className="advanced-parameters"
                active={this.state.showAdvancedParameters}
              >
                <Icon name="dropdown" />
                {t("incident.advancedParameters")}
              </Accordion.Title>
              <Accordion.Content className="accordion-scroll" active={this.state.showAdvancedParameters}>
                {this.getAdvancedParameters()}
              </Accordion.Content>
            </Accordion>

            {this.state.error ? (
              <Message negative className="ua-error">
                <Message.Header>{t("app.error")}</Message.Header>
                <p>{this.state.error}</p>
              </Message>
            ) : null}

          </Form>
        </div>

        <div className="bottom-bar">
          <div className="finished">
            <Button
              type="button"
              disabled={!this.validate() || this.state.loading}
              onClick={this.finishSource}
              loading={this.state.loading}
            >
              {t("sidebar.finished")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    simulationState: store.simulationState,
    incidentState: store.incidentState,
    scenarioState: store.scenarioState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    finishSource: (source, simulationId, scenarioId) => {
      dispatch(Actions.finishSource(source, simulationId, scenarioId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PointPropertiesCard);
