import L from "leaflet";
import { MapControl, withLeaflet } from "react-leaflet";
import Requests from '../../../services/requests';

class RasterDataLegend extends MapControl {

  constructor(props) {
    super(props);
  }

  state = {
    show: false,
    currentZoom: -1,
    index: -1
  }

  createLeafletElement() {
  }

  async createLegend() {

    var bounds = this.props.leaflet.map.getBounds();
    var swLat = bounds.getSouthWest().lat;
    var swLon = bounds.getSouthWest().lng;
    var neLat = bounds.getNorthEast().lat;
    var neLon = bounds.getNorthEast().lng;
    var url = this.props.url + swLat + "," + swLon + "," + neLat + "," + neLon;

    return await Requests.get(url)
      .then((jsonData) => {
        if (jsonData) {

          // Check whether the index changed and whether the legend was previously created
          if (jsonData.index == this.state.index && this.legend) {
            // Legend has not changed index - no need to recreate - exit here
            return null;
          }

          let labelsToAdd = [];

          // if response data has type value 'image' render legend as an image
          if (jsonData.type === 'image') {

            // Add title
            labelsToAdd.push("<h4>" + this.props.layerTitle + "</h4>");
            labelsToAdd.push('<img src=' + jsonData.data + '></img>');
            return labelsToAdd;
          }

          if (!jsonData || !jsonData.Legend[0] || !jsonData.Legend[0].rules[0] || !jsonData.Legend[0].rules[0].symbolizers[0]
            || !jsonData.Legend[0].rules[0].symbolizers[0].Raster || !jsonData.Legend[0].rules[0].symbolizers[0].Raster.colormap) {
            // Structure changed - return null as not to error
            return null;
          }

          let colormap = jsonData.Legend[0].rules[0].symbolizers[0].Raster.colormap;

          // Legend has changed index - create new legend
          this.setState({ index: jsonData.index });

          // Add title
          labelsToAdd.push("<h4>" + this.props.layerTitle + "</h4>");

          // Add entries
          for (let i = colormap.entries.length - 1; i >= 0; i--) {
            const colourMapEntry = colormap.entries[i]; // eslint-disable-line security/detect-object-injection

            let label = colourMapEntry.label;
            // If the label is a number, process it
            if (!isNaN(label)) {
              // Round value to 2 d.p.
              let labelValue = parseFloat(colourMapEntry.label);
              labelValue = Math.round((labelValue + Number.EPSILON) * 100) / 100;
              label = labelValue + this.props.unitsLabel;
            }
            labelsToAdd.push('<i style="background:' + colourMapEntry.color + '"></i> ' + label);
          }
          return labelsToAdd;
        } else {
          // No data - viewport is outside bounding box. When zoomed out, no box will be shown.
          var labels = [];
          labels.push("<b>" + this.props.noDataLabel + "</b>");
          this.setState({ index: -1 });
          return labels;
        }
      })
      .then((labels) => {
        if (labels) {
          if (this.legend) {
            // If a legend exists remove it from map
            this.legend.remove();
          }
          this.legend = L.control({ name: this.props.layerName + "Legend", position: "bottomright" });

          this.legend.onAdd = () => {
            const div = L.DomUtil.create("div", "data-legend legend");

            div.innerHTML = labels.join("<br>");
            return div;
          };
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addLegendToMap() {

    if (!this.legend) {
      // Legend hasn't be created yet
      return;
    }

    var switchZoomLevel = 7;
    if (this.props.leaflet.map.getZoom() < switchZoomLevel) {
      // Hide - too far away
      this.legend.remove();
    } else {
      // Show - close enough
      this.legend.addTo(this.props.leaflet.map);
    }
  }

  addLegend = async (event) => {
    if (event.layer.options.id === this.props.layerName) {
      await this.createLegend();
      this.setState({ show: true });
      this.addLegendToMap();
    }
  }

  removeLegend = (event) => {
    if (event.layer.options.id === this.props.layerName) {
      this.setState({ show: false });
      if (this.legend) {
        this.legend.remove();
      }
    }
  }

  updateLegend = async (event) => {
    // Do not update legend if it is not being displayed
    if (!this.state.show) {
      return;
    }

    // Check zoom level changed
    var newZoom = this.props.leaflet.map.getZoom();
    if (newZoom != this.state.currentZoom) {
      this.addLegendToMap();
      this.setState({ currentZoom: newZoom });
    }

    if (await this.createLegend()) {
      this.addLegendToMap();
    }
  }

  componentDidMount() {
    this.props.leaflet.map.on('overlayadd', this.addLegend);
    this.props.leaflet.map.on('overlayremove', this.removeLegend);
    this.props.leaflet.map.on('moveend', this.updateLegend);
  }

  componentWillUnmount() {
    // Needed to explicitly override MapControl componentWillUnmount method
  }

}

export default withLeaflet(RasterDataLegend);
