/*
    ./client/components/App.jsx
*/
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form } from "semantic-ui-react";
import PanelWrapper from "../../common/panelWrapper.jsx";
import ScenarioEditPopup from "../../common/popups/scenarioEditPopup.jsx";
import "../sidebar.scss";

class ScenarioPanel extends React.Component {

  getScenarioForm = () => {
    const { t } = this.props;
    return (
      <Form className="ua-form content-body">
        <Form.Group>
          <Form.Field>
            <Form.Input
              inline
              readOnly
              label={t("scenario.scenarioName")}
              value={this.props.scenarioState.scenario.name}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Form.TextArea
              inline
              readOnly
              label={t("sidebar.notes")}
              value={this.props.scenarioState.scenario.notes}
              rows={3}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <PanelWrapper title={t("sidebar.scenario")} cancel={this.props.cancel}>
        {this.getScenarioForm()}
        <div className="bottom-bar">
          <div className="finished">
            <ScenarioEditPopup
              trigger={
                <Button
                  loading={this.props.scenarioState.updatingScenario}
                >
                  {t("sidebar.edit")}
                </Button>
              } />
          </div>
        </div>
      </PanelWrapper>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    scenarioState: store.scenarioState,
    ...props,
  };
};

export default compose(withTranslation(), connect(mapStateToProps))(ScenarioPanel);
