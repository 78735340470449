import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    Button,
    Checkbox,
    Form
} from "semantic-ui-react";
import "whatwg-fetch";
import { Error } from "../../../../classes/Error.jsx";
import { Info } from "../../../../classes/Info.jsx";
import {
    SimulationActions,
    UPDATE_RUN_OPTIONS_FAILURE,
    UPDATE_RUN_OPTIONS_SUCCESS,
    UPDATE_SIMULATION_FAILURE,
    UPDATE_SIMULATION_SUCCESS
} from "../../../../services/simulation/actions.jsx";
import InputFieldWithUnits from "../../../common/inputFieldWithUnits.jsx";
import StatusMessage from "../../../common/StatusMessage";
import "../../aois/styles.scss";
import "../../sidebar.scss";
import "../../sources/sources.scss";

/**
 * View to allow user to edit simulation and output settings and run it
 */
class RunOptions extends React.Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        let simulation = this.props.simulationState.selectedSimulation;
        var { t } = props;

        var buildingInteractionsOptions = [
            {
                id: 1,
                name: t("simulation.buildingModes.OFF"),
                value: "OFF",
            },
            {
                id: 2,
                name: t("simulation.buildingModes.NORMAL"),
                value: "NORMAL",
            },
            {
                id: 3,
                name: t("simulation.buildingModes.HIGH"),
                value: "HIGH",
            },
        ];

        this.state = {
            name: simulation.name,
            saveSimulation: false,

            //Advanced Parameters
            buildingInteractionsOptions: buildingInteractionsOptions,
            buildingInteractionsOption: simulation.buildingInteractionsOption ? simulation.buildingInteractionsOption : "NORMAL",
            gridResolution: simulation.gridResolution ? simulation.gridResolution : 100,
            gridType: "IRREGULAR_GRID",
            userDefinedOutput: simulation.outputInterval !== 300 || simulation.modelDuration !== 1800,
            outputInterval: simulation.outputInterval ? simulation.outputInterval : 300,
            modelDuration: simulation.modelDuration ? simulation.modelDuration : 1800,
            message: null,
            fieldErrors: [],
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        var { t } = this.props;
        // Check to see if loading has switched from false to true
        if (!prevState.saveSimulation && this.state.saveSimulation) {
            this.updateSimulation();
        }

        this.validateSimulation(prevState);

        // If configurations are in process of being saved - check the progress
        if (this.state.saveSimulation) {
            if (
                !this.props.simulationState.isUpdatingSimulation &&
                prevProps.simulationState.isUpdatingSimulation
            ) {
                if (
                    this.props.simulationState.actionType === UPDATE_SIMULATION_SUCCESS
                ) {
                    this.updateRunOptions();
                } else if (
                    (this.props.simulationState.actionType = UPDATE_SIMULATION_FAILURE)
                ) {
                    this.setState({
                        message: new Error(t("run.messages.failedToUpdateRunOptions")),
                        saveSimulation: false,
                    });
                }
            }

            if (
                !this.props.simulationState.isUpdatingRunOptions &&
                prevProps.simulationState.isUpdatingRunOptions
            ) {
                if (
                    this.props.simulationState.actionType === UPDATE_RUN_OPTIONS_SUCCESS
                ) {
                    this.setState({ saveSimulation: false, message: new Info(t("run.messages.saveSuccess")) });
                }
                // Checks to if the updateRunOptions action creator has finished
                // and that it failed
                else if (
                    this.props.simulationState.actionType === UPDATE_RUN_OPTIONS_FAILURE
                ) {
                    this.setState({
                        message: new Error(t("run.messages.failedToUpdateRunOptions")),
                        saveSimulation: false,
                    });
                }
            }
        }
    };

    validateSimulation = (prevState) => {
        var { t } = this.props;

        var fieldErrors = {};
        // if (prevState.gridResolution !== this.state.gridResolution) {
        //     fieldErrors["gridResolution"] = this.getValidMessage(
        //         this.state.gridResolution,
        //         this.validateGridResolution,
        //         t("simulation.messages.gridResolutionError")
        //     );
        // }
        if (prevState.userDefinedOutput !== this.state.userDefinedOutput || prevState.outputInterval !== this.state.outputInterval) {
            fieldErrors["outputInterval"] = this.getValidMessage(
                this.state.outputInterval,
                this.validateUserDefined,
                t("simulation.messages.outputIntervalError"));
        }
        if (prevState.userDefinedOutput !== this.state.userDefinedOutput || prevState.modelDuration !== this.state.modelDuration) {
            fieldErrors["modelDuration"] = this.getValidMessage(
                this.state.modelDuration,
                this.validateUserDefined,
                t("simulation.messages.modelDurationError")
            );
        }
        if (
            prevState.userDefinedOutput !== this.state.userDefinedOutput ||
            prevState.outputInterval !== this.state.outputInterval ||
            prevState.modelDuration !== this.state.modelDuration
        ) {
            fieldErrors["intervalDuration"] = this.getValidMessage(
                this.state.modelDuration,
                this.validateIntervalAndDuration,
                t("simulation.messages.intervalDurationError")
            );
        }

        if (Object.keys(fieldErrors).length) {
            fieldErrors = Object.assign({}, this.state.fieldErrors, fieldErrors);
            this.setState({ fieldErrors: fieldErrors });
        }
    };

    getValidMessage = (field, valid, message) => {
        let emptyMessage = this.getEmptyMessage(field);
        if (emptyMessage) {
            return emptyMessage;
        }
        if (!valid(field)) {
            return message;
        } else {
            return false;
        }
    };

    getEmptyMessage = (field) => {
        var { t } = this.props;
        let nonwhitespace = /[^\s]/;
        let valid = field != undefined && field.toString().match(nonwhitespace);
        return !valid ? t("source.messages.emptyError") : "";
    };

    // validateGridResolution = () => {
    //     return this.state.gridResolution >= 50 && this.state.gridResolution <= 1000;
    // };

    validateUserDefined = (field) => {
        return (
            !this.state.userDefinedOutput ||
            (this.state.userDefinedOutput && field >= 1)
        );
    };

    validateIntervalAndDuration = () => {
        return (
            !this.state.userDefinedOutput ||
            (this.state.userDefinedOutput &&
                this.state.outputInterval <= this.state.modelDuration)
        );
    };

    validate = () => {
        if (
            !Array.from(Object.values(this.state.fieldErrors)).every(
                (v) => v === false
            )
        ) {
            return false;
        }
        return true;
    };

    saveConfigs = () => {
        this.setState({ saveSimulation: true, message: null });
    };

    updateSimulation = () => {
        // Initiate the simulationDto object to current values of simulation configuration
        const simulationDto = {
            type: 'SimulationConfiguration',
            id: this.props.simulationState.selectedSimulation.id,
            outputInterval: this.props.simulationState.selectedSimulation.outputInterval,
            modelDuration: this.props.simulationState.selectedSimulation.modelDuration
        };

        // If the user ticked to define the values - replace them
        if (this.state.userDefinedOutput) {
            simulationDto.outputInterval = this.state.outputInterval;
            simulationDto.modelDuration = this.state.modelDuration;
        }

        this.props.updateSimulation(simulationDto, this.props.scenarioState.scenario.id);
    };

    updateRunOptions = () => {
        var runOptions = {
            simulationId: this.props.simulationState.selectedSimulation.id,
            gridType: this.state.gridType,
            gridResolution: this.state.gridResolution,
            buildingInteractionsOption: this.state.buildingInteractionsOption,
        };

        this.props.updateRunOptions(runOptions, this.props.scenarioState.scenario.id);
    };

    setbuildingInteractionsOption = (e, data) => {
        this.setState({
            buildingInteractionsOption: data.value,
        });
    };

    checkIfNaN = (value, allowScientificNotation) => {
        // Checks to see if scientific notation is allowed
        if (allowScientificNotation) {
            // Ensures that correct scientific notation is input
            // only allows one e and -, and e must come before -.
            if (value.includes("e")) {
                if (!value.slice(0, -1).includes("e")) {
                    return false;
                }
                if (value.includes("-")) {
                    if (!value.slice(0, -1).includes("-")) {
                        return false;
                    }
                }
            }
        }

        if (isNaN(value)) {
            console.warn("Cannot parse input to a number", value);
            return true;
        }
        return false;
    };

    // setGridResolution = (data) => {
    //     var value = parseInt(data.target.value);
    //     if (!this.checkIfNaN(value)) {
    //         this.setState({ gridResolution: value });
    //     }
    // };

    getContent = () => {
        const { t } = this.props;
        return (

            <Form className="ua-form">

                <Form.Group>
                    <Form.Field>
                        <Form.Select
                            inline
                            className="ui input"
                            label={t("simulation.buildingInteractionsOption")}
                            options={this.state.buildingInteractionsOptions.map((b) => {
                                return { key: b.id, text: b.name, value: b.value };
                            })}
                            value={this.state.buildingInteractionsOption}
                            onChange={(e, data) => this.setbuildingInteractionsOption(e, data)}
                            error={this.state.fieldErrors["buildingInteractionsOption"]}
                        />
                    </Form.Field>
                </Form.Group>

                {/* <Form.Group>
                    <Form.Field error={this.state.fieldErrors["gridResolution"]}>
                        <Form.Input
                            inline
                            label={t("simulation.gridResolution")}
                            value={this.state.gridResolution}
                            onChange={(v) => this.setGridResolution(v)}
                        />
                        {this.state.fieldErrors["gridResolution"] ? (
                            <div className="ui pointing above prompt label">
                                {this.state.fieldErrors["gridResolution"]}
                            </div>
                        ) : null}
                    </Form.Field>
                </Form.Group> */}

                <Form.Group>
                    <Form.Field>
                        <Checkbox
                            label={t("simulation.userDefinedOutputTimes")}
                            checked={this.state.userDefinedOutput}
                            onChange={(e, value) =>
                                this.setState({ userDefinedOutput: value.checked })
                            }
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <InputFieldWithUnits
                        inline
                        className="ui input"
                        required={true}
                        label={t("simulation.outputInterval")}
                        unit="min"
                        value={this.state.outputInterval}
                        type="DURATION"
                        setValue={(v) => this.setState({ outputInterval: v })}
                        disabled={!this.state.userDefinedOutput}
                        error={this.state.fieldErrors["outputInterval"]}
                    />
                </Form.Group>

                <Form.Group>
                    <InputFieldWithUnits
                        inline
                        required={true}
                        label={t("simulation.modelDuration")}
                        unit="min"
                        value={this.state.modelDuration}
                        type="DURATION"
                        setValue={(v) => this.setState({ modelDuration: v })}
                        disabled={!this.state.userDefinedOutput}
                        error={this.state.fieldErrors["modelDuration"]}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Field error={this.state.fieldErrors["intervalDuration"]}>
                        {this.state.fieldErrors["intervalDuration"] ? (
                            <div className="ui pointing above prompt label">
                                {this.state.fieldErrors["intervalDuration"]}
                            </div>
                        ) : null}
                    </Form.Field>
                </Form.Group>

                {this.state.message && //StatusMessage won't render is this.state.message is falsy
                    <StatusMessage
                        isError={this.state.message.isError}
                        messageHeader={this.state.isError ? t('app.error') : t('app.success')}
                        messageContent={this.state.message.content} />
                }

            </Form>
        );
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="runSimulation-panel content-body">
                    {this.getContent()}
                </div>
                <div className="bottom-bar">
                    <div className="finished">
                        <Button
                            type="button"
                            onClick={() => this.saveConfigs()}
                            loading={this.state.saveSimulation}
                            disabled={!this.validate()}
                        >
                            {t("simulation.saveConfigs")}
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
    return {
        simulationState: store.simulationState,
        contourConfigState: store.contourConfigState,
        metState: store.metState,
        incidentState: store.incidentState,
        resultsState: store.resultsState,
        scenarioState: store.scenarioState,
        ...props,
    };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateSimulation: (simulation, scenarioId) => {
            dispatch(SimulationActions.updateSimulation(simulation, scenarioId));
        },
        cancelEdit: () => {
            dispatch(SimulationActions.cancelEdit());
        },
        updateRunOptions: (runOptions, scenarioId) => {
            dispatch(SimulationActions.updateRunOptions(runOptions, scenarioId));
        }
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(RunOptions);
