import L from 'leaflet';
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Table } from "semantic-ui-react";
import { Actions } from "../../../services/met/actions.jsx";
import CommonButtonsBar from "../../common/CentreEditAddRemoveButtonBar.jsx";
import { AngleUtils } from '../../utils/AngleUtils.jsx';

/**
 * Panel on which the user may view/delete current met observations, and from which the user navigates to create/edit them
 */
class MetDisplay extends React.Component {
  state = {
    selectedIndex: null,
  };

  getTable = (t) => {
    return (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("met.time")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("met.speed")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("met.direction")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.getRows(t)}</Table.Body>
      </Table>
    );
  };

  selectRow = (index) => {
    var newSelectedIndex = index;
    if (this.state.selectedIndex === index) {
      newSelectedIndex = null;
    }
    this.setState({
      selectedIndex: newSelectedIndex,
    });
  };

  getRows = (t) => {
    let metProfiles = [];
    var simulationId = this.props.simulationState.selectedSimulation.id;
    let metsForCurrentSimulation = Object.values(this.props.metState.metProfiles).filter(function (met) {
      return met.simulationId == simulationId;
    });

    for (let m in metsForCurrentSimulation) {
      let met = metsForCurrentSimulation[m]; // eslint-disable-line security/detect-object-injection
      let ind = met.id;
      metProfiles.push(
        <Table.Row
          key={ind}
          onClick={(e) => this.selectRow(ind)}
          className={this.state.selectedIndex === ind ? "active" : "met-row"}
        >
          <Table.Cell key={ind + "name"} className={"met-cell"}>{met.time.toLocaleString('en-GB')}</Table.Cell>
          <Table.Cell key={ind + "windspeed"} className={"met-cell"}>{met.windSpeed}</Table.Cell>
          <Table.Cell key={ind + "winddirection"} className={"met-cell"}>
            {AngleUtils.convertDegreesToDisplayString(met.windDirection, this.props.userState.user.preferences.windDirectionUnit)}
          </Table.Cell>
        </Table.Row>
      );
    }

    if (metProfiles.length === 0) {
      metProfiles.push(
        <Table.Row key="default">
          <Table.Cell>{t("met.clickPlus")}</Table.Cell>
        </Table.Row>
      );
    }
    return metProfiles;
  };

  flyTo = () => {
    if (this.state.selectedIndex != null) {
      var met = this.props.metState.metProfiles[this.state.selectedIndex];
      var coord = met.geometry.coordinates;
      // Met geometry is  a point - so we can safely fly to the first coordinate
      this.props.mapState.map.flyTo(new L.latLng(coord[1], coord[0]), 15);

    }
  };

  editMet = () => {
    if (this.state.selectedIndex != null) {
      this.props.editMetProfile(this.state.selectedIndex);
    }
  };

  removeMet = () => {
    if (this.state.selectedIndex != null) {
      this.props.removeMetProfile(this.state.selectedIndex, this.props.simulationState.selectedSimulation.id, this.props.scenarioState.scenario.id);
      this.setState({ selectedIndex: null });
    }
  };

  createMet = () => {
    this.props.createMetProfile();
  };

  render() {
    var { t } = this.props;
    return (
      <div className="met-display">
        <div className="sources-table">{this.getTable(t)}</div>
        <div className="table-buttons">
        </div>
        <div className="bottom-bar">
          <CommonButtonsBar
            flyTo={this.flyTo}
            flyToItemDescription={t("met.flyToMet")}
            editItem={this.editMet}
            editItemDescription={t("met.editMet")}
            removeItem={this.removeMet}
            removeItemDescription={t("met.removeMet")}
            createItem={this.createMet}
            createItemDescription={t("met.addMet")}
            selectedIndex={this.state.selectedIndex}
          />
        </div>
      </div>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    simulationState: store.simulationState,
    scenarioState: store.scenarioState,
    metState: store.metState,
    userState: store.userState,
    ...props,
  };
};

/*
 * Maps properties to dispatch methods to send actions to the store reducers
 */
const mapDispatchToProps = (dispatch) => {
  return {
    createMetProfile: () => {
      dispatch(Actions.addMet());
    },
    removeMetProfile: (metId, simulationId, scenarioId) => {
      dispatch(Actions.removeMetProfile(metId, simulationId, scenarioId));
    },
    editMetProfile: (metId) => {
      dispatch(Actions.editMetProfile(metId));
    },
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MetDisplay);
