
export class Error {

    constructor(message) {
        // invokes the setter
        this.content = message;
        this.isError = true;
    }

    get content() {
        return this._content;
    }

    set content(value) {
        this._content = value;
    }

    get isError() {
        return this._isError;
    }

    set isError(value) {
        this._isError = value;
    }

}