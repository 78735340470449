import circleToPolygon from 'circle-to-polygon';
import Requests from '../requests';

export class Utils {

    
    static OFFLINE_MAPS = window.env.REACT_APP_OFFLINE_MAPS === "true";

    /**
     * Fetches the population for given area defined by geojson
     * @param {JSON} geoJson geoJson json object
     * @param {*} success callback on success
     * @param {*} error  callback on error
     */
    static fetchPopulation(geoJson, success, error) {
        // Clone the argument into local variable in order not to mutate the argument
        var geoJsonGeometry = Object.assign({}, geoJson.geometry);

        // Checks to see if a circle, geojson does not support circles
        // But typically a radius is stored on properties which indicates that
        // the geoJson is a cirle
        if (geoJson.properties && geoJson.properties.radius) {
            var centre = geoJson.geometry.coordinates;
            var radius = geoJson.properties.radius;

            // Using circleToPolygon extension since geoJson does not support circles
            // final argument is number of lines polygon consists of
            // Replace the geometry 
            geoJsonGeometry = circleToPolygon(centre, radius, 100);
        }

        Requests.post('/population/', geoJsonGeometry)
            .then((response) => {
                if (response) {
                    success(response);
                } else {
                    error("Error Occurred");
                }
            })
            .catch(err => {
                console.error(err);
                error("Error Occurred");
            });
    }
}