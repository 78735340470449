/*
    ./client/components/App.jsx
*/
import geojsonArea from "@mapbox/geojson-area";
import L from "leaflet";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Form, Icon } from "semantic-ui-react";
import InputFieldWithUnits from "../../common/inputFieldWithUnits.jsx";
import CoordinatesInputField from "../../common/coordinatesInputField.jsx";
import { DataUtil } from "../../common/DataUtil.jsx";
import { MapUtils } from "../../utils/MapUtils.jsx";
import { MapLayerStyles } from "../../common/MapLayerStyles";
import "./sources.scss";

const DRAW_ACTION = "DRAW";
const EDIT_ACTION = "EDIT";


class ShapeCreateEdit extends React.Component {
  constructor(props) {
    super(props);

    var leafletShape;
    var shape = props.shape;
    var style = {
      shapeOptions: MapLayerStyles.getEditingStyle(),
    };
    if (shape === "POLYLINE") {
      leafletShape = new L.Draw.Polyline(this.props.mapState.map, style);
    } else if (shape === "POLYGON") {
      leafletShape = new L.Draw.Polygon(this.props.mapState.map, style);
    } else if (shape === "MARKER") {
      leafletShape = new L.Draw.Marker(this.props.mapState.map, style);
    } else if (shape === "LINE") {
      L.Draw.Polyline.Line = L.Draw.Polyline.extend({
        addVertex: function (latlng) {
          var markersLength = this._markers.length;
          // markersLength must be greater than or equal to 2 before intersections can occur

          if (
            markersLength >= 2 &&
            !this.options.allowIntersection &&
            this._poly.newLatLngIntersects(latlng)
          ) {
            this._showErrorTooltip();
            return;
          } else if (this._errorShown) {
            this._hideErrorTooltip();
          }

          this._markers.push(this._createMarker(latlng));

          this._poly.addLatLng(latlng);

          if (this._poly.getLatLngs().length === 2) {
            this._map.addLayer(this._poly);
          }

          this._vertexChanged(latlng, true);
          markersLength = this._markers.length;
          if (markersLength == 2) {
            this._fireCreatedEvent();
            this.disable();
          }
        },
      });

      leafletShape = new L.Draw.Polyline.Line(this.props.mapState.map, style);
    } else if (shape === "CIRCLE") {
      leafletShape = new L.Draw.Circle(this.props.mapState.map, style);
    }

    var mapSelectionLayer = null;

    if (props.geoJson) {
      mapSelectionLayer = this.getLayer(shape, props.geoJson).addTo(
        this.props.mapState.map
      );
    }

    // Don't call this.setState() here!
    this.state = {
      shape: props.shape,
      drawShape: leafletShape,
      mapSelectionLayer: mapSelectionLayer,
      geoJson: props.geoJson,
      action: null,
      showInfo: false
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div className={this.props.className}>
        <div className="shape-create-field">
          <div className="shape-create-edit-buttons">
            <div className="inline-btns">{this.getContent()}</div>
            {this.getDescription()}
          </div>
        </div>
      </div>
    );
  }

  getContent = () => {
    var { t } = this.props;

    var additionClassName = "";
    if (this.state.showInfo) {
      additionClassName = "show";
    }

    if (!this.state.mapSelectionLayer && !this.state.action) {
      return (
        <Button
          type="button"
          animated="fade"
          onClick={this.drawShape}
          disabled={this.props.readOnly}
        >
          <Button.Content hidden>
            {t("shape.selectLocation")}
          </Button.Content>
          <Button.Content visible>
            <Icon name="map marker" />
          </Button.Content>
        </Button>
      );
    }

    if (this.state.mapSelectionLayer && !this.state.action) {
      return (
        <>
          <Button
            type="button"
            animated="fade"
            onClick={this.drawShape}
            disabled={this.props.readOnly}
          >
            <Button.Content hidden>
              {t("shape.editLocation")}
            </Button.Content>
            <Button.Content visible>
              <Icon name="pencil" />
            </Button.Content>
          </Button>
          <div
            className={"dropdown " + additionClassName}
            onClick={() => this.setState({ showInfo: !this.state.showInfo })}
          >
            <i className="fas fa-caret-down"></i>
          </div>
        </>
      );
    }

    if (this.state.action === DRAW_ACTION || this.state.action === EDIT_ACTION) {

      // If creating a marker - only give an option to cancel
      if (this.state.shape === "MARKER") {
        return (
          <>
            <Button type="button" animated="fade" onClick={this.cancelAction}>
              <Button.Content hidden>
                {t("shape.cancel")}
              </Button.Content>
              <Button.Content visible>
                <Icon name="cancel" />
              </Button.Content>
            </Button>
          </>
        );
      }

      return (
        <>
          <Button type="button" animated="fade" onClick={this.drawShape}>
            <Button.Content hidden>
              {t("shape.done")}
            </Button.Content>
            <Button.Content visible>
              <Icon name="check" />
            </Button.Content>
          </Button>
          <Button type="button" animated="fade" onClick={this.cancelAction}>
            <Button.Content hidden>
              {t("shape.cancel")}
            </Button.Content>
            <Button.Content visible>
              <Icon name="cancel" />
            </Button.Content>
          </Button>
        </>
      );
    }
  };

  getDescription = () => {
    var additionClassName = "";
    if (this.state.showInfo) {
      additionClassName = "show ";
    }
    if (this.props.infoClassName) {
      additionClassName += this.props.infoClassName;
    }

    return (
      <div className={"shape-info " + additionClassName}>
        {this.getPoint()}
        {this.getArea()}
        {this.getPerimeter()}
        {this.getRadius()}
      </div>
    );
  };

  getPoint = () => {
    const { t } = this.props;
    const geoJson = this.props.geoJson;
    if (geoJson) {
      const text = geoJson.properties.radius ? "shape.centreCoordinate" : "shape.coordinate"
      if (geoJson.properties.radius || geoJson.geometry.type === "Point") {
        return <Form.Group>
          <CoordinatesInputField
            label={t(text)}
            coordinates={geoJson.geometry.coordinates}
            coordinateUnit={this.props.userState.user.preferences.coordinateUnit}
            readOnly={this.props.shape !== "MARKER"}
            onCoordinatesChange={this.handleMarkerCoordinatesFormInput}
            onErrorStateChange={this.props.onCoordinatesInputErrorStateChange}
          />
        </Form.Group>
      }
    }
    return null;
  };

  getArea = () => {
    const { t } = this.props;
    if (this.props.geoJson) {
      var geoJson = this.props.geoJson;
      var area;
      if (geoJson.properties.radius && geoJson.properties.radius !== null) {
        //This is circle -
        area = Math.pow(geoJson.properties.radius, 2) * Math.PI;
      } else if (
        geoJson.geometry.type === "Point" ||
        geoJson.geometry.type === "LineString"
      ) {
        return null;
      } else {
        area = geojsonArea.geometry(geoJson.geometry);
      }
      return (
        <Form.Group>
          <InputFieldWithUnits
            label={t("sidebar.area")}
            unit="m2"
            value={Math.ceil(area)}
            type="AREA"
            readOnly
          />
        </Form.Group>
      );
    }
    return null;
  };

  getPerimeter = () => {
    const { t } = this.props;
    if (this.props.geoJson) {
      var geoJson = this.props.geoJson;
      var text = "shape.perimeter";

      if (geoJson.properties.radius && geoJson.properties.radius !== null) {
        //This is circle -
        text = "shape.circumference";
      } else if (geoJson.geometry.type === "Point") {
        // Do not display perimeter info for points
        return null;
      } else if (geoJson.geometry.type === "Polygon " || geoJson.geometry.type === "MultiPolygon") {
        text = "shape.perimeter";
      } else {
        text = "shape.length";
      }

      var length = DataUtil.getGeoJsonLength(geoJson);

      return (
        <Form.Group>
          <Form.Field>
            <InputFieldWithUnits
              label={t(text)}
              unit="m"
              value={Math.ceil(length)}
              type="SIZE"
              readOnly
            />
          </Form.Field>
        </Form.Group>
      );
    }
    return null;
  };

  getRadius = () => {
    const { t } = this.props;
    if (this.props.geoJson) {
      var geoJson = this.props.geoJson;
      if (geoJson.properties.radius && geoJson.properties.radius !== null) {
        var radius = geoJson.properties.radius;
        return (
          <Form.Group>
            <Form.Field>
              <InputFieldWithUnits
                label={t("sidebar.radius")}
                unit="m"
                value={Math.ceil(radius)}
                type="SIZE"
                readOnly
              />
            </Form.Field>
          </Form.Group>
        );
      }
    }
    return null;
  };

  cancelAction = () => {
    this.cancel();

    // Special case if the user is cancelling DRAW state for MARKER
    // If marker has previously been drawn - bring it back to the map
    if (this.state.mapSelectionLayer !== null && this.state.shape === "MARKER") {
      this.props.mapState.map.addLayer(this.state.mapSelectionLayer);
    }

    // Cancel editting - remove the currently loaded layer and replace it with a layer from a copy of geoJson
    if (this.state.action === EDIT_ACTION) {
      if (this.state.mapSelectionLayer && this.state.layerCopy) {
        var oldLayer = this.getLayer(
          this.state.shape,
          this.state.layerCopy
        ).addTo(this.props.mapState.map);
        this.props.mapState.map.removeLayer(this.state.mapSelectionLayer);
        this.setState({
          mapSelectionLayer: oldLayer,
          layerCopy: null,
        });
      }
    }
  };

  getLayer = (shape, geoJson) => {
    // Create a GeoJson layer with reverted coordinates (as map layers have lat-lon whereas GeoJson stores lon-lat)
    var geoJsonLayer = new L.GeoJSON(geoJson, {
      coordsToLatLng: function (coords) {
        //                    latitude , longitude, altitude
        //return new L.LatLng(coords[1], coords[0], coords[2]); //Normal behavior
        return new L.LatLng(coords[0], coords[1], coords[2]);
      },
    });

    if (shape === "POLYGON") {
      return new L.Polygon(
        geoJsonLayer.toGeoJSON().features[0].geometry.coordinates,
        MapLayerStyles.getEditingStyle()
      );
    }
    if (shape === "MARKER") {
      return new L.Marker(
        geoJsonLayer.toGeoJSON().features[0].geometry.coordinates,
        MapLayerStyles.getEditingStyle()
      );
    }
    if (shape === "LINE" || shape === "POLYLINE") {
      return new L.Polyline(
        geoJsonLayer.toGeoJSON().features[0].geometry.coordinates,
        MapLayerStyles.getEditingStyle()
      );
    }
    if (shape === "CIRCLE") {
      return new L.Circle(
        geoJsonLayer.toGeoJSON().features[0].geometry.coordinates,
        geoJson.properties.radius,
        MapLayerStyles.getEditingStyle()
      );
    }
    return null;
  };

  cancel = () => {
    if (this.state.action === DRAW_ACTION) {
      if (this.state.drawShape) {
        this.state.drawShape.disable();
      }
      this.props.mapState.map.off("draw:created", this.objectDrawn);

    } else if (this.state.action === EDIT_ACTION) {
      if (this.state.mapSelectionLayer) {
        this.state.mapSelectionLayer.editing.disable();
      }
    }
    // Enable contour layer clicks
    MapUtils.addMapClickForOutputLayer(this.props.mapState.map);

    this.setState({ action: null });
  };

  drawShape = () => {

    if (this.state.action === DRAW_ACTION) {
      this.cancel();
      return;
    }

    // If Editing state - apply changes made
    if (this.state.action === EDIT_ACTION) {
      this.cancel();
      if (this.state.mapSelectionLayer) {
        var geoJson = this.getGeoJson(this.state.mapSelectionLayer);
        this.props.setGeoJson(geoJson);
      }
      return;
    }

    // Marker locations are not editable, but re-drawn so skip the normal flow
    if (this.state.mapSelectionLayer && this.state.shape !== "MARKER") {

      var editingLayer = this.state.mapSelectionLayer.editing.enable();

      if (this.state.shape === "LINE") {
        // Remove existing hooks from the editing layer
        editingLayer.removeHooks();
        // Clear the vertices handlers
        editingLayer._verticesHandlers = [];

        // Extend the normally used PolyVerticesEdit handler, and override the method that creates middle marker
        L.Edit.LineVerticesEdit = L.Edit.PolyVerticesEdit.extend({
          _createMiddleMarker: function (marker1, marker2) {
            // Override the method, although 'private' (defined with underscore)
            // In order not to create middle vertices in a line source
          },
        });
        // Create the new handler, add it to the editing layer, and add hooks on it
        var handler = new L.Edit.LineVerticesEdit(editingLayer._poly, editingLayer.latlngs[0], editingLayer._poly.options.poly);
        editingLayer._verticesHandlers.push(handler);
        handler.addHooks();
      }

      // Disable contour layer clicks
      MapUtils.removeMapClickForOutputLayer(this.props.mapState.map);

      this.setState({
        action: EDIT_ACTION,
        layerCopy: this.getGeoJson(this.state.mapSelectionLayer),
      });
    } else {

      // If marker has been drawn and user hits edit - allow the user to re-select
      // the marker location rather than edit it by dragging.
      // Remove the layer if it exists
      if (this.state.mapSelectionLayer !== null && this.state.shape === "MARKER") {
        this.props.mapState.map.removeLayer(this.state.mapSelectionLayer);
      }

      // Define you draw handler somewhere where you click handler can access it. N.B. pass any draw options into the handler
      var polygonDrawer = this.state.drawShape.enable();
      // Assumming you have a Leaflet map accessible
      this.props.mapState.map.on("draw:created", this.objectDrawn);

      // Disable contour layer clicks
      MapUtils.removeMapClickForOutputLayer(this.props.mapState.map);

      this.setState({
        action: DRAW_ACTION,
      });
    }
  };

  getGeoJson = (layer) => {
    if (layer === null) {
      return null;
    }

    var geoJson = layer.toGeoJSON();
    if (layer.getRadius && layer.getRadius() !== null) {
      geoJson.properties.shapeType = "circle";
      geoJson.properties.radius = layer.getRadius();
    }
    return geoJson;
  };

  handleMarkerCoordinatesFormInput = (lonLatCoordinates) => {
    if (this.props.shape !== "MARKER") {
      return
    }
    const geoJsonObject = {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": lonLatCoordinates
      }
    }

    // Update the parent component with the new geoJson object
    this.props.setGeoJson(geoJsonObject)

    // Update the marker icon on the map
    this.props.mapState.map.removeLayer(this.state.mapSelectionLayer)
    const mapSelectionLayer = this.getLayer("MARKER", geoJsonObject).addTo(this.props.mapState.map)
    this.props.mapState.map.flyTo([lonLatCoordinates[1], lonLatCoordinates[0]], 15);
    this.setState({
      mapSelectionLayer: mapSelectionLayer
    })
  }

  objectDrawn = (event) => {
    var layer = event.layer;
    this.cancel();
    this.props.mapState.map.addLayer(layer);

    var geoJson = this.getGeoJson(layer);

    this.setState({ mapSelectionLayer: layer });
    this.props.setGeoJson(geoJson);
  };

  mapLayerAdded = (layer) => {
    // If the current state action is DRAW or EDIT - remove map click from output layers
    if (this.state.action === DRAW_ACTION || this.state.action === EDIT_ACTION) {
      // Disable contour layer clicks
      MapUtils.removeMapClickForOutputLayer(this.props.mapState.map);
    }
  }

  componentDidMount() {
    // Add a 'layeradd' event listener to leaflet map, in order to detect
    // time slider change, which adds a new layer to the map (if it didn't exist before)
    // so that the map click listener can be disabled on that new layer
    this.props.mapState.map.on('layershow', this.mapLayerAdded);
  }

  componentWillUnmount() {
    this.cancel();
    if (this.state.mapSelectionLayer !== null) {
      this.props.mapState.map.removeLayer(this.state.mapSelectionLayer);
    }

    // Remove the listener added in componentDidMount
    this.props.mapState.map.off('layeradd', this.mapLayerAdded);
  }

}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    mapState: store.mapState,
    userState: store.userState,
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(ShapeCreateEdit);
