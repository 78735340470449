/*
    ./client/components/App.jsx
*/
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Actions } from "../../../services/keyBuild/actions.jsx";
import { withTranslation } from 'react-i18next';
import KeyBuildsDisplay from './keyBuildsDisplay.jsx';
import KeyBuildCreation from './keyBuildCreation.jsx';
import PanelWrapper from '../../common/panelWrapper.jsx';
import './styles.scss';

class KeyBuildsPanel extends React.Component {

    getContent = (t) => {
        if (this.props.keyBuildState.editing != null) {
            var title = t("keyBuild.createKeyBuild");
            if (this.props.keyBuildState.editing.id) {
                title = t("keyBuild.editKeyBuild");
            }
            return (
                <PanelWrapper
                    key={"keyBuild" + this.props.keyBuildState.editing.id}
                    title={title}
                    cancel={this.props.cancelEdit}
                >
                    <KeyBuildCreation object={this.props.keyBuildState.editing} />
                </PanelWrapper>
            );
        }
        return (
            <PanelWrapper key="keyBuild" title={t("keyBuild.keyBuilds")} cancel={this.props.cancel} >
                <KeyBuildsDisplay finish={this.props.cancel} />
            </PanelWrapper>
        )
    }

    render() {
        var { t } = this.props;

        return (
            <div className="keyBuilds-panel">
                {this.getContent(t)}
            </div>
        )
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        keyBuildState: store.keyBuildState,
        ...props
    }
}

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        cancelEdit: () => {
            dispatch(Actions.cancelEdit());
        }
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(KeyBuildsPanel);