import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button, Progress } from 'semantic-ui-react';
import { SimulationActions } from "../../../services/simulation/actions.jsx";
import { DataUtil } from '../../common/DataUtil';
import "../sidebar.scss";
import CancelSimulationModal from './cancelSimulationModal.jsx';
import TableCellTimer from './tableCellTimer.jsx';


class SimulationsDisplay extends React.Component {

    state = {
        showCancelModal: false,
        simulationId: null
    }

    clickSimulation = (simulationId) => {
        if (this.props.simulationState.selectedSimulation != null) {
            // If currently selected simulation is clicked again - deselect it
            if (this.props.simulationState.selectedSimulation.id === simulationId) {
                this.props.selectSimulation(null);
                return;
            }
        }
        this.props.selectSimulation(simulationId);
    }

    getSimulationTable = (requiredStatuses, getLayout) => {
        var { t } = this.props;

        let sims = this.props.simulationState.simulations.filter(function (sim) {
            return requiredStatuses.includes(sim.status);
        });

        if (sims.length === 0) {
            return (
                <table className="table-display">
                    <tbody>
                        <tr><td>{t("sidebar.noData")}</td></tr>
                    </tbody>
                </table>
            )
        } else {
            return (
                <div className="table-display-div">
                    <table className="table-display">
                        <tbody>
                            {this.makeSimTable(sims, getLayout)}
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    makeSimTable = (filteredSims, getLayout) => {
        let simsDiv = [];
        for (let sim in filteredSims) {
            let simulation = filteredSims[sim] // eslint-disable-line security/detect-object-injection
            simsDiv.push(
                <tr
                    className={(this.props.simulationState.selectedSimulation &&
                        this.props.simulationState.selectedSimulation.id === simulation.id)
                        ? "active simulation-row"
                        : "simulation-row"}
                    key={simulation.status + simulation.id}
                    onClick={(e) => this.clickSimulation(simulation.id)}
                >
                    {getLayout(simulation)}
                </tr>
            )
        }
        return simsDiv
    }

    getFinishedSimsLayout = (simulation) => {
        return (
            <>
                <td key={simulation.id + "name"} className="simulation-name">{simulation.name}</td>
                <td key={simulation.id + "time"} className="simulation-timer">
                    {DataUtil.secondsToDurationString(simulation.computationTimeElapsed / 1000)}
                </td>
                <td key={simulation.id + "model"} className="simulation-model">{simulation.modelType}</td>
            </>
        )
    }

    getUnstartedSimsLayout = (simulation) => {
        return (
            <>
                <td key={simulation.id + "name"} className="simulation-name">{simulation.name}</td>
                <td key={simulation.id + "model"} className="simulation-model">{simulation.modelType}</td>
            </>
        )
    }

    getRunningSimsLayout = (simulation) => {
        var { t } = this.props;

        let progressCell = null;
        if (simulation.status === "RUNNING") {
            progressCell = (
                <td className="simulation-progress">
                    <div className="ua-progress">
                        <TableCellTimer startTime={simulation.startTime} />
                        <Progress percent={simulation.progress} active className="simulation-progress" />
                    </div>
                </td>
            )
        } else {
            // Otherwise it is a PENDING status
            progressCell = (
                <td className="simulation-progress-column">
                    <div className="ua-progress">
                        <div className="simulation-timer">{t('simulation.runStatus.QUEUED')}</div>
                        <Progress percent={0} active className="simulation-progress" />
                    </div>
                </td>
            )
        }
        return (
            <>
                <td key={simulation.id + "name"} className="simulation-name">{simulation.name}</td>
                {progressCell}
                <td className="cancel-sim">
                    <Button
                        type="button"
                        icon="cancel"
                        onClick={() => this.cancelSimulation(simulation.id)}
                        className="cancel-simulation"
                    />
                </td>

                <CancelSimulationModal
                    showCancelModal={this.state.showCancelModal}
                    simulationId={this.state.simulationId}
                    scenarioId={this.props.scenarioState.scenario.id}
                    changeCancelState={this.changeCancelState}
                />
            </>
        )
    }

    getErrorSimsLayout = (simulation) => {
        return (
            <>
                <td key={simulation.id + "name"} className="simulation-name">{simulation.name}</td>
                <td key={simulation.id + "error"}>{simulation.errorMessage}</td>
            </>
        )
    }

    cancelSimulation = (simulationId) => {
        this.setState({ showCancelModal: true, simulationId: simulationId });
    }

    changeCancelState = () => {
        this.setState({ showCancelModal: false })
    }

    render() {
        var { t } = this.props;

        return (
            <div >
                <div className="simulation-status-title">{t("sidebar.completed")}:</div>
                {this.getSimulationTable(["FINISHED"], this.getFinishedSimsLayout)}

                <div className="simulation-status-title">{t("sidebar.notStarted")}:</div>
                {this.getSimulationTable(["NOT_STARTED"], this.getUnstartedSimsLayout)}

                <div className="simulation-status-title">{t("sidebar.running")}:</div>
                {this.getSimulationTable(["RUNNING", "PENDING"], this.getRunningSimsLayout)}

                <div className="simulation-status-title">{t("sidebar.didNotRun")}:</div>
                {this.getSimulationTable(["FAILED", "CANCELLED"], this.getErrorSimsLayout)}
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        simulationState: store.simulationState,
        scenarioState: store.scenarioState,
        ...props
    };
};

/*
* Maps properties to dispatch methods to send actions to the store reducers
*/
const mapDispatchToProps = (dispatch) => {
    return {
        selectSimulation: (simulationId) => {
            dispatch(SimulationActions.selectSimulation(simulationId));
        }
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SimulationsDisplay);
