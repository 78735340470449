import L from 'leaflet';
import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { CustomIcon } from '../map/marker/customIcon';
import { ImageURLs } from '../utils/ImagesUtils';


export class KeyBuildStyles {

    static getStyle = () => {

        return {
            color: 'grey',
            fillColor: 'yellow',
            opacity: 0.6,
            weight: 3,
            fillOpacity: 0.4
        }
    }

    static getMarker = (object, center, selectLocation, selectBuilding, buildingId) => {
        return (
            <GeoJSON id={object.id}
                key={object.id + "-" + object.lastUpdated + 'marker'}
                data={{
                    type: "Point",
                    coordinates: [center.lng, center.lat]
                }}
                pointToLayer={(feature, latlng) => {
                    let marker = new L.Marker(latlng, { icon: new CustomIcon(ImageURLs.BUILDING) });

                    marker.on('click', function (e) {
                        var location = [e.latlng.lat, e.latlng.lng];
                        var position = e.containerPoint;
                        selectLocation({ location, position });
                        selectBuilding(buildingId);
                    });
                    return marker;
                }}
            />
        )
    }
}
