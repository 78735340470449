import React from "react";
import { Form, Input } from "semantic-ui-react";
import "./styles.scss";

export default class InputFieldWithInteger extends React.Component {
  state = {
    value: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
        value: props.value,
      };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value) {
      this.setValue(this.props.value, false);
    }
  };

  setValue = (value, propagateToProps) => {
    // Validate that the input is a number if, if it's a null input set it to 0.
    if(value === null || value === undefined){
      value = 0;
    }

    value = Math.round(value);
    if (isNaN(value)) {
      console.warn("Cannot parse input to a number", value);
      return;
    }
    if (propagateToProps) {
      // Convert to default units; call method to setValue to inform parent of changes
      this.setState({ value: value });
      this.props.setValue(value);
    } else {
      // Convert from default to current units
      this.setState({ value: value });
    }
  };

  render() {
    return (
      <Form.Field inline={this.props.inline} required={this.props.required}
        error={this.props.error}>
        <label>{this.props.label}</label>
        <Input
          className={"input-bar"}
          value={this.state.value}
          onChange={(e) => this.setValue(e.target.value, true)}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
        />
        {this.props.error ? <div className="ui pointing above prompt label">
          {this.props.error}
        </div> : null}
      </Form.Field>
    );
  }
}
