import Requests from '../requests';
/*
 * action types
 */

export const FETCH_BEGIN = "FETCH_AUDITS_BEGIN";
export const FETCH_SUCCESS = "FETCH_AUDITS_SUCCESS";
export const FETCH_FAILURE = "FETCH_AUDOTS_FAILURE";

export const CLEAR = 'CLEAR_AUDITS';

export class Actions {
  /*
   * action creators
   */

  static clear() {
    return {
      type: CLEAR
    }
  }

  static fetchBegin = () => ({
    type: FETCH_BEGIN,
  });

  static fetchSuccess = (audits, fetchTime) => ({
    type: FETCH_SUCCESS,
    payload: { audits, fetchTime },
  });

  static fetchFailure = (error) => ({
    type: FETCH_FAILURE,
    payload: { error },
  });

  static fetchAudits = (scenarioId, fromTime) => {
    let url = "/audits/scenario/" + scenarioId;
    // Fetch audits with a 2sec delay (in case last time fetched during when the audits were being created)
    // This might bring back audits that are present in the store already but they'll be filtered out by unique ID
    const fetchTime = new Date(new Date().getTime() - 3000);
    if (fromTime) {
      let queryParam = { time: fromTime };
      url = url + '?' + new URLSearchParams(queryParam);
    }

    return (dispatch) => {
      dispatch(this.fetchBegin());
      return Requests.get(url)
        .then((audits) => {
          dispatch(this.fetchSuccess(audits, fetchTime));
          return audits;
        })
        .catch((error) => {
          console.error("Error occurred fetching audits ", error);
          dispatch(this.fetchFailure(error));
        });
    };
  };
}
