export const ImageURLs = Object.freeze({
    // AOIs
    DEFAULT_AOI: '/images/icons/default-aoi.png',
    STAGING_AREA: '/images/icons/staging-area.png',
    BASE: '/images/icons/base.png',
    ICP: '/images/icons/icp.png',
    ENTRY_ROUTE: '/images/icons/entry-route.png',
    EXIT_ROUTE: '/images/icons/exit-route.png',
    HAZARD_AREA: '/images/icons/hazard-area.png',

    // Incidents/Sources
    EXPLOSIVE: '/images/icons/explosive-marker.png',
    ATP45: '/images/icons/atp45-marker.png',
    CHEMICAL: '/images/icons/chem-marker.png',
    BIOLOGICAL: '/images/icons/bio-marker.png',
    RADIOLOGICAL: '/images/icons/rad-marker.png',

    // Buildings
    BUILDING: '/images/icons/building-marker.png',

    // Users
    GREEN_USER: '/images/icons/user-grn.png',
    ORANGE_USER: '/images/icons/user-ora.png',
    RED_USER: '/images/icons/user-red.png'
});