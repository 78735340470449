import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ContourEditForm from "./contourEditForm.jsx";
import ContourOutputDisplay from "./contourOutputDisplay.jsx";

class OutputOptions extends React.Component {
  constructor(props) {
    super(props);

  }

  checkForValidIncident = () => {
    let incidentsForCurrentSimulation = this.props.incidentState.incidents.filter(
      (inc) => {
        return (
          inc.simulationId == this.props.simulationState.selectedSimulation.id
        );
      }
    );
    if (incidentsForCurrentSimulation.length > 0) {
      return false;
    }
    return true;
  };

  render() {

    if (this.props.contourConfigState.isEditingContourOption) {
      return (
        <ContourEditForm />
      );
    }
    return (
      <>
        <ContourOutputDisplay
          checkForValidIncident={() => this.checkForValidIncident()}
        />
      </>
    );
  }
}

/*
 * Maps state from the store to properties used by this class
 */
const mapStateToProps = (store, props) => {
  return {
    simulationState: store.simulationState,
    contourConfigState: store.contourConfigState,
    metState: store.metState,
    incidentState: store.incidentState,
    resultsState: store.resultsState,
    ...props,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(OutputOptions);
