import React from "react";
import units from "./units.json";

/**
 * Define different unit conversion schemes.
 */
export default class UnitConverter extends React.Component {

  /**
   * Convert between different units by a multiplicative factor.
   */
  static convertMultiplicative = (value, currentUnit, newUnit) => {
    return value * currentUnit.convert / newUnit.convert;
  };

  /**
   * Convert temperature units.
   */
  static convertTemperature = (value, currentUnit, newUnit) => {
    const temperatureUnitsList = units["TEMPERATURE"];

    let nonwhitespace = /[^\s]/;
    if(value === null || value === undefined || !value.toString().match(nonwhitespace)) {
      return null;
    }

    let floatValue = parseFloat(value);
    if(isNaN(floatValue)) {
      return null;
    }

    // Convert to Kelvin if the unit is not Kelvin already.
    let kelvinValue;
    switch(currentUnit.key) {
      case "celsius":
        kelvinValue = floatValue + 273.15;
        break;
      case "fahrenheit":
        kelvinValue = (floatValue + 459.67) * 5 / 9;
        break;
      default:
        kelvinValue = floatValue;
    }

    // Stupid hack to round the number and drop any trailing zeros. This is required when entering values in fahrenheit
    // to avoid any floating point weirdness that might happen when values are propagated to the parent component (which
    // triggers a re-render) causing a convertion to Kelvin and back again.
    let roundTemperature = temperature => parseFloat(temperature.toFixed(3).toString());

    // Convert Kelvin to new unit.
    switch(newUnit.key) {
      case "celsius":
        return roundTemperature(kelvinValue - 273.15);
      case "fahrenheit":
        return roundTemperature(kelvinValue * 9 / 5 - 459.67);
      default:
        // Don't round values being converted to K.
        return kelvinValue;
    }
  };
}
