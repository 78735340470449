import {
    SIMULATION_MET_PROFILE_TYPE, CANCEL_EDIT, CLEAR, CREATE_MET, EDIT_MET, FETCH_MET_SERVICES_BEGIN,
    FETCH_MET_SERVICES_SUCCESS, FETCH_MET_SERVICES_FAILURE, REMOVE_MET, SET_MET_SERVICE_BEGIN,
    SET_MET_SERVICE_FAILURE, SET_MET_SERVICE_SUCCESS, SET_MET_CONFIG_SUCCESS, UPDATE_MET,
    FETCH_MET_FOR_SIMULATION_BEGIN, FETCH_MET_FOR_SIMULATION_SUCCESS, FETCH_MET_FOR_SIMULATION_FAILURE
} from './actions.jsx';
import { FETCH_METS_BEGIN, FETCH_METS_SUCCESS, FETCH_METS_FAILURE } from '../common/actions.jsx';

/**
 * Reducer to handle met actions and global state
 */
export class MetReducer {

    static metInfo(state, action) {

        const initialState = {
            metProfiles: {},
            metConfigs: {},
            loading: false,
            setting: false,
            editing: false, // this will be false, null, or a met object
            availableMetServices: [],
            error: null
        }

        switch (action.type) {
            case CLEAR:
                return initialState;
            case CANCEL_EDIT:
                state.editing = null;
                return Object.assign({}, state, state);
            case UPDATE_MET:
                var newState = Object.assign({}, state, {
                    editing: Object.assign({}, state.editing, action.payload)
                });

                // Set the editted met onto scenario mets 
                var editedMet = newState.editing;
                newState.metProfiles[editedMet.id] = editedMet;

                // No longer editing met so reset it to null
                newState.editing = null;

                return newState;
            case FETCH_MET_FOR_SIMULATION_SUCCESS:
                // The new met profiles are the old met profiles for any other simulation, and the 
                // current met profiles for the specified simulation
                const simulationId = action.payload.simulationId;
                const newMetProfilesList = [
                    ...Object.values(state.metProfiles).filter(profile => profile.simulationId !== simulationId),
                    ...action.payload.metProfiles
                ];
                const newMetProfiles = Object.fromEntries(newMetProfilesList.map(profile => [profile.id, profile]));
                
                let newMetConfigs = state.metConfigs;
                if (action.payload.metConfigs.length > 0) {
                    newMetConfigs = {
                        ...state.metConfigs,
                        ...Object.fromEntries(action.payload.metConfigs.map(metConfig => [metConfig.simulationId, metConfig]))
                    };
                }
                
                return {
                    ...state,
                    metProfiles: newMetProfiles,
                    metConfigs: newMetConfigs,
                    loading: false,
                };
            case CREATE_MET:
                return Object.assign({}, state, {
                    editing: Object.assign({}, state.editing, action.payload)
                });
            case EDIT_MET:
                return Object.assign({}, state, {
                    editing: Object.assign({}, state.editing, state.metProfiles[action.payload])
                });
            case REMOVE_MET:
                delete state.metProfiles[action.payload];
                return Object.assign({}, state, state);
            case FETCH_METS_SUCCESS:
                var metProfiles = {};
                var metConfigs = {};

                // Loop though the met and turn them into a map of id to object
                for (var i = 0; i < action.payload.metProfiles.length; i++) {
                    let profile = action.payload.metProfiles[i]; // eslint-disable-line security/detect-object-injection
                    if (profile.metProfileType === SIMULATION_MET_PROFILE_TYPE) {
                        metProfiles[profile.id] = profile;
                    }
                }

                // Loop though the met services and arrange data
                for (var i = 0; i < action.payload.metConfigs.length; i++) {
                    let config = action.payload.metConfigs[i]; // eslint-disable-line security/detect-object-injection
                    metConfigs[config.simulationId] = config;
                }

                return Object.assign({}, state, {
                    loading: false,
                    editing: null,
                    metProfiles: metProfiles,
                    metConfigs: metConfigs
                });
            case FETCH_METS_BEGIN:
            case FETCH_MET_FOR_SIMULATION_BEGIN:
            case FETCH_MET_SERVICES_BEGIN:
                return Object.assign({}, state, {
                    loading: true,
                    error: null
                });
            case FETCH_METS_FAILURE:
            case FETCH_MET_FOR_SIMULATION_FAILURE:
            case FETCH_MET_SERVICES_FAILURE:
                return Object.assign({}, state, {
                    loading: false,
                    editing: null,
                    error: action.payload.error,
                });
            case FETCH_MET_SERVICES_SUCCESS:
                return Object.assign({}, state, {
                    loading: false,
                    editing: null,
                    availableMetServices: action.payload
                });
            case SET_MET_SERVICE_SUCCESS:
                return Object.assign({}, state, {
                    setting: false,
                    editing: null,
                    metConfigs: {
                        ...state.metConfigs,
                        // The action payload is not a full met config for this action
                        [action.payload.simulationId]: {
                            ...state.metConfigs[action.payload.simulationId],
                            ...action.payload
                        }
                    }
                });
            case SET_MET_SERVICE_BEGIN:
                return Object.assign({}, state, {
                    setting: true,
                    error: null
                });
            case SET_MET_SERVICE_FAILURE:
                return Object.assign({}, state, {
                    setting: false,
                    editing: null,
                    error: action.payload.error,
                });
            case SET_MET_CONFIG_SUCCESS:
                return Object.assign({}, state, {
                    loading: false,
                    editing: null,
                    metConfigs: {
                        ...state.metConfigs,
                        [action.payload.simulationId]: action.payload
                    }
                });
            default:
                return state || initialState;
        }
    }
}