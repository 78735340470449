/*
    ./client/components/App.jsx
*/
import geojsonArea from '@mapbox/geojson-area';
import L from 'leaflet';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RadialChart } from 'react-vis';
import { compose } from 'redux';
import { Form } from 'semantic-ui-react';
import 'whatwg-fetch';
import { Utils } from '../../../services/utils/utils.jsx';
import InputFieldWithUnits from '../../common/inputFieldWithUnits.jsx';
import { MapUtils } from '../../utils/MapUtils.jsx';
import './styles.scss';

class MeasuringTool extends React.Component {

    state = {
        measure: null,
        geoJson: null,
        layer: null,
        hint: false,
        population: null,
        showPopulationBr: false,
        error: null,
        drawing: false
    }

    constructor(props) {

        super(props);
        const { t } = this.props;

        this.measureTypes = [
            { key: 'line', text: t('measuringTool.dropdown.line'), value: 'LINE' },
            { key: 'path', text: t('measuringTool.dropdown.path'), value: 'PATH' },
            { key: 'polygon', text: t('measuringTool.dropdown.polygon'), value: 'POLYGON' },
            { key: 'circle', text: t('measuringTool.dropdown.circle'), value: 'CIRCLE' }
        ]
    }

    fetchPopulationInBounds = (geoJson) => {
        var { t } = this.props;

        Utils.fetchPopulation(geoJson, (pop) => {
            this.setState({ population: pop })
        },
            () => {
                // If errored - null previous population results, so that the breakdown is not shown
                this.setState({ error: t("measuringTool.failedToGetPopulation"), population: null })
            })
    }

    selectTool = (tool) => {

        if (this.state.measure === null && tool === null) {
            return;
        }
        var shapeOptions = {
            shapeOptions: {
                color: '#f7af09',
                weight: 8
            }
        }

        var leafletShape;
        if (tool === 'PATH') {
            leafletShape = new L.Draw.Polyline(this.props.mapState.map, shapeOptions);
        } else if (tool === 'POLYGON') {
            leafletShape = new L.Draw.Polygon(this.props.mapState.map, shapeOptions);
        } else if (tool === 'LINE') {
            L.Draw.Polyline.Line = L.Draw.Polyline.extend({
                addVertex: function (latlng) {
                    var markersLength = this._markers.length;
                    // markersLength must be greater than or equal to 2 before intersections can occur

                    if (markersLength >= 2 && !this.options.allowIntersection && this._poly.newLatLngIntersects(latlng)) {
                        this._showErrorTooltip();
                        return;
                    }
                    else if (this._errorShown) {
                        this._hideErrorTooltip();
                    }

                    this._markers.push(this._createMarker(latlng));

                    this._poly.addLatLng(latlng);

                    if (this._poly.getLatLngs().length === 2) {
                        this._map.addLayer(this._poly);
                    }

                    this._vertexChanged(latlng, true);
                    markersLength = this._markers.length;
                    if (markersLength == 2) {
                        this._fireCreatedEvent();
                        this.disable();
                    }
                }
            });

            leafletShape = new L.Draw.Polyline.Line(this.props.mapState.map, shapeOptions);
        } else if (tool === 'CIRCLE') {
            leafletShape = new L.Draw.Circle(this.props.mapState.map, shapeOptions);
        }

        if (this.state.layer) {
            this.props.mapState.map.removeLayer(this.state.layer);
        }

        this.cancel();

        this.setState({
            measure: tool,
            leafletShape: leafletShape,
            layer: null,
            geoJson: null,
            drawing: true,
            error: null,
            population: null
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.leafletShape !== this.state.leafletShape && this.state.leafletShape != null) {
            this.drawShape();
        }
    }

    cancel = () => {
        // If currently drawing - remove the old listener and disable it
        if (this.state.drawing) {
            if (this.state.leafletShape) {
                this.state.leafletShape.disable();
            }
            this.props.mapState.map.off('draw:created', this.objectDrawn);

            // Enable the contour layer map clicks
            MapUtils.addMapClickForOutputLayer(this.props.mapState.map);
        }
    }

    drawShape = () => {

        // Define you draw handler somewhere where you click handler can access it. N.B. pass any draw options into the handler
        var polygonDrawer = this.state.leafletShape.enable();

        // Assumming you have a Leaflet map accessible
        this.props.mapState.map.on('draw:created', this.objectDrawn);

        // Disable contour layer map clicks
        MapUtils.removeMapClickForOutputLayer(this.props.mapState.map);
    }

    objectDrawn = (event) => {
        var layer = event.layer;
        this.cancel();
        this.props.mapState.map.addLayer(layer);

        var geoJson = this.getGeoJson(layer);

        if (this.state.measure === "POLYGON" || this.state.measure === "CIRCLE" && !Utils.OFFLINE_MAPS) {
            this.fetchPopulationInBounds(geoJson);
        }

        this.setState({
            layer: layer,
            geoJson: geoJson,
            drawing: false
        });
    }

    getGeoJson = (layer) => {
        if (layer === null) {
            return null;
        }

        var geoJson = layer.toGeoJSON();

        if (layer.getRadius && layer.getRadius() !== null) {
            geoJson.properties.shapeType = "circle";
            geoJson.properties.radius = layer.getRadius();
        }
        return geoJson;
    }

    mapLayerShown = () => {
        // If the currently drawing - remove map click from output layers
        if (this.state.drawing) {
            // Disable contour layer clicks
            MapUtils.removeMapClickForOutputLayer(this.props.mapState.map);
        }
    }

    componentDidMount() {
        // Add a 'layeradd' event listener to leaflet map, in order to detect
        // time slider change, which adds a new layer to the map (if it didn't exist before)
        // so that the map click listener can be disabled on that new layer
        this.props.mapState.map.on('layershow', this.mapLayerShown);
    }

    componentWillUnmount() {
        this.cancel();
        if (this.state.layer !== null) {
            this.props.mapState.map.removeLayer(this.state.layer);
        }
    }

    showAdditionalParameters = () => {
        this.setState({
            showAdditionalParameters: !this.state.showAdditionalParameters,
        });
    };

    render() {
        const { t } = this.props;

        return (
            <div className="content-body measuring-tool-card">
                <Form className="ua-form">
                    <Form.Field >
                        <Form.Select
                            fluid
                            required={true}
                            label={t('measuringTool.measure')}
                            options={this.measureTypes}
                            placeholder={t('measuringTool.selectMeasure')}
                            value={this.state.measure}
                            onChange={(e, data) => this.selectTool(data.value)}
                            onClick={(e) => this.selectTool(null)}
                        />
                    </Form.Field>
                    {this.getDescription(t)}
                </Form>
            </div>
        )
    }

    getDescription = (t) => {
        if (this.state.drawing && !this.state.geoJson) {
            return <div>{t("measuringTool.startMeasure")}</div>
        }
        if (!this.state.drawing && this.state.geoJson) {
            return (
                <div className={"shape-content " + this.state.measure.toLowerCase()}>
                    {this.getArea()}
                    {this.getPerimeter()}
                    {this.getRadius()}
                    {!Utils.OFFLINE_MAPS ? this.getPopulation() : null}
                    {!Utils.OFFLINE_MAPS ? this.getPopulationChart() : null}
                </div>
            )
        }
    }

    getArea = () => {
        const { t } = this.props;
        if (this.state.geoJson !== null) {
            var geoJson = this.state.geoJson;
            var area;
            if (geoJson.properties && geoJson.properties.radius) {
                //This is circle -
                area = Math.pow(geoJson.properties.radius, 2) * Math.PI;
            }
            else if (geoJson.geometry.type === "Point" || geoJson.geometry.type === "LineString") {
                return null;
            } else {
                area = geojsonArea.geometry(geoJson.geometry);
            }
            return (
                <Form.Field>
                    <InputFieldWithUnits label={t('sidebar.area')} unit="m2" value={Math.ceil(area)} type="AREA" readOnly />
                </Form.Field>
            )
        }
        return null;
    }

    getPerimeter = () => {
        const { t } = this.props;
        if (this.state.geoJson !== null) {
            var geoJson = this.state.geoJson;
            var text = t("measuringTool.perimeter");
            var length = 0;

            if (geoJson.properties && geoJson.properties.radius) {
                //This is circle -
                length = geoJson.properties.radius * 2 * Math.PI;
                text = t("measuringTool.circumference");
            }
            else if (geoJson.geometry.type === "Point") {
                return null;
            }
            else if (geoJson.geometry.type === "Polygon") {
                var previousPoint = null;
                geoJson.geometry.coordinates[0].forEach((latLng) => {
                    var lLatLon = L.latLng(latLng[1], latLng[0]);
                    if (previousPoint !== null) {
                        length = length + Math.abs(previousPoint.distanceTo(lLatLon));
                    }
                    previousPoint = lLatLon;
                });
            } else {
                text = t("measuringTool.length");
                var previousPoint = null;
                geoJson.geometry.coordinates.forEach((latLng) => {
                    var lLatLon = L.latLng(latLng[1], latLng[0]);
                    if (previousPoint !== null) {
                        length = length + Math.abs(previousPoint.distanceTo(lLatLon));
                    }
                    previousPoint = lLatLon;
                });
            }

            return (
                <Form.Field>
                    <InputFieldWithUnits label={t(text)} unit="m" value={Math.ceil(length)} type="SIZE" readOnly />
                </Form.Field>
            )
        }
        return null;
    }

    getRadius = () => {
        const { t } = this.props;
        if (this.state.geoJson !== null) {
            var geoJson = this.state.geoJson;
            if (geoJson.properties && geoJson.properties.radius) {

                var radius = geoJson.properties.radius;
                return (
                    <Form.Field>
                        <InputFieldWithUnits label={t('sidebar.radius')} unit="m" value={Math.ceil(radius)} type="SIZE" readOnly />
                    </Form.Field>
                )
            }
        }
        return null;
    }

    getPopulation = () => {
        const { t } = this.props;
        if (this.state.error) {
            return (
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            label={t('measuringTool.population')}
                            key={t('measuringTool.noData')}
                            value={t('measuringTool.noData')}
                            readOnly
                        />
                    </Form.Field>
                </Form.Group>

            )
        }

        if (this.state.population) {
            let totalPopulation = 0;
            if(this.state.population['population']) {
                totalPopulation = Math.floor(this.state.population['population']);
            }
            return (
                <Form.Group>
                    <Form.Field>
                        <Form.Input
                            label={t('measuringTool.population')}
                            key={this.state.population.population}
                            value={totalPopulation}
                            readOnly
                        />
                    </Form.Field>
                </Form.Group>
            )
        }
        return null;
    }

    getPopulationChart = () => {
        var { t } = this.props;

        if (this.state.population) {
            var extraClass = "";
            if (this.state.showPopulationBr) {
                extraClass = "show";
            }

            var data = [];
            for (var m in this.state.population) {
                if (m !== 'population') {
                    var object = this.state.population[m]; // eslint-disable-line security/detect-object-injection
                    // data.push({ x: m, y: object, angle: object, label: m, value: object });
                    if (Math.floor(object) != 0) {
                        data.push({ angle: Math.floor(object), label: m });
                    }
                }
            }
            if (data.length == 0) {
                return null;
            }

            return (
                <div className="population data-breakdown">
                    <div className="header" onClick={() => this.setState({ showPopulationBr: !this.state.showPopulationBr })}>
                        <label>
                            {t("sidebar.populationBreakdown")}
                            <i className={"fas fa-caret-down " + extraClass}></i>
                        </label>
                    </div>
                    <div className={"plot-holder " + extraClass}>
                        {/* <XYPlot height={300} width={300} xType="ordinal">
                                <VerticalGridLines />
                                <HorizontalGridLines />
                                <XAxis tickLabelAngle={-45} />
                                <YAxis />
                                <VerticalBarSeries data={data} />
                            </XYPlot> */}
                        <RadialChart
                            className="population-chart"
                            data={data}
                            showLabels
                            labelsRadiusMultiplier={1.1}
                            innerRadius={80}
                            radius={140}
                            onValueMouseOver={v => this.setState({ hint: v })}
                            onSeriesMouseOut={v => this.setState({ hint: false })}
                            labelsStyle={{ fontSize: 16, fill: 'black' }}
                            style={{ stroke: 'darkgrey', strokeWidth: 2 }}
                            width={300}
                            height={300}
                            padAngle={0.04} />
                        <div className="chart-hint">
                            {this.getHint()}
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }

    getHint = () => {
        if (this.state.population[this.state.hint.label]) {
            return "Value: " + Math.floor(this.state.population[this.state.hint.label]);
        }
        return null
    }
}

/*
* Maps state from the store to properties used by this class
*/
const mapStateToProps = (store, props) => {
    return {
        mapState: store.mapState,
        userState: store.userState,
        ...props
    }
}

export default compose(withTranslation(), connect(mapStateToProps))(MeasuringTool);