import {
	CLEAR, FETCH_BEGIN, FETCH_FAILURE, FETCH_SCENARIO_SUCCESS,
	PERSIST_SCENARIO_BEGIN, PERSIST_SCENARIO_FAILURE, PERSIST_SCENARIO_SUCCESS,
	SELECT_SCENARIO, UPDATE_SCENARIO_BEGIN, UPDATE_SCENARIO_FAILURE, UPDATE_SCENARIO_SUCCESS
} from './actions.jsx';

export class ScenarioReducer {

	static scenarioInfo(state, action) {

		const initialState = {
			scenario: null,
			loading: false,
			creatingScenario: false,
			error: null,
			scenarios: [],
			actionType: action.type,
			updatingScenario: false
		}

		switch (action.type) {
			case CLEAR:
				return initialState;
			case UPDATE_SCENARIO_SUCCESS:
				return Object.assign({}, state, {
					scenario: action.payload,
					updatingScenario: false,
					actionType: action.type
				});
			case UPDATE_SCENARIO_BEGIN:
				return Object.assign({}, state, {
					updatingScenario: true,
					actionType: action.type
				});
			case UPDATE_SCENARIO_FAILURE:
				return Object.assign({}, state, {
					updatingScenario: false,
					error: action.payload,
					actionType: action.type
				});
			case SELECT_SCENARIO:
				return Object.assign({}, state, {
					scenario: action.payload,
					actionType: action.type
				});
			case PERSIST_SCENARIO_BEGIN:
				return Object.assign({}, state, {
					creatingScenario: true,
					error: null,
					actionType: action.type
				});
			case PERSIST_SCENARIO_SUCCESS:
				return Object.assign({}, state, {
					creatingScenario: false,
					scenario: Object.assign({}, state.scenario, action.payload.scenario),
					error: null,
					actionType: action.type
				});
			case PERSIST_SCENARIO_FAILURE:
				return Object.assign({}, state, {
					creatingScenario: false,
					error: action.payload.error,
					actionType: action.type
				});
			case FETCH_SCENARIO_SUCCESS:
				// Sort the scenarios by creation date 
				const sortedScenarios = action.payload.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
				return Object.assign({}, state, {
					loading: false,
					error: null,
					scenarios: sortedScenarios,
					actionType: action.type
				});
			case FETCH_BEGIN:
				return Object.assign({}, state, {
					loading: true,
					error: null,
					actionType: action.type
				});
			case FETCH_FAILURE:
				return Object.assign({}, state, {
					loading: false,
					error: action.payload.error.message,
					actionType: action.type
				});
			default:
				return state || initialState;
		}
	}

}