import {
	ADD_KEY_BUILD_BEGIN, ADD_KEY_BUILD_FAILURE, ADD_KEY_BUILD_SUCCESS,
	CANCEL_EDIT,
	DELETE_KEY_BUILD_BEGIN, DELETE_KEY_BUILD_SUCCESS, DELETE_KEY_BUILD_FAILURE,
	CLEAR, CREATE_KEY_BUILD, EDIT_KEY_BUILD,
	FETCH_KEY_BEGIN, FETCH_KEY_FAILURE, FETCH_KEY_SUCCESS,
	UPDATE_KEY_BUILD, UPDATE_KEY_BUILD_SUCCESS, UPDATE_KEY_BUILD_BEGIN, UPDATE_KEY_BUILD_FAILURE
} from './actions.jsx';

export class KeyBuildReducer {

	static keyBuildsInfo(state, action) {

		const initialState = {
			keyBuilds: {},
			editing: null,
			editMode: false,
			loading: false,
			adding: false,
			updating: false,
			actionType: "",
			error: null
		}

		switch (action.type) {
			case CLEAR:
				return initialState;
			case CANCEL_EDIT:
				state.editing = null;
				return Object.assign({}, state, state);
			case FETCH_KEY_BEGIN:
				var newState = Object.assign({}, state, {
					loading: true,
					error: null
				})
				return newState;
			case FETCH_KEY_FAILURE:
				var newState = Object.assign({}, state, {
					loading: false,
					error: action.payload.error,
				})
				return newState;
			case ADD_KEY_BUILD_SUCCESS:
				var newState = Object.assign({}, state, {
					editing: Object.assign({}, state.editing, action.payload),
					adding: false,
					updating: false,
					actionType: action.type
				})

				// Set the editted keyBuild onto scenario keyBuilds (this handles new keyBuild,
				// and replacing the original keyBuild)
				var editedKeyBuild = newState.editing;
				newState.keyBuilds[editedKeyBuild.id] = editedKeyBuild;

				// No longer editing an keyBuilds so reset it to null
				newState.editing = null;
				newState.editMode = false;

				return newState;
			case UPDATE_KEY_BUILD_SUCCESS:
				var newState = Object.assign({}, state, {
					editing: Object.assign({}, state.editing, action.payload),
					adding: false,
					updating: false,
					actionType: action.type
				})

				// Set the editted keyBuild onto scenario keyBuilds (this handles new keyBuild,
				// and replacing the original keyBuild)
				var editedKeyBuild = newState.editing;
				newState.keyBuilds[editedKeyBuild.id] = editedKeyBuild;

				// No longer editing an keyBuilds so reset it to null
				newState.editing = null;
				newState.editMode = false;

				return newState;
			case ADD_KEY_BUILD_BEGIN:
				return Object.assign({}, state, { adding: true })
			case ADD_KEY_BUILD_FAILURE:
				return Object.assign({}, state, { adding: false, actionType: action.type });
			case UPDATE_KEY_BUILD_BEGIN:
				return Object.assign({}, state, { updating: true })
			case UPDATE_KEY_BUILD_FAILURE:
				return Object.assign({}, state, { updating: false, actionType: action.type })
			case FETCH_KEY_SUCCESS:
				var keyBuilds = {};

				// Loop though the met and turn them into a map of id to object
				for (var i = 0; i < action.payload.keyBuildings.length; i++) {
					let object = action.payload.keyBuildings[i]; // eslint-disable-line security/detect-object-injection
					keyBuilds[object.id] = object;
				}

				return Object.assign({}, state, {
					loading: false,
					error: null,
					editing: null,
					keyBuilds: keyBuilds
				})
			case UPDATE_KEY_BUILD:
			case CREATE_KEY_BUILD:
				return Object.assign({}, state, {
					editing: Object.assign({}, state.editing, action.payload),
					editMode: false
				})
			case EDIT_KEY_BUILD:
				return Object.assign({}, state, {
					editing: Object.assign({}, state.editing, state.keyBuilds[action.payload]),
					editMode: true
				})
			case DELETE_KEY_BUILD_FAILURE:
				var newState = Object.assign({}, state, {
					loading: false,
					error: action.payload.error,
				})
				return newState;
			case DELETE_KEY_BUILD_SUCCESS:
				var newState = Object.assign({}, state)
				delete newState.keyBuilds[action.payload];
				return newState;
			default:
				return state || initialState;
		}
	}

}