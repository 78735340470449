import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Message, Table } from "semantic-ui-react";
import Requests from '../../../services/requests';
import "../appHeader.scss";

class AccessLogPanel extends React.Component {

    state = {
        log: [],
        error: null
    };

    componentDidMount() {
        const { t } = this.props;
        if (this.props.userState.user && this.props.userState.user.role === "ROLE_ADMIN") {
            Requests.get('/accounts/access-logs')
                .then(response => {
                    if (response) {
                        this.setState({ log: response.reverse() });
                    } else {
                        this.setState({ error: t('messages.errorCheckLogs') });
                    }
                })
                .catch(err => {
                    console.log("Caught error, ", err);
                    this.setState({ error: t('messages.errorCheckLogs') });
                });
        }
    }

    getUsersTable = () => {
        const { t } = this.props;
        if (this.state.log.length > 0) {
            return (
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell >
                                {t("title.date")}
                            </Table.HeaderCell>
                            <Table.HeaderCell >
                                {t("title.email")}
                            </Table.HeaderCell>
                            <Table.HeaderCell >
                                {t("title.type")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>{this.getRows()}</Table.Body>
                </Table>
            );
        }
    }

    getRows = () => {
        const { t } = this.props;
        let logRows = [];

        for (var m in this.state.log) {
            let ind = m;
            let entry = this.state.log[m]; // eslint-disable-line security/detect-object-injection
            logRows.push(
                <Table.Row
                    key={ind}
                >
                    <Table.Cell>{new Date(entry.date).toLocaleString()}</Table.Cell>
                    <Table.Cell>{entry.userEmail}</Table.Cell>
                    <Table.Cell>{t("profile.accessType." + entry.type)}</Table.Cell>
                </Table.Row>
            );
        }
        return logRows;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="user-window">
                <div className="login-actions-content">
                    <div className="sources-table users-table">{this.getUsersTable()}</div>
                </div>
                {this.state.error ?
                    (
                        <Message negative className="ua-error">
                            <Message.Header>{t('app.error')}</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                    ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        userState: store.userState,
    }
}

export default compose(withTranslation(), connect(mapStateToProps))(AccessLogPanel);
